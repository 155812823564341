import { DrawerType } from '@/hooks/usePageDrawer/types'

// 需要隐藏的菜单，因权限问题还不能直接在【超级管理员 - 菜单设置】中隐藏的
export const hideMenuList = [
	'feeDimensionValue', // 费用管理 - 维值
]

// 性别
export const sexList = [
	{ value: 0, label: '未知' },
	{ value: 1, label: '男' },
	{ value: 2, label: '女' },
]
// 检核结果
export const checkResult = [
	{ value: 1, label: '合格' },
	{ value: 2, label: '不合格' },
]
// 产品种类
export const pruductTypes = [
	{ value: 1, label: '矿泉水' },
	{ value: 2, label: '纯净水' },
]
// 是否在职
export const workStatusList = [
	{ value: 1, label: '在职' },
	{ value: 2, label: '离职' },
]

// 默认状态：1、启用，2、停用
export const statusList = [
	{ value: 1, label: '启用', tag: { color: '#2BA471', bgColor: '#E3F9E9' } },
	{ value: 2, label: '停用', tag: { color: '#F6685D', bgColor: '#FFF0ED' } },
]

// 计划状态
export const planStatus = [
	{ value: 1, label: '待启用', tag: { color: '#FA9550', bgColor: '#FFF1E9' } },
	{ value: 2, label: '启用', tag: { color: '#2BA471', bgColor: '#E3F9E9' } },
	{ value: 3, label: '停用', tag: { color: '#F6685D', bgColor: '#FFF0ED' } },
	{ value: 4, label: '调整中', tag: { color: '#3783FF', bgColor: '#EBF0FC' } },
]

// 终端级别(1-A类；2-B类；3-C类；4-D类；)
export const levels = [
	{ value: 1, label: 'A类' },
	{ value: 2, label: 'B类' },
	{ value: 3, label: 'C类' },
	{ value: 4, label: 'D类' },
]

// 是否重点终端(1-是；2-否)
export const Emphasis = [
	{ value: 1, label: '是' },
	{ value: 2, label: '否' },
]

// 渠道类型(1-商超；2-特通；3-流通)
export const channelTypes = [
	{ value: 1, label: '商超' },
	{ value: 2, label: '特通' },
	{ value: 3, label: '流通' },
]

// 终端类型()
export const terminalTypes = (id: number) => {
	switch (id) {
		case 1:
			return [
				{ value: 1, label: '大卖场1' },
				{ value: 2, label: '连锁便利店2' },
				{ value: 3, label: '超级市场3' },
				{ value: 4, label: '小超市4' },
			]
		case 2:
			return [
				{ value: 1, label: '连锁药店1' },
				{ value: 2, label: '景点2' },
				{ value: 3, label: '餐饮3' },
				{ value: 4, label: '酒店4' },
				{ value: 5, label: '油站5' },
				{ value: 6, label: '其他6' },
			]
		case 3:
			return [
				{ value: 1, label: '自选商店1' },
				{ value: 2, label: '报刊亭2' },
				{ value: 3, label: '面包店3' },
				{ value: 4, label: '冰摊4' },
				{ value: 5, label: '传统零售店5' },
			]
		default:
			return []
	}
}

export const resonOption = [
	{ value: 1, label: '门店倒闭' },
	{ value: 2, label: '淡季退回' },
	{ value: 3, label: '不合作' },
	{ value: 4, label: '新冰箱置换' },
	{ value: 5, label: '其他' },
]

// 是否枚举（全局可用）
export const judgmentType = [
	{ id: 1, name: '是' },
	{ id: 2, name: '否' },
]

export const supplyTypes = [
	{ id: 1, name: '工厂供货' },
	{ id: 2, name: '渠道供货' },
]
//审核类型，1-审核通过；2-审核未通过
export const examineType = [
	{ value: 2, label: '审核通过' },
	{ value: 3, label: '审核未通过' },
]

// 设备绑定状态(1-启用, 2-停用)
export const deviceBindStatus = [
	{ value: 1, label: '开启' },
	{ value: 2, label: '关闭' },
]

//审核状态，1-审核通过；2-审核未通过
export const examineStatus = [
	{ value: 1, label: '待审核' },
	{ value: 2, label: '审核通过' },
	{ value: 3, label: '审核未通过' },
]

//供货商 1-经销商；2-配送商；3-二批商
export const suppliers = [
	{ value: 1, label: '经销商' },
	{ value: 2, label: '配送商' },
	{ value: 3, label: '二批商' },
]

//订单状态 1-待提交；2-已提交；3-已作废
export const orderStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '已提交' },
	{ value: 3, label: '已作废' },
]

//申请单状态 1-待提交；2-已提交；3-已作废
export const approvalStatus = [
	{ value: 1, label: '暂存' },
	{ value: 2, label: '通过' },
	{ value: 3, label: '不通过' },
	{ value: 4, label: '审批中' },
	{ value: 5, label: '关闭' },
]
export const editStatus = [
	{ value: 1, label: '草稿' },
	{ value: 2, label: '完成' },
]
// 费用管理-费用科目 状态
export const feeSubjectStatus = [
	{ value: 1, label: '启用' },
	{ value: 2, label: '停用' },
]

// 费用管理-费用科目 共享上级费用
export const isSharePreviousFeeSubject = [
	{ value: 1, label: '是' },
	{ value: 2, label: '否' },
]

// 费用管理-费用维度 系统预设
// 费用管理-费用科目 共享上级费用
export const isSystemPreset = [
	{ value: 1, label: '是' },
	{ value: 2, label: '否' },
]

// 预算表
export const controlIntervalStatus = [
	{ value: 1, label: '年度' },
	{ value: 2, label: '半年度' },
	{ value: 3, label: '季度' },
	{ value: 4, label: '月度' },
]
export const controlStrategyStatus = [
	{ value: 1, label: '警告' },
	// { value: 2, label: '审核' },
	{ value: 3, label: '禁止' },
]

// 产品单位
export const productUnit = [
	{
		label: '箱',
		value: 1,
	},
	{
		label: '瓶',
		value: 2,
	},
	{
		label: '桶',
		value: 3,
	},
	{
		label: '包',
		value: 4,
	},
]
//审批状态
export const approveStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 4, label: '已通过' },
	{ value: 5, label: '已驳回' },
	{ value: 6, label: '已撤回' },
]

// 审批状态及各自业务状态枚举集合
const statusChangeMap = {
	0: {
		color: '',
		name: '',
	},
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#366EF4',
		name: '审批中',
	},
	4: {
		color: '#2BA471',
		name: '已通过',
	},
	5: {
		color: '#F6685D',
		name: '已驳回',
	},
	6: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const budgetaryStatusChangeMap = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#366EF4',
		name: '审批中',
	},
	4: {
		color: '#2BA471',
		name: '已通过',
	},
	5: {
		color: '#F6685D',
		name: '已驳回',
	},
	6: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const applicationOddStatusChangeMap = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#366EF4',
		name: '审批中',
	},
	4: {
		color: '#2BA471',
		name: '已通过',
	},
	5: {
		color: '#F6685D',
		name: '已驳回',
	},
	20: {
		color: '#A6A6A6',
		name: '已作废',
	},
	6: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const activeStateChange = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#366EF4',
		name: '审批中',
	},
	4: {
		color: '#F6685D',
		name: '已驳回',
	},
	5: {
		color: '#8593AE',
		name: '待开始',
	},
	6: {
		color: '#3783FF',
		name: '进行中',
	},
	7: {
		color: '#A6A6A6',
		name: '已结束',
	},
	8: {
		color: '#A6A6A6',
		name: '已作废',
	},
	9: {
		color: '#894FDB',
		name: '已结案',
	},
	10: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const expenseActivityChange = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#2BA471',
		name: '已通过',
	},
	4: {
		color: '#A6A6A6',
		name: '已作废',
	},
	5: {
		color: '#366EF4',
		name: '审批中',
	},
	6: {
		color: '#F6685D',
		name: '已驳回',
	},
	7: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const policyStatusChangeMap = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#F6BE2C',
		name: '待审批',
	},
	3: {
		color: '#366EF4',
		name: '审批中',
	},
	4: {
		color: '#2BA471',
		name: '待开始',
	},
	5: {
		color: '#F6685D',
		name: '已驳回',
	},
	6: {
		color: '#2BA471',
		name: '进行中',
	},
	7: {
		color: '#2BA471',
		name: '已结束',
	},
	8: {
		color: '#A6A6A6',
		name: '已作废',
	},
	9: {
		color: '#8593AE',
		name: '已撤回',
	},
}
const policyUploadStatusChangeMap = {
	1: {
		color: '#2BA471',
		name: '已上报',
	},
	2: {
		color: '#A6A6A6',
		name: '已作废',
	},
}
const refridgeratorPlanChange = {
	1: {
		color: '',
		name: '待启用',
	},
	2: {
		color: '',
		name: '启用',
	},
	3: {
		color: '',
		name: '停用',
	},
	4: {
		color: '',
		name: '调整中',
	},
}

// 渠道库存状态及颜色
const channelInventoryRecordChange = {
	1: {
		color: '#FA9550',
		name: '待提交',
	},
	2: {
		color: '#2BA471',
		name: '已提交',
	},
	3: {
		color: '#A6A6A6',
		name: '已作废',
	},
}
/**
 * 活动系统预设 状态
 */
const activitySystemPreset = {
	1: {
		color: '',
		name: '系统预置-是',
	},
	2: {
		color: '',
		name: '不是系统预置执行项才',
	},
}

/**
 * 状态的处理 文案和颜色处理
 * @param list 原状态
 * @param status 过滤状态
 */
const approvalStatusFilterFn = (list, status) => {
	return Object.entries(status)
		.filter((item) => list?.includes(+item[0]))
		.reduce((prev, item) => {
			prev[item[0]] = item[1]
			return prev
		}, {})
}

/**审批状态及颜色 */
export const ApprovalStatusMap = approvalStatusFilterFn([0, 1, 2, 3, 4, 5, 6], statusChangeMap)
// console.log(`output->ApprovalStatusMap`, ApprovalStatusMap)
//预算申请
export const budgetaryStatusMap = approvalStatusFilterFn([1, 2, 3, 4, 5, 6], budgetaryStatusChangeMap)
// 申请单
export const applicationOddStatusMap = approvalStatusFilterFn([1, 2, 3, 4, 5, 6, 20], applicationOddStatusChangeMap)
//政策申请
export const policyStatusMap = approvalStatusFilterFn([1, 2, 3, 4, 5, 6, 7, 8, 9], policyStatusChangeMap)
// 政策上报
export const policyUploadStatusMap = approvalStatusFilterFn([1, 2], policyUploadStatusChangeMap)

// 四种核销(核销页面审批状态没有已作废)
export const cancellationStatusMap = {
	...approvalStatusFilterFn([1, 2, 3, 4, 5, 6], statusChangeMap),
}

/**冰箱协议管理明细表状态及颜色 */
export const ChannelApprovalStatusMap = {
	...approvalStatusFilterFn([1, 2, 3, 4, 5, 6], statusChangeMap),
	98: {
		color: '#A6A6A6',
		name: '已作废',
	},
}

/**活动方案状态 */
export const AcctiveStatusMap = approvalStatusFilterFn([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], activeStateChange)
/**活动状态 */
export const expenseActivityStatusMap = approvalStatusFilterFn([1, 2, 3, 4, 5, 6, 7], expenseActivityChange)
/**库存调拨状态及颜色 */
export const StockTransferStatusMap = {
	...approvalStatusFilterFn([1, 2, 3, 4, 5, 6], statusChangeMap),
	11: {
		color: '#A6A6A6',
		name: '已作废',
	},
}
// approvalStatusFilterFn([1, 2, 3, 4, 5, 6], statusChangeMap)
/**活动管理 系统是否预设  活动执行项目 */
export const ActivitySystemPresetMap = approvalStatusFilterFn([1, 2], activitySystemPreset)

/**年度冰箱计划的状态 */
export const RefridgeratorPlanStatusMap = approvalStatusFilterFn([2, 3, 4], refridgeratorPlanChange)

/**冰箱订单状态及颜色 */
export const refridgeratorApprovalStatusMap = {
	...approvalStatusFilterFn([1, 2, 3, 4, 5, 6], statusChangeMap),
	99: {
		color: '#F6685D',
		name: '关闭',
	},
}

/**渠道库存上报 */
export const channelInventoryRecordStatusMap = approvalStatusFilterFn([1, 2, 3], channelInventoryRecordChange)

/**结案申请-结案审批状态 */
export const ActivityOverApplicationStatusMap = approvalStatusFilterFn([0, 1, 2, 3, 4, 5, 6], statusChangeMap)

/**合同列表审批状态及颜色 */
export const ContractApprovalStatusMap = {
	0: {
		color: '#ffffff',
		name: '', //空状态
	},
	1: {
		color: '#FA9550',
		name: '待生成合同',
	},
	2: {
		color: '#FA9550',
		name: '待接收方签署',
	},
	3: {
		color: '#F6BE2C',
		name: '待发起方签署',
	},
	4: {
		color: '#F6685D',
		name: '接收方退回',
	},
	5: {
		color: '#2BA471',
		name: '已生效',
	},
	6: {
		color: '#F6685D',
		name: '即将失效',
	},
	7: {
		color: '#A6A6A6',
		name: '已失效',
	},
	8: {
		color: '#A6A6A6',
		name: '已作废',
	},
	9: {
		color: '#8593AE',
		name: '作废中',
	},
	10: {
		color: '#DE47AC',
		name: '发起方撤回',
	},
}

export const addType = [
	{ value: 1, label: '期初预算' },
	{ value: 2, label: '预算调拨' },
	{ value: 3, label: '预算调整' },
]
export const newAddType = [
	{ value: 1, label: '期初预算' },
	{ value: 2, label: '预算编制' },
	{ value: 3, label: '预算调整' },
]
//冰箱投放状态
export const useStatusList = [
	{ value: 1, label: '未投放' },
	{ value: 2, label: '待确认投放' },
	{ value: 3, label: '已投放' },
	{ value: 4, label: '报废' },
	{ value: 5, label: '遗失' },
	{ value: 6, label: '失控' },
	{ value: 7, label: '故障' },
]

//冰箱使用状态
export const FridgeUseStatusList = [
	{ value: 1, label: '正常' },
	{ value: 2, label: '异常' },
]

//是否维修完成
export const isFinish = [
	{ value: 1, label: '未完成' },
	{ value: 2, label: '已完成' },
]

//冰箱维修审批状态
export const maintenanceApproval = [
	{ value: 1, label: '待填写预计维修费用' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批通过' },
	{ value: 4, label: '审批不通过' },
	{ value: 5, label: '待填写实际维修费用' },
	{ value: 6, label: '维修完成' },
]

//冰箱协议管理审批状态
export const protocolApproval = [
	{ value: 1, label: '审批中' },
	{ value: 2, label: '通过' },
	{ value: 3, label: '不通过' },
	{ value: 4, label: '已作废' },
]
// 费用预算状态
export const feeBudgetaryStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '已通过' },
]

export const feeApplicationOddStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '已通过' },
	{ value: 6, label: '已作废' },
]
export const cancellationApproveStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '已通过' },
]
// 政策申请
export const policyApplicationStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '待开始' },
	{ value: 6, label: '进行中' },
	{ value: 7, label: '已结束' },
	{ value: 8, label: '已作废' },
]
// 促销政策
export const promotionPolicyOddtypeList = [
	{ value: 1, label: '直营' },
	{ value: 2, label: '分销' },
]

export const promotionPolicyTypeIsSystem = [
	{ value: 1, label: '是' },
	{ value: 0, label: '否' },
]
// 政策申请
export const dealerList = [
	{ value: 1, label: '商超' },
	{ value: 2, label: '特通' },
	{ value: 3, label: '流通' },
]
//签收状态
export const shipStatus = [
	{ value: 1, label: '未签收' },
	{ value: 2, label: '已签收' },
]
// 费用申请单核销状态
export const feeApplicationCancellationStatus = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审批' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '已通过' },
]
// 费用核销方式
export const feeCancellationStatus = [
	{ value: 1, label: '按申请单核销' },
	{ value: 2, label: '按活动核销' },
	{ value: 3, label: '按促销政策核销' },
	{ value: 4, label: '自定义核销' },
]
//促销类型
export const policyPromotionTypeList = [
	{ value: 1, label: '出厂搭赠' },
	{ value: 2, label: '促销搭赠' },
]

//促销类型
//促销类型
export const policyPromotionWayList1 = [
	{ value: 3, label: '搭赠(矿水)' },
	{ value: 2, label: '搭赠(纯水)' },
]
export const policyPromotionWayList2 = [
	{ value: 1, label: '现金' },
	{ value: 3, label: '搭赠(矿水)' },
	{ value: 2, label: '搭赠(纯水)' },
]

//其他主页的核销情况
export const policyPromotionCancellationStatus = [
	{ value: 1, label: '未核销' },
	{ value: 2, label: '部分核销' },
	{ value: 3, label: '核销完成' },
]

export const cancellationChangeStatus = [
	{ value: 1, label: '未核销' },
	{ value: 2, label: '已核销' },
	{ value: 3, label: '终止核销' },
]
export const promotionStatus = [
	{ value: 1, label: '未核销' },
	{ value: 2, label: '核销中' },
	{ value: 3, label: '已核销' },
]
export const policyPromotionCancellationStatu = [
	{ value: 1, label: '待提交' },
	{ value: 2, label: '待审核' },
	{ value: 3, label: '审批中' },
	{ value: 5, label: '已驳回' },
	{ value: 4, label: '已通过' },
]

/**公司性质 */
export const companyNatureMap = [
	{ value: 1, label: '母公司' },
	{ value: 2, label: '子公司' },
]

/**签署要求 */
export const signTypeMap = [
	{
		index: 0,
		checked: true,
		label: '签字',
		value: 'signature',
	},
	{
		index: 1,
		checked: true,
		label: '盖章',
		value: 'stamp',
	},
	{
		index: 2,
		checked: true,
		label: '骑缝章',
		value: 'ridingSeamBadge',
	},
	{
		index: 3,
		checked: true,
		label: '签署日期',
		value: 'signatureDate',
	},
]

/**签署方案类型 */
export const signPlanMap = [
	{
		label: '双方',
		value: 1,
	},
	{
		label: '仅发起方',
		value: 2,
	},
	{
		label: '仅接收方',
		value: 3,
	},
]

export enum BaseType {
	/**渠道客户类型 */
	CHANNEL = 'CHANNEL',
	/**终端客户类型 */
	TERMINAL = 'TERMINAL',
	/**ka客户类型 */
	KA = 'KA',
	/**预算表 */
	BUDGET = 'BUDGET',
	/**执行项目 */
	IMPLEMENTATION_PROJECTS = 'IMPLEMENTATION_PROJECTS',
}
/**
 * @description 高德地图全局枚举
 * @param ANDROIDKEY     Android平台（景田市场APP）
 * @param IOSKEY         IOS平台（景田市场APP）
 * @param WEBSERVICEKEY  web服务
 * @param WEBKEY         web端Key
 * @param WEBSECRETKEY   web端秘钥
 */
export enum Mapkeys {
	ANDROIDKEY = '3c5a099a6fd1b2ff9c4b943979b8cce8',
	IOSKEY = 'd36427dd7130e721960979b73f3bca60',
	WEBSERVICEKEY = 'd47d4569d76f2ab1cfd4f56c378007f7',
	WEBKEY = '5202b8694c6f3d5ddb3ff3d6a90c2e6a',
	WEBSECRETKEY = '7497f07c1e96fb71c932ded5d9305fcd',
}

// 无详情菜单抽屉
export const drawerMissing = [
	DrawerType.ACCOUNTMGR,
	DrawerType.CLEARBINDING,
	DrawerType.POSITIONMGR,
	DrawerType.ORGANIZATIONMGR,
	DrawerType.DATAPERMISSIONSMANAGEMENT,
	DrawerType.CUSTOMERLABEL,
	DrawerType.PERSONALCUSTOMERTAGS,
	DrawerType.UNIVERSALCUSTOMERTAGS,
	DrawerType.SALESAREA,
	DrawerType.FACTORYMANAGEMENT,
	DrawerType.REFRIGERATORBRAND,
	DrawerType.REFRIGERATORTYPE,
	DrawerType.ADVERTISINGSTICKERS,
	DrawerType.VISITLIST,
	DrawerType.VISITWORKFLOWCONFIG,
	DrawerType.LIVELYCHECK,
	DrawerType.COMPETITORACQUISITION,
	DrawerType.ATTENDANCERECORD,
	DrawerType.ROUTEMANAGEMENTS,
	DrawerType.ROUTESUMMARYS,
	DrawerType.WORKTRAJECTORYS,
	DrawerType.ASSETINSPECTIONFORM,
	DrawerType.REFRIGERATORADJUSTMENTDETAILS,
	DrawerType.REFRIGERATORTYPESHIPMENTSUMMARY,
	DrawerType.RECEIPTSCHEDULE,
	DrawerType.REFRIGERATORTYPESHIPMENTSUMMARY,
	DrawerType.REFRIGERATORAPPLICATIONORDERDETAILS,
	DrawerType.REFRIGERATORSIGNATURESUMMARY,
	DrawerType.SEALTYPE,
	DrawerType.SEAL,
	DrawerType.REGIONALFACTORIES,
	DrawerType.CHANNELRELATIONMAINTENANCE,
	DrawerType.CUSTOMERMONTHPICK,
	DrawerType.CUSTOMERMONTHSALES,
	DrawerType.FEESUBJECT,
	DrawerType.FEEDIMENSION,
	DrawerType.FEEAPPLICATIONTYPE,
	DrawerType.ACTIVITYEXECUTEPROJECT,
	DrawerType.ACTIVITYTYPE,
	DrawerType.PROMOTIONSALESREPORT,
	DrawerType.FEEDIMENSIONVALUE,
	DrawerType.ACTIVITYOVERAPPLICATION,
	DrawerType.OPERATIONLOG,
	DrawerType.DICTIONARYMANAGE,
	DrawerType.MESSAGECENTER,
	DrawerType.PLANIMPORTRECORD,
	DrawerType.PLANSUMMARY,
]

//固定标签
export const LABELCOLOR = {
	'#FFFFFF': { color: '#5A56FF', 'background-color': '#EBEDFC', 'border-color': '#EBEDFC' },
	'#3783FF': { color: '#3783FF', 'background-color': '#EBF0FC', 'border-color': '#EBF0FC' },
	'#00AF71': { color: '#00AF71', 'background-color': '#E3F9E9', 'border-color': '#E3F9E9' },
	'#FFCE40': { color: '#CA9600', 'background-color': '#FFFBBE', 'border-color': '#FFFBBE' },
	'#FF9946': { color: '#F86F00', 'background-color': '#FFEFDD', 'border-color': '#FFEFDD' },
	'#FF6559': { color: '#FF5447', 'background-color': '#FFEDEA', 'border-color': '#FFEDEA' },
	'#FF7CD4': { color: '#DE47AC', 'background-color': '#FFEEF5', 'border-color': '#FFEEF5' },
	'#AF76FF': { color: '#9F5BFF', 'background-color': '#F2EBFF', 'border-color': '#F2EBFF' },
	'#00B9FC': { color: '#00A1DB', 'background-color': '#EEF7FF', 'border-color': '#EEF7FF' },
	'#9C9C9C': { color: '#494D68', 'background-color': '#F2F2F2', 'border-color': '#F2F2F2' },
}
