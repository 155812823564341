<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'XZLTable',
})
</script>
<template>
	<el-table
		ref="tableRef"
		:scrollbar-always-on="true"
		v-bind="$attrs"
		@copy.native="handleCopy"
		@current-change="handleCurrentChange"
	>
		<!-- Table插槽 -->
		<template v-for="name in Object.keys($slots)" #[name]="scope">
			<slot v-if="['append', 'empty'].includes(name)" :name="name" v-bind="scope || {}"></slot>
		</template>

		<!-- Table无数据时 -->
		<template v-if="!$slots.empty" #empty>
			<empty-state type="list" />
		</template>

		<!-- 列 -->
		<template v-for="item in columns" :key="item.prop || item.type">
			<!-- selection(复选) || radio(单选) || index(索引) || expand(展开) -->
			<el-table-column
				v-if="item.type && ['selection', 'radio', 'index', 'expand'].includes(item.type)"
				v-bind="item"
				:fixed="item.fixed ?? true"
				:width="item.width ?? getTypeColumnWidth(item)"
				:align="item.align ?? 'center'"
				:reserve-selection="getReserveSelection(item)"
			>
				<!-- 展开 -->
				<template v-if="item.type == 'expand'" #default="scope">
					<component v-if="item.render" :is="item.render" v-bind="scope"></component>
					<slot v-else :name="item.type" v-bind="scope"></slot>
				</template>

				<!-- 单选 -->
				<template v-else-if="item.type == 'radio'" #default="scope">
					<el-radio
						:label="scope.row?.[$attrs?.rowKey]"
						v-model="activeRow[$attrs?.rowKey]"
						:disabled="getRowSelectable(scope)"
					>
						<span></span>
					</el-radio>
				</template>
			</el-table-column>

			<!-- 其他 -->
			<template v-else>
				<TableColumn v-if="showColumn(item)" :column="item">
					<template v-for="name in Object.keys($slots)" #[name]="scope">
						<slot :name="name" v-bind="scope || {}"></slot>
					</template>
				</TableColumn>
			</template>
		</template>
	</el-table>
</template>

<script setup lang="ts" name="XZLTable">
import { ref } from 'vue'
import { ElTable } from 'element-plus'
import { TableColumnProps } from './types'

import TableColumn from './tableColumn.vue'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'

interface Props {
	columns: TableColumnProps[]
}

const props = defineProps<Props>()
const emit = defineEmits(['current-change'])

defineOptions({
	inheritAttrs: false,
})

const tableRef = ref<InstanceType<typeof ElTable>>()
const activeRow = ref<any>({})

const showColumn = (item: TableColumnProps): boolean => {
	// 如果isShow属性未配置，则默认显示
	const isShow = item.isShow ?? true
	return (isShow && !item.type && item.prop && !item.isHidden) as boolean
}

// 多选是否可以分页勾选, 默认false
const getReserveSelection = (item: TableColumnProps): boolean => {
	if (typeof item.reserveSelection === 'boolean' && item.type === 'selection') {
		return item.reserveSelection
	}
	return false
}

// 获取单选的可选状态
const getRowSelectable = (scope) => {
	if (scope.column.type === 'radio') {
		return typeof scope.column?.selectable == 'function' ? !scope.column.selectable(scope.row) : false
	}
	return false
}

// 单选事件
const handleCurrentChange = (currentRow: any, oldRow: any): void => {
	const typeColumn = props.columns.find((item) => item.type === 'radio')

	if (!currentRow) {
		if (typeColumn && typeColumn.reserveSelection !== true) {
			activeRow.value = {}
			emit('current-change', currentRow, oldRow)
		}
		return
	}

	if (typeColumn && typeof typeColumn?.selectable === 'function') {
		const selectable = typeColumn.selectable(currentRow, null)
		if (!selectable && !!oldRow) {
			return
		}
	}
	activeRow.value = currentRow ?? {}
	emit('current-change', currentRow, oldRow)
}

// 获取功能类型列宽
const getTypeColumnWidth = (item: TableColumnProps) => {
	if (item.type === 'index') {
		return 60
	}
	return 50
}

// 解决快速点击三次表格内容文案，复制出来的内容尾缀携带空格
const handleCopy = (event) => {
	event.preventDefault()
	const selection = window.getSelection()
	const selectedText = selection.toString().replace(/\s+$/, '') // 移除尾部空格
	event.clipboardData.setData('text/plain', selectedText)
}

defineExpose({
	XZLTable: tableRef,
})
</script>

<style scoped lang="scss">
:deep(.el-radio) {
	.el-radio__label {
		padding-left: 0;
	}
}
:deep(.cell) {
	white-space: nowrap;
	user-select: text;
}
</style>

