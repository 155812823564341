import service from '@/utils/request'

// 获取表格基本数据
export const getDemandPlanList = (data: any) => {
	return service({
		url: '/demandPlan/getDemandPlanList',
		method: 'post',
		data,
	})
}
// 导入
export const importApi = (data: any) => {
	return service({
		url: '/demandPlan/importDemandPlan',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}
// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/demandPlan/getDemandPlanList',
		// responseType: 'blob',
		method: 'post',
		data,
	})
}
// 用id查询需求计划
export const findDemandPlan = (data: any) => {
	return service({
		// url: `/demandPlan/findDemandPlan`,
		url: `/demandPlan/getDemandPlanDetailList`,
		method: 'post',
		data,
	})
}

// 批量删除需求计划
export const deleteDemandPlanByIds = (data: any) => {
	return service({
		url: '/demandPlan/deleteDemandPlanByIds',
		method: 'DELETE',
		data,
	})
}
// 获取所有DemandPlanAuthority列表
export const getAllDemandPlanAuthority = (params: any) => {
	return service({
		url: '/demandPlanAuthority/getAllDemandPlanAuthority',
		method: 'get',
		params,
	})
}

// 获取所有渠道类型
export const getAllDemandPlanChannels = () => {
	return service({
		url: '/demandPlanChannels/getAllDemandPlanChannels',
		method: 'get',
	})
}
