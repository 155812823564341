<template>
	<!-- 没有头像，显示姓名最后两个字符 -->
	<div
		v-if="!src && avatar"
		class="flex items-center justify-center text-white"
		:style="{
			height: size + 'px',
			width: size + 'px',
			background: '#407ff9',
			borderRadius: '50%',
			fontSize: fontSize + 'px',
		}"
	>
		{{ avatar }}
	</div>
	<el-avatar
		v-else
		:class="{ default: !src }"
		:size="size"
		:src="avatar"
		v-bind="omit(props, ['src', 'name', 'fontSize', 'defaultIconSize'])"
	>
		<!-- 默认插槽 -->
		<slot v-if="$slots.default" name="default"></slot>

		<!-- 头像/用户名/插槽，都为空时，显示默认icon -->
		<el-icon v-else-if="!props.icon" :size="defaultIconSize">
			<UserFilled />
		</el-icon>
	</el-avatar>
</template>

<!-- 头像组件，涉及显示用户头像的都用此组件 -->
<script setup lang="ts">
import { computed } from 'vue'
import { AvatarProps } from 'element-plus'
import { omit } from 'lodash-es'
import { UserFilled } from '@element-plus/icons-vue'
import { getFullPath } from '@/utils/common'

interface Props extends Partial<AvatarProps> {
	// 用户名（如果没有头像地址，则显示用户名后两个字符）
	name?: string

	// 文本字体大小
	fontSize?: number

	// 默认icon大小
	defaultIconSize?: number
}

const props = withDefaults(defineProps<Props>(), {
	size: 36,
	fontSize: 12,
	defaultIconSize: 24,
})

const avatar = computed(() => {
	return props.src ? getFullPath(props.src) : props.name?.substring(props.name?.length - 2)
})
</script>
