<template>
	<el-collapse v-model="activeNames" @change="handleCollapseChange" class="XZLCollapseStyle" v-bind="$attrs">
		<slot></slot>
	</el-collapse>
</template>
<script setup lang="ts">
import { ref } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
	value: [String, Array],
})
const activeNames = ref(props.value)
const handleCollapseChange = (names) => {
	activeNames.value = names
	emit('update:modelValue', names)
}
</script>
