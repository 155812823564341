import BigNumber from 'bignumber.js'

/**
 * 加法
 * @param num { number }
 * @param rest { number }
 * @return {number}
 */
export const add = (num, ...rest) => {
	let bn = new BigNumber(num)

	rest.forEach((item) => {
		bn = new BigNumber(bn.plus(item))
	})
	bn = bn.isNaN() ? new BigNumber(0) : bn
	return bn.toNumber()
}

/**
 * 减法
 * @param num{ number }
 * @param rest{ number }
 * @return {number}
 */
export const minus = (num, ...rest) => {
	let bn = new BigNumber(num)

	rest.forEach((item) => {
		bn = new BigNumber(bn.minus(item))
	})
	bn = bn.isNaN() ? new BigNumber(0) : bn
	return bn.toNumber()
}

/**
 * 乘法
 * @param num{ number }
 * @param rest{ number }
 * @return {number}
 */
export const multiply = (num, ...rest) => {
	let bn = new BigNumber(num)

	rest.forEach((item) => {
		bn = new BigNumber(bn.multipliedBy(item))
	})
	bn = bn.isNaN() ? new BigNumber(0) : bn
	return bn.toNumber()
}

/**
 * 除法
 * @param num{ number }
 * @param rest{ number }
 * @return {number}
 */
export const divide = (num, ...rest) => {
	let bn = new BigNumber(num)

	rest.forEach((item) => {
		bn = new BigNumber(bn.dividedBy(item))
	})
	bn = bn.isNaN() ? new BigNumber(0) : bn
	return bn.toNumber()
}
