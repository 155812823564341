import { getFieldName } from './../../../view/workflow/workflowConfig/components/drawer/components/flowUser/index'
import { FieldItem } from './../../../config/EditorFields'
import { useAsyncState } from '@vueuse/core'
import { getCusDistributorList } from '@/api/serveApi/basicInformation/dealerMgr'
import { getCusKaManageList } from '@/api/serveApi/basicInformation/kaSystem'
import { getBaseDistributorList } from '@/api/serveApi/inventoryManagement/channelTransferRecord'
import { getTerminalManageList } from '@/api/serveApi/basicInformation/terminalMgr'
import { BaseType } from '@/config/Constantconfig'

import { getBudgetTableList } from '@/api/serveApi/feeManagement/budgetTable'
import { getActivityExeItemList } from '@/api/serveApi/activityManagement/activityExcuteProject'

/**渠道客户-经销商 */
const channelDistributors = {
	api: getCusDistributorList,
	searchProp: 'fieldValues',
	searchName: 'name',
	filters: [
		{ fieldName: 'name', fieldValues: [], operator: 'LIKE', fromType: 'text', placeholder: '客户名称' },
		{
			fieldName: 'saleAreaIds',
			fieldValues: [],
			operator: 'IN',
			fromType: 'select',
			placeholder: '销售区域',
			selectType: 5,
		},
		{ fieldName: 'address', fieldValues: [], operator: 'LIKE', fromType: 'text', placeholder: '客户地址' },
		{
			fieldName: 'channel',
			fieldValues: [],
			operator: 'IN',
			fromType: 'select',
			placeholder: '渠道客户类型',
			valueList: [], // 动态获取
		},
		{
			fieldName: 'duty_ids',
			fieldValues: [],
			operator: 'IN',
			fromType: 'select',
			selectType: 1,
			placeholder: '负责人',
		},
	],

	filterLabel: 'filters',
	defaultColumns: [
		{
			label: '渠道客户',
			prop: 'name',
		},
		{
			label: '渠道编码',
			prop: 'code',
		},
		{
			label: '销售区域',
			prop: 'saleAreaNames',
		},
		{
			label: '客户地址',
			prop: 'address',
		},
		{
			label: '渠道客户类型',
			prop: 'channelName',
		},
		{
			label: '负责人',
			prop: 'dutys',
		},
	],
}
/**所属KA */
const affiliationKA = {
	api: getCusKaManageList,
	searchProp: 'fieldValues',
	searchName: 'name',
	filters: [
		{ fieldName: 'name', fieldValues: undefined, operator: 'LIKE', fromType: 'text', placeholder: '请搜索KA系统名称' },
	],
	filterLabel: 'filters',
	defaultColumns: [
		{
			label: 'KA系统名称',
			prop: 'name',
		},
		{
			label: 'KA系统编号',
			prop: 'code',
		},
	],
}
/**终端客户 */
const terminalType = {
	api: getTerminalManageList,
	searchProp: 'fieldValues',
	searchName: 'name',
	filters: [
		{ fieldName: 'name', fieldValues: [], operator: 'LIKE', fromType: 'text', placeholder: '终端名称' },
		{
			fieldName: 'code',
			fieldValues: [],
			operator: 'LIKE',
			fromType: 'text',
			placeholder: '终端编号',
		},
	],
	filterLabel: 'filters',
	defaultColumns: [
		{
			label: '终端名称',
			prop: 'name',
		},
		{
			label: '终端编号',
			prop: 'code',
		},
	],
}
/**预算表 */
const budgetIdList = {
	api: getBudgetTableList,
	searchProp: 'fieldValues',
	searchName: 'budgetName',
	filters: [
		{ fieldName: 'budgetName', fieldValues: [], operator: 'LIKE', fromType: 'text', placeholder: '预算表名称' },
	],
	filterLabel: 'filters',
	defaultColumns: [
		{
			label: '预算表名称',
			prop: 'budgetName',
		},
		{
			label: '预算编号',
			prop: 'budgetCode',
		},
	],
}
/**执行项目 */
const implementationProjects = {
	api: getActivityExeItemList,
	searchProp: 'fieldValues',
	searchName: 'exeName',
	filters: [{ fieldName: 'exeName', fieldValues: [], operator: 'LIKE', fromType: 'text', placeholder: '执行项目名称' }],
	filterLabel: 'filters',
	defaultColumns: [
		{
			label: '执行项目名称',
			prop: 'exeName',
		},
		{
			label: '关联AI模型名称',
			prop: 'aiModelName',
		},
	],
}

/**定义常用类型及默认方式并增加可扩展 */
export const baseTypeOptions = {
	[BaseType.CHANNEL]: {
		base: channelDistributors,
	},
	[BaseType.KA]: {
		base: affiliationKA,
	},
	[BaseType.TERMINAL]: {
		base: terminalType,
	},
	[BaseType.BUDGET]: {
		base: budgetIdList,
	},
	[BaseType.IMPLEMENTATION_PROJECTS]: {
		base: implementationProjects,
	},
}

/**抛出对应常用类型信息 */
export const selectTableTypeOptions = (type: BaseType) => baseTypeOptions[type] ?? { base: () => {} }

export const selectTableTypeOptionsBase = (type: BaseType) => baseTypeOptions[type]?.base ?? {}
