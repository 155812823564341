<template>
	<div class="flex justify-between">
		<el-button type="primary" @click="dialogVisible = !dialogVisible">
			<template #icon>
				<SvgIcon name="icon-screen" />
			</template>
			筛选
		</el-button>
		<div v-if="hasExposed" class="flex items-center cursor-pointer" @click="showFilter = !showFilter">
			<el-button link>
				<span class="mr-1">{{ showFilter ? '收起' : '展开' }}</span>
				<el-icon :class="{ 'rotate-180': showFilter }">
					<ArrowDown />
				</el-icon>
			</el-button>
		</div>
	</div>

	<!-- 外露 -->
	<div v-show="hasExposed && showFilter && isLoadReady" class="content-box mt-md">
		<div class="content-main grid grid-cols-2 gap-x-20 gap-y-md">
			<template v-for="(formItem, index) in formList" :key="formItem.fieldName + formItem.operator">
				<el-row v-if="formItem.isExposed" style="padding: 0">
					<!-- 第一个筛选条件 -->
					<el-col :span="6" class="form-title">
						<div class="el-input">
							<div class="el-input__wrapper w-full max-w">
								<div class="truncate">
									{{ formItem.name }}
								</div>
							</div>
						</div>
					</el-col>
					<!-- 第二个筛选条件 -->
					<el-col :span="6" class="form-title">
						<el-select
							class="w-full"
							v-model="formItem.operator"
							@change="selectChange(formItem)"
							filterable
							clearable
							:disabled="formItem.fieldName ? false : true"
							placeholder="请选择范围"
						>
							<el-option
								v-for="(item, index) in formItem.operatorList"
								:key="index"
								:label="item.label"
								:value="item.value"
							/>
						</el-select>
					</el-col>
					<!-- 第三个筛选条件 -->
					<el-col :span="12" class="pl-3">
						<FilterContent
							v-model="formList[index]"
							v-model:selectable="selectableData"
							:pageId="pageId"
							:fieldRules="fieldRules"
							:disabled="!formItem.operator"
							:firstName="formItem.name"
						></FilterContent>
					</el-col>
				</el-row>
			</template>

			<el-row
				class="justify-end"
				:class="{ 'col-span-2': formList.filter((item) => item.isExposed)?.length % 2 === 0 }"
			>
				<el-button type="primary" @click="handleSearch">筛选</el-button>
				<el-button @click="clearFilterVal">重置</el-button>
			</el-row>
		</div>
	</div>

	<!-- 弹窗 -->
	<el-dialog
		v-if="dialogVisible"
		v-model="dialogVisible"
		title="设置筛选"
		:before-close="handleClose"
		:close-on-click-modal="false"
		append-to-body
		modal-class="scress"
	>
		<div class="scress-context">
			<div style="z-index: 2">
				<el-button text size="small" @click="clearFilter">
					<el-icon color="red" :size="14">
						<Remove />
					</el-icon>
					<span style="color: darkgrey">且</span>
				</el-button>
			</div>
			<div class="scress-form">
				<el-form ref="ruleFormRef" :model="formList">
					<template v-for="(formItem, index) in formList" :key="index">
						<el-row class="form-row" :gutter="5">
							<!-- 第一个筛选条件 -->
							<el-col :span="5">
								<el-form-item :prop="`[${index}].fieldName`" :rules="rules.fieldName" class="flex">
									<el-button link @click="handleRemove(formItem, index)">
										<el-icon color="red" :size="14">
											<Remove />
										</el-icon>
									</el-button>
									<el-select
										class="flex-1 w-0 ml-sm"
										v-model="formItem.fieldName"
										placeholder="请选择字段"
										@change="fieldChange(formItem)"
										filterable
										clearable
									>
										<el-option v-for="item in fieldRules" :key="item.Field" :label="item.Name" :value="item.Field" />
									</el-select>
								</el-form-item>
							</el-col>
							<!-- 第二个筛选条件 -->
							<el-col :span="5">
								<el-form-item>
									<el-select
										class="w-full"
										v-model="formItem.operator"
										placeholder="请选择范围"
										:disabled="!formItem.fieldName"
										@change="selectChange(formItem)"
										filterable
										clearable
									>
										<el-option
											v-for="(item, index) in formItem.operatorList"
											:key="index"
											:label="item.label"
											:value="item.value"
										/>
									</el-select>
								</el-form-item>
							</el-col>
							<!-- 第三个筛选条件 -->
							<el-col :span="10">
								<FilterContent
									v-model="formList[index]"
									v-model:selectable="selectableData"
									:pageId="pageId"
									:fieldRules="fieldRules"
									:disabled="!formItem.fieldName || !formItem.operator"
									:firstName="formItem.fieldName"
								>
								</FilterContent>
							</el-col>
							<!-- 外露 -->
							<el-col :span="3">
								<el-form-item>
									<el-checkbox
										class="ml-md"
										label="外露"
										v-model="formItem.isExposed"
										@change="handleCheck(formItem, `[${index}].fieldName`)"
									/>
								</el-form-item>
							</el-col>
						</el-row>
					</template>
				</el-form>
				<div class="add">
					<el-button style="height: 30px" @click="handleAdd">
						<SvgIcon name="icon-add" :size="12" class="mr-xs" />
						添加
					</el-button>
				</div>
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="handleSearch">筛选</el-button>
				<el-button @click="clearFilterVal">重置</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, computed, watchEffect } from 'vue'
import { useVModel } from '@vueuse/core'
import { ElMessageBox, ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { Remove, ArrowDown } from '@element-plus/icons-vue'
import { cloneDeep, flattenDeep, isEqualWith } from 'lodash-es'

// import { useFormValue } from './useFormValue'
import { formatSearchField } from '@/utils/format'
import { InitFieldItem, OperatorType, SelectType, FromType, type FieldItemType, type FilterType } from './types'

import FilterContent from './filterContent.vue'

interface Props {
	isReady: boolean
	modelValue: any
	pageId: string
	searchParam: Record<string, any>
	searchField: any[]
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'filter-confirm', 'save-config'])
const pageConfig = useVModel(props, 'modelValue', emit)

const isLoadReady = ref<boolean>(false)
const isFirstLoad = ref<boolean>(true)
const isSearch = ref<boolean>(false)
const fieldRules = ref([])
const dialogVisible = ref<boolean>(false)
const showFilter = ref<boolean>(true)
const formList = ref<Array<FieldItemType>>([])
const selectableData = ref([])
const ruleFormRef = ref<FormInstance>()
const rules = reactive<FormRules>({
	fieldName: [{ required: true, message: '请选择字段', trigger: 'change' }],
})

// const { list } = useFormValue(formList)
// watch(
// 	() => list,
// 	(newVal) => {
// 	},
// 	{ deep: true }
// )

// 是否存在外露筛选项
const hasExposed = computed<boolean>(() => {
	return !!formList.value.find((item) => item.isExposed)
})

// 监听列表返回的searchField
watch(
	() => props.searchField,
	(newVal) => {
		if (newVal?.length && fieldRules.value.length === 0) {
			fieldRules.value = formatSearchField(newVal)
			// ISHidden【0：只在列表展示，1：列表和流程配置都需要展示】
			fieldRules.value = fieldRules.value.filter((item) => {
				return item.IsHidden === 0
			})
			if (pageConfig.value.fieldList?.length > 0) {
				setNewFormList(pageConfig.value.fieldList)
			}
		}
	},
	{ deep: true }
)

// 监听后端返回的筛选配置
watch(
	() => pageConfig.value?.fieldList,
	(newval) => {
		if (newval?.length > 0 && formList.value.length === 0) {
			if (fieldRules.value?.length > 0) {
				setNewFormList(newval)
			} else {
				formList.value.splice(0)
				formList.value.push(...newval)
			}
		}
	}
)

const unWatch = watchEffect(() => {
	if (props.isReady && !isSearch.value) {
		isLoadReady.value = true
		const filters = props.searchParam?.filters ?? []
		if (filters.length > 0) {
			filters.forEach((item) => {
				const current = formList.value?.find(
					(el) => el.fieldName === item.fieldName && el.operator === item.operator && !el.fieldValues?.length
				)
				// 如果记忆配置有该字段，则从缓存或初始化搜索参数中更新字段值
				if (current) {
					Object.assign(current, item)
				} else {
					const obj = fieldRules.value.find((el) => el.Field === item.fieldName)
					if (obj) {
						// 否则添加一个配置
						formList.value.push(
							Object.assign(new InitFieldItem(), item, {
								name: obj.Name,
								selectType: obj.SelectType,
								operatorList: obj.Operator,
								valueList: obj.Setting,
								fromType: obj.FromType,
								objectName: obj.ObjectName,
							})
						)
					}
				}
			})
		}

		if (formList.value.length === 0) {
			formList.value.push(new InitFieldItem())
		}

		unWatch()
	}
})

onMounted(() => {
	// formList.value.push(new InitFieldItem())
})

/**
 * 设置筛选列表
 * @description 将存储的筛选字段配置，与列表接口的 searchField 进行对比，如果 searchField 有变化，则筛选配置也实时变化
 * @param {Array} fieldList 存储的筛选字段配置
 *
 */
const setNewFormList = (fieldList: Array<FieldItemType>) => {
	// 只有第一次加载需要匹配
	if (!isFirstLoad.value) {
		return
	}
	isFirstLoad.value = false
	const list: Array<FieldItemType> = []
	fieldList.forEach((item) => {
		const obj = fieldRules.value.find((el) => el.Field === item.fieldName)
		if (obj) {
			list.push(
				Object.assign(new InitFieldItem(), item, {
					name: obj.Name,
					selectType: obj.SelectType,
					operatorList: obj.Operator,
					valueList: obj.Setting,
					fromType: obj.FromType,
					objectName: obj.ObjectName,
				})
			)
		}
	})
	// if (list.length === 0) {
	// 	list.push(new InitFieldItem())
	// }
	formList.value = list
}

// 筛选搜索
const handleSearch = async () => {
	isSearch.value = true
	// 筛选校验
	const valid = validate()
	if (!valid) {
		return
	}

	// 筛选出有筛选值的筛选项
	const list = cloneDeep(
		formList.value.filter((item: FieldItemType) => {
			// 条件为【为空、不为空】的，不需要筛选值。其他条件则需要过滤出有筛选值的数据
			if (
				[OperatorType.isNull, OperatorType.isNotNull, OperatorType.jsonIsNull, OperatorType.jsonIsNotNull].includes(
					item.operator
				)
			) {
				return true
			}
			// 是否有值
			return hasValue(item.fieldValues)
		})
	)

	// 设置筛选值缓存
	setStorage(list)

	// 将字段值转为string类型
	const paramsList = valueToString(list)

	// 特殊处理
	// paramsList.forEach((item) => {
	// 	// fromType为text文本以及条件为属于或不属于，需要将输入框的内容分割
	// 	if (item.fromType === FromType.text && [OperatorType.in, OperatorType.notIn].includes(item.operator)) {
	// 		let value: string = item.fieldValues[0] ?? ''
	// 		value = value.replace(/；/g, ';')
	// 		item.fieldValues = value.split(';')
	// 	}
	// })

	let data: FilterType[] = []
	if (paramsList.length > 0) {
		data = paramsList.map(({ fieldName, fieldValues, operator, fromType, objectName }) => {
			const obj = { fieldName, fieldValues, operator, fromType, objectName }
			if (!objectName) {
				delete obj.objectName
			}
			return obj
		})
	}

	// 判断筛选配置是否有修改
	const isChange = getConfigChanged()

	// 更新配置项
	pageConfig.value.fieldList = formList.value
		.map((item) => {
			return { ...item, fieldValues: [] }
		})
		.filter((item) => item.fieldName)

	emit('filter-confirm', data, isChange)
	dialogVisible.value = false
}

// 筛选配置是否有修改
const getConfigChanged = () => {
	return !isEqualWith(pageConfig.value.fieldList, formList.value, (arr1, arr2) => {
		if (arr1?.length !== arr2?.length) {
			return false
		}
		return arr1.every((item, index) => {
			return (
				item.fieldName === arr2[index].fieldName &&
				item.operator === arr2[index].operator &&
				item.isExposed === arr2[index].isExposed
			)
		})
	})
}

// 将筛选值存储在本地缓存
const setStorage = (list = []) => {
	const pages = JSON.parse(sessionStorage.getItem('pages')) || []
	const page = {
		pageId: props.pageId,
		value: list.reduce((arr, next) => {
			if (
				next.fieldValues?.length > 0 ||
				[OperatorType.isNull, OperatorType.isNotNull, OperatorType.jsonIsNull, OperatorType.jsonIsNotNull].includes(
					next.operator
				)
			) {
				arr.push({
					fieldName: next.fieldName,
					operator: next.operator,
					fieldValues: next.fieldValues,
					fromType: next.fromType,
					selectable: selectableData.value.find((item) => item.fieldName === next.fieldName)?.data,
				})
			}
			return arr
		}, []),
	}

	const index = pages.findIndex((item) => item.pageId === page.pageId)
	if (index >= 0) {
		if (page.value?.length > 0) {
			// 更新缓存
			pages.splice(index, 1, page)
		} else {
			// 如果筛选值都为空时，则清除之前的缓存
			pages.splice(index, 1)
		}
	} else {
		if (page.value?.length > 0) {
			// 添加缓存
			pages.push(page)
		}
	}
	sessionStorage.setItem('pages', JSON.stringify(pages))
}

// 将字段值转为string类型
const valueToString = (list = []) => {
	return list.map((item) => {
		// formType为select下拉以及类型为销售区域
		if (item.fromType === FromType.select && item.selectType === SelectType.salesArea) {
			item.fieldValues = flattenDeep(item.fieldValues)
		}
		return {
			...item,
			fieldValues: item.fieldValues?.map((value) => {
				return value || value === 0 ? String(value) : ''
			}),
		}
	})
}

// 检测筛选项是否有值
const hasValue = (values) => {
	const type = Object.prototype.toString.call(values)
	switch (type) {
		case '[object Number]':
			return true
		case '[object String]':
			return true
		case '[object Array]':
			return !!values.filter((value) => {
				return value || value === 0
			}).length
		default:
			return false
	}
}

/**
 * 筛选校验
 * @returns true:校验通过, false: 校验不通过
 */
const validate = () => {
	let flag: boolean = true
	for (let i = 0; i < formList.value.length; i++) {
		const item = formList.value[i]
		if (item.operator === OperatorType.between && item.fromType === FromType.number) {
			if (typeof item.fieldValues[0] !== 'number' && typeof item.fieldValues[1] === 'number') {
				ElMessage.warning('请填写最小值')
				flag = false
				break
			}
			if (typeof item.fieldValues[0] === 'number' && typeof item.fieldValues[1] !== 'number') {
				ElMessage.warning('请填写最大值')
				flag = false
				break
			}
		}
	}
	return flag
}

// 弹框关闭
const handleClose = () => {
	dialogVisible.value = false
	// 更新配置项
	pageConfig.value.fieldList = formList.value
		.map((item) => {
			return { ...item, fieldValues: [] }
		})
		.filter((item) => item.fieldName)

	if (pageConfig.value.fieldList?.length > 0) {
		emit('save-config')
	}
}

// 清除筛选值
const clearFilterVal = () => {
	formList.value.forEach((formItem) => {
		return (formItem.fieldValues = [])
	})
	selectableData.value = []
}

// 选择字段时
const fieldChange = (fieldItem: FieldItemType) => {
	// 重置筛选条件与筛选值
	fieldItem.operator = null
	fieldItem.fieldValues = []

	const obj = fieldRules.value?.find((item) => item.Field === fieldItem.fieldName)
	if (obj) {
		fieldItem = Object.assign(fieldItem, {
			name: obj.Name,
			fieldName: obj.Field,
			selectType: obj.SelectType,
			operatorList: obj.Operator,
			valueList: obj.Setting,
			fromType: obj.FromType,
			objectName: obj.ObjectName,
		})
		fieldItem.operator = fieldItem.operatorList[0]?.value
	}
}

// 选择范围时
const selectChange = (formItem) => {
	formItem.fieldValues = []
	selectableData.value = []

	const pages = JSON.parse(sessionStorage.getItem('pages')) || []
	const pageValue = pages.find((item: any) => item.pageId === props.pageId)?.value ?? []

	const field = pageValue?.find((item: any) => item.fieldName === formItem.fieldName)
	if (field) {
		field.selectable = []
		sessionStorage.setItem('pages', JSON.stringify(pages))
	}
}

// 添加筛选条件
const handleAdd = () => {
	formList.value.push(new InitFieldItem())
}

// 删除筛选条件
const handleRemove = (formItem, index) => {
	ElMessageBox.confirm('操作后，外露的筛选条件将一并删除，是否继续?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning',
	}).then(() => {
		formList.value.splice(index, 1)
		if (formList.value.length === 0) {
			handleAdd()
			ElMessageBox.alert('此组需要至少保留一个条件！', '提示', {
				confirmButtonText: '我知道了',
			})
		}
	})
}

// 清除筛选
const clearFilter = () => {
	if (formList.value.length == 1 && formList.value[0]?.fieldName == '') {
		ElMessageBox.alert('此组需要至少保留一个条件！', '提示', {
			confirmButtonText: '我知道了',
		})
	} else {
		ElMessageBox.confirm('操作后，外露的筛选条件将一并删除，是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning',
		}).then(() => {
			formList.value.splice(0)
			handleAdd()
		})
	}
}

// 勾选外露时验证
const handleCheck = (formItem: FieldItemType, prop: string) => {
	ruleFormRef.value.validateField(prop, (valid) => {
		if (!valid) {
			formItem.isExposed = false
		}
	})
}
defineExpose({
	handleSearch,
})
</script>
<style lang="scss" scoped>
.content-box {
	.content-main {
		position: relative;

		.form-title {
			color: var(--el-input-text-color);

			.el-input .el-input__wrapper {
				justify-content: flex-start;
				box-shadow:
					1px 0 0 0 var(--el-input-border-color, var(--el-border-color)) inset,
					0 1px 0 0 var(--el-input-border-color, var(--el-border-color)) inset,
					0 -1px 0 0 var(--el-input-border-color, var(--el-border-color)) inset;
				border-radius: 4px 0 0 4px;
			}

			:deep(.el-select .el-input__wrapper) {
				border-radius: 0 4px 4px 0;
			}
		}

		:deep(.el-select .el-input__inner) {
			height: var(--el-input-height);
		}
	}
}

.scress {
	.scress-context {
		display: flex;
		align-items: center;

		.scress-form {
			position: relative;
			flex: 1;
			padding-left: 35px;
			margin-left: -32px;

			&::before {
				content: ' ';
				position: absolute;
				top: 16px;
				left: 34px;
				bottom: 13px;
				width: 0;
				border-right: 1px solid theme('colors.line.2');
				overflow: hidden;
			}

			.form-row {
				position: relative;
				top: 0;
				left: 27px;
				width: 100%;
				padding: 0;

				&::before {
					content: '';
					position: absolute;
					top: 16px;
					left: -25px;
					width: 20px;
					border-top: 1px solid theme('colors.line.2');
				}
			}

			.add {
				position: relative;
				top: 0;
				left: 23px;
				width: 18px;

				&::before {
					content: '';
					position: absolute;
					left: -24px;
					top: 17px;
					width: 20px;
					border-top: 1px solid theme('colors.line.2');
				}
			}
		}
	}
}
</style>
