<template>
	<template v-if="users?.length > 0">
		<el-divider />
		<div>
			<div class="my-5">抄送</div>

			<ul ref="boxRef" class="copy-users">
				<template v-for="(item, index) in users" :key="index">
					<NodeBox v-if="index < maxShowNum" :userInfo="item" />
				</template>

				<template v-if="overflowList.length > 0">
					<PopoverBox type="copy" :overflowList="overflowList" />
				</template>
			</ul>
		</div>
	</template>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'
import { drop } from 'lodash-es'
import { ApprovalUserType } from '../types'
import NodeBox from '../flowChart/nodeBox.vue'
import PopoverBox from '../flowChart/popoverBox.vue'

interface Props {
	users?: ApprovalUserType[]
}

const props = defineProps<Props>()

const boxRef = ref<HTMLElement | null>(null)
const overflowList = ref<ApprovalUserType[]>([])
const maxShowNum = ref<number>(4) // 最大显示数量

const { width: boxWidth } = useElementSize(boxRef)

watch(boxWidth, (newVal) => {
	if (newVal > 0) {
		const value = Math.floor(newVal / 140) - 2
		value >= 0 && (maxShowNum.value = value)
	}
})

watch(
	[() => props.users, maxShowNum],
	() => {
		if (props.users?.length > maxShowNum.value) {
			overflowList.value = drop(props.users, maxShowNum.value)
		}
	},
	{ deep: true, flush: 'post' }
)
</script>

<style lang="scss" scoped>
$nodeBoxWidth: 140px;

.copy-users {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 16px;
	padding: 0 8px;

	.node-box {
		width: $nodeBoxWidth;
	}

	.avatar-box {
		display: flex;
		width: $nodeBoxWidth;
		padding: 8px;
		box-shadow: 0px 1px 8px 0px #e1e7f4;
		border-radius: 8px;
		background-color: #fff;
		cursor: pointer;
	}
}

.node-popover {
	.node-box {
		width: $nodeBoxWidth;
	}
}
</style>
