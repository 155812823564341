import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		renderFunction: Function,
	},
	setup(props) {
		console.log(props, 'renderFunction')
		return () => (props?.renderFunction ? props?.renderFunction?.() : null)
	},
})
