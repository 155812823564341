import { Status } from './../../view/visitManagement/visitRecord/constant'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import router from '@/router/index'
import { usePageDrawer } from '../usePageDrawer'
import { DrawerType } from '../usePageDrawer/types'
import { useUserStore } from '@/pinia/modules/user'
import { useChannelInventoryRecord } from '@/view/inventoryManagement/channel/channelInventoryRecord/useChannelInventoryRecord'
import { OpeationBtnType } from '../useOperationBtnList'
import { useWebSocket, WebsocketStatus } from '../useWebSocket'
import { WebSocketMessageType } from '../useWebSocket/types'
import { toValue } from 'vue'
import { navigationPage } from '@/utils/navigation'
import { drawerMissing } from '@/config/Constantconfig'

export enum JumpType {
	/**不跳转类型 */
	None = 'None',
	/** 默认类型 */
	Default = 'Default',
	/**直接进入详情 */
	Detail = 'Detail',
	/**渠道库存上报新增弹窗 */
	AddChannelInventory = 'AddChannelInventory',
	/**需求计划导入记录页列表 */
	PlanImportRecordList = 'PlanImportRecordList',
	/**拜访列表页 */
	VisitList = 'VisitList',
	/** 批量审批消息跳转待我审批列表页 */
	MyApproval = 'MyApproval',
	/** 提示去app查看 */
	AppView = 'AppView',
}
interface ItemMessage {
	msgType: number
	menuId: number
	menuRouterName?: string
	contentId?: number | string
	contentCode?: string
	contentList?: Record<string, any>[]
	jumpType?: JumpType
	title?: string
}

export const useMessageCenter = () => {
	// app端冰箱流程管理（该页面存在 appUseDrawer才会挂载）
	const REFRIGERATORPROCESSMGR = 'REFRIGERATORPROCESSMGR'

	// app详情菜单抽屉
	const appUseDrawer = [
		DrawerType.REFRIGERATORRETURNRECORD,
		DrawerType.REFRIGERATORSCRAPRECORD,
		DrawerType.REFRIGERATORLOSSRECORD,
	]

	const { drawerOpen } = usePageDrawer()

	const userStore = useUserStore()

	/**tooltip提示语组合在一起 */
	const tooltipFn = (itemMessage: ItemMessage) => {
		return itemMessage?.contentList?.reduce((prev, val) => {
			prev += val.fieldValue || val.msgPart
			return prev
		}, '')
	}

	const getWebMenuUrl = (menuId: number, menuRouterName?: string) => {
		const pageList = userStore.userPermissions ?? []
		// 有匹配菜单才可以跳转
		const menuUrl = pageList?.find((val) => val.id === menuId || val.name === menuRouterName)?.name ?? ''
		return menuUrl
	}

	// 有相应权限，请前往app查看
	const validAppToView = () => {
		const hasPermission = userStore.userAppPermissions.find(
			(item) => item.name?.toUpperCase() === REFRIGERATORPROCESSMGR && item.status === 1
		)
		return !!hasPermission
	}

	// 没用详情页
	const disabledJumpToDrawer = (menuKey) => {
		return menuKey ? [...drawerMissing].includes(menuKey) : true
	}

	// 渠道库存上报需要直接打开新增弹窗
	const addChannelInventoryRecord = (itemMessage, isRefreshObj) => {
		const menuKey = getWebMenuUrl(itemMessage.menuId)

		const { buttonList, initStockType } = useChannelInventoryRecord()
		// 设置类型为 核销库存上报
		initStockType(2)
		// 判断是否有新增权限
		const addBtn = toValue(buttonList)?.find((item) => item.type === OpeationBtnType.ADD)

		if (!addBtn) return ElMessage.warning('您没有该业务新增权限，请联系管理员处理!')

		// menuKey就是当前抽屉组件的唯一标志
		// 将当前抽屉的唯一标志加按钮类型来标志当前要在这个抽屉里渲染的弹窗标志
		const drawerInfo = {
			title: addBtn.title || addBtn.label,
			isDialog: true,
			...addBtn,
			...isRefreshObj,
		}
		drawerOpen(`${menuKey}${addBtn.type}`, drawerInfo)

		return true
	}
	const hasIdOrCode = (itemMessage) => {
		// 没有对应的id或code不进行跳转
		if (!itemMessage.contentId && !itemMessage.contentCode) return false
		return true
	}
	/**有变量就跳详情，没有则不跳转 */
	const hasFieldValueToDetail = (itemMessage) => {
		// 没有变量的不进行交互操作
		if (!itemMessage.contentList?.some((item) => item.fieldValue)) return false
		return hasIdOrCode(itemMessage)
	}
	/**直接跳列表页 */
	const toList = (itemMessage, menuRouterName) => {
		const menuKey = getWebMenuUrl(itemMessage.menuId, menuRouterName)

		// menuKey 存在才进行后面操作
		if (!menuKey) {
			ElMessage.warning('您没有权限查看此页面的内容!')
			return false
		}
		console.log(menuRouterName, itemMessage?.menuRouterName, ' itemMessage?.menuRouterName')
		navigationPage({ name: menuRouterName || itemMessage?.menuRouterName })

		return false
	}
	/**有变量跳列表页 */
	const hasFieldValueToList = (itemMessage, menuRouterName) => {
		// 没有变量的不进行交互操作
		if (!itemMessage.contentList?.some((item) => item.fieldValue)) return false

		const menuKey = getWebMenuUrl(itemMessage.menuId, menuRouterName)
		// menuKey 存在才进行后面操作
		if (!menuKey) {
			ElMessage.warning('您没有权限查看此页面的内容!')
			return false
		}
		navigationPage({ name: menuRouterName || itemMessage?.menuRouterName })

		return false
	}
	/**为不同消息模版添加标识，用于页面展示区分是否可点击跳转 */
	const addJumpTypeTag = (itemMessage) => {
		// 与app菜单权限名保持一直（大写）
		const appMenuKey = itemMessage?.menuRouterName?.toUpperCase() as DrawerType

		// 只有app才有的操作
		if (appUseDrawer.includes(appMenuKey)) {
			if (validAppToView()) {
				itemMessage.jumpType = JumpType.AppView
			} else {
				itemMessage.jumpType = JumpType.None
			}
			return itemMessage
		}

		// 批量审批消息跳转待我审批列表页
		if (msgTypeMap(itemMessage.msgType) === WebSocketMessageType.MessageTypeApproval && itemMessage.contentId === 0) {
			itemMessage.jumpType = JumpType.MyApproval
			return itemMessage
		}

		switch (+itemMessage?.msgModelId) {
			case 1001:
			case 1002:
			case 1003:
				itemMessage.jumpType = hasIdOrCode(itemMessage) ? JumpType.Detail : JumpType.None
				break
			case 2001:
				const has = hasFieldValueToDetail(itemMessage)
				itemMessage.jumpType = has ? JumpType.Detail : JumpType.None
				break

			case 3001:
				itemMessage.jumpType = JumpType.VisitList
				break

			case 3002:
				itemMessage.jumpType = JumpType.None
				break

			case 3003:
				itemMessage.jumpType = hasIdOrCode(itemMessage) ? JumpType.Detail : JumpType.None
				break

			case 3004:
				itemMessage.jumpType = JumpType.None
				break

			case 4001:
				itemMessage.jumpType = hasIdOrCode(itemMessage) ? JumpType.Detail : JumpType.None
				break

			case 5001:
				itemMessage.jumpType = JumpType.AddChannelInventory
				break

			case 6001:
				itemMessage.jumpType = JumpType.PlanImportRecordList
				break

			case 6002:
				itemMessage.jumpType = JumpType.None
				break

			case 6003:
				itemMessage.jumpType = JumpType.PlanImportRecordList
				break

			case 6004:
				itemMessage.jumpType = JumpType.PlanImportRecordList
				break

			case 7001:
				itemMessage.jumpType = hasIdOrCode(itemMessage) ? JumpType.Detail : JumpType.None
				break

			default:
				if (itemMessage.msgType === 2) {
					// 旧数据不做标识及跳转
					itemMessage.jumpType = JumpType.Default
				} else {
					// 旧数据不做标识及跳转
					itemMessage.jumpType = JumpType.None
				}

				break
		}
		return itemMessage
	}

	/**根据消息模版id进行分类判断是否打开对应详情页 */
	const moduleSplitJump = (itemMessage, isRefreshObj) => {
		//![JumpType.None, JumpType.AppView].includes(item?.jumpType) 就是为了没有标签也可点击提示
		if (itemMessage.jumpType === JumpType.AppView) {
			ElMessage.warning('请前往APP查看具体详情！')
			return false
		}

		if (itemMessage.jumpType === JumpType.Detail) {
			return true
		}
		if (itemMessage.jumpType === JumpType.VisitList) {
			// 跳转至拜访列表
			toList(itemMessage, 'visitList')
			return false
		}
		if (itemMessage.jumpType === JumpType.PlanImportRecordList) {
			// 跳转至需求计划导入记录页
			toList(itemMessage, 'planImportRecord')
			return false
		}
		if (itemMessage.jumpType === JumpType.MyApproval) {
			// 跳转至待我审批页
			toList(itemMessage, 'myApproval')
			return false
		}
		if (itemMessage.jumpType === JumpType.AddChannelInventory) {
			// 渠道库存上报打开对应新增弹窗
			addChannelInventoryRecord(itemMessage, isRefreshObj)
			return false
		}

		if (itemMessage.jumpType === JumpType.Default) {
			// 没有变量的不进行交互操作
			if (!itemMessage.contentList?.some((item) => item.fieldValue)) {
				return false
			}
			return true
		}
		if (itemMessage.jumpType === JumpType.None) {
			return false
		}
	}

	/**处理消息是否跳转交互 */
	const isContinueOpenDrawer = async (itemMessage: ItemMessage, proTable?: any) => {
		// 是否刷新当前页面
		const isRefreshObj = proTable?.value
			? {
					refresh: true,
					listRef: proTable.value, //关闭抽屉后需要刷新页面
				}
			: {}

		// 处理通知消息跳转
		const isJumpToDetail = moduleSplitJump(itemMessage, isRefreshObj)
		// 不可跳转到详情的根据各自消息模版id处理
		if (!isJumpToDetail) return
		// 重复确认是否有相应权限
		const menuKey = getWebMenuUrl(itemMessage.menuId)
		// menuKey 存在才进行后面操作
		if (!menuKey) {
			ElMessage.warning('您没有权限查看此页面的内容!')
			return false
		}
		// 与菜单权限名保持一直（大写）
		const DrawerKey = menuKey?.toUpperCase()
		// 打开相应抽屉
		// 后端id来源有两个
		// 审批消息都是走code
		await drawerOpen(DrawerKey, {
			title: itemMessage.contentCode || itemMessage.title,
			props: {
				rowId: itemMessage.contentId || itemMessage.contentCode,
				//通知类消息，走id形式，不走code
				flowCode: itemMessage.msgType === 1 ? '' : itemMessage.contentCode,
				// 审批消息直接切换到审批纪录tab
				drawerTab: itemMessage.msgType === 1 ? null : 'flow-record',
			},
			isHiddenBtnList: true,
			...isRefreshObj,
		})
	}

	/**
	 * @description 设置消息为已读
	 * @param ids 已读内容的id
	 *  */
	const setMessageRead = async (ids = []) => {
		const { status, data, send, open, close } = useWebSocket()
		console.log(`output->status`, status)
		// 主动判断是否websocket断联
		if (status === WebsocketStatus.CLOSED) {
			// await open()
		}
		const messageData = JSON.stringify({
			msgType: 9,
			userId: userStore?.userInfo?.ID,
			data: {
				ids,
			},
		})
		send(messageData)
		return true
	}
	/**转换映射
	 * @param type number
	 */
	const msgTypeMap = (type: number) => {
		switch (type) {
			case 1:
				return WebSocketMessageType.MessageTypeNotify
			case 2:
				return WebSocketMessageType.MessageTypeApproval
			case 3:
				return WebSocketMessageType.MessageTypeWorkOrder
		}
	}
	return {
		tooltipFn,
		isContinueOpenDrawer,
		setMessageRead,
		msgTypeMap,
		appUseDrawer,
		validAppToView,
		addJumpTypeTag,
	}
}

