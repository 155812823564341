<template>
	<el-form :disabled="disabled" @submit.native.prevent>
		<template v-if="!formData.fieldName">
			<XZLInput placeholder="请输入" />
		</template>
		<template v-else>
			<!-- text 文本框 -->
			<template v-if="formData.fromType === FromType.text">
				<XZLInput v-model="inputText.value1" :placeholder="placeholder" :disabled="isDisabled" />
			</template>
			<!-- select 下拉框 -->
			<template v-if="[FromType.select, FromType.image].includes(formData.fromType)">
				<!-- 默认下拉 -->
				<el-select
					v-if="formData.fromType === FromType.image || (formData.fromType === FromType.select && !formData.selectType)"
					class="w-full"
					v-model="formData.fieldValues"
					:placeholder="placeholder"
					multiple
					collapse-tags
					collapse-tags-tooltip
					filterable
					clearable
					:disabled="isDisabled"
				>
					<el-option v-for="item in formData.valueList" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>

				<!-- 负责人 -->
				<pick-user
					v-else-if="formData.selectType === SelectType.user"
					v-model="formData.fieldValues"
					:isIncludeDisabled="true"
					placeholder="请选择人员"
					:disabled="disabled || isDisabled"
					collapseTags
				/>

				<!-- 组织 -->
				<comOrganiz
					v-else-if="formData.selectType === SelectType.depart"
					v-model="formData.fieldValues"
					:disabled="isDisabled"
					:checkStrictly="true"
				/>

				<!-- 行政区域 -->
				<provinceLinkage
					v-else-if="formData.selectType === SelectType.province"
					v-model="formData.fieldValues"
					:multiple="true"
					:checkStrictly="true"
					:disabled="isDisabled"
				/>

				<!-- 销售区域 -->
				<comSalesArea
					v-else-if="formData.selectType === SelectType.salesArea"
					v-model="formData.fieldValues"
					:multiple="true"
					:checkStrictly="true"
					:disabled="isDisabled"
					:isScreen="true"
				/>

				<!-- 标签 -->
				<comTagTree
					v-else-if="[SelectType.dealerMgrTag, SelectType.terminalMgrTag]?.includes(formData.selectType)"
					v-model="formData.fieldValues"
					:disabled="isDisabled"
					:tagType="formData.selectType === SelectType.terminalMgrTag ? 1 : 2"
					:systemTags="1"
				/>

				<!-- 职位 -->
				<comPosition
					v-else-if="formData.selectType === SelectType.position"
					v-model="formData.fieldValues"
					:disabled="isDisabled"
				/>

				<!-- 渠道客户 -->
				<XZLSelectTable
					v-else-if="formData.selectType === SelectType.dealer"
					v-model="formData.fieldValues"
					@change="handleSelectChange(formData.fieldName, $event)"
					:tranmitData="tranmitData"
					:baseType="BaseType.CHANNEL"
					:options="{
						defaultLabel: 'name',
						defaultValue: 'ID',
						searchAllStatus: true,
					}"
					:requestParams="selectTableRequestParams"
					:disabled="disabled || isDisabled"
				></XZLSelectTable>
				<!-- 终端客户 -->
				<XZLSelectTable
					v-else-if="formData.selectType === SelectType.terminal"
					v-model="formData.fieldValues"
					@change="handleSelectChange(formData.fieldName, $event)"
					:tranmitData="tableData.find((item) => item.fieldName === formData.fieldName)?.data"
					:baseType="BaseType.TERMINAL"
					:options="{
						defaultLabel: 'name',
						defaultValue: 'ID',
						searchAllStatus: true,
					}"
					:disabled="disabled || isDisabled"
					collapseTags
				></XZLSelectTable>
				<!-- <comDealer
					v-else-if="formData.selectType === SelectType.dealer"
					v-model="formData.fieldValues"
					:disabled="isDisabled"
					supplyWholesalers
					:channelType="
						firstName == '所属经销商'
							? 1
							: firstName == 'distributor'
							? 1
							: firstName == '供货批发商'
							? 2
							: firstName == 'supplyWholesalers'
							? 2
							: 0
					"
				/> -->
				<!-- 广告贴画、冰箱类型、冰箱品牌、冰箱型号 -->
				<comRefrigerator
					v-else-if="
						[
							SelectType.sticker,
							SelectType.refrigeratortype,
							SelectType.refrigeratorBrand,
							SelectType.refrigeratorModel,
						].includes(formData.selectType)
					"
					v-model="formData.fieldValues"
					:disabled="isDisabled"
					:typeid="formData.selectType"
				/>

				<!-- 费用科目 -->
				<comFeeSubject v-else-if="formData.selectType === SelectType.feeSubject" v-model="formData.fieldValues" />

				<!-- 费用维度 -->
				<comFeeDimension v-else-if="formData.selectType === SelectType.feeDimension" v-model="formData.fieldValues" />

				<!-- 所属KA -->
				<XZLSelectTable
					v-else-if="formData.selectType === SelectType.affiliationKA"
					v-model="formData.fieldValues"
					:tranmitData="tranmitData"
					@change="handleSelectChange(formData.fieldName, $event)"
					:baseType="BaseType.KA"
					:options="{
						defaultLabel: 'name',
						defaultValue: 'ID',
						searchAllStatus: true,
					}"
					:disabled="isDisabled"
				></XZLSelectTable>

				<!-- 合同管理 - 模板列表 -->
				<comContractTemplate
					v-else-if="formData.selectType === SelectType.refrigeratorProtocolTemplate"
					v-model="formData.fieldValues"
					:modelType="3"
				/>

				<!-- 需求计划导入记录 -->
				<comPlanningManagementChannelType
					v-else-if="formData.selectType === SelectType.planningManagementChannelType"
					v-model="formData.fieldValues"
				/>
			</template>

			<!-- 时间 -->
			<template v-if="formData.fromType === FromType.date && Number.isFinite(dateType)">
				<XZLYearRange v-if="dateType === DateTimeType.Year" v-model="formData.fieldValues" @change="handleDateChange" />
				<el-date-picker
					v-else
					v-model="formData.fieldValues"
					@change="handleDateChange"
					:type="DateTimeMap[dateType]"
					:shortcuts="dateType === DateTimeType.Day ? shortcuts : []"
					:disabled="isDisabled"
					:format="dateType === DateTimeType.Day ? DateType.YMD : DateType.YM"
					:value-format="dateType === DateTimeType.Day ? DateType.YMD : DateType.YM"
					:start-placeholder="dateType === DateTimeType.Day ? '开始日期' : '开始年月'"
					:end-placeholder="dateType === DateTimeType.Day ? '结束日期' : '结束年月'"
					unlink-panels
				/>
			</template>

			<!-- 数字 -->
			<template v-if="formData.fromType === FromType.number">
				<div v-if="formData.operator === OperatorType.between" class="flex">
					<el-input-number
						class="flex-1"
						v-model="inputText.value1"
						:controls="false"
						placeholder="最小值"
						:disabled="isDisabled"
					/>
					<span class="mx-2 mt-2">-</span>
					<el-input-number
						class="flex-1"
						v-model="inputText.value2"
						:controls="false"
						placeholder="最大值"
						:disabled="isDisabled"
					/>
				</div>
				<el-input-number
					v-else
					v-model="inputText.value1"
					:controls="false"
					:placeholder="placeholder"
					:disabled="isDisabled"
				/>
			</template>
		</template>
	</el-form>
</template>

<script setup lang="ts">
import { computed, reactive, watch, ref, onMounted } from 'vue'
import { dayjs } from 'element-plus'
import { useVModel } from '@vueuse/core'
import { DateType } from '@/utils/format'
import { BaseType } from '@/config/Constantconfig'
import { type FieldItemType, FromType, SelectType, OperatorType, DateTimeType, DateTimeMap } from './types'

import comRefrigerator from './component/comRefrigerator.vue'
import comFeeSubject from './component/comFeeSubject.vue'
import comFeeDimension from './component/comFeeDimension.vue'
import comContractTemplate from './component/comContractTemplate.vue'
import comPlanningManagementChannelType from './component/comPlanningManagementChannelType.vue'
import XZLSelectTable from '@/components/proxyComponents/XZLSelectTable/index.vue'
import XZLYearRange from '@/components/proxyComponents/XZLYearRange/index.vue'

interface Props {
	modelValue: FieldItemType
	selectable: any[]
	pageId: string
	fieldRules: any[]
	disabled?: boolean
	firstName: String
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'update:selectable'])
const formData = useVModel(props, 'modelValue', emit)
const tableData = useVModel(props, 'selectable', emit)

const inputText = reactive({
	value1: null,
	value2: null,
})
const shortcuts = [
	{
		text: '今天',
		value: () => {
			const date = new Date()
			return [date, date]
		},
	},
	{
		text: '7天内',
		value: () => {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
			return [start, end]
		},
	},
	{
		text: '30天内',
		value: () => {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
			return [start, end]
		},
	},
]

// 时间类型
const dateType = computed(() => {
	if (formData.value.fromType === FromType.date) {
		return props.fieldRules.find((item) => item.Field === formData.value.fieldName)?.DateType
	}
	return null
})

// 是否禁用
const isDisabled = computed(() => {
	return [OperatorType.isNull, OperatorType.isNotNull, OperatorType.jsonIsNull, OperatorType.jsonIsNotNull].includes(
		formData?.value.operator
	)
})

// 占位符
const placeholder = computed(() => {
	switch (formData.value.fromType) {
		case FromType.text:
		case FromType.number:
			return '请输入'
		case FromType.select:
		case FromType.image:
			return '请选择'
		case FromType.date:
			return '选择日期'
		default:
			return ''
	}
})
const tranmitData = computed(() => {
	return tableData?.value?.find((item) => item.fieldName === formData?.value?.fieldName)?.data
})

const selectTableRequestParams = computed(() => {
	return {
		initialFilters: [
			{
				fieldName: 'channel',
				fieldValues: ['supplyWholesalers', 'provision_id'].includes(formData?.value?.fieldName) ? ['2', '3'] : ['1'],
				operator: 'IN',
				fromType: 'select',
			},
		],
	}
})
watch(
	inputText,
	(newVal) => {
		if (formData.value.operator === OperatorType.between) {
			formData.value.fieldValues = [newVal.value1, newVal.value2]
		} else {
			formData.value.fieldValues = newVal.value1 === null || newVal.value1 === undefined ? [] : [newVal.value1]
		}
	},
	{ deep: true }
)

watch(
	() => formData.value.fieldValues,
	(newVal) => {
		if (newVal?.length > 0) {
			if ([FromType.text, FromType.number, FromType.date].includes(formData.value.fromType)) {
				if (formData.value.operator === OperatorType.between) {
					inputText.value1 = newVal[0] ?? null
					inputText.value2 = newVal[1] ?? null
				} else {
					inputText.value1 = newVal[0] ?? null
				}
			}
		} else {
			inputText.value1 = null
			inputText.value2 = null
		}
	},
	{ deep: true, immediate: true }
)

onMounted(() => {
	const pages = JSON.parse(sessionStorage.getItem('pages')) || []
	const pageValue = pages.find((item: any) => item.pageId === props.pageId)?.value ?? []
	pageValue?.forEach((item) => {
		if (item.selectable?.length > 0) {
			tableData.value.push({
				fieldName: item.fieldName,
				data: item.selectable,
			})
		}
	})
})

const handleDateChange = (value) => {
	// 年
	if (dateType.value === DateTimeType.Year) {
		formData.value.fieldValues[0] = value[0] ? value[0] + '-01-01' : ''
		formData.value.fieldValues[1] = value[1] ? value[1] + '-12-31' : ''
	} else if (dateType.value === DateTimeType.Month) {
		// 月
		formData.value.fieldValues[0] = dayjs(value[0]).startOf('month').format(DateType.YMD)
		formData.value.fieldValues[1] = dayjs(value[1]).endOf('month').format(DateType.YMD)
	}
}

const handleSelectChange = (fieldName, values) => {
	const index = tableData.value.findIndex((item) => item.fieldName === fieldName)
	if (index !== -1) {
		tableData.value.splice(index, 1, { fieldName, data: values?.value })
	} else {
		tableData.value.push({ fieldName, data: values?.value })
	}
}
</script>

<style lang="scss" scoped>
.el-input-number {
	width: 100%;

	:deep(.el-input__inner) {
		text-align: left;
	}
}
</style>
