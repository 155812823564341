<template>
	<el-cascader
		:options="organizData"
		v-model="ids"
		:props="prop"
		:show-all-levels="showAllLevels"
		clearable
		filterable
		getCheckedNodes
		placeholder="可输入名称搜索"
		collapse-tags-tooltip
		collapse-tags
		@change="handleChange"
		style="width: 100%"
	/>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick, onBeforeUnmount, reactive } from 'vue'
import { getOrgDepartmentTreeList } from '@/api/serveApi/basicInformation/organizationMgr'
import { findSelectData } from '@/utils/percent'
import { handleDepartData } from '@/utils/common'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'

const emit = defineEmits(['update:modelValue', 'change'])
const props = defineProps({
	checkStrictly: {
		type: Boolean,
		required: false,
		default: false,
	},
	modelValue: {
		type: [Array<Number>, Number, null],
		required: true,
	},
	emitPath: {
		type: Boolean,
		required: false,
		default: false,
	},
	isShowId: {
		type: Boolean,
		required: false,
		default: false,
	},
	multiple: {
		type: Boolean,
		required: false,
		default: true,
	},
	showAllLevels: {
		type: Boolean,
		required: false,
		default: true,
	},
	type: {
		type: String,
		required: false,
		default: '',
	},
})
const contentRef = ref()
const ids = ref(0)
const organizData = ref([])
const timer = ref(null)

watch(
	() => props.modelValue,
	(val) => {
		ids.value = val
	},
	{
		immediate: true,
		deep: true,
	}
)
onMounted(async () => {
	const res = await getOrgDepartmentTreeList({})
	organizData.value = handleDepartData({
		data: res.data,
		type: props.type as OpeationBtnType,
		currentIds: props.type === OpeationBtnType.EDIT ? props.modelValue : [],
	})
})

const prop = reactive({
	checkStrictly: props.checkStrictly,
	multiple: props.multiple,
	emitPath: props.emitPath,
	value: props.isShowId ? 'id' : 'departmentId',
	label: 'name',
	children: 'subDept',
})

const handleChange = async (data) => {
	let paramData = null
	if (Array.isArray(data)) {
		paramData = data
	} else {
		paramData = [data]
	}
	const prop = props.isShowId ? 'id' : 'departmentId'
	const findArr = findSelectData(paramData, organizData.value, prop, 'subDept')
	emit('change', findArr)
	emit('update:modelValue', ids.value)
}

onBeforeUnmount(() => {
	timer.value = null
})
</script>

<style></style>
