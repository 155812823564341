<template>
	<div v-loading="loading.init">
		<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
			<div class="grid grid-cols-2 gap-x-form">
				<div class="col-span-1">
					<el-form-item label="渠道客户类型" prop="channel">
						<XZLSelect
							v-model="ruleForm.channel"
							:joinList="suppliers"
							filterable
							clearable
							class="w-full"
							placeholder="请选择渠道客户类型"
							:options="{ defaultLabel: 'label', defaultValue: 'value' }"
						/>
					</el-form-item>
				</div>
				<div class="col-span-1">
					<el-form-item label="渠道客户名称" prop="distributorId">
						<XZLSelectTable
							ref="xzlSelectTable"
							v-model="ruleForm.distributorId"
							:tranmitData="tranmitData"
							@change="handleChangeCustomer"
							:baseType="BaseType.CHANNEL"
							:multiple="false"
							:options="{
								defaultLabel: 'name',
								defaultValue: 'ID',
							}"
							:requestParams="selectTableRequestParams"
						/>
					</el-form-item>
				</div>
				<div class="col-span-1">
					<el-form-item v-if="stockType === 1" label="上报仓库" prop="warehouses">
						<!-- 暂不替换XZLSelectV2  :value="item" 组件目前不支持  -->
						<el-select
							class="w-full"
							v-model="ruleForm.warehouses"
							value-key="ID"
							multiple
							collapse-tags
							collapse-tags-tooltip
							filterable
							clearable
							:disabled="!ruleForm.distributorId"
							@change="handleChangeWarehouse"
						>
							<el-option v-for="item in warehouseList" :key="item.ID" :label="item.WarehouseName" :value="item" />
						</el-select>
					</el-form-item>
					<el-form-item v-if="stockType === 2" label="核销年月" prop="verifyYears">
						<el-date-picker
							v-model="ruleForm.verifyYears"
							type="month"
							:format="DateType.YM"
							:value-format="DateType.YM"
							:disabled-date="disabledDate"
							placeholder="请选择月份"
						/>
					</el-form-item>
					<el-form-item label="上报备注" prop="remark">
						<XZLInput v-model="ruleForm.remark" type="textarea" rows="3" />
					</el-form-item>
				</div>
				<div class="col-span-1">
					<el-form-item label="库存照片" prop="stockImgList">
						<div class="flex items-start">
							<image-preview class="!mr-3" :url-list="ruleForm.stockImgList" :on-remove="handleRemove" />
							<el-upload
								v-if="ruleForm.stockImgList?.length < 3"
								action=""
								:show-file-list="false"
								:limit="3"
								:before-upload="beforeAvatarUpload"
								:http-request="handleAvatarSuccess"
							>
								<div class="el-upload el-upload--picture-card">
									<el-icon>
										<Plus />
									</el-icon>
								</div>
							</el-upload>
						</div>
					</el-form-item>
				</div>
			</div>
		</el-form>

		<div class="mb-5">
			<el-button type="primary" @click="showAddDialog = true">添加产品</el-button>
			<el-button type="danger" plain @click="deleteRow">删除</el-button>
		</div>

		<el-table
			:data="tableState.list"
			style="width: 100%"
			max-height="400"
			:scrollbar-always-on="true"
			:border="true"
			@selection-change="selectionChange"
		>
			<el-table-column type="selection" width="50" :align="'center'" fixed="left" />
			<el-table-column type="index" label="序号" width="60" :align="'center'" fixed="left" />
			<el-table-column prop="code" label="产品编号" width="120" fixed="left">
				<template #default="{ row }">
					<el-link
						type="primary"
						@click="
							() =>
								drawerOpen(DrawerType.PRODUCTLIST, {
									title: row.name,
									props: {
										rowId: row.productId,
									},
									selectList: [{ ...row, ID: row.productId }],
								})
						"
						>{{ row.code }}</el-link
					>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="产品名称" width="120" fixed="left" />
			<el-table-column prop="brandName" label="产品品牌" width="90" />
			<el-table-column prop="categoryName" label="产品品类" width="90" />
			<el-table-column prop="specification" label="规格型号" width="100" />
			<el-table-column prop="unitName" label="单位" width="60" :align="'center'" />
			<el-table-column label="上报总数" width="100" :align="'center'">
				<template #default="{ row }">
					{{ getTotal(row) }}
				</template>
			</el-table-column>
			<!-- 展示仓库 -->
			<el-table-column v-for="(column, index) in ruleForm.warehouses" :key="column.ID" width="100" :align="'center'">
				<template #header>
					<el-tooltip class="box-item" effect="dark" :content="column.WarehouseName" placement="top">
						<span class="truncate">{{ column.WarehouseName }}</span>
					</el-tooltip>
				</template>
				<template #default="{ row }">
					<el-input-number
						class="w-full"
						v-model="row.stockDetailsList[index].stockNum"
						:controls="false"
						:min="0"
						:step="0.01"
						:precision="2"
					/>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="产品备注" min-width="150">
				<template #default="{ row }">
					<XZLInput v-model="row.remark" />
				</template>
			</el-table-column>
		</el-table>

		<div class="dialog-footer">
			<el-tooltip
				effect="dark"
				content="跨仓库上报请先保存，上报下一个仓库时可以继续编辑，全部上报完成之后就可以提交。"
				placement="top"
			>
				<el-button link>
					<SvgIcon name="icon-question" />
				</el-button>
			</el-tooltip>
			<el-button text @click="handleCancel">取消</el-button>
			<el-button type="primary" :disabled="loading.submit || !isConfirm" :loading="loading.save" @click="submitForm(1)"
				>保存</el-button
			>
			<el-button type="primary" :disabled="loading.save || !isConfirm" :loading="loading.submit" @click="submitForm(2)"
				>提交</el-button
			>
		</div>
	</div>

	<!-- 新增产品 -->
	<addProduct
		v-if="showAddDialog"
		v-model="showAddDialog"
		:row-ids="tableState.list.map((o) => o.productId)"
		:checkable-ids="tableState.list.filter((o) => o.related === 1).map((o) => o.productId)"
		@confirm="addRow"
	/>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import {
	ElMessage,
	ElMessageBox,
	dayjs,
	type FormInstance,
	type FormRules,
	type UploadRequestOptions,
} from 'element-plus'
import { sumBy, isEqual } from 'lodash-es'
import { formatDate, DateType } from '@/utils/format'
import addProduct from '@/components/addProduct/index.vue'
import XZLSelectTable from '@/components/proxyComponents/XZLSelectTable/index.vue'

import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { suppliers, BaseType } from '@/config/Constantconfig'
import { Warehouse, ChannelInventoryRecord } from '../types'
import { BtnItem, OpeationBtnType } from '@/hooks/useOperationBtnList'
import { beforeAvatarUpload } from '@/utils/common'

import { upload } from '@/api/serveApi/common/common'
import { getDistributorStockProductList } from '@/api/serveApi/inventoryManagement/channelInventory'
import { getWarehouseList } from '@/api/serveApi/basicInformation/dealerMgr'
import {
	getDistributorStockList,
	findDistributorStock,
	createDistributorStock,
	updateDistributorStock,
} from '@/api/serveApi/inventoryManagement/channelInventoryRecord'
import { getBaseDistributorList } from '@/api/serveApi/inventoryManagement/channelTransferRecord'
import { getCusDistributorList } from '@/api/serveApi/basicInformation/dealerMgr'
interface Props {
	buttonInfo?: BtnItem
	rowId?: number
	stockType: number // 1 常规,  2 核销
}

interface RuleFormType {
	ID?: number
	channel: number // 渠道客户类型
	distributorId: number // 渠道客户id
	distributorName: string
	warehouses: Warehouse.WarehouseList[] // 所选仓库
	stockImgList: string[] // 库存照片
	verifyYears?: string
	remark: string // 备注
}

class InitFormData implements RuleFormType {
	channel = null // 渠道客户类型
	distributorId = null // 渠道客户id
	distributorName = ''
	warehouses = [] // 所选仓库
	stockImgList = [] // 库存照片
	verifyYears = formatDate(new Date(), DateType.YM)
	remark = '' // 备注
}

const props = defineProps<Props>()

console.log('渠道props', props)

const emit = defineEmits(['close', 'refresh'])

const { drawerMap, drawerOpen } = usePageDrawer()

const ruleFormRef = ref<FormInstance>()
const xzlSelectTable = ref()

const showAddDialog = ref<boolean>(false) // 添加产品弹窗
const loading = reactive<{ [key: string]: boolean }>({
	init: false, // 初始化
	select: false, // 搜索渠道客户名称
	save: false, // 保存
	submit: false, // 提交
})

const isConfirm = ref<boolean>(true) // 是否可以保存或提交

const customerList = ref([]) // 渠道客户列表
const warehouseList = ref([]) // 仓库列表

const ruleForm = ref<RuleFormType>(new InitFormData())

const tableState = reactive<Record<'list' | 'selected', ChannelInventoryRecord.StockProductDetails[]>>({
	list: [], // 产品列表
	selected: [], // 勾选的产品
})

const rules = reactive<FormRules>({
	distributorId: [{ required: true, message: '请选择渠道客户', trigger: 'change' }],
	warehouses: [{ required: true, message: '请选择上报仓库', trigger: 'change' }],
	stockImgList: [{ required: true, message: '请上传库存照片', trigger: 'change' }],
	verifyYears: [{ required: true, message: '请选择核销年月', trigger: 'change' }],
})
const tranmitData = computed(() => {
	if ((props.buttonInfo?.type === 'ADD' && drawerMap.value?.size === 1) || !ruleForm.value?.distributorId) {
		return []
	}
	return [{ ID: ruleForm.value?.distributorId, name: ruleForm.value?.distributorName }]
})
const selectTableRequestParams = computed(() => {
	return {
		initialFilters: ruleForm.value?.channel
			? [
					{
						fieldName: 'channel',
						fieldValues: [`${ruleForm.value?.channel}`],
						operator: 'IN',
						fromType: 'select',
					},
				]
			: [],
	}
})

onMounted(async () => {
	if (props.buttonInfo?.type !== OpeationBtnType.ADD && props.rowId) {
		getInfo()
	}
	// 从渠道客户详情，点击新增调库申请进入，携带的rowId为渠道客户id
	if (props.buttonInfo?.type === OpeationBtnType.ADD && props.rowId && drawerMap.value?.size > 1) {
		const params = {
			page: 1,
			pageSize: 10,
			filters: [
				{
					fieldName: 'id',
					fieldValues: [props.rowId.toString()],
					fromType: 'text',
					operator: '=',
				},
			],
		}
		customerList.value = await getDistributorData(params)
		if (customerList.value?.[0]) {
			ruleForm.value.distributorId = customerList.value[0]?.id
			ruleForm.value.distributorName = customerList.value[0]?.name
			handleChangeCustomer(customerList)
		}
	}
})

// 获取上报详情
const getInfo = async () => {
	loading.init = true
	const res = await findDistributorStock({ ID: props.rowId })
	if (res && res.code === 0 && res.data) {
		const detail = res.data
		await getWarehouseData(detail.distributorId) // 获取仓库列表

		// 表单
		ruleForm.value = {
			ID: detail.ID,
			channel: detail.channel,
			distributorId: detail.distributorId,
			distributorName: detail.name,
			stockImgList: detail.stockImg ? JSON.parse(detail.stockImg) : [],
			verifyYears: detail.verifyYears,
			remark: detail.remark,
			warehouses: [],
		}

		customerList.value.push({
			id: detail.distributorId,
			name: detail.name,
		})

		// 表格
		tableState.list = detail.productDetails ? JSON.parse(detail.productDetails) : []
		tableState.list.forEach((item) => {
			// -1代表未填写，需要置空
			item.stockDetailsList.forEach((item) => {
				if (item.stockNum === -1) item.stockNum = null
			})
			const productInfo = res.data?.stockProductList?.find((el) => el.productId === item.productId)
			if (productInfo) {
				Object.assign(item, productInfo)
			}
		})

		// 所选仓库
		const ids = tableState.list[0]?.stockDetailsList?.map((item) => item.warehouseId)
		ruleForm.value.warehouses = warehouseList.value.filter((item) => ids.includes(item.ID))
	}
	loading.init = false
}

// 搜索渠道客户名称
const remoteMethod = async (query: string) => {
	if (query) {
		loading.select = true
		const params = {
			filters: [
				{
					fieldName: 'status',
					fieldValues: ['1'],
					fromType: 'select',
					operator: '=',
				},
				{
					fieldName: 'name',
					fieldValues: [query],
					fromType: 'text',
					operator: 'LIKE',
				},
			],
		}
		// 如果先选择了渠道客户类型，则只查找所选客户类型下的客户
		if (ruleForm.value.channel) {
			params.filters.push({
				fieldName: 'channel',
				fieldValues: [ruleForm.value.channel.toString()],
				fromType: 'select',
				operator: '=',
			})
		}
		customerList.value = await getDistributorData(params)
		loading.select = false
	} else {
		customerList.value = []
	}
}

// 获取渠道客户列表
const getDistributorData = async (params) => {
	const res = await getBaseDistributorList(params)
	if (res && res.code === 0) {
		return res.data?.list
	}
	return []
}

/**
 * 获取仓库列表
 * @param {number} id 渠道客户id
 */
const getWarehouseData = async (id: number): Promise<void> => {
	if (typeof id !== 'number') return
	const res = await getWarehouseList({ id })
	if (res && res.code === 0) {
		warehouseList.value = res.data ?? []
	}
}

// 获取渠道库存明细
const getInventoryDetails = async (): Promise<void> => {
	const res = await getDistributorStockProductList({
		filters: [
			{
				fieldName: 's.distributor_id',
				fieldValues: [ruleForm.value.distributorId?.toString()],
				fromType: 'select',
				operator: '=',
			},
		],
	})
	if (res && res.code === 0) {
		tableState.list = res.data?.list?.map((item) => {
			return {
				productId: item.productId, // 产品id
				code: item.productCode, // 产品编号
				name: item.productName, // 产品名称
				brandName: item.brandName, // 品牌
				categoryName: item.categoryName, // 品类
				specification: item.specification, // 规格型号
				unitName: item.unitName, // 单位
				remark: '', // 备注
				// 上报仓库
				stockDetailsList: ruleForm.value.warehouses?.map((item) => {
					return {
						stockNum: null, // 上报数量
						warehouseId: item.ID, // 仓库id
					}
				}),
				// 是否渠道客户关联产品：1 是，2 否。tips：关联产品不可删除。
				// 常规选择客户带出的产品可以删除，核销选择带出的产品不可删除。
				related: props.stockType === 2 ? 1 : 2,
			}
		})
	}
}

/**
 * 核销时选择客户，需要查看该客户当月是否有过历史数据
 * @param {number} id 渠道客户id
 * @returns {boolean} true: 当月有核销记录，false: 当月无核销记录
 */
const getRecord = async (id: number): Promise<boolean> => {
	const params = {
		page: 1,
		pageSize: 10,
		filters: [
			{
				fieldValues: [id.toString()],
				fieldName: 's.distributor_id',
				operator: '=',
				fromType: 'select',
			},
			{
				fieldValues: [props.stockType.toString()],
				fieldName: 's.stock_type',
				operator: '=',
				fromType: 'select',
			},
			{
				fieldValues: ['2023-08-01', '2023-08-31'],
				fieldName: 's.created_at',
				operator: 'BETWEEN',
				fromType: 'date',
			},
		],
	}
	const res = await getDistributorStockList(params)
	if (res && res.code === 0) {
		isConfirm.value = !res.data?.total
		return !!res.data?.total
	}
	return true
}

// 选择渠道客户
const handleChangeCustomer = async (list) => {
	const value = list?.value?.[0]
	ruleForm.value.warehouses = []
	ruleForm.value.channel = null
	if (value) {
		// 核销时需要查看该客户当月有没有上报记录
		if (props.stockType === 2) {
			const hasRecord = await getRecord(ruleForm.value.distributorId)
			if (hasRecord) {
				ElMessage.warning('该客户当月已有上报记录')
				return
			}
		}
		ruleForm.value.channel = value.channel
		ruleForm.value.distributorName = value.name
		getInventoryDetails() // 获取库存明细
		await getWarehouseData(ruleForm.value.distributorId) // 获取仓库列表
		if (props.stockType === 2) {
			// 核销库存上报则默认全选所有仓库
			ruleForm.value.warehouses = warehouseList.value
		}
	} else {
		tableState.list = tableState.selected = []
		isConfirm.value = true
	}
}

// 选择上报仓库
const handleChangeWarehouse = async (values) => {
	if (values.length > 0) {
		const ids: number[] = values.map((o) => o.ID)
		// 遍历产品列表，给每一项添加仓库列表
		tableState.list.forEach((item) => {
			// 去除已删除的仓库
			item.stockDetailsList = item.stockDetailsList.filter((el) => ids.includes(el.warehouseId))
			// 遍历上报仓库列表
			values.forEach((el) => {
				// 如果该产品不存在此仓库，则加上此仓库
				const index = item.stockDetailsList.findIndex((o) => o.warehouseId === el.ID)
				if (index < 0) {
					item.stockDetailsList.push({
						stockNum: null, // 仓库上报数量
						warehouseId: el.ID, // 仓库id
					})
				}
			})
		})
	} else {
		// 未选择仓库
		tableState.list.forEach((item) => {
			item.stockDetailsList = []
		})
	}
}

// 上传图片成功回调
const handleAvatarSuccess = async (options: UploadRequestOptions): Promise<void> => {
	const formdata = new FormData()
	formdata.append('file', options.file)
	const res: ResultType = await upload(formdata, { fileType: 'appendix' })
	if (res && res.code === 0 && res.data?.file?.url) {
		ruleForm.value.stockImgList.push(res.data.file.url)
	}
	ruleFormRef.value.validateField('stockImgList')
}
/**
 * 核销年月时间限制
 */
const disabledDate = (time: Date) => {
	return time.getTime() > Date.now()
}
// 删除图片
const handleRemove = (index: number) => {
	ruleForm.value.stockImgList.splice(index, 1)
}

// 添加产品
const addRow = (value) => {
	console.log('aa', value)
	// 取消勾选的产品将从列表中删除
	const productIds = value.map((o) => o.ID)
	tableState.list = tableState.list.filter((item) => productIds.includes(item.productId))

	value.forEach((row) => {
		// 如果该产品不存在，则添加该产品
		const exist: boolean = !!tableState.list.find((item) => item.productId === row.ID)
		if (!exist) {
			tableState.list.push({
				productId: row.ID, // 产品id
				code: row.code, // 产品编号
				name: row.name, // 产品名称
				brandName: row.brandName, // 品牌
				categoryName: row.categoryName, // 品类
				specification: row.specification, // 规格型号
				unitName: row.unitName, // 单位
				remark: '', // 备注
				// 上报仓库
				stockDetailsList: ruleForm.value.warehouses?.map((item) => {
					return {
						stockNum: null, // 上报数量
						warehouseId: item.ID, // 仓库id
					}
				}),
				related: 2, // 是否渠道客户关联产品：1 是，2 否。tips：关联产品不可删除
			})
		}
	})
}

// 删除产品
const deleteRow = () => {
	if (tableState.selected.length === 0) {
		ElMessage.warning('请先勾选需要删除的数据')
		return
	}
	const ids: number[] = tableState.selected.map((item) => item.productId)
	tableState.list = tableState.list.filter((item) => !ids.includes(item.productId))
}

// 勾选
const selectionChange = (value: ChannelInventoryRecord.StockProductDetails[]) => {
	tableState.selected = value
}

// 上报总数
const getTotal = (row: ChannelInventoryRecord.StockProductDetails): string | number => {
	const list = row.stockDetailsList?.filter((item) => typeof item.stockNum === 'number')
	if (list.length > 0) {
		return sumBy(list, 'stockNum')
	}
	return ''
}

/**
 * 上报产品保存校验
 * @param {Number} type 1 保存，2 提交
 * @returns {boolean} true:校验通过，false:校验不通过
 */
const validProduct = (type: number): boolean => {
	if (tableState.list.length === 0) {
		ElMessage.warning('请至少上报一个SKU')
		return false
	}
	// 保存不校验
	if (type === 1) {
		return true
	}
	// 提交时校验
	let flag: boolean = true
	for (let i = 0; i < tableState.list.length; i++) {
		const item = tableState.list[i]
		if (props.stockType === 1) {
			// 该仓库是否上报，每行产品至少有一个仓库填写了数据
			const hasCheck: number = item.stockDetailsList.findIndex((el) => typeof el.stockNum === 'number')
			if (hasCheck < 0) {
				flag = false
				ElMessage.warning('上报总数不能为空')
				break
			}
		} else {
			// 如果是核销，则全部仓库都必须上报
			flag = item.stockDetailsList.every((el) => typeof el.stockNum === 'number')
			if (!flag) {
				ElMessage.warning('有仓库未上报，请检查')
				break
			}
		}
	}
	return flag
}

// 取消
const handleCancel = () => {
	if (
		props.buttonInfo?.type === OpeationBtnType.ADD &&
		(!isEqual(ruleForm.value, new InitFormData()) || tableState.list?.length)
	) {
		ElMessageBox.confirm('数据未保存，是否要取消?', '提示', {
			confirmButtonText: '是',
			cancelButtonText: '否',
			type: 'warning',
		}).then(() => {
			emit('close')
		})
	} else {
		emit('close')
	}
}

/**
 * 保存和提交校验
 * @param {Number} type 1 保存，2 提交
 */
const submitForm = async (type) => {
	await ruleFormRef.value?.validate((valid) => {
		if (valid) {
			// 上报产品校验
			const isSaving: boolean = validProduct(type)
			if (!isSaving) {
				return
			}
			const productDetailsList = tableState.list.map((item) => {
				return {
					productId: item.productId,
					remark: item.remark,
					stockDetailsList: item.stockDetailsList.map((el) => {
						return {
							stockNum: typeof el.stockNum === 'number' ? el.stockNum : -1, // 若未填写，则传-1
							warehouseId: el.warehouseId,
							isCheck: typeof el.stockNum === 'number' && el.stockNum > 0 ? 1 : 2, // 是否上报：1 是，2 否
						}
					}),
					related: item.related,
				}
			})
			const params = {
				ID: ruleForm.value.ID,
				type,
				stockType: props.stockType,
				distributorId: ruleForm.value.distributorId, // 渠道客户id
				stockImgList: ruleForm.value.stockImgList, // 库存照片
				verifyYears: props.stockType === 2 ? ruleForm.value.verifyYears : '', // 核销年月
				remark: ruleForm.value.remark, // 备注
				productDetailsList, // 产品列表
			}
			if (!params.ID) {
				delete params.ID
			}

			if (type === 1) {
				handleSave(params)
			} else {
				ElMessageBox.confirm('库存上报记录提交后不可修改，确认是否继续提交上报?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						handleSave(params)
					})
					.catch(() => {})
			}
		}
	})
}

/**
 * 保存和提交
 * @param {Number} type 1 保存，2 提交
 */
const handleSave = async (params): Promise<void> => {
	if (params.type === 1) {
		loading.save = true
	} else {
		loading.submit = true
	}

	let res = {} as ResultType

	if (props.buttonInfo.type === OpeationBtnType.ADD) {
		res = await createDistributorStock(params)
	} else {
		res = await updateDistributorStock(params)
	}
	if (res && res.code === 0) {
		ElMessage.success(`${params.type == 1 ? '保存' : '提交'}成功`)
		// emit('close')
		emit('refresh')
	}
	if (params.type === 1) {
		loading.save = false
	} else {
		loading.submit = false
	}
}
</script>

<style lang="scss" scoped>
.el-input-number {
	width: 100%;
}
</style>

