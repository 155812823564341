const fmtTitle = (title, now) => {
	const reg = /\$\{(.+?)\}/
	const reg_g = /\$\{(.+?)\}/g
	const result = title.match(reg_g)
	if (result) {
		result.forEach((item) => {
			const key = item.match(reg)[1]
			const value = now.params[key] || now.query[key]
			title = title.replace(item, value)
		})
	}
	return title
}

export default function getPageTitle(pageTitle, route) {
	if (pageTitle) {
		const title = fmtTitle(pageTitle, route)
		return `${title}`
	}
	return `景云销`
}
