<template>
	<div>
		<el-button type="info" @click="handleClick(ApprovalType.REJECT)">驳回 </el-button>
		<el-button type="primary" @click="handleClick(ApprovalType.AGREE)">通过</el-button>
	</div>

	<el-dialog
		v-if="dialogVisible"
		v-model="dialogVisible"
		:title="dialogTitle"
		width="30%"
		:close-on-click-modal="false"
		append-to-body
	>
		<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
			<el-form-item class="w-full" prop="approvalComments">
				<XZLInput
					v-model="ruleForm.approvalComments"
					type="textarea"
					rows="3"
					placeholder="请输入处理意见"
					maxlength="100"
					show-word-limit
				/>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button text @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" :loading="loading" @click="submitForm(ruleFormRef)">确认</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang="ts">
import { reactive, ref, computed } from 'vue'
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { agree, reject } from '@/api/serveApi/workflow/workflowOperation'

interface Props {
	code: string
	isRequiredField: boolean // 是否有必填字段
	agreeNeed: boolean // 同意审批意见是否必填 1-是 2-否 (审批节点必须)
	rejectNeed: boolean // 拒绝审批意见是否必填 1-是 2-否 (审批节点必须)
	drawerKey: any // 当前详情页标识
	onConfirm?: (data?: any) => any
}

interface RuleForm {
	approvalComments: string
}

/**
 * @description 审批类型
 * @param AGREE 通过 => 4
 * @param REJECT 驳回 => 5
 */
enum ApprovalType {
	AGREE = 4,
	REJECT,
}

/**
 * web端没有编辑页的模块
 * @param STOREINSPECT 门店检查
 * @param ASSETSINSPECT 资产巡检
 * @param MISSEDVISIT 失访原因
 * @param REFRIGERATORSHIPPINGPLAN 年度冰箱发货计划
 * @param REFRIGERATORPUTTINGRECORD 冰箱投放记录表
 */
const notEditPage = [
	DrawerType.STOREINSPECT,
	DrawerType.ASSETSINSPECT,
	DrawerType.MISSEDVISIT,
	DrawerType.REFRIGERATORSHIPPINGPLAN,
	DrawerType.REFRIGERATORPUTTINGRECORD,
]

const props = defineProps<Props>()
const emit = defineEmits(['refresh'])

const dialogVisible = ref<boolean>(false)
const currentType = ref<ApprovalType>()
const loading = ref<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
	approvalComments: '',
})
const validateMsg = (rule: any, value: any, callback: any) => {
	if (
		!value &&
		((currentType.value === ApprovalType.AGREE && props.agreeNeed) ||
			(currentType.value === ApprovalType.REJECT && props.rejectNeed))
	) {
		callback(new Error('请输入处理意见'))
	} else {
		callback()
	}
}
const rules = reactive<FormRules>({
	approvalComments: [{ validator: validateMsg, trigger: 'blur' }],
})

const dialogTitle = computed<string>(() => {
	let title: string = '处理意见'
	if (currentType.value === ApprovalType.AGREE) {
		return props.agreeNeed ? title : title + '(选填)'
	} else {
		return props.rejectNeed ? title : title + '(选填)'
	}
})

// 点击通过/驳回
const handleClick = async (type: ApprovalType) => {
	try {
		// 审批中编辑，需要校验必填，有未填写的则不显示弹窗
		if (type === ApprovalType.AGREE && props?.onConfirm) {
			dialogVisible.value = await props.onConfirm()
			const msgEl = document.querySelector('.el-message')
			!dialogVisible.value && !msgEl && ElMessage.warning('请填写必填项')
		} else {
			dialogVisible.value = true
		}
		currentType.value = type
	} catch (error) {
		const msgEl = document.querySelector('.el-message')
		!msgEl && ElMessage.warning('请填写必填项')
	}
}

// 校验
const submitForm = async (formEl: FormInstance | undefined) => {
	if (!formEl) return
	await formEl.validate((valid) => {
		if (valid) {
			// 有必填项时，需要校验web端有没有此模块的编辑页，如果没有需要提示去app审批
			if (currentType.value === ApprovalType.AGREE && props.isRequiredField && notEditPage.includes(props.drawerKey)) {
				ElMessage.warning('请前往App编辑必填项!')
			} else {
				// 没有必填项直接审批
				handleApprove()
			}
		}
	})
}

// 同意/驳回
const handleApprove = async () => {
	loading.value = true

	// 各模块编辑页直接点击通过，需要先保存后审批
	let flag: boolean = true
	if (currentType.value === ApprovalType.AGREE) {
		flag = props.onConfirm ? await props.onConfirm?.(true) : true
	}

	// 保存失败，审批中止
	if (!flag) {
		loading.value = false
		return
	}

	const params = {
		...ruleForm,
		approvalRequestCode: props.code,
		approvalStatus: currentType.value,
	}
	let res: ResultType
	if (currentType.value === ApprovalType.AGREE) {
		res = await agree(params)
	} else {
		res = await reject(params)
	}
	if (res && res.code === 0) {
		ElMessage.success('审批成功')
		dialogVisible.value = false
		emit('refresh', true)
	}
	loading.value = false
}
</script>
