<template>
	<div class="flex items-center justify-between h-[36px] hover:bg-gray-100 group rounded-md">
		<div class="inline-flex items-center h-8">
			<el-switch v-model="column.isShow" size="small"></el-switch>
			<span class="ml-sm text-gray-700" v-if="keyword">
				<template v-for="(text, index) in column.label.split(keyword)" :key="index">
					<i class="not-italic">{{ text }}</i>
					<i v-if="index < column.label.split(keyword).length - 1" class="not-italic text-primary font-semibold">{{
						keyword
					}}</i>
				</template>
			</span>
			<span class="ml-sm text-gray-700" v-else>
				{{ column.label }}
			</span>
		</div>

		<!-- 默认固定在左侧或右侧的，不能拖动，别的元素也不能放置在其前后 -->
		<template v-if="!['left', 'right'].includes(column.fixed as string) && column.fixed !== true">
			<!-- 已锁定 -->
			<el-tooltip v-if="column.isLock" effect="dark" content="取消固定" placement="top">
				<SvgIcon
					:name="column.isLock ? 'ele-Lock' : 'ele-Unlock'"
					@click="emit('handleLock')"
					class="cursor-pointer"
					:size="16"
					:color="column.isLock ? '#bbb' : ''"
				/>
			</el-tooltip>

			<!-- 未锁定 -->
			<div v-else class="inline-flex items-center h-8 side-btns">
				<el-tooltip class="box-item" effect="dark" content="置顶" placement="top">
					<span class="hidden group-hover:block">
						<SvgIcon name="icon-topping" @click="emit('handleToping')" class="cursor-pointer" :size="16" color="#666" />
					</span>
				</el-tooltip>

				<el-tooltip class="box-item" effect="dark" content="拖动" placement="top">
					<span class="hidden group-hover:block">
						<SvgIcon name="icon-drag" class="ml-sm mr-sm cursor-move" :size="16" color="#666" />
					</span>
				</el-tooltip>

				<el-tooltip class="box-item" effect="dark" content="固定在左侧" placement="top">
					<span class="hidden group-hover:block">
						<SvgIcon
							:name="column.isLock ? 'icon-disable' : 'icon-enable'"
							@click="emit('handleLock')"
							class="cursor-pointer"
							:size="16"
							:color="column.isLock ? '#bbb' : '#444'"
						/>
					</span>
				</el-tooltip>
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { PageConfigSetting } from '@/components/proTableV2/common/hooks/usePageConfig/types'

interface Props {
	modelValue: PageConfigSetting
	keyword: string
}

const props = withDefaults(defineProps<Props>(), {
	keyword: '',
})
const emit = defineEmits(['update:modelValue', 'handleLock', 'handleToping'])
const column = useVModel(props, 'modelValue', emit)
</script>
