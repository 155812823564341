<template>
	<teleport to="body">
		<el-dialog
			:model-value="modelValue"
			title="地址信息"
			width="40%"
			:before-close="handleCancel"
			@closed="handleCancel"
		>
			<div class="map" id="gd_map_d" style="height: 400px"></div>
		</el-dialog>
	</teleport>
</template>

<script setup>
import { nextTick, onMounted } from 'vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { Mapkeys } from '@/config/Constantconfig'
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
	pointData: {
		type: Object,
		default: () => {},
	},
})
let mapPrototype = null
let AMaps = null
const handleCancel = () => {
	mapPrototype.clearMap() // 清除点位信息
	emits('update:modelValue', false)
}
//  点位上图
function addMarker(dataList) {
	var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -20) })
	dataList.forEach((item) => {
		const marker = new AMap.Marker({
			map: mapPrototype,
			position: item.position,
			icon: new AMap.Icon({
				size: new AMap.Size(25, 36), // 图标尺寸
				image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', // Icon的图像
				imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
				imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
			}),
			offset: new AMap.Pixel(-13, -25),
		})
		// 弹框内容
		const contentBox = `<div style="display: flex">
        <div> <h4  style=\"font-weight: 700;\">${item.address}</h4></div>`
		// 点击弹框事件
		marker.content = contentBox
		marker.on('click', (e) => {
			console.log(e, 36666)
			infoWindow.setContent(e.target.content)
			infoWindow.open(mapPrototype, e.target.getPosition())
		})
		// reverseGeography([113.952558,22.541045])
		// geoCode(item.address)
	})
	mapPrototype.setFitView() // 让地图飞路到点位
}
window._AMapSecurityConfig = {
	securityJsCode: Mapkeys.WEBSECRETKEY, // 秘钥 必填
}
//进行地图初始化
async function initMap() {
	try {
		const AMap = await AMapLoader.load({
			key: Mapkeys.WEBKEY,
			version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
			plugins: [
				'AMap.Scale',
				'AMap.ToolBar',
				'AMap.ControlBar',
				'AMap.Geocoder',
				'AMap.Marker',
				'AMap.CitySearch',
				'AMap.Geolocation',
				'AMap.AutoComplete',
				'AMap.InfoWindow',
			], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
		})
		const map = new AMap.Map('gd_map_d', {
			//设置地图容器id
			viewMode: '3D', //是否为3D地图模式
			zoom: 10, //初始化地图级别
			zoomEnable: true, // 地图是否可缩放，默认值为true
			dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
			doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
			expandZoomRange: true,
			// pitch: 60, // 地图倾斜角度
		})
		//添加插件
		AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.HawkEye'], function () {
			//异步同时加载多个插件
			// map.addControl(new AMap.HawkEye()) //显示缩略图
			map.addControl(new AMap.Scale()) //显示当前地图中心的比例尺
		})
		// 实例原型
		mapPrototype = map
		AMaps = AMap
		// 地理信息的逆向解析
		if (props.pointData.position.length > 0) {
			addMarker([props.pointData]) // 点位上图
		} else if (props.pointData.address) {
			geoCode(props.pointData.address)
		}
	} catch (error) {
		console.log(error)
	}
}
// 逆向地理编码 坐标---> 地址
function reverseGeography(postion) {
	AMap.plugin('AMap.Geocoder', function () {
		var geocoder = new AMap.Geocoder({})
		geocoder.getAddress(postion, function (status, result) {
			if (status === 'complete' && result.info === 'OK') {
				console.log(result.regeocode.formattedAddress, '地理位置详细信息', postion, '坐标---> 地址')
			}
		})
	})
}
// 逆向地理编码 地址--->  坐标 map.setFitView(marker) // 飞路
function geoCode(address) {
	AMap.plugin('AMap.Geocoder', function () {
		var geocoder = new window.AMap.Geocoder({})
		geocoder.getLocation(address, function (status, result) {
			if (status === 'complete' && result.geocodes.length) {
				const lnglat = result.geocodes[0].location // 经纬度
				addMarker([
					{
						address: address,
						position: [lnglat.lng, lnglat.lat],
					},
				])
				console.log(lnglat, '地址---> 坐标')
			} else {
				log.error('根据地址查询位置失败')
			}
		})
	})
}

onMounted(() => {
	nextTick(() => {
		initMap()
	})
})
</script>
<style lang="scss" scoped>
.amap-sug-result {
	z-index: 9999 !important;
}

.amap-marker-label {
	border: 0;
	background-color: transparent;
}

.info {
	position: relative;
	margin: 0;
	top: 0;
	right: 0;
	min-width: 0;
}
</style>
