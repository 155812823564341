import service from '@/utils/request'

// 分页获取审批流列表-全部审批
export const getAllList = (data: any) => {
	return service({
		url: '/approvalProcessRecord/getAllList',
		method: 'post',
		data,
	})
}

// 分页获取审批流列表-抄送我的
export const getCopyToMeList = (data: any) => {
	return service({
		url: '/approvalProcessRecord/getCopyToMeList',
		method: 'post',
		data,
	})
}

// 分页获取审批流列表-我已审批
export const getMyHasApprovalList = (data: any) => {
	return service({
		url: '/approvalProcessRecord/getMyHasApprovalList',
		method: 'post',
		data,
	})
}

// 分页获取审批流列表-我发起的
export const getMyRequestList = (data: any) => {
	return service({
		url: '/approvalProcessRecord/getMyRequestList',
		method: 'post',
		data,
	})
}

// 分页获取审批流列表-待我审批
export const getWaitMyApprovalList = (data: any) => {
	return service({
		url: '/approvalProcessRecord/getWaitMyApprovalList',
		method: 'post',
		data,
	})
}

// 根据申请编号查询审批流
export const findByCode = (params: any) => {
	return service({
		url: '/approvalProcessRecord/findByCode',
		method: 'get',
		params,
	})
}

// 根据rowId和菜单id查询审批流
export const getApprovalRecord = (params: any) => {
	return service({
		url: '/approvalProcessRecord/getApprovalRecord',
		method: 'get',
		params,
	})
}
