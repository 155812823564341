<template>
	<el-select
		class="w-full"
		v-model="value"
		multiple
		collapse-tags
		collapse-tags-tooltip
		filterable
		clearable
		placeholder="请选择"
	>
		<el-option v-for="item in dataList" :key="item.ID" :label="item.categoryName" :value="item.ID" />
	</el-select>
</template>

<!-- 费用维度级联选择 -->
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import { getPreviousFeeDimensions } from '@/api/serveApi/feeManagement/budgetTable'

interface Props {
	modelValue: number[]
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const dataList = ref([])

onMounted(() => {
	getData()
})

const getData = async () => {
	const res = await getPreviousFeeDimensions({})
	if (res && res.code === 0) {
		dataList.value = res.data
	}
}
</script>
