import { dayjs } from 'element-plus'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/pinia/modules/user'
import { createSysFrontLog, createSysFrontLogList } from '@/api/serveApi/common/common'
import { useAsyncState } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { StorageKey } from '@/config/storageConfig'
export enum EventType {
	/**用于页面统计 */
	PAGE = 'PAGE',
	/**调试 记录详细的调试信息，主要用于开发和调试阶段。*/
	DEBUG = 'DEBUG',
	/**信息  记录系统或应用程序的正常操作和状态。用于跟踪系统或应用的健康状况和流程，不需要特别关注或采取行动。*/
	INFO = 'INFO',
	/**警告  记录可能存在问题的事件或情况，虽然不会立即导致系统或应用程序错误，但需要关注和评估。例如，资源使用量接近上限、某些操作未按预期进行。*/
	WARNING = 'WARNING',
	/**错误  记录影响系统或应用程序正常运行的错误，需要立即关注和处理。*/
	ERROR = 'ERROR',
	/**致命  记录导致系统或应用程序崩溃或无法继续运行的严重问题，通常需要立即采取措施来恢复系统。*/
	FATAL = 'FATAL',
}
enum UpdateType {
	/**单个上传 */
	SINGLE = 'SINGLE',
	/**批量上传 */
	MULTIPLE = 'MULTIPLE',
}
interface EventInfo {
	data: string
	tip: string
	type?: EventType
}

interface Options {
	type: UpdateType
}
interface BetchUpdateOptions {
	isForce: boolean
	maxLength: number
}

interface UpdateParams {
	type: EventType
	time: string
	data: string
	tip: string
	traceId: string
	userId?: number
	departmentId?: number
	jobPositionId?: number
	userName?: string
	phone?: number
	authorityName?: string
	authorityId?: string
	userAgent?: string
	browser?: string
	routeFullPath?: string
	meteTitle?: string

	isSendBeacon?: number //1false，2true 判断是否是通过navigator.sendBeacon 提交
}

// 定义类型，可以是单个对象或者对象数组
type EventTrackingInfo = Record<string, any> | Array<Record<string, any>>
const MaxLength = 10
const isChrome = (userAgent) => {
	return (
		userAgent.indexOf('Chrome') > -1 &&
		userAgent.indexOf('Safari') > -1 &&
		userAgent.indexOf('Edge') === -1 &&
		userAgent.indexOf('OPR') === -1
	)
}
const isSafari = (userAgent) => {
	return (
		userAgent.indexOf('Safari') > -1 &&
		userAgent.indexOf('Chrome') === -1 &&
		userAgent.indexOf('Edge') === -1 &&
		userAgent.indexOf('OPR') === -1
	)
}
/**获取埋点基础信息 */
const getBaseInfo = (userStore, route) => {
	// 用户信息
	const { ID, departmentId, jobPositionId, nickName, phone, authority } = userStore?.userInfo ?? {}
	// 职位信息
	const { authorityName, authorityId } = authority ?? {}
	// 浏览器信息
	const userAgent = navigator.userAgent ?? ''
	console.log(`output->navigator`, navigator)
	// 明确具体浏览器类型
	const browser = isChrome(userAgent) ? 'Chrome' : isSafari(userAgent) ? 'Safari' : ''
	// 完整路由信息
	const routeFullPath = route.fullPath
	// 菜单名
	const meteTitle = route?.meta?.title ?? ''

	// 上传服务对象结构
	return {
		userId: ID,
		departmentId,
		jobPositionId,
		userName: nickName,
		phone,
		authorityName,
		authorityId,
		userAgent,
		browser,
		routeFullPath,
		meteTitle,
		traceId: uuidv4(),
		isSendBeacon: 1,
	}
}
/**保持埋点信息至缓存中 */
const saveEventTracking = (eventTrackingInfo, type = UpdateType.SINGLE) => {
	let events = JSON.parse(localStorage.getItem(StorageKey.TRACKEVENT)) || []
	if (type === UpdateType.SINGLE) {
		events.push(eventTrackingInfo)
	} else {
		events = events.concat(eventTrackingInfo)
	}
	localStorage.setItem(StorageKey.TRACKEVENT, JSON.stringify(events))
}
/**批量删除已上报缓存日志 */
const removeEventTracking = (trackList) => {
	const traceIdList = trackList.map((item) => item.traceId)
	const events = JSON.parse(localStorage.getItem(StorageKey.TRACKEVENT)) || []
	const restTrackList = events.filter((item) => !traceIdList.includes(item.traceId))
	localStorage.setItem(StorageKey.TRACKEVENT, JSON.stringify(restTrackList))
}

export const useEventTracking = () => {
	// 获取用户信息
	const userStore = useUserStore()
	// 获取路由信息
	const route = useRoute()

	const { execute: fetchCreateSysFrontLog } = useAsyncState(
		(arg) => createSysFrontLog({ ...arg }),
		{ code: 0, data: '' },
		{
			immediate: false,
		}
	)
	const { execute: fetchCreateSysFrontLogList } = useAsyncState(
		(arg) => createSysFrontLogList({ ...arg }),
		{ code: 0, data: '' },
		{
			immediate: false,
		}
	)

	const handleEvent = (e: EventInfo) => {
		// 当前操作埋点时间
		const eventTime = dayjs().format()
		// 用户主要处理类型及data信息
		const { type = EventType.INFO, tip = '', data = '' } = e ?? {}
		const dataToString = JSON.stringify(data)
		const baseInfo = getBaseInfo(userStore, route)
		// 上传服务对象结构
		const eventTrackingInfo: UpdateParams = {
			...baseInfo,
			type,
			time: eventTime,
			data: dataToString,
			tip,
		}
		saveEventTracking(eventTrackingInfo)
		// 高优先级直接上传
		if ([EventType.ERROR, EventType.FATAL].includes(type)) {
			updateEventTracking(eventTrackingInfo, { type: UpdateType.SINGLE })
		}
	}
	/**
	 * @description 埋点上传接口
	 * @param eventTrackingInfo 埋点信息
	 * @param options UpdateType 上传类型
	 */
	const updateEventTracking = async (eventTrackingInfo: EventTrackingInfo, options: Options) => {
		const { type = UpdateType.SINGLE } = options
		if (type === UpdateType.SINGLE) {
			try {
				const res = await fetchCreateSysFrontLog(0, eventTrackingInfo)
				if (res.code === 0) {
					removeEventTracking([eventTrackingInfo])
				}
			} catch (error) {}
		} else {
			try {
				const res = await fetchCreateSysFrontLogList(0, { list: eventTrackingInfo })
				if (res.code === 0) {
					removeEventTracking(eventTrackingInfo)
				}
			} catch (error) {}
		}
	}
	/**批量阈值提交 */
	const betchUpdateEventTracking = (options?: Partial<BetchUpdateOptions>) => {
		const { isForce = false, maxLength = MaxLength } = options ?? {}

		const events = JSON.parse(localStorage.getItem(StorageKey.TRACKEVENT)) || []
		// 正常清空根据后续条件触发，应用销毁或账号退出时应全量提交
		if (events.length === 0) return // 为空，无需提交
		if (!isForce && events.length < maxLength) return // 如果不是强制提交且事件列表长度小于最大长度，无需提交

		const firstTenItems = isForce ? events : events.slice(0, maxLength)
		updateEventTracking(firstTenItems, { type: UpdateType.MULTIPLE })
	}
	/**批量sendBeacon上报埋点 */
	const betchSendBeaconUpdate = () => {
		const sendBeaconUrl = '/api/sysFrontLog/createSysFrontLogList'
		const events = JSON.parse(localStorage.getItem(StorageKey.TRACKEVENT)) || []
		if (events.length > 0) {
			// 增加销毁提交标识
			const tempList = events.map((item) => {
				item.isSendBeacon = 2
				return item
			})
			navigator.sendBeacon(sendBeaconUrl, JSON.stringify({ list: tempList, token: userStore.token }))
		}
	}

	return {
		EventLog: handleEvent,
		updateEventTracking,
		betchUpdateEventTracking,
		betchSendBeaconUpdate,
	}
}
