import { ApprovalFieldType, ApprovalMethodType, NodeType } from '@/view/workflow/workflowConfig/interface'
import { ApprovalStatus } from '@/view/workflow/types'

/**
 * @description 审批操作
 * @param TRANSFER 转批
 * @param COPYTO   抄送
 * @param INTERACT 沟通
 * @param EDIT     编辑
 * @param WITHDRAW 撤回
 * @param ADDSIGN  加签
 */
export enum OperateType {
	TRANSFER = 'TRANSFER',
	COPYTO = 'COPYTO',
	INTERACT = 'INTERACT',
	EDIT = 'EDIT',
	WITHDRAW = 'WITHDRAW',
	ADDSIGN = 'ADDSIGN',
}

export const OperateTypeText: { [key in OperateType]: string } = {
	[OperateType.TRANSFER]: '转批',
	[OperateType.COPYTO]: '抄送',
	[OperateType.INTERACT]: '沟通',
	[OperateType.EDIT]: '编辑',
	[OperateType.WITHDRAW]: '撤回',
	[OperateType.ADDSIGN]: '加签',
}

/**
 * 审批流程图人员
 * @param headerImg       头像
 * @param userId          用户id
 * @param userName        用户名称
 * @param jobPositionId   职位id
 * @param jobPositionName 职位名称
 * @param approvalStatus 	审批状态
 */
export interface ApprovalUserType {
	headerImg: string
	userId: number
	userName: string
	jobPositionId: string
	jobPositionName: string
	approvalStatus: ApprovalStatus
}

/**
 * 审批流程图
 * @param nodeId 				 节点id
 * @param nodeName 			 节点名称
 * @param nodeType 			 节点类型
 * @param prevId 				 父节点id
 * @param approvalMethod 审批类型 【1 会签，2 或签】
 * @param approvalStatus 审批状态
 * @param approvers      审批人
 * @param child 				 子节点
 * @param conditions 		 条件分支
 */
export interface FlowChatType {
	nodeId: string
	nodeName: string
	nodeType: NodeType
	prevId: string
	approvalMethod: ApprovalMethodType
	approvalStatus: ApprovalStatus
	approvers: Array<ApprovalUserType>
	child: FlowChatType
	conditions: Array<FlowChatType>
}

/**
 * 审批数据类型
 * @param approvalContentId         每个业务模块主键ID
 * @param approvalRequestCode       流程编号
 * @param approvalFieldConfigs      审批步骤字段配置
 * @param approvalFlow 					    审批流程图
 * @param approvalProcessHistory    审批记录
 * @param approvalStatus 				    审批状态
 * @param approvalRequester         审批流发起人
 * @param copyUsers 						    抄送人
 * @param currentApprovers          当前审批人
 * @param currentApprovalNodeID     当前审批节点id
 * @param approvalAgreeNeedComment  同意审批意见是否必填 1-是 2-否 (审批节点必须)
 * @param approvalRejectNeedComment 拒绝审批意见是否必填 1-是 2-否 (审批节点必须)
 * @param strategy                  审批策略
 * @param addSign                   是否开启加签：0 是，1 否
 * @param isAddSignNode             是否为加签节点
 * @param msgNotifyUserIdList       所有的被沟通人
 */
export interface FlowDataType {
	approvalContentId: number
	approvalRequestCode?: string
	approvalFieldConfigs: ApprovalFieldType[]
	approvalFlow: FlowChatType
	approvalProcessHistory: any[]
	approvalStatus: ApprovalStatus
	approvalRequester: number
	copyUsers: ApprovalUserType[]
	currentApprovers: number[]
	currentApprovalNodeID: string
	approvalAgreeNeedComment?: 1 | 2
	approvalRejectNeedComment?: 1 | 2
	strategy?: number
	addSign?: 0 | 1
	isAddSignNode?: boolean
	msgNotifyUserIdList: number[]
}

/**
 * 初始化审批数据
 */
export class InitFlowData implements FlowDataType {
	approvalContentId = null
	approvalFieldConfigs = []
	approvalFlow = null
	approvalProcessHistory = []
	approvalRequester = null
	approvalStatus = null
	copyUsers = []
	currentApprovers = []
	currentApprovalNodeID = ''
	msgNotifyUserIdList = []
}
