import { useRoute } from 'vue-router'

/**
 * @description 维护各自页面 PageId  主要做一份配置记录表 方便路由更改后，有地方查找是否PageId错误问题
 * @returns 返回当前页面唯一pageId
 */
export const useCreatePageId = (pageId: string = '', data): string => {
	const { fullPath } = useRoute()
	const location: string = pageId || fullPath
	// 拆分url与参数params
	const locationUrl = location.split('?')
	// 标识字段名必须是type (统一)
	const type = locationUrl?.[1]?.split('type=')?.[1]
	// 参数整合
	const typeParams = type ? `?type=${type}` : ''
	// 完整pageId校验
	const urlId = locationUrl[0] + typeParams

	console.log(urlId, data, 'urlId')
	switch (urlId) {
		case '/layout/basicInformation/orgPersonnel/accountMgr': // 基础资料 - 组织人员 - 账号管理
		case '/layout/basicInformation/orgPersonnel/personnelMgr': // 基础资料 - 组织人员 - 人员管理
		case '/layout/basicInformation/orgPersonnel/positionMgr': // 基础资料 - 组织人员 - 职位管理
		case '/layout/basicInformation/orgPersonnel/organizationMgr': // 基础资料 - 组织人员 - 组织管理
		case '/layout/basicInformation/orgPersonnel/clearBinding': // 基础资料 - 组织人员 - 组织管理
		case '/layout/basicInformation/orgPersonnel/workHandover': // 基础资料 - 组织人员 - 工作交接
		case '/layout/basicInformation/factoryManagement': // 基础资料 - 工厂管理
		case '/layout/basicInformation/customerManagement/terminalMgr': // 基础资料 - 客户管理 - 终端管理
		case '/layout/basicInformation/customerManagement/salesArea': // 基础资料-客户管理-销售区域
		case '/layout/basicInformation/customerManagement/universalCustomerTags': // 基础资料-客户管理-客户标签-通用
		case '/layout/basicInformation/customerManagement/personalCustomerTags': // 基础资料-客户管理-客户标签-个人
		case '/layout/basicInformation/customerManagement/dealerMgr': // 基础资料-客户管理-渠道客户管理
		case '/layout/basicInformation/customerManagement/kaSystem': // 基础资料-客户管理-KA系统管理
		case '/layout/basicInformation/product/productList': // 基础资料 - 产品管理 - 产品管理
		case '/layout/basicInformation/product/productPrice?type=priceList': // 基础资料 - 产品管理 - 产品价格表管理
		case '/layout/basicInformation/product/productPrice?type=region': // 基础资料 - 产品管理 - 产品价格表管理
		case '/layout/basicInformation/product/productPrice?type=specific': // 基础资料 - 产品管理 - 产品价格表管理
		case '/layout/basicInformation/product/competitors': // 基础资料 - 产品管理 - 竞品管理
		case '/layout/basicInformation/product/gift': // 基础资料 - 产品管理 - 礼品管理
		case '/layout/basicInformation/product/giftCategory': // 基础资料 - 产品管理 - 礼品品类及单位管理
		case '/layout/basicInformation/dataPermissionsManagement': //基础资料 - 数据权限管理
		case '/layout/visitManagement/lineManagements/routeManagements': // 线路管理-线路列表
		case '/layout/visitManagement/lineManagements/routeSummarys': // 线路管理-线路汇总
		case '/layout/visitManagement/visitList?type=worker': // 拜访管理 - 拜访列表 - 负责的外勤
		case '/layout/visitManagement/visitList?type=visit': // 拜访管理 - 拜访列表 - 协访
		case '/layout/visitManagement/visitList?type=check': // 拜访管理 - 拜访列表 - 检核
		case '/layout/visitManagement/visitRecord?type=worker': // 拜访管理 - 拜访记录-负责的外勤
		case '/layout/visitManagement/visitRecord?type=visit': // 拜访管理 - 拜访记录-协防
		case '/layout/visitManagement/visitRecord?type=check': // 拜访管理 - 拜访记录-检核
		case '/layout/visitManagement/visitWorkflowConfig': // 拜访管理 - 拜访流程配置
		case '/layout/visitManagement/visitCollection/missedVisit': // 拜访管理 - 拜访采集-失访原因
		case '/layout/visitManagement/visitCollection/livelyCheck': // 拜访管理 - 拜访采集-生动化检查
		case '/layout/visitManagement/visitCollection/assetsInspect': // 拜访管理 - 拜访采集-资产巡检
		case '/layout/visitManagement/visitCollection/storeInspect': // 拜访管理 - 拜访采集-门店检查
		case '/layout/visitManagement/visitCollection/competitorAcquisition': // 拜访管理 - 拜访采集-竞品采集
		case '/layout/visitManagement/visitCollection/executeActivity': // 拜访管理 - 拜访采集-活动执行
		case '/layout/visitManagement/attendanceRecord/onTime': // 拜访管理 - 考勤记录-打卡记录
		case '/layout/visitManagement/attendanceRecord/overTime': // 拜访管理 - 考勤记录-加班记录
		case '/layout/visitManagement/attendanceRecord/absences': // 拜访管理 - 考勤记录-请假记录
		case '/layout/visitManagement/attendanceRecord/outworkCard': // 拜访管理 - 考勤记录-外勤打卡
		case '/layout/basicInformation/refrigeratorManage/refrigeratorBrand': // 基础资料-冰箱管理-冰箱品牌
		case '/layout/basicInformation/refrigeratorManage/refrigeratorType': // 基础资料-冰箱管理-冰箱类型
		case '/layout/basicInformation/refrigeratorManage/refrigeratorModel': // 基础资料-冰箱管理-冰箱型号
		case '/layout/basicInformation/refrigeratorManage/refrigeratorDRules': // 基础资料-冰箱管理-冰箱陈列规则
		case '/layout/basicInformation/refrigeratorManage/advertisingStickers': // 基础资料-冰箱管理-广告贴画
		case '/layout/contractManagement/contractTemplateMgr?type=1': //合同管理-模块管理-主合同
		case '/layout/contractManagement/contractTemplateMgr?type=2': //合同管理-模块管理-冰箱协议
		case '/layout/contractManagement/contractTemplateMgr?type=3': //合同管理-模块管理-活动协议
		case '/layout/contractManagement/contractTemplateMgr?type=4': //合同管理-模块管理-附件
		case '/layout/contractManagement/contractTemplateMgr?type=5': //合同管理--模块管理-切结书
		case '/layout/contractManagement/contractList': // 合同管理-合同列表
		case '/layout/contractManagement/refrigeratorAgreement': //合同管理-冰箱协议
		case '/layout/contractManagement/activityAgreement': // 合同管理-活动协议
		case '/layout/contractManagement/contractDataManagement/subjectManagement': //合同管理-资料管理-主体管理
		case '/layout/contractManagement/contractDataManagement/sealManagement/sealType': //合同管理- 资料管理-印章管理-印章类型
		case '/layout/contractManagement/contractDataManagement/sealManagement/seal': //  合同管理-资料管理-印章管理-印章
		case '/layout/assetManagement/refrigeratorAssetManagement/refrigeratorAssetCard': // 资产管理-冰箱资产管理-冰箱资产卡
		case '/layout/assetManagement/refrigeratorAssetManagement/businessAssetManagement': // 资产管理-冰箱资产管理-业务方
		case '/layout/assetManagement/refrigeratorAssetManagement/refrigeratorMaintenanceRecord': // 资产管理-冰箱资产管理-冰箱维修记录
		case '/layout/assetManagement/refrigeratorProtocolManagement/ProtocolManagementDetails': // 资产管理-冰箱资产管理-冰箱协议管理
		case '/layout/assetManagement/refrigeratorAssetManagement/assetInspectionForm': // 资产管理-冰箱资产管理-资产巡检表
		case '/layout/assetManagement/refrigeratorAssetManagement/statisticsOnScrappedAssets': // 资产管理-冰箱资产管理-报废资产统计
		case '/layout/assetManagement/refrigeratorAssetManagement/refrigeratorPuttingRecord': // 资产管理-冰箱资产管理-冰箱投放记录表
		case '/layout/assetManagement/refrigeratorPlanControl/refrigeratorShippingPlan': // 资产管理-冰箱计划控制-年度冰箱发货计划
		case '/layout/assetManagement/refrigeratorPlanControl/refrigeratorAdjustmentDetails': // 资产管理-冰箱计划控制-计划调整明细
		case '/layout/assetManagement/refrigeratorOrderManagement/areaShipmentSummary': // 资产管理-冰箱订单管理-区域发货汇总表
		case '/layout/assetManagement/refrigeratorOrderManagement/refrigeratorApplicationOrderDetails': // 资产管理-冰箱订单管理-冰箱申请计划明细表
		case '/layout/assetManagement/refrigeratorOrderManagement/refridgeratorOrder': // 资产管理-冰箱订单管理-冰箱订单
		case '/layout/assetManagement/refrigeratorOrderManagement/refrigeratorTypeShipmentSummary': // 资产管理-冰箱订单管理-冰箱类型发货汇总表
		case '/layout/assetManagement/refrigeratorOrderManagement/receiptSchedule': // 资产管理-冰箱订单管理-签收明细表
		case '/layout/assetManagement/refrigeratorOrderManagement/refrigeratorSignatureSummary': // 资产管理-冰箱订单管理-冰箱签收汇总表
		case '/layout/orderManage/directOrders': // 订单管理-直营订单
		case '/layout/statisticalReport/customerMonthPick': // 报表管理-客户月提货汇总表
		case '/layout/statisticalReport/customerMonthSales': // 报表管理-客户月销量汇总表
		case '/layout/planningManagement/planImportRecord': // 需求计划管理-需求计划导入记录
		case '/layout/planningManagement/planSummary': // 需求计划管理-需求计划汇总表
		case '/layout/systemSetting/messageCenter': // 系统设置-消息中心
		case '/layout/systemSetting/dictionaryManage': // 系统设置-字典管理
		case '/layout/systemSetting/operationLog': // 系统设置-操作日志
		case '/layout/inventoryManagement/channel/channelInventory': // 库存管理 - 渠道客户 - 渠道客户库存
		case '/layout/inventoryManagement/channel/channelRuleInventory': // 库存管理 - 渠道客户 - 常规库存上报记录
		case '/layout/inventoryManagement/channel/channelCancelInventory': // 库存管理 - 渠道客户 - 核销库存上报记录
		case '/layout/inventoryManagement/channel/channelTransferRecord': // 库存管理 - 渠道客户 - 库存调拨记录
		case '/layout/inventoryManagement/terminal/terminalInventory': // 库存管理 - 终端客户 - 终端库存
		case '/layout/inventoryManagement/terminal/terminalInventoryRecord': // 库存管理 - 终端客户 - 库存判断记录
		case '/layout/workflow/workflowConfig': // 流程管理 - 流程配置
		case '/layout/workflow/mySend': // 流程管理 - 我发起的
		case '/layout/workflow/myApproval': // 流程管理 - 待我审批
		case '/layout/workflow/myApproved': // 流程管理 - 我已审批
		case '/layout/workflow/sendMy': // 流程管理 - 抄送我的
		case '/layout/workflow/allApproval': // 流程管理 - 全部审批
		case '/layout/feeManagement/feeSubject': // 费用管理 - 费用科目
		case '/layout/feeManagement/feeDimension': // 费用管理 - 费用维度
		case `/layout/feeManagement/feeDimensionValue`: // 费用管理 - 费用维度值
		case '/layout/feeManagement/budgetTable': // 费用管理 - 预算表
		case '/layout/feeManagement/feeBudgetary': // 费用管理 - 费用预算
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryOdd`: // 费用管理-预算申请
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryOdd?type=plant`: // 费用审批-汇总表
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryDetailSummary?type=budget`: // 费用汇总明细表-预算
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryDetailSummary?type=pickup&salesPlan`: // 费用汇总明细表-提货&销售计划
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryChangedSummary`: // 预算变动明细表
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryApprove/${data}`: // 预算申请审批
		case `/layout/feeManagement/feeBudgetary/feeBudgetaryApprove?type=plant`: // 预算审批查看
		case '/layout/feeManagement/feeApplication': // 费用管理 - 费用申请
		case '/layout/feeManagement/feeApplication/feeApplicationType': // 费用管理 - 费用申请-费用申请类型
		case `/layout/feeManagement/feeApplication/feeApplicationOdd`: // 费用管理 - 费用申请-费用申请单
		case `/layout/feeManagement/feeApplication/feeApplicationApprove/${data}`: //费用申请 - 费用申请审批
		case '/layout/feeManagement/feeCancellation': // 费用管理 - 费用核销-
		case `/layout/feeManagement/feeCancellation/cancellationByActivity`: // 费用管理 -费用核销- 活动核销
		case `/layout/feeManagement/feeCancellation/cancellationByApplicationOdd`: // 费用管理 -费用核销- 申请单核销
		case `/layout/feeManagement/feeCancellation/cancellationByPromotionpolicy`: // 费用管理 -费用核销- 促销政策核销(test)
		case `/layout/feeManagement/feeCancellation/customVerification`: // 费用管理 -费用核销- 自定义核销核销
		case `/layout/feeManagement/feeCancellation/customVerification/${data}`: // 费用管理 -费用核销- 自定义核销核销(新版)
		case '/layout/feeManagement/feeCancellation/expenseVerificationRecords?type=cost': // 费用管理 -费用核销-费用核销记录-费用申请单
		case '/layout/feeManagement/feeCancellation/expenseVerificationRecords?type=activity': // 费用管理 -费用核销-费用核销记录-活动方案
		case '/layout/feeManagement/feeCancellation/expenseVerificationRecords?type=promotion': // 费用管理 -费用核销-费用核销记录-促销政策
		case '/layout/feeManagement/feeCancellation/expenseVerificationRecords?type=vertification': // 费用管理 -费用核销-费用核销记录-自定义
		case `/layout/feeManagement/feeCancellation/feeCancellationApprove/${data}`: // 费用管理- 费用核销审批
		case '/layout/feeManagement/feeReport/salesPlanSummary': // 费用管理-费用报表-销售计划汇总表
		case '/layout/activityManagement/activityAImodel': // 活动管理 - AI模型
		case '/layout/activityManagement/activityExecuteProject': // 活动管理 - 执行项目
		case '/layout/activityManagement/activityType': // 活动管理 - 活动类型
		case `/layout/activityManagement/activityProject/proposalApplication`: // 活动管理 - 活动方案-方案申请
		case '/layout/activityManagement/activityProject/activitySchemeApproval': // 活动管理 - 活动方案-活动方案审批
		case '/layout/activityManagement/activeProcess/activityExecution': // 活动管理 - 活动进程-进程管理
		case '/layout/activityManagement/activeProcess/activityApplyFor': // 活动管理 - 活动审批
		case '/layout/activityManagement/activeProcess/executeApproval': // 活动管理 - 活动进程-执行审批
		case '/layout/activityManagement/activeProcess/rectificationApproval': // 活动管理 - 活动进程-整改审批
		case '/layout/activityManagement/activityOver/activityOverApplication': // 活动管理 - 活动结案-结案申请
		case '/layout/activityManagement/activityOver/activityOverApproval': // 活动管理 - 活动方案-结案审批
		case '/layout/promotionPolicy/promotionType': // 促销政策 - 促销类型
		case '/layout/promotionPolicy/policyApplication': // 促销政策 - 促销申请
		case '/layout/promotionPolicy/policyApplication?type=getOutPlant':
		case '/layout/promotionPolicy/policyApplication?type=promotion':
		case '/layout/promotionPolicy/promotionSalesReport': // 促销政策 - 促销出厂上报
		case '/layout/promotionPolicy/promotionSalesReport?type=getOutPlant':
		case '/layout/promotionPolicy/promotionSalesReport?type=promotion':
		case `/layout/promotionPolicy/policyApprove/${data}`: //促销政策 - 政策审批
		case `/layout/statisticalReport/refrigeratorDetails`: // 统计报表 - 冰箱情况明细
		case '/layout/statisticalReport/fieldDaily': // 统计报表 - 外勤日报
		case '/layout/planningManagement/regionalFactories': // 需求计划 - 区域工厂关系表
		case '/layout/planningManagement/channelRelationMaintenance': // 需求计划 - 渠道关系维护表
		case '/layout/systemSetting/announcement': // 系统设置 - 通知公告
		case '/layout/feeManagement/feeReport/monthlyDealerTable': // 费用管理 - 经销商月度总结表
		case '/layout/systemTools/frontEndLogList': // 系统工具 - 前端日志列表
		case '/layout/systemTools/backendTools/errorCode': // 系统工具 - 后端工具 - 错误码查询
		case '/layout/orderManage/terminalOrders': // 订单管理 - 终端订单上报
			return urlId
		default:
			return ''
	}
}

