<template>
	<!-- 虚拟选择器 -->
	<el-select-v2
		v-if="options.isSelect"
		@change="handleChange"
		@visible-change="handleVisibleChange"
		v-model="values"
		:multiple="multiple"
		:options="optionList"
		style="width: 100%"
		collapse-tags
		collapse-tags-tooltip
		v-bind="$attrs"
	>
		<template #default="{ item }">
			<XZLTooltip :content="item.label" placement="right">
				<!-- <span>
					{{ item.label }}
				</span> -->
			</XZLTooltip>
		</template>
		<!-- <template #tag>
			<el-tag v-for="item in values">
				<el-tooltip :content="item">
					{{ item }}
				</el-tooltip>
			</el-tag>
		</template> -->
	</el-select-v2>
	<!-- 级联选择器-->
	<!-- <el-cascader v-else :options="optionList" v-model="values" :props="{
		checkStrictly: checkStrictly,
		multiple: multiple,
		emitPath: emitPath,
		value: options.defaultValue,
		label: options.defaultLabel,
	}" :clearable="clearable" :filterable="filterable" getCheckedNodes collapse-tags collapse-tags-tooltip
		:show-all-levels="false" style="width: 100%" :placeholder="placeholder" :disabled="disabled" /> -->
</template>
<!-- 不需要要接口传递数据, 直接传递数据, 设置仅仅使用传递数据, 需要则走接口 -->
<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import XZLTooltip from '@/components/proxyComponents/XZLTooltip/index.vue'
interface Prop {
	options?: {
		type: String // 操作类型(ADD, EDIT)
		disabledValue: Boolean | number // 禁用的key对应的value(目前没有使用)
		isSelect: Boolean // 是否是选择器
		disabledName?: String // 禁用的key (默认disabled, 可能是status为key 值为1启用, 2禁用)

		defaultLabel?: String // 设置选择器上的label属性
		defaultValue?: String // 设置选择器的value属性
	}
	//传递数据
	tranmitData?: { list: []; joinList: [] } // 传递进来的数据
	// 仅仅使用传递数据
	modelValue: [Array<number>, Number] | null | number // 外部v-model绑定值
	multiple?: Boolean // 是否多选
	[key: string]: any
}
const props = withDefaults(defineProps<Prop>(), {
	options: () => ({
		type: OpeationBtnType.ADD,
		disabledName: 'status',
		childrenName: 'children',
		isSelect: true,
		disabledValue: false,
		// api: (data: {}) => {},

		defaultLabel: 'label',
		defaultValue: 'value',
	}),
	//传递数据
	tranmitData: () => ({ list: [], joinList: [] }),
	// 仅仅使用传递数据
	filterable: true,
	modelValue: null,
	emitPath: false,
	clearable: true,
	multiple: false,
	checkStrictly: false,
	placeholder: '可输入名称搜索',
})
const emits = defineEmits([
	'insertSuccess',
	'update:modelValue',
	'change',
	'resetValid',
	'visibleChange',
	'getSelectedObject',
])
const options = Object.assign(
	{
		type: OpeationBtnType.ADD,
		disabledName: 'status',
		tranmitData: { list: [], joinList: [] },
		childrenName: 'children',
		isSelect: true,
		disabledValue: false,
		api: (data: {}) => {},
		requestParam: { immediate: false, page: 1, pageSize: 20 },
		defaultLabel: 'label',
		defaultValue: 'value',
	},
	props.options
)
console.log(options.requestParam.immediate, props.options, 'optiosns')
const resultData = ref([])
const optionList = ref([])
let initEchoData = ref([])
const handleChange = (data) => {
	emits(
		'getSelectedObject',
		optionList.value.find((item) => item[options.defaultValue] == data)
	)
	emits('change', data)
}
const values = computed({
	get() {
		return props.modelValue
	},
	set(val) {
		console.log(val, 'val4444444')
		emits('update:modelValue', val)
	},
})

const setStatus = (data) => {
	const status = {
		1: false, // status 1 启用
		2: true,
	}
	return data?.map((item) => {
		return {
			...item,
			label: item[options.defaultLabel],
			value: item[options.defaultValue],
			disabled: item.hasOwnProperty('disabled') ? item.disabled : status[item[options.disabledName]], // 如果不传了状态key则默认为false(不禁用)
		}
	})
}
// 根据操作按钮设置数据
const setOptionList = (data) => {
	if (props.options.type == OpeationBtnType.ADD) {
		return data.filter((item) => {
			return item.disabled === false
		})
	}
	console.log(data, 'data11111111')
	return data
}

const getNeedData = (list, joinList) => {
	console.log(`output->list, joinList`, list, joinList)
	const needData = list?.filter((item) => joinList.includes(item?.[options.defaultValue]))
	console.log(`output->needData`, list, needData, joinList)
	list = list?.filter((item) => !joinList.includes(item?.[options.defaultValue]) && item?.status !== 2) ?? []
	return list.concat(needData)
}

const handleVisibleChange = (data) => {
	emits(
		'visibleChange',
		optionList.value.find((item) => item[options.defaultValue] == values.value),
		data
	)
}
// 是否仅仅使用传递数据
watch(
	() => props.tranmitData,
	async (val, oldVal) => {
		console.log(`output->val, oldVal-tranmitData`, val, oldVal)
		console.log(`output->val-props.tranmitData`, val, props.options.type)
		let list = val.list ?? []
		// 数据处理-只在list从无到有处理一次 后续无须处理 使用完整数据即可
		if (initEchoData.value?.length <= 0) {
			initEchoData.value = val?.joinList?.filter(Boolean) ?? []
		}
		// 置空或者初始化
		if (val?.joinList?.length === 0) {
			initEchoData.value = []
		}
		let joinList = initEchoData.value
		if (props.options.type == OpeationBtnType.ADD) {
			list = list?.filter((item) => item?.status !== 2) ?? []
			// 有默认初始值
			if (
				list?.length > 0 &&
				!list.some((item) => item?.[options.defaultValue] == props.modelValue) &&
				(typeof props.modelValue == 'number' || typeof props.modelValue == 'string')
			) {
				emits('update:modelValue', null)
				emits('resetValid', true)
			}
		} else if (props.options.type === OpeationBtnType.VIEW) {
			list?.forEach((item) => {
				item && (item.status = 1)
			})
		} else {
			// [1,2,3]
			if (joinList.every((item) => typeof item === 'number' || typeof item === 'string')) {
				list = getNeedData(list, joinList)
			} else {
				//[{ id: 1, name: '3333' }]
				// 使用 Map的唯一性，如果传入数据与列表数据一致，直接使用列表数据
				let needDataMap = new Map()
				joinList.forEach((item) => {
					needDataMap.set(item?.[options.defaultValue], { ...item, status: 2 })
				})
				list
					?.filter((item) => item?.status !== 2)
					?.forEach((item) => {
						needDataMap.set(item?.[options.defaultValue], item)
					})
				list = [...needDataMap.values()]
			}
		}
		optionList.value = setStatus(list)
	},
	{
		immediate: true,
		deep: true,
	}
)
</script>

<style scoped lang="scss">
:deep(.el-input__validateIcon) {
	display: none;
}
</style>
