<template>
	<div ref="rootRef">
		<div ref="parentRef" :style="{ maxWidth: parentWidth + 'px' }" class="inline-flex gap-md overflow-hidden">
			<el-button
				v-for="(button, index) in state.normalList"
				:key="button.type"
				class="!m-0"
				:type="button.buttonType ? button.buttonType : index === 0 ? 'primary' : ''"
				:plain="!!button.buttonType"
				@click="handleClick(button)"
			>
				<template v-if="button.icon" #icon>
					<SvgIcon :name="button.icon" />
				</template>
				<component v-if="typeof button.label === 'function'" :is="button.label" />
				<span v-else>{{ button.label }}</span>
			</el-button>
		</div>
		<el-dropdown v-if="state.overflowList.length" ref="dropdownRef" placement="bottom-start">
			<el-button class="ml-md"><SvgIcon class="mr-[6px]" name="icon-more" />更多</el-button>
			<template #dropdown>
				<el-dropdown-menu>
					<el-dropdown-item
						v-for="button in state.overflowList"
						:key="button.type"
						:class="getDrawItemClass(button)"
						@click="handleClick(button)"
					>
						<SvgIcon v-if="button.icon" :name="button.icon" />
						<component v-if="typeof button.label === 'function'" :is="button.label" />
						<span v-else>{{ button.label }}</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick, isRef } from 'vue'
import { ElDropdown } from 'element-plus'
import { useElementSize } from '@vueuse/core'
import { slice, debounce } from 'lodash-es'
import { ApplyType } from '@/hooks/usePageDrawer/types'
import { DialogDataType } from '@/hooks/usePageDrawer/types'

interface Props {
	type: ApplyType
	buttonList: DialogDataType[]
}
const props = defineProps<Props>()
const emit = defineEmits(['click'])

const _Max_List = 7
const _Max_Drawer = 4

const rootRef = ref<HTMLElement>()
const parentRef = ref<HTMLElement>()
const dropdownRef = ref<InstanceType<typeof ElDropdown>>()
const parentWidth = ref<number>(0)
const sliceLength = ref<number>(0)

const { width: rootWidth } = useElementSize(rootRef)

const state = computed(() => {
	const buttonList = (isRef(props.buttonList) ? (props.buttonList.value as Array<any>) : props.buttonList) ?? []
	const data = buttonList?.filter((item) => {
		if (props.type === ApplyType.LIST) {
			return !item.apply || item.apply?.includes(ApplyType.ALL) || item.apply?.includes(ApplyType.LIST)
		} else if (props.type === ApplyType.DRAWER) {
			return item.apply?.includes(ApplyType.ALL) || item.apply?.includes(ApplyType.DRAWER)
		}
		return false
	})

	const max = props.type === ApplyType.LIST ? _Max_List : _Max_Drawer
	const index = sliceLength.value > 0 && sliceLength.value < max ? sliceLength.value : max

	const normalList = slice(data, 0, index)
	const overflowList = slice(data, index)

	if (overflowList.length === 1) {
		normalList.push(overflowList[0])
		overflowList.splice(0, 1)
	}

	return { normalList, overflowList }
})

watch(rootWidth, async () => {
	parentWidth.value = rootWidth.value - (dropdownRef.value?.$el.offsetWidth || 0)
	await nextTick()
	initPage()
})

onMounted(() => {
	initPage()
})

const initPage = debounce(async () => {
	sliceLength.value = 0
	const parentRect = await parentRef.value?.getBoundingClientRect()

	parentRef.value?.childNodes?.forEach((item: HTMLElement, index) => {
		if (item.nodeName === 'BUTTON') {
			const clientRect = item.getBoundingClientRect()

			if (clientRect.right > parentRect.left + parentWidth.value && sliceLength.value <= 0) {
				// 详情页很多有5个按钮的情况，要是超出4个就有更多，更多里面就只有一个，所以要减2
				sliceLength.value = props.type === ApplyType.LIST ? index - 1 : index - 2
			}
		}
	})
}, 30)

const getDrawItemClass = (button: DialogDataType) => {
	switch (button.buttonType as string) {
		case 'primary':
			return '!text-primary'
		case 'success':
			return '!text-success'
		case 'warning':
			return '!text-warning'
		case 'danger':
			return '!text-danger'
		case 'error':
			return '!text-error'
		case 'info':
			return '!text-info'
		default:
			return ''
	}
}

const handleClick = (btn) => {
	emit('click', btn)
}
</script>
