import { h, ref } from 'vue'
import { useAsyncState, useThrottleFn, useWebSocket as socket, useNetwork } from '@vueuse/core'
import { useUserStore } from '@/pinia/modules/user'
import { v4 as uuidv4 } from 'uuid'
import { WebSocketMessageType } from './types'
import { useWebsocketStore } from '@/pinia/modules/websocket'
import { useMessageCenter } from '@/hooks/useMessageCenter'
import { useDashboardAnnouncement } from '@/view/dashboard/announcement/useDashboardAnnouncement'
import { useEventTracking } from '@/hooks/useEventTracking'
export enum WebsocketStatus {
	OPEN = 'OPEN',
	CONNECTING = 'CONNECTING',
	CLOSED = 'CLOSED',
}

const { fetchGetPublicAnnouncementList } = useDashboardAnnouncement()
const { isSupported, isOnline } = useNetwork()

/**
 * @description websocket 原生不支持header添加数据 故使用子协议传递参数
 * header=[x-token,x-request-type,x-user-id,x-trace-id]
 * x-token
 * x-request-type  APP的时候需要传1  web端默认不填或者为0
 * x-user-id
 * x-trace-id
 */
const getwebSocketHeader = () => {
	const userStore = useUserStore()
	return [userStore.token, 0, userStore?.userInfo?.ID, uuidv4(), 'json']
}

/**接收真实后端反馈已读消息，变更socketStore */
const receiveReadMessage = async (val, websocketStore) => {
	const { msgTypeMap } = useMessageCenter()
	// 拍平 所有消息类型
	const totalMessage = websocketStore.getAllValues()
	// 遍历消息类型，寻找与已读ids对应的消息，改变状态为已读
	totalMessage.forEach((item) => {
		if (val.includes(item.ID)) {
			// 映射的枚举值转换
			const key = msgTypeMap(item.msgType)
			//设置消息为已读并同步状态到store中
			websocketStore.setItem(key, { ...item, isRead: true })
		}
	})
}

const typeEevnt = (value, websocketStore) => {
	const { msgType, data } = value ?? {}

	// 更新消息总数
	// websocketStore.socketStoreTotal = data?.unReadCount ?? websocketStore.socketStoreTotal
	switch (msgType) {
		case 1:
			websocketStore.setItem(WebSocketMessageType.MessageTypeNotify, data)
			websocketStore.notifyTotal = data?.notifyUnreadCount ?? 0
			websocketStore.approvalTotal = data?.approvalUnreadCount ?? 0
			break
		case 2:
			websocketStore.setItem(WebSocketMessageType.MessageTypeApproval, data)
			websocketStore.notifyTotal = data?.notifyUnreadCount ?? 0
			websocketStore.approvalTotal = data?.approvalUnreadCount ?? 0
			break
		case 3:
			websocketStore.setItem(WebSocketMessageType.MessageTypeWorkOrder, data)
			break
		case 6:
			// downloadFinished 标识为有文件被下载了
			if (data === 'downloadFinished') {
				websocketStore.isNewDownLoad = true
			} else {
				websocketStore.setItem(WebSocketMessageType.MessageTypeSyncDownloadResultMsgRsp, data)
			}
			break
		case 10:
			const { ids } = data
			receiveReadMessage(ids, websocketStore)
			websocketStore.notifyTotal = data?.notifyUnreadCount ?? 0
			websocketStore.approvalTotal = data?.approvalUnreadCount ?? 0
			break
		case 14:
			fetchGetPublicAnnouncementList(0, { page: 1 })
			break

		case 16:
			const { content } = data
			websocketStore.setItem(WebSocketMessageType.MessageTypeWebUpgradeNotify, content)
			break
		case 17:
			const { contentList, menuRouterName, title } = data
			websocketStore.setItem(WebSocketMessageType.MessageTypeBudgetarySummary, { contentList, menuRouterName, title })
			break
		case 18:
			// 删除枚举未使用，setItem 需要的是单条而删除返回的是数组类型故 减少遍历性能消耗，直接进行具体类型过滤

			const { ids: subClassList = [], sysSettingMessageList = [] } = data
			/** app端进行全部删除动作时，后端返回空数组，表示进行了全部删除动作，web端不区分类型全部删除，然后根据sysSettingMessageList进行追加 */
			if (subClassList?.length === 0) {
				websocketStore.removeItem(WebSocketMessageType.MessageTypeNotify)
				websocketStore.removeItem(WebSocketMessageType.MessageTypeApproval)
				websocketStore.removeItem(WebSocketMessageType.MessageTypeWorkOrder)
			} else {
				// 删除当前类子项数据
				websocketStore.removeItemSubclass(WebSocketMessageType.MessageTypeNotify, subClassList)
				websocketStore.removeItemSubclass(WebSocketMessageType.MessageTypeApproval, subClassList)
				websocketStore.removeItemSubclass(WebSocketMessageType.MessageTypeWorkOrder, subClassList)
			}

			// 当用户未读数还存在，继续补充至首页及铃铛处
			if (sysSettingMessageList?.length > 0) {
				sysSettingMessageList.forEach((item) => {
					if (item.msgType === 1) {
						websocketStore.setItem(WebSocketMessageType.MessageTypeNotify, item)
					}
					if (item.msgType === 2) {
						websocketStore.setItem(WebSocketMessageType.MessageTypeApproval, item)
					}
					if (item.msgType === 3) {
						websocketStore.setItem(WebSocketMessageType.MessageTypeWorkOrder, item)
					}
				})
			}

			websocketStore.notifyTotal = data?.notifyUnreadCount ?? 0
			websocketStore.approvalTotal = data?.approvalUnreadCount ?? 0
			break
	}
	const socketStoreTotal = websocketStore.notifyTotal + websocketStore.approvalTotal
	websocketStore.socketStoreTotal = socketStoreTotal
}

const createWebSocket = (websocketStore) => {
	const { betchUpdateEventTracking } = useEventTracking()
	const header = JSON.parse(JSON.stringify(getwebSocketHeader()))

	// 如果token缺失
	if (!header?.[0]) return null
	// 根据地址变更websocket请求地址
	const hostname = `${location?.protocol === 'http:' ? 'ws' : 'wss'}://${location?.host}`
	const installObj = socket(`${hostname}/sysSettingMessages/getWebSocket`, {
		protocols: [...header],
		immediate: false,
		//心跳
		heartbeat: {
			message: 'ping',
			interval: 15e3,
			pongTimeout: 15e3,
		},
		//自动重连
		autoReconnect: {
			delay: 10e3,
			onFailed() {
				console.error('消息通讯连接重连3次失败!')
			},
		},
		onConnected: (ws: WebSocket) => {
			// console.log(`output->onConnected,ws`, ws)
		},
		onDisconnected: async (ws: WebSocket, event: CloseEvent) => {
			console.log(`output->onDisconnected,ws,event`, ws, event)

			const { code, wasClean, type } = event
			// 自定义判断是否异常断开情况
			const isFail = code === 1006 && type === 'close' && wasClean === false
			// 确保重联只发生在网页活动状态（有网）并且此刻ws是正常的
			if (isOnline.value && isSupported.value && isFail) {
				console.log('到达了onDisconnected-isFail', isFail)
				// 确保其他场景如果存在同时触发，就关闭上一个
				installObj.close(1000)
				// 等待websocket 关闭完成，否则会引起上一个自动重新连接
				await nextTick()
				installObj.open()
			}
		},
		onError: (ws: WebSocket, event: Event) => {
			console.log('WebSocket closed with code: ', ws, event)
		},
		onMessage: (ws: WebSocket, event: MessageEvent) => {
			console.log(ws, event, 'fdsfsfdsf111111111')
			// console.log(`output->onMessage,ws,event`, ws, event)
			if (event.type === 'message') {
				// pong 是用于心跳保持连接 不做具体的信息传递
				if (event.data === 'pong') {
					// 通过心跳处理批量埋点触发时机
					betchUpdateEventTracking?.()
					return false
				}
				if (event.data === 'close') {
					// 后端通知关闭，前端主动关闭
					// 兼容异地登录问题
					installObj.close(1000)
					return false
				}

				const data = JSON.parse(event.data ?? '{}')
				typeEevnt(data, websocketStore)
			}
		},
	})
	return installObj
}

export const useWebSocket = () => {
	const websocketStore = useWebsocketStore()
	if (!websocketStore.instanceWebSocket) {
		websocketStore.instanceWebSocket = createWebSocket(websocketStore)
	}
	return websocketStore.instanceWebSocket
}

