<template>
	<div ref="parentRef">
		<el-form ref="formRef" v-bind="$attrs" :label-width="showLabel ? 'auto' : ''" :label-position="labelPosition">
			<div :class="[btnWidth > 0 ? 'grid gap-4' : '']" :style="{ gridTemplateColumns: `repeat(${rowLength}, 1fr)` }">
				<slot></slot>
				<div :class="getFormItemClass()">
					<div ref="btnRef" class="inline-block whitespace-nowrap">
						<slot name="prepend"></slot>
						<el-button type="primary" @click="handleQuery">{{ confirmText }}</el-button>
						<el-button @click="handleReset">{{ cancelText }}</el-button>
						<slot name="append"></slot>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script setup lang="ts">
import { ref, watch, computed, useAttrs } from 'vue'
import { type FormInstance } from 'element-plus'
import { useElementSize } from '@vueuse/core'
import { ceil } from 'lodash-es'

interface Props {
	// 是否显示label标题，默认false，此属性会引起表单项的宽度变化
	showLabel?: boolean

	// 按钮位置，默认右边
	buttonPosition?: 'left' | 'center' | 'right'

	// 搜索文案
	confirmText?: string

	// 重置文案
	cancelText?: string
}

const props = withDefaults(defineProps<Props>(), {
	showLabel: false,
	inline: false,
	buttonPosition: 'right',
	confirmText: '搜索',
	cancelText: '重置',
})
const emit = defineEmits(['search', 'reset'])

defineOptions({
	inheritAttrs: false,
})

const parentRef = ref<HTMLElement | null>()
const formRef = ref<FormInstance>()
const btnRef = ref<HTMLElement | null>()

const rowLength = ref<number>(6) // 默认一行显示6个
const buttonColSpan = ref<number>(1)
const formItemWidth: Record<string, number> = {
	default: 200,
	label: 320,
}
const expose = ref<Record<string, any>>({})

const attrs = useAttrs()
const { width: parentWidth } = useElementSize(parentRef)
const { width: btnWidth } = useElementSize(btnRef)

// 同element-plus => form => label-posotion
const labelPosition = computed<any>(() => {
	const porision = [1, 2].includes(rowLength.value) ? 'top' : 'left'
	return porision || (attrs.labelPosition as string)
})

watch(
	[btnWidth, parentWidth],
	() => {
		if (!btnWidth.value || !parentWidth.value) return
		// 拿到默认宽度
		const defaultWidth = props.showLabel ? formItemWidth.label : formItemWidth.default
		// 计算每行显示几个
		rowLength.value = Math.floor(parentWidth.value / defaultWidth)
		// 计算按钮占的列数
		const itemWidth = parentWidth.value / rowLength.value
		let span: number = btnWidth.value > itemWidth ? ceil(btnWidth.value / itemWidth) : 1

		if (span > 1 && span % 2 === 1) {
			span++
		}

		buttonColSpan.value = span
	},
	{ flush: 'sync' }
)

const getFormItemClass = () => {
	return [
		getClass(`text-${props.buttonPosition}`),
		getClass(`col-span-${buttonColSpan.value}`),
		getClass(`col-end-${rowLength.value + 1}`),
	]
}

// 获取class
const getClass = (className: string) => {
	switch (className) {
		case 'text-left':
		case 'text-center':
		case 'text-right':
		case 'col-span-1':
		case 'col-span-2':
		case 'col-span-3':
		case 'col-span-4':
		case 'col-span-5':
		case 'col-span-6':
		case 'col-span-7':
		case 'col-span-8':
		case 'col-span-9':
		case 'col-span-10':
		case 'col-span-11':
		case 'col-span-12':
		case 'col-end-1':
		case 'col-end-2':
		case 'col-end-3':
		case 'col-end-4':
		case 'col-end-5':
		case 'col-end-6':
		case 'col-end-7':
		case 'col-end-8':
		case 'col-end-9':
		case 'col-end-10':
		case 'col-end-11':
		case 'col-end-12':
			return className
		default:
			return ''
	}
}

// 搜索
const handleQuery = () => {
	emit('search')
}

// 重置
const handleReset = () => {
	emit('reset')
}

defineExpose(expose.value)
</script>

<style lang="scss" scoped>
.el-form :deep(.el-form-item) {
	margin-bottom: 0;
	.el-form-item__label-wrap {
		margin-right: 0 !important;
		.el-form-item__label {
			width: auto;
		}
	}
}
</style>
