export interface UserType {
	empId: string
	userId: number
	userName: string
	departmentId: string
	departmentName: string
	departmentNameList: string[]
	jobPositionId: string
	jobPositionName: string
	headerImg: string
}

export interface DisabledUserType {
	userIds: number[]
	empIds: string[]
}

export type DepartType = Pick<UserType, 'departmentId' | 'departmentName'> & {
	employeeNum: number
	subDept?: Array<DepartType>
}

export enum Category {
	User = 1,
	Depart,
}

export type ListItem = Partial<UserType & DepartType> & {
	type: Category
}

export type ListDataKey = 'userList' | 'departList'

export type ListDataType = Record<ListDataKey, ListItem[]>

export interface TabType {
	label: string
	data: ListDataType
	checked: boolean
	indeterminate: boolean
	checkDisabled: boolean
}
