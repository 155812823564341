<template>
	<div style="width: 100%" ref="boxRef">
		<ul
			v-if="state.data.imageList.length > 0"
			class="gap-3 el-upload-list el-upload-list--picture-card"
			v-bind="$attrs"
		>
			<li v-for="(src, index) in showUrlList" :key="src" class="flex el-upload-list__item" :style="style">
				<Image :src="src" :disabled="disabled" @click="handlePreview(index)" />
				<span v-if="!disabled" class="el-upload-list__item-actions">
					<span class="el-upload-list__item-preview">
						<el-icon><zoom-in @click="handlePreview(index)" /></el-icon>
					</span>
					<span class="el-upload-list__item-delete">
						<el-icon>
							<Delete @click="handleRemove(index)" />
						</el-icon>
					</span>
				</span>
			</li>

			<template v-if="state.data.overflowList.length > 0">
				<el-popover placement="top" width="auto" trigger="hover" v-model:visible="showPopover">
					<template #reference>
						<li class="flex el-upload-list__item" :style="{ width: imageSize + 'px', height: imageSize + 'px' }">
							<Image
								:src="state.data.overflowList[0]"
								:disabled="disabled"
								@click="handlePreview(state.data.imageList.length - 1)"
							/>
							<span class="el-upload-list__item-actions !opacity-100" style="font-size: 16px"
								>+ {{ state.data.overflowList.length }}</span
							>
						</li>
					</template>

					<ul class="gap-3 el-upload-list el-upload-list--picture-card">
						<li
							v-for="(src, index) in state.data.overflowList"
							:key="src"
							class="el-upload-list__item !m-0 flex"
							:style="{ width: imageSize + 'px', height: imageSize + 'px' }"
						>
							<Image :src="src" :disabled="disabled" @click="handlePreview(state.data.imageList.length + index)" />
						</li>
					</ul>
				</el-popover>
			</template>
		</ul>

		<el-image-viewer
			v-if="state.index >= 0"
			:url-list="fullPathList"
			:initialIndex="state.index"
			@close="handleClose"
			teleported
		/>
	</div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, ComputedRef } from 'vue'
import { ZoomIn, Delete } from '@element-plus/icons-vue'
import { slice, drop, compact } from 'lodash-es'
import { getFullPath } from '@/utils/common'
import Image from './image.vue'
import { useElementSize } from '@vueuse/core'

interface Props {
	// 图片列表
	urlList: string[]

	// 图片尺寸，默认 148px，最大高度或最大宽度优先级高于此属性
	imageSize?: number

	// 最大显示数量，仅在查看时生效（编辑时不生效），也就是disabled属性为true时生效
	max?: number

	// 最大宽度
	maxWidth?: number

	// 最大高度
	maxHeight?: number

	// 禁用时不可删除，可以点击图片预览
	disabled?: boolean

	// 点击时是否预览图片，默认true
	isClickPreview?: boolean

	// 删除方法
	onRemove?: (index: number) => void

	// 最大显示图片列表数量
	maxUrlList?: number

	//图片根据所处dom宽度自适应展示
	adaptiveDisplay?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	imageSize: 80,
	isClickPreview: true,
	adaptiveDisplay: false,
})

const showPopover = ref<boolean>(false)

const fullPathList = computed(() => {
	const imageList = compact(props.urlList)
	return imageList?.map((src) => getFullPath(src)) ?? []
})

const state = reactive<{ index: number; data: ComputedRef<Record<'imageList' | 'overflowList', string[]>> }>({
	// 当前预览第几张图片，默认为-1，则不开启图片预览功能
	index: -1,

	// 图片列表
	data: computed(() => {
		let imageList: string[] = [] // 在最大数量限制内的图片
		let overflowList: string[] = [] // 超出最大数量限制的图片

		if (typeof props.max === 'number' && props.max > 0 && props.urlList?.length > props.max && props.disabled) {
			imageList = slice(fullPathList.value, 0, props.max)
			overflowList = drop(fullPathList.value, props.max)
			return { imageList, overflowList }
		} else if (props.adaptiveDisplay) {
			imageList = slice(fullPathList.value, 0, maxShowNum.value)
			overflowList = drop(fullPathList.value, maxShowNum.value)
			return { imageList, overflowList }
		}
		return { imageList: fullPathList.value, overflowList: [] }
	}),
})

const showUrlList = computed(() => {
	const { maxUrlList } = props
	if (!maxUrlList || maxUrlList >= state.data.imageList.length) return state.data.imageList
	return slice(state.data.imageList, 0, maxUrlList)
})

const style = computed(() => {
	return {
		width: props.maxWidth || props.maxHeight ? 'auto' : props.imageSize + 'px',
		maxWidth: props.maxWidth + 'px',
		height: props.maxWidth || props.maxHeight ? 'auto' : props.imageSize + 'px',
		maxHeight: props.maxHeight + 'px',
	}
})

// 关闭
const handleClose = (): void => {
	state.index = -1
}

// 预览
const handlePreview = (index: number): void => {
	if (!props.isClickPreview) return
	state.index = index
	showPopover.value && (showPopover.value = false)
}

// 删除
const handleRemove = (index: number): void => {
	props.onRemove && props.onRemove(index)
}
const boxRef = ref<HTMLElement | null>(null)
const maxShowNum = ref<number>(4) // 最大显示数量
const urlListValue = ref<number>()
const { width: boxWidth } = useElementSize(boxRef)

watch(boxWidth, (newVal) => {
	if (newVal > 0 && props.maxWidth) {
		const width = props.maxWidth + 12
		urlListValue.value = Math.floor(newVal / width)
		urlListValue.value >= 0 && (maxShowNum.value = urlListValue.value - 1)
	} else if (newVal > 0 && props.imageSize) {
		const width = props.imageSize + 12
		urlListValue.value = Math.floor(newVal / width)
		urlListValue.value >= 0 && (maxShowNum.value = urlListValue.value - 1)
	}
})
</script>

<style lang="scss" scoped>
.el-upload-list__item {
	margin: 0;
}
</style>
