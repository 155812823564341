<template>
	<el-select
		v-model="values"
		:clearable="props.clearable"
		:filterable="props.filterable"
		:remote-method="remoteMethod"
		:remote-show-suffix="props.remoteShowSuffix"
		v-bind="$attrs"
		v-el-select-loadmore="loadMore"
	>
		<template #prefix v-if="props.slotName?.includes('prefix')">
			<slot name="prefix" />
		</template>
		<template #empty v-if="props.slotName?.includes('empty')">
			<slot name="empty" />
		</template>
		<el-option
			v-for="item in optionList"
			:key="item[props.options.defaultValue]"
			:label="item[props.options.defaultLabel]"
			:value="item[props.options.defaultValue]"
			:disabled="item.disabled"
		/>
		<p v-if="isMore" class="loading">正在加载...</p>
	</el-select>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed, reactive } from 'vue'
import { watchDebounced } from '@vueuse/core'

interface Prop {
	modelValue: Array<any> | null | number | string // 外部v-model绑定值
	api?: Function // 请求函数
	requestType?: String // 请求类型 （列表表格请求过滤条件通过filter字段过滤）和（其他请求）  table | custom
	options?: {
		// 列表大部分使用 name - ID
		defaultLabel?: string // 设置选择器上的label属性
		defaultValue?: string // 设置选择器的value属性
	}
	joinList?: Array<any> // 传递到selec的optionList的数据
	filterParams?: [] // 列表fliter 过滤 参数 （适用于列表接口）
	requestParams?: Object // 接口请求参数
	immediate?: boolean // 是否立即请求
	enableData?: boolean // 查询的启用数据
	disabledFieldStatus?: boolean // 是否禁用状态为2的数据
	handleReqData?: Function | null // 处理接口数据的回调函数
	pageSize?: number // 接口分页大小
	slotName?: Array<any>
	clearable?: boolean
	filterable?: boolean
	remoteShowSuffix?: boolean
}

const props = withDefaults(defineProps<Prop>(), {
	// 仅仅使用传递数据
	modelValue: null,
	api: () => [],
	requestType: () => 'table',
	pageSize: () => 20,
	filterParams: () => [],
	requestParams: () => ({}),
	joinList: () => [],
	options: () => ({
		defaultLabel: 'name',
		defaultValue: 'ID',
	}),
	immediate: true,
	disabledFieldStatus: false,
	handleReqData: null,
	checkStrictly: false,
	placeholder: '可输入名称搜索',
	slotName: () => [],
	clearable: true,
	filterable: true,
	remoteShowSuffix: true,
})

const emits = defineEmits(['update:modelValue'])
const values = computed({
	get() {
		return props.modelValue
	},
	set(val) {
		console.log('被设置了')
		emits('update:modelValue', val)
	},
})

const optionList = ref([]) // 下拉显示的数据
const isMore = ref(true) // 是否下一页
let searchFilterParams = [] // 远程搜索  (只用于表格接口搜索)
const keyWord = ref('')
const pageParams = reactive({
	page: 1,
	pageSize: props.pageSize,
	filters: [],
}) // 接口参数信息

const loading = ref(false) // 是否是loading状态

// 获取接口数据
const getOptionsData = async (isPush: boolean = false, joinList = props.joinList) => {
	try {
		loading.value = true
		const { api, requestParams, handleReqData, disabledFieldStatus, filterParams, requestType } = props
		// 区分请求类型
		switch (requestType) {
			case 'table':
				// 合并默认参数和搜索参数和传入的参数
				pageParams.filters = [...searchFilterParams, ...filterParams]
				break
			case 'custom':
				break
		}
		const res = await api({ ...pageParams, ...requestParams })
		loading.value = false
		let data = res.data?.list ?? []
		if (typeof handleReqData === 'function') {
			data = handleReqData(data)
		} else if (disabledFieldStatus) {
			data = data.map((item) => ({ ...item, disabled: item.status === 2 }))
		}
		// 是否还有下一页
		isMore.value = data.length === props.pageSize
		// 过滤join传入
		isPush ? optionList.value.push(...data) : (optionList.value = data)
		// 传入数据和接口数据合并
		optionList.value = [...joinList, ...optionList.value]
		// 过滤重复defaultValue的数据
		optionList.value = deduplication(optionList.value)
	} catch (error) {
		console.log(error, 'erererer')
		loading.value = false
	}
}

// 监听传入的optionList变化
watch(
	() => [props.requestParams, props.filterParams, props.api, props.joinList],
	(newValue) => {
		pageParams.page = 1
		getOptionsData()
	},
	{ deep: true, immediate: props.immediate }
)

// 搜索
const remoteMethod = (query: string) => {
	keyWord.value = query
}

watchDebounced(
	() => keyWord.value,
	(newValue, oldValue) => {
		if (newValue || oldValue) {
			pageParams.page = 1
			let joinList: Array<any> = props.joinList
			if (newValue) {
				searchFilterParams = [
					{ fieldName: props.options.defaultLabel, fieldValues: [newValue], fromType: 'text', operator: 'LIKE' },
				]
				// 防止接口数据未返回joinList的数据（停用），手动搜索joinList数据
				joinList = joinList.filter((item) => {
					const value: any = item[props.options.defaultLabel]
					if (typeof value === 'string') {
						return value.includes(newValue)
					}
					return false
				})
			} else {
				searchFilterParams = []
			}

			getOptionsData(false, joinList)
		}
	},
	{ debounce: 300, maxWait: 1000 }
)

// 加载下一页
const loadMore = () => {
	if (isMore.value && loading.value === false) {
		pageParams.page++
		getOptionsData(true)
	}
}

// 去除当前数组含有目标数组是否有相同key的数据
const deduplication = (options) => {
	const map = new Map()
	options.forEach((item) => {
		map.set(item[props.options.defaultValue], item)
	})
	return Array.from(map.values())
}
</script>

<style scoped>
.loading {
	color: #409eff;
	cursor: pointer;
	line-height: 30px;
	text-align: center;
}
</style>
