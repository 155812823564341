<template>
	<div>
		<div class="flex cursor-pointer items-center" @click="handlePoints" v-show="props.address">
			<el-icon class="el-input__icon" style="color: red">
				<LocationFilled />
			</el-icon>
			<XZLTooltip :maxWidth="360" :content="props.address" />
		</div>
		<!-- 查看地图 -->
		<mapPoints
			v-if="mapData.visible"
			v-model="mapData.visible"
			:pointData="{ address: mapData.address, position: mapData.position }"
		/>
	</div>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue'
import { LocationFilled } from '@element-plus/icons-vue'
import mapPoints from '@/components/mapPoints/index.vue'
import XZLTooltip from '@/components/proxyComponents/XZLTooltip/index.vue'

const props = defineProps({
	location: {
		type: Object,
		default: () => {},
	},
	address: {
		type: String,
		default: '',
	},
})
// 查看地图
const mapData = reactive<{ visible: boolean; address: string; position: any }>({
	visible: false,
	address: '',
	position: [],
})
//点击预览
const handlePoints = () => {
	const { longitude, latitude } = props.location
	mapData.visible = true
	mapData.address = props.address
	if (longitude && latitude) {
		mapData.position = [longitude, latitude]
	}
}
</script>
