/*
 * @Author: zouliang 535841308@qq.com
 * @Date: 2024-03-05 14:41:22
 * @LastEditors: zouliang 535841308@qq.com
 * @LastEditTime: 2024-05-11 14:59:53
 * @FilePath: \digitizing_web\src\hooks\userPagePermissions\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useUserStore } from '@/pinia/modules/user'
import { ref } from 'vue'
/**
 * @description 该注释为后端的 各个模块的 按钮映射关系  在数据处理中已经全部转换成大些字母
 * @description 在操作时 字母全部大些即可 例如以下 param
 * @param ADD  新增
 * @param EDIT  编辑
 * @param DELETE  删除
 * @param ENABLE  启用
 * @param DISABLE  停用
 * 映射关系看 同文件下OperationBtnList.md 文档
 *  */
/**
 * @description 将tree数组直接全部拉平 将路由名字与扁平化的tree 对比
 * @returns 当前模块的最终权限集合
 * @param btnList=[{type: 'ADD', label: '新增', width: '60%'},{type: 'EDIT', label: '编辑', width: '60%'}, {type: 'ENABLE', label: '启用', width: '20%'},{type: 'DISABLE', label: '停用', width: '20%'}]
 * @param stettingData =[{label: '职位编号', prop: 'jobPositionId', isShow: true, isLock: false},{label: '状态', prop: 'status', isShow: true, isLock: false, slotName: 'status'}]
 * @param permissionName 抽屉需要展示相应模块权限按钮
 */
export const handlePermissions = (btnList, stettingData, permissionName = undefined) => {
	// 注意: 下面的配置不要更改，如需跳过权限请使用 npm run test -- --d
	if (import.meta.env.VITE_NOPERMISSION === 'true') {
		return { btnList: btnList, pagTableColumn: stettingData }
	}
	const userStore = useUserStore()

	const pageRouterName = permissionName ?? sessionStorage.getItem('activeRouter')?.toUpperCase()
	const pageList = userStore.userPermissions
	const rolseList = pageList.find((item) => item?.name?.toUpperCase() == pageRouterName)
	// 字符串头部大写
	function toUpper(str) {
		return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
	}
	const settings = stettingData.reduce((list, current) => {
		const item = rolseList?.fieldList?.find(
			(el) => current.prop === el.name || current.slotName === el.name || current.prop === toUpper(el.name)
		)
		if (item?.status === 1 || item?.isDyField) {
			list.push(current)
		}
		// 添加动态维度(带有isDyField标识)
		if (item == undefined) {
			current.isDyField && list.push(current)
		}
		return list
	}, [])
	const btn = ref([])
	if (rolseList?.buttonList.length > 0) {
		btnList.forEach((item) => {
			rolseList.buttonList.forEach((res) => {
				if (item.type == res.name.toUpperCase() && res.status === 1) {
					btn.value.push(item)
				}
			})
			if (item?.hasPermission) btn.value.push(item)
		})
	}
	console.log(rolseList, 'buttonLsit')
	return { btnList: btn.value, pagTableColumn: settings }
}
