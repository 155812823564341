<template>
	<!-- <el-select
		v-if="frequency === 1"
		v-model="dateTimes"
		:multiple="true"
		clearable
		placeholder="请选择"
		@change="handlerSelectClear"
		@remove-tag="handleRemoveTage"
		style="width: 100%"
	>
		<div>
			<el-option
				v-for="item in weekList"
				:key="item.value"
				:label="item.label"
				:value="item.value"
				style="width: 100%"
			/>
		</div>
	</el-select> -->
	<XZLSelect
		v-bind="$attrs"
		v-if="frequency === 1"
		v-model="dateTimes"
		:multiple="true"
		:joinList="weekList"
		:options="{
			defaultLabel: 'label',
			defaultValue: 'value',
		}"
		clearable
		placeholder="请选择"
		@change="handlerSelectClear"
		@remove-tag="handleRemoveTage"
		style="width: 100%"
	>
	</XZLSelect>
	<!-- <el-select
		v-if="frequency === 2"
		v-model="dateTimes"
		:multiple="true"
		clearable
		placeholder="请选择"
		@change="handlerSelectClear"
		@remove-tag="handleRemoveTage"
		style="width: 100%"
	>
		<template #empty>
			<div class="grid grid-cols-7 date-select">
				<div class="select-item" v-for="(item, index) in datelist" :key="index" @click="handleMoth(item)">
					<div class="date-item" :class="item.status ? 'select' : ''">
						{{ item.label }}
					</div>
				</div>
			</div>
		</template>
	</el-select> -->
	<XZLSelect
		v-bind="$attrs"
		v-if="frequency === 2"
		:slotName="['empty']"
		v-model="dateTimes"
		:multiple="true"
		clearable
		:filterable="false"
		placeholder="请选择"
		@change="handlerSelectClear"
		@remove-tag="handleRemoveTage"
		style="width: 100%"
	>
		<template #empty>
			<div class="grid grid-cols-7 date-select">
				<div class="select-item" v-for="(item, index) in datelist" :key="index" @click="handleMoth(item)">
					<div class="date-item" :class="item.status ? 'select' : ''">
						{{ item.label }}
					</div>
				</div>
			</div>
		</template>
	</XZLSelect>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import { datelist, weekList } from './config'

const props = defineProps({
	modelValue: {
		type: Array,
		default: () => [],
	},
	frequency: {
		type: Number,
		default: 1,
	},
	refs: {
		type: Object,
		default: () => {},
	},
})

const emit = defineEmits(['update:modelValue'])

const dateTimes = ref<any>([])

watch(
	() => props.modelValue,
	() => {
		dateTimes.value = props.modelValue
	},
	{
		deep: true,
		immediate: true,
	}
)

// 监听radio变化，如果选择每月，则清空之前选择的状态
watch(
	() => props.frequency,
	(newVal) => {
		if (newVal === 2) {
			datelist.value.forEach((item) => {
				item.status = false
			})
			dateTimes.value = []
		}
	}
)

// 选择每月
const handleMoth = (val: any) => {
	const indexs = dateTimes.value.findIndex((item: any) => item === val.label)
	const selectIndex = datelist.value.findIndex((str: any) => str.label === val.label)
	if (indexs !== -1) {
		datelist.value[selectIndex].status = false
		dateTimes.value.splice(indexs, 1)
	} else {
		dateTimes.value.push(val.label)
		datelist.value[selectIndex].status = true
	}
	dateTimes.value.sort((a, b) => a - b)
	emit('update:modelValue', dateTimes.value)
	if (props.refs) {
		props.refs.validateField('visitedTime')
	}
}
// 多选的tag移除事件
const handleRemoveTage = (val: number) => {
	const removeIndex = datelist.value.findIndex((str: any) => str.label === val)
	datelist.value[removeIndex].status = false
}
// 清除所有
const handlerSelectClear = () => {
	if (dateTimes.value.length !== 0) {
		emit('update:modelValue', dateTimes.value)
		return
	}
	datelist.value.forEach((item: any) => {
		item.status = false
	})
	emit('update:modelValue', [])
}
</script>
<style lang="scss" scoped>
.frequency-box {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.date-select {
	.select-item {
		cursor: pointer;
		user-select: none;
		box-sizing: border-box;
		padding: 7px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

		.date-item {
			width: 24px;
			height: 24px;
			line-height: 24px;
			border-radius: 50%;
			text-align: center;
			font-size: 12px;
		}

		.select {
			background-color: #5974f9;
			color: #fff;
		}
	}
}
</style>
