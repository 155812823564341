<template>
	<el-select
		v-model="values"
		multiple
		placeholder="可输入名称搜索"
		style="width: 100%"
		@change="handleChange"
		filterable
		collapse-tags
		collapse-tags-tooltip
	>
		<el-option v-for="item in options" :key="item.jobPositionId" :label="item.name" :value="item.jobPositionId" />
	</el-select>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { getOrgPositionNameList } from '@/api/serveApi/basicInformation/positionMgr'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
	modelValue: {
		type: Array<Number>,
		required: true,
	},
})
const options = ref([])
const values = ref([])
watch(
	() => props.modelValue,
	() => {
		console.log('values', values)
		values.value = props.modelValue
	},
	{ deep: true, immediate: true }
)
onMounted(() => {
	getPosition()
})
//获取经销商
const getPosition = async () => {
	const res = await getOrgPositionNameList({})
	options.value = res.data.positionNameList
}
const handleChange = () => {
	emit('update:modelValue', values.value)
}
</script>

<style></style>
