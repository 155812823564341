import service from '@/utils/request'

//分页获取冰箱型号列表
export const getRefFridgeModelList = (data: any) => {
	return service({
		url: '/refFridgeModel/getRefFridgeModelList',
		method: 'post',
		data,
	})
}

//查询所有冰箱型号
export const getAllRefFridgeModel = (params?: any) => {
	return service({
		url: '/refFridgeModel/getAllRefFridgeModel',
		method: 'get',
		params,
	})
}

//创建冰箱型号
export const createRefFridgeModel = (data: any) => {
	return service({
		url: '/refFridgeModel/createRefFridgeModel',
		method: 'post',
		data,
	})
}

//删除冰箱型号
export const deleteRefFridgeModel = (data: any) => {
	return service({
		url: '/refFridgeModel/deleteRefFridgeModel',
		method: 'delete',
		data,
	})
}

//批量删除冰箱型号
export const deleteRefFridgeModelByIds = (data: any) => {
	return service({
		url: '/refFridgeModel/deleteRefFridgeModelByIds',
		method: 'delete',
		data,
	})
}

//停用冰箱型号
export const disabledStatus = (data: any) => {
	return service({
		url: '/refFridgeModel/disabledStatus',
		method: 'put',
		data,
	})
}

//启用冰箱型号
export const enableStatus = (data: any) => {
	return service({
		url: '/refFridgeModel/enableStatus',
		method: 'put',
		data,
	})
}

//用id查询冰箱型号
export const findRefFridgeModel = (params: any) => {
	return service({
		url: '/refFridgeModel/findRefFridgeModel',
		method: 'get',
		params,
	})
}

//更新冰箱型号
export const updateRefFridgeModel = (data: any) => {
	return service({
		url: '/refFridgeModel/updateRefFridgeModel',
		method: 'put',
		data,
	})
}

//文件导出
export const exportFile = (data: any) => {
	return service({
		url: '/refFridgeModel/getRefFridgeModelList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}

//审核
export const approval = (data: any) => {
	return service({
		url: '/refFridgeModel/approval',
		method: 'put',
		data,
	})
}
