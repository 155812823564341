<template>
	<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
		<el-form-item label="审批人变更为" prop="userId">
			<pick-user
				v-model="ruleForm.userId"
				:isAuth="false"
				:multiple="false"
				:unSelectable="currentApprovers"
				:uniqueUser="false"
			/>
		</el-form-item>
		<el-form-item label="变更原因(选填)" prop="reason">
			<XZLInput v-model="ruleForm.reason" type="textarea" rows="3" maxlength="200" show-word-limit />
		</el-form-item>
	</el-form>

	<div class="text-right">
		<el-button text @click="emit('close')">取消</el-button>
		<el-button type="primary" :loading="loading" @click="handleConfirm">确定</el-button>
	</div>
</template>

<script setup lang="ts">
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { transfer } from '@/api/serveApi/workflow/workflowOperation'
import { reactive, ref } from 'vue'

interface Props {
	code: string
	currentApprovers: number[]
}

const props = defineProps<Props>()

const emit = defineEmits(['refresh', 'close'])

interface RuleForm {
	reason: string
	userId: number
}

const loading = ref<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
	reason: '',
	userId: null,
})
const rules = reactive<FormRules>({
	userId: [{ required: true, message: '请选择审批人', trigger: 'change' }],
})

const handleConfirm = async () => {
	await ruleFormRef.value?.validate(async (valid) => {
		if (valid) {
			loading.value = true
			const res = await transfer({
				...ruleForm,
				approvalRequestCode: props.code,
			})
			if (res && res.code === 0) {
				ElMessage.success(res.msg || '转批成功')
				emit('refresh', true)
				emit('close')
			}
			loading.value = false
		}
	})
}
</script>
