import { DrawerType } from '@/hooks/usePageDrawer/types'
import { reactive, Component, toRefs, markRaw, toValue } from 'vue'
import { dayjs, ElMessage, ElTable } from 'element-plus'
import { v4 as uuidv4 } from 'uuid'
import { useUserStore } from '@/pinia/modules/user'
import { useOperationBtnList, ModalWidthType, BtnItem } from '../useOperationBtnList'
import { getCancellationApproveTypeList } from '@/api/serveApi/feeManagement/feeCancellationApprove'
import { useAsyncState } from '@vueuse/core'
// import { useDealerMgr } from '@/view/basicInformation/customerManagement/dealerMgr/useDealerMgr'
// import { usePersonnelMgr } from '@/view/basicInformation/organizationpersonnel/personnelMgr/usePersonnelMgr'
// import { useBtnterminalMgr } from '@/view/basicInformation/customerManagement/terminalMgr/useBtnOperation'
// import { useChannelTransferRecord } from '@/view/inventoryManagement/channel/channelTransferRecord/useChannelTransferRecord'
// import { useProductList } from '@/view/basicInformation/productManagement/productList/useProductList'
// import { useProductPrice } from '@/view/basicInformation/productManagement/productPrice/useProductPrice'
// import { useCompetitors } from '@/view/basicInformation/productManagement/competitors/useCompetitors'
// import { useGift } from '@/view/basicInformation/productManagement/gift/useGift'
// import { useTerminalInventories } from '@/view/inventoryManagement/terminal/terminalInventory/useTerminalInventory'
// import { useTerminalInventoryRecord } from '@/view/inventoryManagement/terminal/terminalInventoryRecord/useTerminalInventoryRecord'
// import { useRefrigeratorDRules } from '@/view/basicInformation/refrigeratorManage/refrigeratorDRules/useBtnOperation'
// import { useBudgetOperation } from '@/view/feeManagement/budgetTable/useBudgetTableOperation'
// import { useYearFeeOperation } from '@/view/feeManagement/feeBudgetary/yearFeeBudgetary/useYearFeeOperation'
// import { useMonthFeeOperation } from '@/view/feeManagement/feeBudgetary/monthFeeBudgetary/useMonthFeeOperation'
// import { usePromotionTypeOperation } from '@/view/promotionPolicy/promotionType/usePromotionTypeOperation'
// import { useFeeAppliactionOddOperation } from '@/view/feeManagement/feeApplication/feeApplicationOdd/useFeeApplicationOddOperation'
// import { useCancellationByActivityOperation } from '@/view/feeManagement/feeCancellation/cancellationByActivity/useCancellationByActivityOperation'
// import { usePromotionApplicationOperation } from '@/view/promotionPolicy/policyApplication/usePromotionApplicationOperation'
// import { useContractTemplateMgr } from '@/view/contractManagement/contractTemplateMgr/useContractTemplateMgr'
// import { useContractList } from '@/view/contractManagement/contractList/useContractList'
// import { useChannelInventory } from '@/view/inventoryManagement/channel/channelInventory/useChannelInventory'
// import { useChannelInventoryRecord } from '@/view/inventoryManagement/channel/channelInventoryRecord/useChannelInventoryRecord'
// import { useRefrigeratorPlan } from '@/view/assetManagement/refrigeratorPlanControl/refrigeratorShippingPlan/useRefrigeratorPlan'
// import { useRefridgeratorOrder } from '@/view/assetManagement/refrigeratorOrderManagement/refridgeratorOrder/useRefridgeratorOrder'
// import { useRefrigeratorAssetCard } from '@/view/assetManagement/refrigeratorAssetManagement/refrigeratorAssetCard/useRefrigeratorAssetCard'
// import { useBusinessAssetManagement } from '@/view/assetManagement/refrigeratorAssetManagement/businessAssetManagement/useBusinessAssetManagement'
// import { useRefrigeratorMaintenanceRecord } from '@/view/assetManagement/refrigeratorAssetManagement/refrigeratorMaintenanceRecord/useRefrigeratorMaintenanceRecord'
// import { useStatisticsOnScrappedAssets } from '@/view/assetManagement/refrigeratorAssetManagement/statisticsOnScrappedAssets/useStatisticsOnScrappedAssets'
// import { useProtocolManagementDetails } from '@/view/assetManagement/refrigeratorProtocolManagement/ProtocolManagementDetails/useProtocolManagementDetails'
// import { useRefrigeratorPuttingRecord } from '@/view/assetManagement/refrigeratorAssetManagement/refrigeratorPuttingRecord/useRefrigeratorPuttingRecord'
// import { useActivityAImodel } from '@/view/activityManagement/activityAImodel/useActivityAImodelOperation'
// import { useActivityExecution } from '@/view/activityManagement/activeProcess/activityExecution/useActivityExecution'
// import { useActivitySchemeApproval } from '@/view/activityManagement/activityProject/activitySchemeApproval/useActivitySchemeApproval'
// import { useCancellationByApplicationOddOperation } from '@/view/feeManagement/feeCancellation/cancellationByApplicationOdd/useCancellationByApplicationOddOperation'
// import { useAssetsInspect } from '@/view/visitManagement/visitCollection/assetsInspect/useAssetsInspect'
// import { useCancellationByCustomizeOperation } from '@/view/feeManagement/feeCancellation/cancellationByCustomize/useCancellationByCustomizeOperation'
// import { useCustomVerification } from '@/view/feeManagement/feeCancellation/customVerification/useCustomVerification'
// import { useMissedVisit } from '@/view/visitManagement/visitCollection/missedVisit/useMissedVisit'
// import { useStoreInspect } from '@/view/visitManagement/visitCollection/storeInspect/useStoreInspect'
// import { useLivelyCheck } from '@/view/visitManagement/visitCollection/livelyCheck/useLivelyCheck'
// import { useWorkflowConfig } from '@/view/workflow/workflowConfig/useWorkflowConfig'
// import { useProposalApplication } from '@/view/activityManagement/activityProject/proposalApplication/useProposalApplication'
// import { useExecuteApproval } from '@/view/activityManagement/activeProcess/executeApproval/useExecuteApproval'
// import { useActivityApplyFor } from '@/view/activityManagement/activityApplication/activityApplyFor/useActivityApplyFor'
// import { useRefrigeratorModel } from '@/view/basicInformation/refrigeratorManage/refrigeratorModel/useRefrigeratorModel'
// import { useYearBudgetaryApproval } from '@/view/feeManagement/feeBudgetary/yearBudgetaryApprove/useYearBudgetaryApprove'
// import { useFeeBudgetaryApproveOperation } from '@/view/feeManagement/feeBudgetary/feeBudgetaryApprove/useFeeBudgetaryApproveOperation'
// import { useMonthBudgetaryApproval } from '@/view/feeManagement/feeBudgetary/monthBudgetaryApprove/useMonthBudgetaryApprove'
// import { useFeeApplicationApproval } from '@/view/feeManagement/feeApplication/feeApplicationApprove/useFeeApplicationApprove'
// import { useActivityOverApplication } from '@/view/activityManagement/activityOver/activityOverApplication/useActivityOverApplication'
// import { userPolicyApprove } from '@/view/promotionPolicy/policyApprove/userPolicyApprove'
// import { useSubjectManagement } from '@/view/contractManagement/contractDataManagement/subjectManagement/useSubjectManagement'
// import { useFeeBudgetaryOddOperation } from '@/view/feeManagement/feeBudgetary/feeBudgetaryOdd/useFeeBudgetaryOddOperation'
// import { useAnnouncement } from '@/view/systemSetting/announcement/useAnnouncement'
// import { useDashboardAnnouncement } from '@/view/dashboard/announcement/useDashboardAnnouncement'
// import { useKaSystem } from '@/view/basicInformation/customerManagement/kaSystem/useKaSystem'
// import { usePlanSummary } from '@/view/planningManagement/planSummary/usePlanSummary'

import { formData } from '@/view/feeManagement/feeCancellation/feeCancellationApprove/useFeeCancellationApproveOperation'
// import { usePromotionSalesReportOperation } from '@/view/promotionPolicy/promotionSalesReport/usePromotionSalesReportOperation'
import { storeToRefs } from 'pinia'
import { useDrawer } from '@/pinia/modules/drawer'
import { checkServiceFn } from './preCheckService'

const { execute: asyncGetCancellationApproveTypeList } = useAsyncState(
	(data?: any) => getCancellationApproveTypeList(data) as any,
	{ code: 0, msg: '', data: [] },
	{
		immediate: false,
		onSuccess(res) {
			if (res?.code !== 0) return
		},
	}
)
/**
 * @description 统一弹窗宽度百分比
 * @param PERCENTAGE20  20%
 * @param PERCENTAGE30  30%
 * @param PERCENTAGE40  40%
 * @param PERCENTAGE60  60%
 * @param PERCENTAGE80  80%
 * @param PERCENTAGE100  100%
 */

export enum DrawerWidthType {
	PERCENTAGE20 = '20%',
	PERCENTAGE30 = '30%',
	PERCENTAGE40 = '40%',
	PERCENTAGE60 = '60%',
	PERCENTAGE80 = '80%',
	PERCENTAGE92 = '92%',
	PERCENTAGE100 = '100%',
}

// 抽屉按钮类型
export type DrawerButtonType = Pick<BtnItem, 'type' | 'width'> & {
	path?: string // 按钮所属模块，根据模块以及类型获取按钮权限。若为空则默认为所有人可见
	label?: string // 按钮名称
	isHide?: boolean // 是否隐藏按钮
	onClick?: () => void // 按钮点击方法
}
// 抽屉按钮枚举

// 抽屉参数类型
export type DrawerDataType = {
	title: string // 抽屉标题
	width?: DrawerWidthType | string // 抽屉宽度
	buttonList?: DialogDataType[] // 抽屉按钮
	component?: Component // 抽屉组件
	props?: {
		//传递组件需要绑定及向下传递的数据
		[key: string]: any
	} // 抽屉组件属性
	selectList?: Record<string, any>[] // 选中的数据-按钮校验时可能需要使用到
	refresh?: boolean // 标识当前对象是否需要重载
	uuid?: string //是否重新加载的key
	isDialog?: boolean // 弹窗标识
	listRef?: Ref //关联列表ref
}

// 弹窗参数类型
export type DialogDataType = BtnItem & {
	isSelect?: boolean
	selectionArr?: []
	selectedValue?: number
	isDialog: boolean // 弹窗标识
	title?: string // 弹窗标题，默认为按钮名称
	content?: string // 弹窗内容
	contentAlign?: 'left' | 'center' | 'right' // 弹窗内容显示位置
	width?: ModalWidthType | string // 弹窗宽度，默认为 useOperationBtnList.ts 定义的宽度
	component?: Component // 弹窗内容组件
	refresh?: any // 为布尔值时，刷新抽屉组件，否则执行刷新方法
	showButton?: boolean // 是否显示底部按钮
	align?: 'start' | 'center' | 'end' // 按钮显示位置
	cancelText?: string // 取消按钮文案
	confirmText?: string // 确认按钮文案
	onConfirm?: () => void // 确认按钮回调
	selectKey?: string //props中传递id的key键 默认rowId
	props?: {
		[key: string]: any
	} // 弹窗组件属性
	events?: {
		[key: string]: () => {} // 组件内部事件 （关闭弹框）
	}
	selectList?: Record<string, any>[] // 选中的数据
	listRef?: Ref //关联列表ref
	preOpenValidation?: () => void //点击按钮前置校验方法
	showClose?: boolean // 是否显示弹窗关闭按钮
	fullscreen?: boolean // 是否全屏
	modalClass?: string // 	遮罩的自定义类名
	postDialogValidation?: <T>(data: T) => Promise<T> //点击弹窗确认后置校验方法
	[key: string]: any
}
type CombinedDataType = Partial<DrawerDataType | DialogDataType>

/** 抽屉默认参数定义 */
const drawerDefaultConfig = {
	title: '默认标题',
	width: DrawerWidthType.PERCENTAGE60,
	buttonList: [],
	refresh: false,
	uuid: '',
	isDialog: false,
}
export const usePageDrawer = () => {
	const drawerStore = useDrawer()
	const { drawerMap } = storeToRefs(drawerStore)
	// console.log(`output->drawerMap-storeToRefs.value`, drawerMap)

	// 各自抽屉自定义字段交互
	const typeFn = async <
		K,
		T extends {
			selectList?: any[]
			[key: string]: any
		},
	>(
		type: K,
		options: T
	): Promise<T> => {
		switch (type) {
			case DrawerType.DEALERMGR:
				const { useDealerMgr } = await import('@/view/basicInformation/customerManagement/dealerMgr/useDealerMgr')
				let { dealerMgrDetailConfig, initelectList } = useDealerMgr()
				// 为按钮操作保存选中数据
				options?.selectList && (await initelectList(options?.selectList))
				return {
					...dealerMgrDetailConfig,
					...options,
				}

			case DrawerType.PERSONNELMGR:
				const { usePersonnelMgr } = await import(
					'@/view/basicInformation/organizationpersonnel/personnelMgr/usePersonnelMgr'
				)
				let { drawerConfigPersonnelMgr, initelectList: initUserList } = usePersonnelMgr()
				// 为按钮操作保存选中数据
				options?.selectList && (await initUserList(options?.selectList))
				return {
					...drawerConfigPersonnelMgr.value,
					...options,
				}

			case DrawerType.WORKHANDOVER:
				const { useWorkHandover } = await import(
					'@/view/basicInformation/organizationpersonnel/workHandover/useWorkHandover'
				)
				let { drawerConfigHandover } = useWorkHandover()
				// 为按钮操作保存选中数据
				return {
					...drawerConfigHandover,
					...options,
				}

			case DrawerType.TERMINALMGR:
				const { useBtnterminalMgr } = await import(
					'@/view/basicInformation/customerManagement/terminalMgr/useBtnOperation'
				)
				const { terminalMgrDetailConfig, initelectList: terminalMgrInitelectList } = useBtnterminalMgr()
				// 为按钮操作保存选中数据
				options?.selectList && (await terminalMgrInitelectList(options?.selectList))
				return {
					...terminalMgrDetailConfig,
					...options,
				}

			case DrawerType.CHANNELTRANSFERRECORD:
				const { useChannelTransferRecord } = await import(
					'@/view/inventoryManagement/channel/channelTransferRecord/useChannelTransferRecord'
				)
				const { transferOrderDetailConfig, initelectList: CHANNELTRANSFERRECORDList } = useChannelTransferRecord()
				options?.selectList && (await CHANNELTRANSFERRECORDList(options?.selectList))
				return {
					...transferOrderDetailConfig,
					...options,
				}

			case DrawerType.PRODUCTLIST:
				const { useProductList } = await import('@/view/basicInformation/productManagement/productList/useProductList')
				const { drawerConfigProductList, initelectList: productList } = useProductList()
				options?.selectList && (await productList(options?.selectList))
				return {
					...drawerConfigProductList,
					...options,
				}

			case DrawerType.PRODUCTPRICE:
				const { useProductPrice } = await import(
					'@/view/basicInformation/productManagement/productPrice/useProductPrice'
				)
				const { drawerConfigProPrice, initelectList: proPriceList } = useProductPrice()
				options?.selectList && (await proPriceList(options?.selectList))
				return {
					...drawerConfigProPrice,
					...options,
				}

			case DrawerType.COMPETITORS:
				const { useCompetitors } = await import('@/view/basicInformation/productManagement/competitors/useCompetitors')
				const { drawerConfigCompetitors, initelectList: competitorsList } = useCompetitors()
				options?.selectList && (await competitorsList(options?.selectList))
				return {
					...drawerConfigCompetitors,
					...options,
				}

			case DrawerType.GIFT:
				const { useGift } = await import('@/view/basicInformation/productManagement/gift/useGift')
				const { drawerConfigGift, initelectList: giftList } = useGift()
				options?.selectList && (await giftList(options?.selectList))
				return {
					...drawerConfigGift,
					...options,
				}

			case DrawerType.TERINVENTORYDETAILS:
				const { useTerminalInventories } = await import(
					'@/view/inventoryManagement/terminal/terminalInventory/useTerminalInventory'
				)
				const { useTerminalInventoryConfig, initelectList: terInventoryList } = useTerminalInventories({})
				options?.selectList && (await terInventoryList(options?.selectList))
				return {
					...useTerminalInventoryConfig,
					...options,
				}

			case DrawerType.TERMINALINVENTORYRECORD:
				const { useTerminalInventoryRecord } = await import(
					'@/view/inventoryManagement/terminal/terminalInventoryRecord/useTerminalInventoryRecord'
				)
				const { terminalInventoryRecordConfig, initelectList: terDetailsList } = useTerminalInventoryRecord()
				options?.selectList && (await terDetailsList(options?.selectList))
				return {
					...terminalInventoryRecordConfig,
					...options,
				}

			case DrawerType.REFRIGERATORDRULES:
				const { useRefrigeratorDRules } = await import(
					'@/view/basicInformation/refrigeratorManage/refrigeratorDRules/useBtnOperation'
				)
				const { refrigeratorDRulesDetailConfig, initelectList: initelectrerefrigeratorDRules } = useRefrigeratorDRules()
				// 为按钮操作保存选中数据
				options?.selectList && (await initelectrerefrigeratorDRules(options?.selectList))
				return {
					...refrigeratorDRulesDetailConfig,
					...options,
				}

			case DrawerType.BUDGETTABLE:
				const { useBudgetOperation } = await import('@/view/feeManagement/budgetTable/useBudgetTableOperation')
				const { budgetTableConfig, initelectList: budgetTableList } = useBudgetOperation({})
				options?.selectList && (await budgetTableList(options?.selectList))
				return {
					...budgetTableConfig,
					...options,
				}

			case DrawerType.CONTRACTTEMPLATEMGR:
				const { useContractTemplateMgr } = await import(
					'@/view/contractManagement/contractTemplateMgr/useContractTemplateMgr'
				)
				const { contractTemplateMgrDetailConfig, initSelectList: contractTemplateMgrInitList } =
					useContractTemplateMgr()
				options?.selectList && (await contractTemplateMgrInitList(options?.selectList))
				return {
					...contractTemplateMgrDetailConfig,
					...options,
				}

			case DrawerType.CONTRACTLIST:
				const { useContractList } = await import('@/view/contractManagement/contractList/useContractList')
				const { contractListDetailConfig, initSelectList: contractListInitList } = useContractList()
				options?.selectList && (await contractListInitList(options?.selectList))
				return {
					...contractListDetailConfig,
					...options,
				}

			case DrawerType.CHANNELINVENTORY:
				const { useChannelInventory } = await import(
					'@/view/inventoryManagement/channel/channelInventory/useChannelInventory'
				)
				const { drawerConfigChannelInventory } = useChannelInventory()
				return {
					...drawerConfigChannelInventory,
					...options,
				}

			case DrawerType.CHANNELINVENTORYRECORD:
				const { useChannelInventoryRecord } = await import(
					'@/view/inventoryManagement/channel/channelInventoryRecord/useChannelInventoryRecord'
				)
				const {
					drawerConfigChannelInventoryRecord,
					initelectList: inventoryRecordInitList,
					initStockType,
				} = useChannelInventoryRecord()
				options?.selectList && (await inventoryRecordInitList(options?.selectList))
				options?.stockType && (await initStockType(options?.stockType))
				return {
					...drawerConfigChannelInventoryRecord.value,
					...options,
				}

			case DrawerType.REFRIGERATORSHIPPINGPLANADJUST:
			case DrawerType.REFRIGERATORSHIPPINGPLAN:
				const { useRefrigeratorPlan } = await import(
					'@/view/assetManagement/refrigeratorPlanControl/refrigeratorShippingPlan/useRefrigeratorPlan'
				)
				if (type === DrawerType.REFRIGERATORSHIPPINGPLANADJUST) {
					const { refrigeratorPlanADJUStDetailConfig, initSelectList: refrigeratorPlanAdjustInitList } =
						useRefrigeratorPlan()
					options?.selectList && (await refrigeratorPlanAdjustInitList(options?.selectList))
					return {
						...refrigeratorPlanADJUStDetailConfig,
						...options,
					}
				} else {
					const { refrigeratorPlanDetailConfig, initSelectList: refrigeratorPlanInitList } = useRefrigeratorPlan()
					options?.selectList && (await refrigeratorPlanInitList(options?.selectList))
					return {
						...refrigeratorPlanDetailConfig,
						...options,
					}
				}

			case DrawerType.CLOSELOG:
			case DrawerType.REFRIDGERATORORDER:
				const { useRefridgeratorOrder } = await import(
					'@/view/assetManagement/refrigeratorOrderManagement/refridgeratorOrder/useRefridgeratorOrder'
				)
				if (type === DrawerType.CLOSELOG) {
					const { refrigeratorOderCloseLogConfig, initSelectList: refrigeratorOrderInitList } = useRefridgeratorOrder()
					options?.selectList && (await refrigeratorOrderInitList(options?.selectList))
					return {
						...refrigeratorOderCloseLogConfig,
						...options,
					}
				} else {
					const { refrigeratorOrderConfig, initSelectList: refrigeratorOrderLogInitList } = useRefridgeratorOrder()
					options?.selectList && (await refrigeratorOrderLogInitList(options?.selectList))
					return {
						...refrigeratorOrderConfig,
						...options,
					}
				}

			case DrawerType.BUSINESSDETAIL:
			case DrawerType.BUSINESSASSETMANAGEMENT:
				const { useBusinessAssetManagement } = await import(
					'@/view/assetManagement/refrigeratorAssetManagement/businessAssetManagement/useBusinessAssetManagement'
				)
				if (type === DrawerType.BUSINESSDETAIL) {
					const { businessAssetManagementConfig, initSelectList: businessAssetManagementInitList } =
						useBusinessAssetManagement()
					options?.selectList && (await businessAssetManagementInitList(options?.selectList))
					return {
						...businessAssetManagementConfig,
						...options,
					}
				} else {
					const { businessDetailConfig, initSelectList: businessAssetDetailInitList } = useBusinessAssetManagement()
					options?.selectList && (await businessAssetDetailInitList(options?.selectList))
					return {
						...businessDetailConfig,
						...options,
					}
				}

			case DrawerType.REFRIGERATORMAINTENANCERECORD:
				const { useRefrigeratorMaintenanceRecord } = await import(
					'@/view/assetManagement/refrigeratorAssetManagement/refrigeratorMaintenanceRecord/useRefrigeratorMaintenanceRecord'
				)
				const { refrigeratorMaintenanceRecordConfig, initSelectList: MaintenanceRecordInitList } =
					useRefrigeratorMaintenanceRecord()
				options?.selectList && (await MaintenanceRecordInitList(options?.selectList))
				return {
					...refrigeratorMaintenanceRecordConfig,
					...options,
				}

			case DrawerType.RECYCLINGDETAILS:
			case DrawerType.STATISTICSONSCRAPPEDASSETS:
				const { useStatisticsOnScrappedAssets } = await import(
					'@/view/assetManagement/refrigeratorAssetManagement/statisticsOnScrappedAssets/useStatisticsOnScrappedAssets'
				)
				if (type === DrawerType.RECYCLINGDETAILS) {
					const { statisticsOnScrappedAssetsConfig, initSelectList: ScrappedAssetsInitList } =
						useStatisticsOnScrappedAssets()
					options?.selectList && (await ScrappedAssetsInitList(options?.selectList))
					return {
						...statisticsOnScrappedAssetsConfig,
						...options,
					}
				} else {
					const { statisticsDetailConfig, initSelectList: StatisticsDetailInitList } = useStatisticsOnScrappedAssets()
					options?.selectList && (await StatisticsDetailInitList(options?.selectList))
					return {
						...statisticsDetailConfig,
						...options,
					}
				}

			case DrawerType.PROTOCOLMANAGEMENTDETAILS:
				const { useProtocolManagementDetails } = await import(
					'@/view/assetManagement/refrigeratorProtocolManagement/ProtocolManagementDetails/useProtocolManagementDetails'
				)
				const { protocolManagementDetailsConfig, initSelectList: protocolManagementInitList } =
					useProtocolManagementDetails()
				options?.selectList && (await protocolManagementInitList(options?.selectList))
				return {
					...protocolManagementDetailsConfig,
					...options,
				}

			// case DrawerType.YEARLYBUDGETARY:
			// 	const { useYearFeeOperation } = await import(
			// 		'@/view/feeManagement/feeBudgetary/yearFeeBudgetary/useYearFeeOperation'
			// 	)
			// 	const { yearlyBudgetaryConfig, initelectList: yearlyBudgetaryList } = useYearFeeOperation({})
			// 	options?.selectList && (await yearlyBudgetaryList(options?.selectList))
			// 	console.log(options, '6565')
			// 	return {
			// 		...yearlyBudgetaryConfig,
			// 		...options,
			// 	}

			case DrawerType.FEEBUDGETARYODD:
				const { useFeeBudgetaryOddOperation } = await import(
					'@/view/feeManagement/feeBudgetary/feeBudgetaryOdd/useFeeBudgetaryOddOperation'
				)
				const { feeBudgetaryOddConfig, initelectList: feeBudgetaryOddList } = useFeeBudgetaryOddOperation({})
				options?.selectList && (await feeBudgetaryOddList(options?.selectList))
				console.log(options, '65651111')
				return {
					...feeBudgetaryOddConfig,
					...options,
				}

			// case DrawerType.MONTHLYBUDGETARY:
			// 	const { useMonthFeeOperation } = await import(
			// 		'@/view/feeManagement/feeBudgetary/monthFeeBudgetary/useMonthFeeOperation'
			// 	)
			// 	const { monthlyBudgetaryConfig, initelectList: monthlyBudgetaryList } = useMonthFeeOperation({})
			// 	options?.selectList && (await monthlyBudgetaryList(options?.selectList))
			// 	return {
			// 		...monthlyBudgetaryConfig,
			// 		...options,
			// 	}

			case DrawerType.PROMOTIONTYPE:
				const { usePromotionTypeOperation } = await import(
					'@/view/promotionPolicy/promotionType/usePromotionTypeOperation'
				)
				const { promotionTypeConfig, initelectList: promotionTypeList } = usePromotionTypeOperation({})
				options?.selectList && (await promotionTypeList(options?.selectList))
				return {
					...promotionTypeConfig,
					...options,
				}

			case DrawerType.POLICYAPPLICATION:
				const { usePromotionApplicationOperation } = await import(
					'@/view/promotionPolicy/policyApplication/usePromotionApplicationOperation'
				)
				const { promotionApplicationConfig, initelectList: policyApplicationList } = usePromotionApplicationOperation(
					{}
				)
				options?.selectList && (await policyApplicationList(options?.selectList))
				return {
					...promotionApplicationConfig,
					...options,
				}

			case DrawerType.FEEAPPLICATIONODD:
				const { useFeeAppliactionOddOperation } = await import(
					'@/view/feeManagement/feeApplication/feeApplicationOdd/useFeeApplicationOddOperation'
				)
				const { feeApplicationOddConfig, initelectList: feeApplicationOddList } = useFeeAppliactionOddOperation({})
				options?.selectList && (await feeApplicationOddList(options?.selectList))
				return {
					...feeApplicationOddConfig,
					...options,
				}

			case DrawerType.CANCELLATIONBYACTIVITY:
				const { useCancellationByActivityOperation } = await import(
					'@/view/feeManagement/feeCancellation/cancellationByActivity/useCancellationByActivityOperation'
				)
				const { feeCancellationByActivityConfig, initelectList: cancellationByActivityList } =
					useCancellationByActivityOperation({})
				options?.selectList && (await cancellationByActivityList(options?.selectList))
				return {
					...feeCancellationByActivityConfig,
					...options,
				}

			case DrawerType.ACTIVITYAIMODEL:
				const { useActivityAImodel } = await import(
					'@/view/activityManagement/activityAImodel/useActivityAImodelOperation'
				)
				const { activityAImodelConfig, initelectList: acctiveInitelectList } = useActivityAImodel()
				options?.selectList && (await acctiveInitelectList(options?.selectList))
				return {
					...activityAImodelConfig,
					...options,
				}

			case DrawerType.REFRIGERATORASSETCARD:
			case DrawerType.WARRANTYRECORDDETAILS:
				const { useRefrigeratorAssetCard } = await import(
					'@/view/assetManagement/refrigeratorAssetManagement/refrigeratorAssetCard/useRefrigeratorAssetCard'
				)
				if (type === DrawerType.REFRIGERATORASSETCARD) {
					const { assetChangeRecordConfig, initSelectList: assetChangeRecordInitList } = useRefrigeratorAssetCard()
					options?.selectList && (await assetChangeRecordInitList(options?.selectList))
					return {
						...assetChangeRecordConfig,
						...options,
					}
				} else {
					const { warrantyRecordConfig, initSelectList: warrantyRecordInitList } = useRefrigeratorAssetCard()
					options?.selectList && (await warrantyRecordInitList(options?.selectList))
					return {
						...warrantyRecordConfig,
						...options,
					}
				}

			case DrawerType.APPROVECANCELLATIONBYAPPLICATIONODD:
				const { useCancellationByApplicationOddOperation } = await import(
					'@/view/feeManagement/feeCancellation/cancellationByApplicationOdd/useCancellationByApplicationOddOperation'
				)
				const { cancellationByApplicationOdd, initelectList: cancellationByApplicationOddList } =
					useCancellationByApplicationOddOperation()
				options?.selectList && (await cancellationByApplicationOddList(options?.selectList))
				return {
					...cancellationByApplicationOdd,
					...options,
				}
			case DrawerType.CANCELLATIONLOGVIEW:
				const { useCancellationByApplicationOddOperation: cancelLationlogViewOperation } = await import(
					'@/view/feeManagement/feeCancellation/cancellationByApplicationOdd/useCancellationByApplicationOddOperation'
				)
				const { drawerConfigCancellationLogView } = cancelLationlogViewOperation()
				return {
					...drawerConfigCancellationLogView,
					...options,
				}
			case DrawerType.CANCELLATIONPRODUCTS:
				const { useCancellationByApplicationOddOperation: cancelLationProductsOperation } = await import(
					'@/view/feeManagement/feeCancellation/cancellationByApplicationOdd/useCancellationByApplicationOddOperation'
				)
				const { drawerConfigProducts } = cancelLationProductsOperation()
				return {
					...drawerConfigProducts,
					...options,
				}
			case DrawerType.CANCELLATIONBYAPPLICATIONODD:
				const { useCancellationByApplicationOddOperation: feeExpenseDetailsOperation } = await import(
					'@/view/feeManagement/feeCancellation/cancellationByApplicationOdd/useCancellationByApplicationOddOperation'
				)
				const { drawerConfigExpenseDetails } = feeExpenseDetailsOperation()
				return {
					...drawerConfigExpenseDetails,
					...options,
				}

			case DrawerType.FEECANCELLATIONAPPROVE:
				const { useCancellationApproval } = await import(
					'@/view/feeManagement/feeCancellation/feeCancellationApprove/useFeeCancellationApproveOperation'
				)
				const { feeCancellationApprovalDetailsConfig, initelectList: cancellationApproveList } =
					useCancellationApproval({})
				options?.selectList && (await cancellationApproveList(options?.selectList))
				return {
					...feeCancellationApprovalDetailsConfig,
					...options,
				}
			case DrawerType.CUSTOMVERIFICATION:
				const { useCustomVerification: useCustomVerificationNumberConfig } = await import(
					'@/view/feeManagement/feeCancellation/customVerification/useCustomVerification'
				)
				const { verificationNumberConfig, initelectList: initelect } = useCustomVerificationNumberConfig()
				options?.selectList && (await initelect(options?.selectList))
				return {
					...verificationNumberConfig,
					...options,
				}
			case DrawerType.VERIFICATIONNUMBEDETAILS:
				const { useCustomVerification: useCustomVerificationNumberDetailsConfig } = await import(
					'@/view/feeManagement/feeCancellation/customVerification/useCustomVerification'
				)
				const { verificationNumbeDetailsConfig, initelectList: initelects } = useCustomVerificationNumberDetailsConfig()
				options?.selectList && (await initelects(options?.selectList))
				return {
					...verificationNumbeDetailsConfig,
					...options,
				}
			case DrawerType.ASSETSINSPECT:
			case DrawerType.AIPICTURE:
				const { useAssetsInspect } = await import(
					'@/view/visitManagement/visitCollection/assetsInspect/useAssetsInspect'
				)
				if (type === DrawerType.ASSETSINSPECT) {
					const { assetsInspectConfig, initSelectList: assetsInspectInitList } = useAssetsInspect()
					options?.selectList && (await assetsInspectInitList(options?.selectList))
					return {
						...assetsInspectConfig,
						...options,
					}
				} else {
					const { aiPictureCheckConfig, initSelectList: aiAssetsInspectInitList } = useAssetsInspect()
					options?.selectList && (await aiAssetsInspectInitList(options?.selectList))
					return {
						...aiPictureCheckConfig,
						...options,
					}
				}

			case DrawerType.MISSEDVISIT:
				const { useMissedVisit } = await import('@/view/visitManagement/visitCollection/missedVisit/useMissedVisit')
				const { missedVisitConfig, initSelectList: missedVisitInitList } = useMissedVisit()
				options?.selectList && (await missedVisitInitList(options?.selectList))
				return {
					...missedVisitConfig,
					...options,
				}

			case DrawerType.STOREINSPECT:
				const { useStoreInspect } = await import('@/view/visitManagement/visitCollection/storeInspect/useStoreInspect')
				const { storeInspectConfig, initSelectList: storeInspectConfigInitList } = useStoreInspect()
				options?.selectList && (await storeInspectConfigInitList(options?.selectList))
				return {
					...storeInspectConfig,
					...options,
				}

			case DrawerType.LIVELYCHECK:
				const { useLivelyCheck } = await import('@/view/visitManagement/visitCollection/livelyCheck/useLivelyCheck')
				const { livelyCheckConfig, initSelectList: livelyCheckConfigInitList } = useLivelyCheck()
				options?.selectList && (await livelyCheckConfigInitList(options?.selectList))
				return {
					...livelyCheckConfig,
					...options,
				}
			case DrawerType.EXECUTEACTIVITY:
				const { useExecuteActivity } = await import(
					'@/view/visitManagement/visitCollection/executeActivity/useExecuteActivity'
				)
				const { executeActivityConfig } = useExecuteActivity()
				return {
					...executeActivityConfig,
					...options,
				}
			case DrawerType.PROCESSDETAILS:
			case DrawerType.ACTIVITYEXECUTION:
			case DrawerType.IDENTIFICATIONDETAILS:
				const { useActivityExecution } = await import(
					'@/view/activityManagement/activeProcess/activityExecution/useActivityExecution'
				)
				const {
					activityExecutionConfig,
					identificationdetailsConfig,
					activityDetailsConfig,
					initSelectList: activityInitelectList,
				} = useActivityExecution()
				options?.selectList && (await activityInitelectList(options?.selectList))
				const activityListMap = new Map([
					[DrawerType.PROCESSDETAILS, activityExecutionConfig],
					[DrawerType.IDENTIFICATIONDETAILS, identificationdetailsConfig],
					[DrawerType.ACTIVITYEXECUTION, activityDetailsConfig],
				])
				return {
					...activityListMap.get(type),
					...options,
				}
			case DrawerType.ACTIVITYOVERAPPROVAL:
				const { useActivityOverApplication } = await import(
					'@/view/activityManagement/activityOver/activityOverApplication/useActivityOverApplication'
				)
				const { activityOverApplicationConfig, initSelectList: activityInitelect } = useActivityOverApplication()
				options?.selectList && (await activityInitelect(options?.selectList))
				return {
					...activityOverApplicationConfig,
					...options,
				}

			case DrawerType.REFRIGERATORPUTTINGRECORD:
				const { useRefrigeratorPuttingRecord } = await import(
					'@/view/assetManagement/refrigeratorAssetManagement/refrigeratorPuttingRecord/useRefrigeratorPuttingRecord'
				)
				const { refrigeratorPuttingRecordConfig, initSelectList: refrigeratorPuttingRecordInitList } =
					useRefrigeratorPuttingRecord()
				options?.selectList && (await refrigeratorPuttingRecordInitList(options?.selectList))
				return {
					...refrigeratorPuttingRecordConfig,
					...options,
				}

			case DrawerType.WORKFLOWCONFIG:
				const { useWorkflowConfig } = await import('@/view/workflow/workflowConfig/useWorkflowConfig')
				let { drawerConfigWorkFlowConfig, initelectList: initFlowConfigList } = useWorkflowConfig()
				// 为按钮操作保存选中数据
				options?.selectList && (await initFlowConfigList(options?.selectList))
				return {
					...drawerConfigWorkFlowConfig.value,
					...options,
				}

			case DrawerType.PROPOSALAPPLICATION:
				const { useProposalApplication } = await import(
					'@/view/activityManagement/activityProject/proposalApplication/useProposalApplication'
				)
				const { planDetailConfig, initSelectList: applicationInitelectList } = useProposalApplication()
				options?.selectList && (await applicationInitelectList(options?.selectList))
				return {
					...planDetailConfig,
					...options,
				}

			case DrawerType.ACTIVITYAPPLYFOR:
				const { useActivityApplyFor } = await import(
					'@/view/activityManagement/activityApplication/activityApplyFor/useActivityApplyFor'
				)
				const { applyDetailForConfig, initSelectList: applyForInitelectList } = useActivityApplyFor()
				options?.selectList && (await applyForInitelectList(options?.selectList))
				return {
					...applyDetailForConfig,
					...options,
				}

			case DrawerType.REFRIGERATORMODEL:
				const { useRefrigeratorModel } = await import(
					'@/view/basicInformation/refrigeratorManage/refrigeratorModel/useRefrigeratorModel'
				)
				const { refrigeratorModelConfig, initSelectList: refrigeratorModelInitelectList } = useRefrigeratorModel()
				options?.selectList && (await refrigeratorModelInitelectList(options?.selectList))
				return {
					...refrigeratorModelConfig,
					...options,
				}

			case DrawerType.ACTIVITYSCHEMEAPPROVAL:
				const { useActivitySchemeApproval } = await import(
					'@/view/activityManagement/activityProject/activitySchemeApproval/useActivitySchemeApproval'
				)
				const { planApprovalDetailsConfig, initSelectList: planApprovalDetailsInitelectList } =
					useActivitySchemeApproval()
				options?.selectList && (await planApprovalDetailsInitelectList(options?.selectList))
				return {
					...planApprovalDetailsConfig,
					...options,
				}

			// case DrawerType.YEARBUDGETARYAPPROVE:
			// 	const { useYearBudgetaryApproval } = await import(
			// 		'@/view/feeManagement/feeBudgetary/yearBudgetaryApprove/useYearBudgetaryApprove'
			// 	)
			// 	const { yearApprovalDetailsConfig, initelectList: yearApproveInitelectList } = useYearBudgetaryApproval({})
			// 	options?.selectList && (await yearApproveInitelectList(options?.selectList))
			// 	return {
			// 		...yearApprovalDetailsConfig,
			// 		...options,
			// 	}

			case DrawerType.FEEBUDGETARYAPPROVE:
				const { useFeeBudgetaryApproveOperation } = await import(
					'@/view/feeManagement/feeBudgetary/feeBudgetaryApprove/useFeeBudgetaryApproveOperation'
				)
				const { feeBudgetaryApproveConfig, initelectList: feeBudgetaryApproveInitelectList } =
					useFeeBudgetaryApproveOperation({})
				options?.selectList && (await feeBudgetaryApproveInitelectList(options?.selectList))
				return {
					...feeBudgetaryApproveConfig,
					...options,
				}

			case DrawerType.FEEAPPLICATIONAPPROVE:
				const { useFeeApplicationApproval } = await import(
					'@/view/feeManagement/feeApplication/feeApplicationApprove/useFeeApplicationApprove'
				)
				const { applicationApprovalDetailsConfig, initelectList: applicationApproveInitelectList } =
					useFeeApplicationApproval({})
				options?.selectList && (await applicationApproveInitelectList(options?.selectList))
				return {
					...applicationApprovalDetailsConfig,
					...options,
				}

			// case DrawerType.MONTHBUDGETARYAPPROVE:
			// 	const { useMonthBudgetaryApproval } = await import(
			// 		'@/view/feeManagement/feeBudgetary/monthBudgetaryApprove/useMonthBudgetaryApprove'
			// 	)
			// 	const { monthApprovalDetailsConfig, initelectList: monthApproveInitelectList } = useMonthBudgetaryApproval({})
			// 	options?.selectList && (await monthApproveInitelectList(options?.selectList))
			// 	return {
			// 		...monthApprovalDetailsConfig,
			// 		...options,
			// 	}

			case DrawerType.EXECUTEAPPROVAL:
				const { useExecuteApproval } = await import(
					'@/view/activityManagement/activeProcess/executeApproval/useExecuteApproval'
				)
				const { executeApprovalConfig, initSelectList: executeApprovalInitelectList } = useExecuteApproval()
				options?.selectList && (await executeApprovalInitelectList(options?.selectList))
				return {
					...executeApprovalConfig,
					...options,
				}
			case DrawerType.RECTIFICATIONAPPROVAL:
				const { useRectificationApproval } = await import(
					'@/view/activityManagement/activeProcess/rectificationApproval/useRectificationApproval'
				)
				const { rectificationApprovalConfig, initSelectList: rectificationApprovalList } = useRectificationApproval()
				options?.selectList && (await rectificationApprovalList(options?.selectList))
				return {
					...rectificationApprovalConfig,
					...options,
				}
			case DrawerType.PROMOTIONSALESREPORT:
				const { usePromotionSalesReportOperation } = await import(
					'@/view/promotionPolicy/promotionSalesReport/usePromotionSalesReportOperation'
				)
				const { promotionSalesReportConfig, initelectList: promotionSalesReportInitelectList } =
					usePromotionSalesReportOperation({})
				options?.selectList && (await promotionSalesReportInitelectList(options?.selectList))
				return {
					...promotionSalesReportConfig,
					...options,
				}

			case DrawerType.POLICYAPPROVE:
				const { userPolicyApprove } = await import('@/view/promotionPolicy/policyApprove/userPolicyApprove')
				const { policyApproveDetailsConfig, initSelectList: policyApproveInitelectList } = userPolicyApprove({})
				options?.selectList && (await policyApproveInitelectList(options?.selectList))
				return {
					...policyApproveDetailsConfig,
					...options,
				}

			case DrawerType.SUBJECTMANAGEMENT:
				const { useSubjectManagement } = await import(
					'@/view/contractManagement/contractDataManagement/subjectManagement/useSubjectManagement'
				)
				const { subjectManagementDetail, initSelectList: subjectManagementInitelectList } = useSubjectManagement()
				options?.selectList && (await subjectManagementInitelectList(options?.selectList))
				return {
					...subjectManagementDetail,
					...options,
				}

			case DrawerType.ANNOUNCEMENT:
			case DrawerType.ANNOUNCEMENTVIEWRATE:
				const { useAnnouncement } = await import('@/view/systemSetting/announcement/useAnnouncement')
				if (type === DrawerType.ANNOUNCEMENT) {
					const { drawerConfigAnnouncement } = useAnnouncement({})
					return {
						...drawerConfigAnnouncement,
						...options,
					}
				} else {
					const { drawerConfigAnnouncementViewRate } = useAnnouncement({})
					return {
						...drawerConfigAnnouncementViewRate,
						...options,
					}
				}

			case DrawerType.ANNOUNCEMENTCENTER:
				const { useAnnouncementCenter } = await import('@/view/systemSetting/announcementCenter/useAnnouncementCenter')
				const { drawerConfig: drawerConfigAnnouncementCenter } = useAnnouncementCenter()
				return {
					...drawerConfigAnnouncementCenter,
					...options,
					width: DrawerWidthType.PERCENTAGE80,
				}

			case DrawerType.KASYSTEM:
				const { useKaSystem } = await import('@/view/basicInformation/customerManagement/kaSystem/useKaSystem')
				const { drawerConfigKaSystem, initSelectList: kaInitSelectList } = useKaSystem()
				options?.selectList && (await kaInitSelectList(options?.selectList))
				return {
					...drawerConfigKaSystem,
					...options,
				}
			case DrawerType.VIEWREPORTDATA:
				const { usePlanSummary } = await import('@/view/planningManagement/planSummary/usePlanSummary')
				const { drawerConfigReportData } = usePlanSummary()
				return {
					...drawerConfigReportData,
					...options,
					width: DrawerWidthType.PERCENTAGE30,
				}
			case DrawerType.WORKTRAJECTORYS:
				const { useWorkTrajectorys } = await import('@/view/visitManagement/workTrajectorys/useWorkTrajectorys')
				const { workTrajectorysConfig } = useWorkTrajectorys()
				return {
					...options,
					...workTrajectorysConfig,
				}
			case DrawerType.TERMINALORDERS:
				const { useTerminalOrders } = await import('@/view/orderManage/terminalOrders/useTerminalOrders')
				const { drawerConfigTerminalOrderList, initelectList: terminalOrderList } = useTerminalOrders()
				options?.selectList && (await terminalOrderList(options?.selectList))
				return {
					...drawerConfigTerminalOrderList,
					...options,
				}
			default:
				// 对于按钮操作弹窗 直接返回不做处理
				// 增加一个default 字段用于排查问题时区分是否走了默认操作
				return {
					...options,
					default: true,
				}
		}
	}

	// 打开白名单
	const openCommonDetail = async (rowId) => {
		const res = await asyncGetCancellationApproveTypeList(0, { ids: [rowId] })
		if (res.code == 0) {
			const cancelType = res.data?.list?.[0]?.cancelType ?? 1
			formData.useComponent = cancelType
		}
		console.log(res, formData.useComponent, 'openCommonDetail')
	}
	// 打开抽屉
	const drawerOpen = async (type: DrawerType | any, options: CombinedDataType) => {
		// 点击弹窗-前置校验是否有权限
		const valid = await checkServiceFn(type, options?.props?.rowId)
		if (!valid) return ElMessage.warning('抱歉，您无权查看此内容!')

		console.log(type, options, 'type, options')
		// 添加白名单//如果是核销审批需要通过id反查判断参数 这里有问题(林安安)
		type == 'FEECANCELLATIONAPPROVE' && (await openCommonDetail(options.props.rowId))
		// 是否主动标识
		let params: CombinedDataType
		try {
			params = await typeFn<DrawerType, CombinedDataType>(type, options)
			console.log(params, 'params')
			// 拼接默认属性
			params = { ...drawerDefaultConfig, ...params }
		} catch (error) {
			console.error(`output->error`, error)
			params = { ...options }
		}

		console.log(`output->params`, params)

		// 如果有重复的 提升到最顶层
		if (drawerMap.value?.has(type)) {
			console.log(`output->寻找层级提升组件`, type)
			let current = drawerMap.value?.get(type)
			drawerMap.value?.delete(type)
			drawerMap.value?.set(type, markRaw({ ...current, ...params, levelUpUuid: uuidv4() }))
		} else {
			let item = markRaw({ ...params })
			drawerMap.value?.set(type, item)
		}

		console.log('drawerMap', drawerMap)
	}

	// 关闭抽屉
	const drawerClose = async (isOK: boolean = false, type?: DrawerType) => {
		console.log(`output->chufal`)
		// 没有明确关闭抽屉类型 默认最后一个
		const deleteType = type ?? [...drawerMap.value?.keys()][drawerMap.value?.size - 1]
		const lastDrawer = drawerMap.value?.get(deleteType)
		// 最后一层需要判断列表刷新问题
		if (drawerMap.value?.size <= 1) {
			// 如果绑定了listRef并且当前层有刷新属性 需要刷新列表
			if ((lastDrawer?.isForceRefresh || lastDrawer?.refresh || isOK) && !!lastDrawer?.listRef) {
				let listRef = toValue(lastDrawer?.listRef)
				let pageable = listRef?.pageable
				let searchParam = listRef?.searchParam

				// 暂时用于处理es 睡眠一秒问题
				if (lastDrawer?.postDialogValidation) {
					lastDrawer?.postDialogValidation(() => {
						listRef?.getList && listRef.getList({ params: { ...searchParam }, pageInfo: { ...pageable } })
						listRef?.getTableList && listRef.getTableList()
					})
				} else {
					listRef?.getList && listRef.getList({ params: { ...searchParam }, pageInfo: { ...pageable } })
					listRef?.getTableList && listRef.getTableList()
				}
			}
		}
		/**
		 * @description 直接销毁掉Ref的动态引入-防止性能问题
		 * 不取消动态ref，会造成组件已存在，ref后赋值问题，生产环境会报错
		 *  */
		// const { props, component, listRef, ...rest } = markRaw(lastDrawer)

		// const { ref, ...restProps } = props
		// console.log(`output->props-lastDrawer`, props)
		// 增加isShow 是因为需要主动触发弹窗、抽屉的销毁子组件方法，否则导致组件无法销毁
		// drawerMap.value?.set(deleteType, { ...rest, props: restProps, isShow: false })

		// await nextTick()

		drawerMap.value?.delete(deleteType)
		// 是否要刷新下层抽屉
		if (isOK) {
			// 当是保存时，当前Map都需要刷新
			// 给当前Map中的item 标记刷新，用于每一层级关闭，
			drawerMap.value?.forEach((value, key, Map) => {
				// 处理点击关闭弹窗后不刷新下一个弹窗(在当前要关闭弹窗配置项中添加 notRefreshDrawerType:下一个弹窗的drawerType)
				const isRefresh = lastDrawer?.notRefreshDrawerType !== key
				let current = markRaw({ ...markRaw(value), refresh: isRefresh })
				Map.set(key, current)
			})
		}

		// 逐层关闭时，判断当前Map项是否需要刷新，不是一次性刷新所有Map
		if (drawerMap.value?.size > 0) {
			const currentType = [...drawerMap.value?.keys()][drawerMap.value?.size - 1]
			const currentDrawer = drawerMap.value?.get(currentType)

			// 增加 uuid 作为key 刷新组件
			if (currentDrawer.refresh) {
				let current = markRaw({ ...markRaw(currentDrawer), uuid: uuidv4() })
				drawerMap.value?.set(currentType, current)
			}

			// 抽屉中删除操作时需要判断是否由详情打开
			if (deleteType.includes('DELETE')) {
				const typeSplit = deleteType.split('DELETE')
				// 确定DELETE是在最尾部
				if (typeSplit?.[1] === '') {
					const parentType = typeSplit?.[0] as DrawerType
					// 删除 挂载删除按钮交互的详情页
					drawerClose(true, parentType)
				}
			}
		}
	}

	/**
	 * @deprecated
	 * 弹窗方法 统一使用drawerOpen
	 */
	const dialogOpen = (data: DialogDataType) => {}
	/**
	 * @deprecated
	 * 弹窗方法 统一使用drawerClose
	 */
	const dialogClose = () => {}

	/**清空抽屉 */
	const clearDrawerMap = () => {
		drawerMap.value?.clear()
	}
	return {
		drawerMap, // 抽屉数据map集合
		drawerOpen, // 抽屉打开方法
		drawerClose, // 抽屉关闭方法
		dialogOpen, // 弹窗打开方法
		dialogClose, // 弹窗关闭方法
		clearDrawerMap, //清空抽屉
	}
}
