import service from '@/utils/request'
// 获取费用科目列表
// export const getSonSubjectList = (data: any) => {
// 	return service({
// 		url: '/expenseAccount/getSubAccount',
// 		method: 'post',
// 		data,
// 	})
// }

// 获取费用科目列表
export const getCancellationApproveList = (data: any) => {
	return service({
		url: '/expenseCancellationApproval/getExpenseCancellationApprovalList',
		method: 'post',
		data,
	})
}

// 获取核销审批类型·
export const getCancellationApproveTypeList = (data: any) => {
	return service({
		url: '/expenseCancellationApproval/getExpenseCancellationApprovalListByIds',
		method: 'post',
		data,
	})
}
// 费用管理-费用科目(删除接口)

export const deleteCancellationApprove = (data: any) => {
	return service({
		url: '/expenseCancellationApproval/deleteExpenseCancellationApprovalByIds',
		method: 'DELETE',
		data,
	})
}

// //费用管理-费用科目(新增)

// export const createCancellationByCustomize = (data: any) => {
// 	return service({
// 		url: '/expenseCancellation/createExpenseCancellation',
// 		method: 'post',
// 		data,
// 	})
// }

// 费用管理-费用科目(编辑)

export const findCancellationApprove = (data: any) => {
	return service({
		url: '/expenseCancellationApproval/findExpenseCancellationApproval',
		method: 'post',
		data,
	})
}

// (核销审批)
export const updateCancellationApprove = (data: any) => {
	return service({
		url: '/expenseCancellationApproval/updateExpenseCancellationApproval',
		method: 'put',
		data,
	})
}

//获取所有销售区域tree结构数据
// export const findCustomSaleAreaAll = () => {
// 	return service({
// 		url: '/saleArea/findCustomSaleAreaAll',
// 		method: 'get',
// 	})
// }

// (促销政策添加帅选)
export const filterPromotionCancellation = (data: any) => {
	return service({
		url: ' /promotionPolicy/getPromotionPolicyInfoListInScreen',
		method: 'post',
		data,
	})
}

// 核销审批筛选添加
export const addFeeActivityApprove = (data: any) => {
	return service({
		url: '/activityPlan/getActivityPlanInfoListInScreen',
		method: 'post',
		data,
	})
}

// 核销审批筛选添加
export const addCustomizeApprove = (data: any) => {
	return service({
		url: '/expenseCancellation/expenseCancellationSearcher',
		method: 'post',
		data,
	})
}
