import { RouteLocationRaw, RouteLocationNamedRaw } from 'vue-router'
import router from '@/router/index'
import { ElMessage } from 'element-plus'

enum NavigationType {
	push = 'push',
	replace = 'replace',
}

export const navigationPage = (params: RouteLocationRaw, type = NavigationType.push) => {
	if (params.hasOwnProperty('name') && !router.hasRoute((params as RouteLocationNamedRaw).name)) {
		return ElMessage.warning('您没有权限查看此页面的内容!')
	}
	router[type](params)
}
