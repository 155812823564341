import { defineAsyncComponent } from 'vue'
import type { App } from 'vue'
import * as svg from '@element-plus/icons-vue'

// 引入组件
const SvgIcon = defineAsyncComponent(() => import('@/components/svgIcon/index.vue'))

export function elSvg(app: App) {
	const icons = svg as any
	for (const i in icons) {
		app.component(`ele-${icons[i].name}`, icons[i])
	}
	app.component('SvgIcon', SvgIcon)
}

export function uuid(hasHyphen: string) {
	return (hasHyphen ? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx' : 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx').replace(
		/[xy]/g,
		function (c) {
			const r = (Math.random() * 16) | 0
			const v = c == 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		}
	)
}

/**
 * 对象深克隆
 * @param obj 源对象
 * @returns 克隆后的对象
 */
export function deepClone(obj: EmptyObjectType) {
	let newObj: EmptyObjectType
	try {
		newObj = obj.push ? [] : {}
	} catch (error) {
		newObj = {}
	}
	for (let attr in obj) {
		if (obj[attr] && typeof obj[attr] === 'object') {
			newObj[attr] = deepClone(obj[attr])
		} else {
			newObj[attr] = obj[attr]
		}
	}
	return newObj
}

/**
 * 判断是否是移动端
 */
export function isMobile() {
	if (
		navigator.userAgent.match(
			/('phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone')/i
		)
	) {
		return true
	} else {
		return false
	}
}

/**
 * 判断数组对象中所有属性是否为空，为空则删除当前行对象
 * @description @感谢大黄
 * @param list 数组对象
 * @returns 删除空值后的数组对象
 */
export function handleEmpty(list: EmptyArrayType) {
	const arr = []
	for (const i in list) {
		const d = []
		for (const j in list[i]) {
			d.push(list[i][j])
		}
		const leng = d.filter((item) => item === '').length
		if (leng !== d.length) {
			arr.push(list[i])
		}
	}
	return arr
}

// array to tree
export function array2tree(array: [], options?: any) {
	const clone = JSON.parse(JSON.stringify(array))
	options = {
		idKey: 'id',
		parentKey: 'parentId',
		childrenKey: 'children',
		leafKey: 'leaf',
		leafValue: true,
		...options,
	}
	const { parentKey, idKey, childrenKey, leafKey, leafValue } = options
	// !a[parentKey] ||
	const roots = clone.filter((a: object) => !clone.some((b: object) => b[idKey] === a[parentKey]))
	const tree = (arr: []) => {
		return arr.map((c: object) => {
			const children = clone.filter((a: object) => c[idKey] === a[parentKey])
			if (children.length) {
				c[childrenKey] = children
				tree(children)
			} else {
				c[leafKey] = leafValue
			}
			return c
		})
	}
	return tree(roots)
}

/**
 * 统一批量导出
 * @method elSvg 导出全局注册 element plus svg 图标
 */
const other = {
	elSvg: (app: App) => {
		elSvg(app)
	},
}

// 统一批量导出
export default other
