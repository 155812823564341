import { nextTick } from 'vue'
import { useFormItem } from 'element-plus'

//  触发element-plus表单校验事件 trigger
export function useTrigger() {
	const { formItem } = useFormItem() // form formItem
	const emitTrigger = (value?: any) => {
		if (formItem) {
			nextTick(() => {
				formItem.validate('blur') // 触发校验
				formItem.validate('change') // 触发校验
			})
		}
	}
	return { formItem, emitTrigger }
}
