<template>
	<div>
		<div v-if="baseInfo.list?.length > 0 || baseInfo.mainValueList?.length > 0">
			<section class="flex items-center baseContainer gap-5xl mb-2xl" v-if="baseInfo.mainValueList?.length > 0">
				<template v-for="(item, index) in baseInfo.mainValueList" :key="index">
					<div class="mainItem">
						<div class="mainItemLabel">{{ item.label }}</div>
						<div class="mainItemValue">{{ item.value }}</div>
					</div>
					<div class="verticalLine" v-if="baseInfo.mainValueList?.length - 1 !== index"></div>
				</template>
			</section>
			<section
				:class="`baseContainer grid grid-cols-${baseInfo.cols || 2} gap-md mb-3xl `"
				v-if="baseInfo.list?.length > 0"
			>
				<template v-for="(item, index) in baseInfo.list" :key="index">
					<div :class="`baseClass col-span-${item.col || 1} flex overflow-hidden`" v-if="!item.hidden">
						<div class="baseLabel">{{ item.label }}:</div>

						<!-- 标签展示 -->
						<div v-if="item.viewConfig?.type === 'tag'" class="baseValue">
							<!-- 多个标签 -->
							<template v-if="Array.isArray(item.value)">
								<el-tag
									v-for="(tag, inx) in item.value"
									:key="inx"
									:type="item.viewConfig?.tag?.type"
									:size="item.viewConfig?.tag?.size"
									:color="tag.color || item.viewConfig?.tag?.color"
									:style="tagStyle(tag, item)"
									>{{ tag.name }}</el-tag
								>
							</template>
							<!-- 单个标签 -->
							<el-tag
								v-else
								:type="item.viewConfig?.tag?.type"
								:size="item.viewConfig?.tag?.size"
								:color="item.viewConfig?.tag?.color"
								:style="item.viewConfig?.tag?.type ? {} : getLabelColor(item.viewConfig?.tag?.color)"
								>{{ item.value }}</el-tag
							>
						</div>
						<div v-else class="baseValue">
							<MultiLineText type="BASE" :textValue="item.value || '--'"></MultiLineText>
						</div>
					</div>
				</template>
			</section>
			<div style="width: 100%; height: 12px; background: #f5f5f5"></div>
		</div>

		<div v-if="listInfo.length > 0" :class="`${paddingClass}`">
			<!-- //折叠面板模式 -->
			<XZLCollapse v-if="collapse" :modelValue="activeNames">
				<template v-for="(sectionItem, sectionIndex) in listInfo" :key="sectionIndex">
					<XZLCollapseItem
						v-if="!sectionItem.hidden"
						:title="sectionItem.title"
						:name="sectionIndex"
						:disabled="sectionItem.disabled"
						:showDisableTag="true"
					>
						<!-- 文本模式 -->
						<div class="flex flex-col">
							<div v-if="sectionItem.type === 'list'" class="flex flex-col gap-3">
								<template v-for="(listItem, listIndex) in sectionItem.value" :key="listIndex">
									<section :class="`itemContent grid grid-cols-${sectionItem.cols || 2} `">
										<template v-for="(item, index) in sectionItem.list" :key="index">
											<div :class="`itemClass col-span-${item.col || 1}`" v-if="!item.hidden">
												<div class="itemLabel">
													{{ item.label }}
												</div>
												<div class="itemValue">
													<!-- {{
														!item.label
															? ''
															: item.callback
															? item.callback(listItem[item.value])
															: listItem[item.value] || '--'
													}} -->
													<MultiLineText
														:textValue="
															!item.label
																? ''
																: item.callback
																	? item.callback(listItem[item.value])
																	: listItem[item.value] || '--'
														"
													></MultiLineText>
												</div>
											</div>
										</template>
									</section>
								</template>
							</div>

							<section
								v-else
								:class="`${sectionItem.hiddenBorder ? '' : 'itemContent'} grid grid-cols-${sectionItem.cols || 2}`"
							>
								<template v-for="(item, index) in sectionItem.list" :key="index">
									<template v-if="!item.hidden">
										<div :class="`itemClass col-span-${item.col || 1}`" v-if="item.component">
											<component :key="index" :is="item.component" />
										</div>
										<div :class="`itemClass col-span-${item.col || 1}`" v-else>
											<div class="itemLabel">
												{{ item.label }}
											</div>
											<div v-if="item.jsxFn" class="itemValue">
												<RenderComponent :renderFunction="item.jsxFn"> </RenderComponent>
											</div>
											<div v-else class="itemValue">
												<!-- 标签展示 -->
												<template v-if="item.viewConfig?.type === 'tag'">
													<!-- 多个标签 -->
													<template v-if="Array.isArray(item.value)">
														<el-tag
															class="ml-2"
															v-for="(tag, inx) in item.value"
															:key="inx"
															:type="item.viewConfig?.tag?.type"
															:size="item.viewConfig?.tag?.size"
															:color="tag.color || item.viewConfig?.tag?.color"
															:style="tagStyle(tag, item)"
															>{{ tag.name }}</el-tag
														>
													</template>
													<!-- 单个标签 -->
													<el-tag
														v-else
														:type="item.viewConfig?.tag?.type"
														:size="item.viewConfig?.tag?.size"
														:color="item.viewConfig?.tag?.color"
														:style="item.viewConfig?.tag?.type ? {} : getLabelColor(item.viewConfig?.tag?.color)"
														>{{ item.value }}</el-tag
													>
												</template>
												<!-- 图片展示 -->
												<template v-else-if="item.viewConfig?.type === 'image'">
													<image-preview
														:url-list="Array.isArray(item.value) ? item.value : [item.value]"
														:image-size="item.viewConfig?.image?.imageSize ?? 76"
														disabled
													/>
												</template>
												<template v-else-if="item.viewConfig?.type === 'link'">
													<el-link type="primary" @click="item.viewConfig?.link.func">{{ item.value }}</el-link>
												</template>
												<!-- 文本展示 -->
												<span
													v-else
													:style="{
														fontSize:
															item.label && item.viewConfig?.text?.fontSize
																? item.viewConfig?.text?.fontSize + 'px'
																: '',
														color: item.label && item.viewConfig?.text?.color ? item.viewConfig?.text?.color : '',
													}"
												>
													<!-- {{ !item.label ? '' : item.value || '--' }} -->
													<MultiLineText :textValue="!item.label ? '' : item.value || '--'"> </MultiLineText>
												</span>
											</div>
										</div>
									</template>
									<template v-if="isFilledUp(sectionItem, index)">
										<div class="col-span-1 itemClass">
											<div class="itemLabel"></div>
											<div class="itemValue"></div>
										</div>
									</template>
								</template>
							</section>
						</div>
					</XZLCollapseItem>
				</template>
			</XZLCollapse>
			<!-- 文本模式 -->
			<div class="flex flex-col" v-else>
				<template v-for="(sectionItem, sectionIndex) in listInfo" :key="sectionIndex">
					<div v-if="sectionItem.title" class="itemTitle">{{ sectionItem.title }}</div>
					<template v-if="sectionItem.type === 'list'">
						<template v-for="(listItem, listIndex) in sectionItem.value" :key="listIndex">
							<section :class="`itemContent grid grid-cols-${sectionItem.cols || 2} mb-4`">
								<template v-for="(item, index) in sectionItem.list" :key="index">
									<div :class="`itemClass col-span-${item.col || 1}`" v-if="!item.hidden">
										<div class="itemLabel">
											{{ item.label }}
										</div>
										<div class="itemValue">
											<!-- {{ !item.label ? '' : listItem[item.value] || '--' }} -->
											<MultiLineText :textValue="!item.label ? '' : listItem[item.value] || '--'"> </MultiLineText>
										</div>
									</div>
								</template>
							</section>
						</template>
					</template>

					<section
						v-else
						:class="`${sectionItem.hiddenBorder ? '' : 'itemContent'}  grid grid-cols-${sectionItem.cols || 2} mb-4`"
					>
						<template v-for="(item, index) in sectionItem.list" :key="index">
							<template v-if="!item.hidden">
								<div :class="`itemClass col-span-${item.col || 1}`" v-if="item.component">
									<component :key="index" :is="item.component" />
								</div>
								<div :class="`itemClass col-span-${item.col || 1}`" v-else>
									<div class="itemLabel">
										{{ item.label }}
									</div>
									<div v-if="item.jsxFn" class="itemValue">
										<RenderComponent :renderFunction="item.jsxFn"> </RenderComponent>
									</div>
									<div v-else class="itemValue">
										<!-- 标签展示 -->
										<template v-if="item.viewConfig?.type === 'tag'">
											<!-- 多个标签 -->
											<template v-if="Array.isArray(item.value)">
												<el-tag
													v-for="(tag, inx) in item.value"
													:key="inx"
													:type="item.viewConfig?.tag?.type"
													:size="item.viewConfig?.tag?.size"
													:color="item.viewConfig?.tag?.color"
													:style="tagStyle(tag, item)"
													>{{ tag }}</el-tag
												>
											</template>
											<!-- 单个标签 -->
											<el-tag
												v-else
												:type="item.viewConfig?.tag?.type"
												:size="item.viewConfig?.tag?.size"
												:color="item.viewConfig?.tag?.color"
												:style="item.viewConfig?.tag?.type ? {} : getLabelColor(item.viewConfig?.tag?.color)"
												>{{ item.value }}</el-tag
											>
										</template>
										<!-- 图片展示 -->
										<template v-else-if="item.viewConfig?.type === 'image'">
											<image-preview
												:url-list="Array.isArray(item.value) ? item.value : [item.value]"
												:image-size="item.viewConfig?.image?.imageSize"
												disabled
											/>
										</template>
										<template v-else-if="item.viewConfig?.type === 'link'">
											<el-link type="primary" @click="item.viewConfig?.link.func">{{ item.value }}</el-link>
										</template>
										<!-- 文本展示 -->
										<span
											v-else
											:style="{
												fontSize:
													item.label && item.viewConfig?.text?.fontSize ? item.viewConfig?.text?.fontSize + 'px' : '',
												color: item.label && item.viewConfig?.text?.color ? item.viewConfig?.text?.color : '',
											}"
										>
											<!-- {{ !item.label ? '' : item.value || '--' }} -->
											<MultiLineText :textValue="!item.label ? '' : item.value || '--'"> </MultiLineText>
										</span>
									</div>
								</div>
							</template>
							<template v-if="isFilledUp(sectionItem, index)">
								<div class="col-span-1 itemClass">
									<div class="itemLabel"></div>
									<div class="itemValue"></div>
								</div>
							</template>
						</template>
					</section>
				</template>
			</div>
		</div>
		<empty-state v-if="isEmpty" type="list" description="暂无数据" />
	</div>
</template>
<script setup lang="tsx">
import { computed, toValue } from 'vue'
import RenderComponent from '@/components/RenderComponent/index.tsx'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'
import XZLCollapse from '@/components/proxyComponents/XZLCollapse/index.vue'
import XZLCollapseItem from '@/components/proxyComponents/XZLCollapseItem/index.vue'
import MultiLineText from './multiLineText.vue'
import { getLabelColor } from '@/utils/common'

type Value = string | number | (number | string)[] | Record<string, any>[]
interface Item {
	/**字段名称 */
	label?: string
	/**字段值 */
	value?: Value
	/**对value字段进行加工 */
	callback?: (value: Value) => any
	/**字段颜色 */
	// color?: string
	/**组件 */
	component?: string | (() => JSX.Element) | string[] | Array<() => JSX.Element>
	/**jsx节点函数 */
	jsxFn?: Function
	/**显示设置 */
	viewConfig?: {
		// 默认 text 文本
		type?: 'text' | 'tag' | 'image' | 'link'
		// 最大显示数量，只对tag和image有效
		max?: number
		// 文本设置
		text?: {
			// 字体大小
			fontSize?: number
			// 字体颜色
			color?: string
		}
		// 标签设置: 同Element-plus Tag标签，目前只有部分属性，后期需要再拓展
		tag?: {
			type?: 'success' | 'info' | 'warning' | 'danger' | ''
			size?: 'large' | 'default' | 'small' | ''
			color?: string
		}
		// 图片设置
		image?: {
			// 图片尺寸
			imageSize: number
		}
		// 超链接
		link?: {
			func: () => void
		}
	}
	/**当前字段所占cols份额 */
	col?: number
	/**当前行总列数 */
	cols?: number
	/**当前模块标题 */
	title?: string
	/**当前模块类型 list(列表) */
	type?: string
	/**当前字段是否隐藏 */
	hidden?: boolean
	/** 折叠面板模式下当前项是否可操作 */
	disabled?: boolean
	/**左下边框样式兼容 */
	hiddenBorder?: boolean
	/**子项列表数据 */
	list?: Array<Item>
}
type BaseItem = Pick<Item, 'label' | 'value' | 'col' | 'viewConfig' | 'hidden'>
type Cols = Item['cols']
const props = defineProps({
	list: {
		type: Array as () => Item[],
		default: () => [],
	},
	base: {
		type: Object as () => {
			list: BaseItem[]
			cols?: Cols
		},
		default: () => ({
			list: [],
			cols: 2,
		}),
	},
	isEmpty: {
		type: Boolean,
		default: false,
	},
	collapse: {
		type: Boolean,
		default: false,
	},
	defaultName: {
		type: Object as () => number[],
		default: () => {
			return [0]
		},
	},
	defaultExpandAll: {
		type: Boolean,
		default: false,
	},
	paddingClass: {
		type: String,
		default: 'content',
	},
})

const baseInfo = computed(() => {
	return props.base
})
const listInfo = computed(() => {
	return props.list
})
const isEmpty = computed(() => {
	return props.isEmpty
})
const activeNames = computed(() => {
	if (props.collapse && props.defaultExpandAll) {
		return [...Array(listInfo.value.length).keys()]
	}
	return props.defaultName
})

const tagStyle = (tag, item) => {
	// 使用了类型就走类型样式
	console.log(`output->item.viewConfig?.tag?.type`, item.viewConfig)
	if (item.viewConfig?.tag?.type) return {}
	return tag.color ? getLabelColor(tag.color) : getLabelColor(item.viewConfig?.tag?.color)
}
// 是否需要补充空白格
const isFilledUp = (sectionItem, index) => {
	if (sectionItem.cols === 1) {
		return false
	}
	// 是否存在跨行
	let flag = sectionItem.list.every((item) => item.col !== 2)
	let list = sectionItem.list.filter((item) => !item.hidden)
	if (!flag) {
		let sectionList = []
		if (index !== sectionItem.list.length - 1) {
			let item = sectionItem.list[index + 1]
			if (item.col === 2) {
				for (let i = index; i >= 0; i--) {
					if (sectionItem.list[i].col === 2) {
						break
					}
					sectionList.push(sectionItem.list[i])
				}
			}
		}
		list = sectionList.filter((item) => !item.hidden)
		if (list.length % 2 !== 0) {
			return true
		}
	} else {
		if (list.length % 2 !== 0 && index === list.length - 1) {
			return true
		}
	}
	return false
}
</script>
<style lang="scss" scoped>
.baseContainer {
	.mainItem {
		.mainItemLabel {
			@apply mb-xs;
			font-weight: 400;
			font-size: 12px;
			color: theme('colors.gray.400');
			line-height: 16px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}
		.baseValue {
			font-weight: 400;
			font-size: 14px;
			color: theme('colors.gray.700');
			line-height: 20px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}
	}
	.verticalLine {
		width: 0px;
		height: 26px;
		border-left: 1px solid theme('colors.line.1');
		transform: scaleX(0.5);
		transform-origin: left; /* 将变换基点设置为左边 */
	}
}

.baseClass {
	.baseLabel {
		color: theme('colors.gray.400');
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		margin-right: theme('padding.xs');
	}
	.baseValue {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: theme('colors.gray.700');
		word-break: break-word;
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
	}
}

.itemTitle {
	height: 24px;
	font-size: 14px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.8);
	line-height: 24px;
	margin-bottom: 8px;
}

.itemContent {
	border-left: 1px solid #efefef;
	border-bottom: 1px solid #efefef;
}

.itemClass {
	display: flex;
	align-items: stretch;
	overflow: hidden;

	.itemLabel {
		width: 166px;
		min-width: 166px;
		min-height: 46px;
		background: #f8f8f8;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border-top: 1px solid #efefef;
		border-right: 1px solid #efefef;

		font-weight: 400;
		color: rgba(0, 0, 0, 0.6);
		line-height: 22px;
		display: flex;
		padding-top: 12px;
		padding-left: 12px;
	}

	.itemValue {
		flex: 1;
		min-height: 46px;
		background: #ffffff;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border-top: 1px solid #efefef;
		border-right: 1px solid #efefef;
		font-weight: 400;
		color: theme('colors.gray.600');

		line-height: 22px;
		align-items: center;
		padding: 7px 12px;
		word-break: break-word;
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		// overflow: hidden;
	}
}
</style>
