import service from '@/utils/request'

// 获取产品列表
export const getProductList = (data: any) => {
	return service({
		url: '/productManage/getProductList',
		method: 'post',
		data,
	})
}

// 批量获取产品信息
export const getProductByIds = (data: any) => {
	return service({
		url: '/productManage/getProductByIds',
		method: 'post',
		data,
	})
}

// 获取产品详情
export const findProduct = (params: any) => {
	return service({
		url: '/productManage/findProduct',
		method: 'get',
		params,
	})
}

// 添加产品
export const createProduct = (data: any) => {
	return service({
		url: '/productManage/createProduct',
		method: 'post',
		data,
	})
}

// 修改产品
export const updateProduct = (data: any) => {
	return service({
		url: '/productManage/updateProduct',
		method: 'put',
		data,
	})
}

// 删除产品
export const deleteProduct = (data: any) => {
	return service({
		url: '/productManage/deleteProductByIds',
		method: 'delete',
		data,
	})
}

// 启用
export const enableStatus = (data: any) => {
	return service({
		url: '/productManage/enableStatus',
		method: 'put',
		data,
	})
}

// 停用
export const disableStatus = (data: any) => {
	return service({
		url: '/productManage/disableStatus',
		method: 'put',
		data,
	})
}

// 导入
export const importApi = (data: any) => {
	return service({
		url: '/productManage/importProduct',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}

// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/productManage/getProductList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}

// 获取产品编号
export const getProductCode = (data: any) => {
	return service({
		url: '/productManage/getProductCode',
		method: 'post',
		data,
	})
}
