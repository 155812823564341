<template>
	<el-select
		class="w-full"
		v-model="value"
		multiple
		collapse-tags
		collapse-tags-tooltip
		filterable
		clearable
		placeholder="请选择"
		v-bind="$attrs"
	>
		<el-option v-for="item in dataList" :key="item.ID" :label="item.modelName" :value="item.ID" />
	</el-select>
</template>

<!-- 合同管理 - 模板管理 -->
<script lang="ts" setup>
import { ref, onMounted, watchEffect } from 'vue'
import { useVModel } from '@vueuse/core'
import { getContractModelAll } from '@/api/serveApi/contractManagement/contractList'

interface Props {
	modelValue: number[]
	modelType: number // 模板类型
	areaId?: number // 区域id
	data?: any[]
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const dataList = ref([])

watchEffect(() => {
	if (props.data) {
		dataList.value = props.data
	}
})

onMounted(() => {
	if (!props.data) {
		getData()
	}
})

const getData = async () => {
	const res = await getContractModelAll({
		modelType: props.modelType,
		areaId: props.areaId,
	})
	if (res && res.code === 0) {
		dataList.value = res.data
	}
}
</script>
