import { useUserStore } from '@/pinia/modules/user'
import { useRouterStore } from '@/pinia/modules/router'
import getPageTitle from '@/utils/page'
import router from '@/router'
import Nprogress from 'nprogress'

const whiteList = ['Login']

/**获取用户信息、菜单信息、用户权限信息 */
export const getRouter = async (userStore, routerStore) => {
	try {
		const data = await userStore.GetUserInfo()
		await routerStore.SetAsyncRouter()

		const asyncRouters = routerStore.asyncRouters
		asyncRouters.forEach((asyncRouter) => {
			router.addRoute(asyncRouter)
		})
		await userStore.GetUserPower()
		console.log('asyncRouters', asyncRouters)
		return true
	} catch (error) {
		return false
	}
}

async function handleKeepAlive(to) {
	if (to.matched.some((item) => item.meta.keepAlive)) {
		if (to.matched && to.matched.length > 2) {
			for (let i = 1; i < to.matched.length; i++) {
				const element = to.matched[i - 1]
				if (element.name === 'layout') {
					to.matched.splice(i, 1)
					await handleKeepAlive(to)
				}
				// 如果没有按需加载完成则等待加载
				if (typeof element.components.default === 'function') {
					await element.components.default()
					await handleKeepAlive(to)
				}
			}
		}
	}
}
router.beforeEach(async (to, from) => {
	Nprogress.remove()
	Nprogress.start()
	const routerStore = useRouterStore()
	const userStore = useUserStore()
	to.meta.matched = [...to.matched]
	handleKeepAlive(to)
	const token = userStore.token
	// 在白名单中的判断情况
	document.title = getPageTitle(to.meta.title, to)
	// to.name菜单不存在 会调整到404
	if (whiteList.indexOf(to.name) > -1) {
		if (token) {
			if (!routerStore.asyncRouterFlag && whiteList.indexOf(from.name) < 0) {
				await getRouter(userStore, routerStore)
			}
			// token 可以解析但是却是不存在的用户 id 或角色 id 会导致无限调用
			if (userStore.userInfo?.authority?.defaultRouter != null) {
				return { name: userStore.userInfo?.authority?.defaultRouter }
			} else {
				// 强制退出账号
				userStore.ClearStorage()
				return {
					name: 'Login',
					query: {
						redirect: to.href,
					},
				}
			}
		} else {
			return true
		}
	} else {
		// 不在白名单中并且已经登录的时候
		if (token) {
			// 添加flag防止多次获取动态路由和栈溢出
			if (!routerStore.asyncRouterFlag && whiteList.indexOf(from.name) < 0) {
				const valid = await getRouter(userStore, routerStore)
				// 如果没有获取到用户信息 不允许执行replace 防止栈溢出
				if (!valid) return
				if (userStore.token) {
					return { ...to, replace: true }
				} else {
					return {
						name: 'Login',
						query: { redirect: to.href },
					}
				}
			} else {
				if (to.matched.length) {
					return true
				} else {
					return { path: '/layout/404' }
				}
			}
		}
		// 不在白名单中并且未登录的时候
		if (!token) {
			return {
				name: 'Login',
				query: {
					redirect: to.href,
				},
			}
		}
	}
})

/**解决骨架屏遮挡内容，展示app样式 */
const showApp = () => {
	// 移除骨架屏节点
	const skeletonTemplate = document.getElementById('skeleton-template')
	if (skeletonTemplate) {
		skeletonTemplate.parentNode?.removeChild?.(skeletonTemplate)
	}
}

router.afterEach(() => {
	showApp()
	// 路由加载完成后关闭进度条
	document.getElementsByClassName('main-cont main-right')[0]?.scrollTo(0, 0)
	Nprogress.done()
})

router.onError(() => {
	showApp()
	// 路由发生错误后销毁进度条
	Nprogress.remove()
})
