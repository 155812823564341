<template>
	<el-dialog
		:model-value="modelValue"
		title="添加产品"
		width="60%"
		:modal="true"
		:close-on-click-modal="false"
		append-to-body
		@close="handleClose"
	>
		<div class="flex flex-col">
			<QueryForm class="mb-md" @search="handleCurrentChange(1)" @reset="resetData">
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.code"
						placeholder="产品编号"
						:disabled="disbledParams && defaultParams['code']"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.name"
						:disabled="disbledParams && defaultParams['name']"
						placeholder="产品名称"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.specification"
						:disabled="disbledParams && defaultParams['specification']"
						placeholder="规格型号"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLSelect
						class="w-full"
						v-model="searchParam.category"
						placeholder="产品品类"
						:disabled="disbledParams && defaultParams['category']"
						multiple
						collapse-tags
						collapse-tags-tooltip
						filterable
						clearable
						:joinList="dictionaryMap[DictionaryType.PRODUCTCATEGORY]"
						:options="{ defaultValue: 'optionId', defaultLabel: 'optionValue' }"
					></XZLSelect>
				</QueryFormItem>
				<QueryFormItem>
					<XZLSelect
						class="w-full"
						v-model="searchParam.brand"
						placeholder="产品品牌"
						:disabled="disbledParams && defaultParams['brand']"
						multiple
						collapse-tags
						collapse-tags-tooltip
						filterable
						clearable
						:joinList="dictionaryMap[DictionaryType.BRANDING]"
						:options="{ defaultValue: 'optionId', defaultLabel: 'optionValue' }"
					></XZLSelect>
				</QueryFormItem>
			</QueryForm>
			<el-table
				v-loading="tableState.loading"
				ref="tableRef"
				class="flex-1"
				:data="tableState.list"
				style="width: 100%"
				:scrollbar-always-on="true"
				:border="true"
				row-key="ID"
				@selection-change="selectionChange"
			>
				<el-table-column
					type="selection"
					:selectable="selectable"
					width="50"
					:align="'center'"
					:reserve-selection="true"
				></el-table-column>
				<el-table-column type="index" label="序号" width="60" :align="'center'" :index="indexMethod" />
				<el-table-column prop="code" label="产品编号">
					<template #default="{ row }">
						<el-link
							type="primary"
							@click="
								() =>
									drawerOpen(DrawerType.PRODUCTLIST, {
										title: row.name,
										props: {
											rowId: row.ID,
										},
										selectList: [{ ...row }],
									})
							"
							>{{ row.code }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="产品名称" />
				<el-table-column prop="brandName" label="产品品牌" />
				<el-table-column prop="categoryName" label="产品品类" />
				<el-table-column prop="specification" label="规格型号" />
				<el-table-column prop="unitName" label="单位" />
			</el-table>
			<xzl-pagination
				:current-page="searchParam.page"
				:page-size="searchParam.pageSize"
				:total="tableState.total"
				:size-change="handleSizeChange"
				:current-change="handleCurrentChange"
			/>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button text @click="handleClose">取消</el-button>
				<el-button type="primary" @click="handleConfirm">确定</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch, nextTick } from 'vue'
import { concat } from 'lodash-es'
import { ElMessage, ElTable } from 'element-plus'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'

import QueryForm from '@/components/queryForm/index.vue'
import QueryFormItem from '@/components/queryForm/item.vue'

import { getProductList, getProductByIds } from '@/api/serveApi/basicInformation/product/productList'
import { useDictionaryType, DictionaryType } from '@/hooks/useDictionaryType'

interface Props {
	modelValue: boolean
	rowIds?: number[] // 已勾选的产品id集合
	checkableIds?: number[] // 不可勾选的产品id集合
	defaultParams?: Partial<SearchParam> // 搜索条件
	disbledParams?: boolean // 如果有默认搜索条件时，默认搜索条件是否可修改
	addPolicyPromotionType?: number
	ids?: number[] // 你将选择的产品，只能从这id里面·的产品里选择
}

interface SearchParam {
	page: number
	pageSize: number
	code: string
	name: string
	specification: string
	category: number[]
	brand: number[]
}

class InitSearchParam implements SearchParam {
	page: number = 1
	pageSize: number = 20
	code: string = '' // 产品编号
	name: string = '' // 产品名称
	specification: string = '' // 规格型号
	category: number[] = [] // 产品品类
	brand: number[] = [] // 产品品牌
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue', 'confirm'])

const tableRef = ref<InstanceType<typeof ElTable>>()

const { drawerOpen } = usePageDrawer()

const searchParam = reactive<InitSearchParam>(new InitSearchParam())

const tableState = reactive({
	loading: false,
	list: [],
	selected: [],
	total: 0,
})
const indexMethod = (index: number) => {
	return searchParam.pageSize * (searchParam?.page - 1) + index + 1
}
const checkList = ref([])

const { dictionaryMap } = useDictionaryType()

watch(
	() => props.defaultParams,
	(newVal) => {
		if (newVal) {
			for (const key in searchParam) {
				if (newVal[key]) searchParam[key] = newVal[key]
			}
		}
	},
	{ deep: true, immediate: true }
)

watch(
	[checkList, () => tableState.list],
	(newVal) => {
		if (newVal[0].length > 0 && newVal[1].length > 0) {
			nextTick(() => {
				const ids: number[] = checkList.value.map((o) => o.ID)
				tableState.list.forEach((row) => {
					if (ids.includes(row.ID)) {
						tableRef.value!.toggleRowSelection(row, true)
					}
				})
			})
		}
	},
	{ deep: true }
)

onMounted(() => {
	if (props.rowIds?.length > 0) {
		getCheckProduct()
	}
	getList()
})

// 获取产品列表
const getList = async () => {
	tableState.loading = true
	const params = {
		page: searchParam.page,
		pageSize: searchParam.pageSize,
		filters: getFilters(),
	}
	const res: ResultType = await getProductList(params)
	if (res && res.code === 0) {
		tableState.list = res.data?.list ?? []
		tableState.total = res.data?.total ?? 0
	}
	tableState.loading = false
}

// 获取已勾选的产品
const getCheckProduct = async () => {
	const res = await getProductByIds({
		ids: props.rowIds,
		status: 1,
	})
	if (res && res.code === 0) {
		checkList.value = res.data?.list
	}
}

// 获取查询参数
const getFilters = () => {
	// 产品用到
	if (props.addPolicyPromotionType == 2) {
		//纯水
		searchParam.category = [2]
	}
	if (props.addPolicyPromotionType == 3) {
		//矿·水
		searchParam.category = [1]
	}
	const list = [
		{
			fieldName: 'status',
			fieldValues: ['1'],
			fromType: 'select',
			operator: '=',
		},
	]
	if (searchParam.code) {
		list.push({
			fieldName: 'code',
			fieldValues: [searchParam.code],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.name) {
		list.push({
			fieldName: 'name',
			fieldValues: [searchParam.name],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.specification) {
		list.push({
			fieldName: 'specification',
			fieldValues: [searchParam.specification],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.brand.length > 0) {
		list.push({
			fieldName: 'brand',
			fieldValues: searchParam.brand?.map((o) => String(o)),
			fromType: 'select',
			operator: 'in',
		})
	}
	if (searchParam.category.length > 0) {
		list.push({
			fieldName: 'category',
			fieldValues: searchParam.category?.map((o) => String(o)),
			fromType: 'select',
			operator: 'in',
		})
	}
	// 如果是预算汇总添加产品(只能添加箱的)
	if (props.addPolicyPromotionType == 4) {
		list.push({
			fieldName: 'unit',
			fieldValues: ['2'],
			fromType: 'select',
			operator: 'NOT IN',
		})
	}
	// 根据id查询
	if (props.addPolicyPromotionType == 5) {
		list.push({
			fieldName: 'id',
			fieldValues: props.ids?.map((id) => String(id)),
			fromType: 'select',
			operator: 'in',
		})
	}
	return list
}

// 重置
const resetData = () => {
	const params = new InitSearchParam()
	for (const key in searchParam) {
		if (key !== 'pageSize') {
			searchParam[key] = props.defaultParams?.[key] ?? params[key]
		}
	}
	getList()
}

// 分页
const handleSizeChange = (val: number) => {
	searchParam.pageSize = val
	getList()
}

// 分页
const handleCurrentChange = (val: number) => {
	searchParam.page = val
	getList()
}

// 勾选
const selectionChange = (val) => {
	tableState.selected = val
	const ids: number[] = val?.map((item) => item.ID)
	checkList.value = checkList.value.filter((item) => !ids.includes(item.ID))
}

// 不可取消勾选的行
const selectable = (row) => {
	return !props.checkableIds?.includes(row.ID)
}

// 取消
const handleClose = () => {
	emit('update:modelValue', false)
}

// 确认
const handleConfirm = () => {
	const list = concat(tableState.selected, checkList.value)
	if (list.length === 0) {
		ElMessage.warning('请选择产品')
		return
	}
	emit('confirm', list)
	handleClose()
}
</script>

