import service from '@/utils/request'
// 新建标签组
export const createTagGroup = (data: any) => {
	return service({
		url: '/tagGroup/createTagGroup',
		method: 'post',
		data: data,
	})
}
// 编辑标签组
export const updateTagGroup = (data: any) => {
	return service({
		url: '/tagGroup/updateTagGroup',
		method: 'put',
		data: data,
	})
}
// 删除标签组
export const deleteTagGroupByIds = (data: any) => {
	return service({
		url: '/tagGroup/deleteTagGroupByIds',
		method: 'DELETE',
		data: data,
	})
}
// 获取标签组列表
export const getTagGroupList = (data: any) => {
	return service({
		url: '/tagGroup/getTagGroupList',
		method: 'get',
		params: data,
	})
}
/**
 * 禁用 启用 标签组
 * @param data
 * @param status true 启用  false 禁用
 * @returns
 */
export const switchTagGroupStatus = (data: any, status: boolean) => {
	return service({
		url: `/tagGroup/${status ? 'openTagGroupStatus' : 'closeTagGroupStatus'}`,
		method: 'put',
		data: data,
	})
}
// 表格
export const getTagInfoList = (data: any) => {
	return service({
		url: `/tagInfo/getTagInfoList?groupId=${data.gropId}`,
		method: 'post',
		data,
	})
}
// 新增
export const createTagInfo = (data: any) => {
	return service({
		url: '/tagInfo/createTagInfo',
		method: 'post',
		data,
	})
}
/**
 *标签的启用 禁用
 * @param data
 * @param status 1 启用 openTagInfoStatus   2 禁用 closeTagInfoStatus
 * @returns
 */
/**
 * 标签启用
 * @param data
 * @returns
 */
export const openTagInfoStatus = (data: any) => {
	return service({
		url: '/tagInfo/openTagInfoStatus',
		method: 'put',
		data,
	})
}
/**
 * 标签停用
 * @param data
 * @returns
 */
export const closeTagInfoStatus = (data: any) => {
	return service({
		url: '/tagInfo/closeTagInfoStatus',
		method: 'put',
		data,
	})
}

// 编辑
export const updateTagInfo = (data: any) => {
	return service({
		url: '/tagInfo/updateTagInfo',
		method: 'put',
		data,
	})
}
// 删除
export const deleteTagInfoByIds = (data: any) => {
	return service({
		url: '/tagInfo/deleteTagInfoByIds',
		method: 'DELETE',
		data,
	})
}
