<template>
	<div class="multiLineText">
		<div
			:class="['contentBox', type === 'BASE' && 'baseContentBox']"
			:line-clamp="showStatus === textStatus.EXPAND ? 9999 : 3"
			ref="contentBoxRef"
		>
			<el-link
				:class="['btn', showExpand && showStatus === textStatus.EXPAND ? '' : 'ellipsis', type === 'BASE' && 'baseBtn']"
				:underline="false"
				type="primary"
				@click="toggle"
				v-if="showExpand && textValue"
				>{{ showStatus === textStatus.EXPAND ? '收起' : '展开' }}</el-link
			>
			<span :class="`${type === 'BASE' && 'baseText'} text `">{{ props.textValue }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, nextTick, watch } from 'vue'
const props = defineProps({
	textValue: {
		type: String,
		default: '',
	},
	type: {
		type: String,
		default: '',
	},
})
enum textStatus {
	EXPAND = 'EXPAND', // 展开
	PACKUP = 'PACKUP', // 收起
}
const contentBoxRef = ref()
const showExpand = ref(false)
const showStatus = ref(textStatus.PACKUP)
const maxLine = ref(3)
const resizeObserver = ref()
const width = ref()
const frameId = ref()
const updateWidth = (entries) => {
	for (let entry of entries) {
		width.value = entry.contentRect.width
	}
}

onMounted(() => {
	if (props.textValue && contentBoxRef.value) {
		resizeObserver.value = new ResizeObserver(updateWidth)
		resizeObserver.value.observe(contentBoxRef.value)
	}
})
watch(width, (newWidth) => {
	if (frameId.value) {
		cancelAnimationFrame(frameId.value)
	}
	frameId.value = requestAnimationFrame(() => {
		const scrollHeight = contentBoxRef.value.scrollHeight
		const clientHeight = contentBoxRef.value.clientHeight
		console.log(`output->scrollHeight,clientHeight`, scrollHeight, clientHeight)
		showExpand.value = scrollHeight > clientHeight
	})
})
const toggle = () => {
	showStatus.value = showStatus.value === textStatus.EXPAND ? textStatus.PACKUP : textStatus.EXPAND
}
onUnmounted(() => {
	if (resizeObserver.value && contentBoxRef.value) {
		resizeObserver.value.unobserve(contentBoxRef.value)
		resizeObserver.value.disconnect()
	}
	if (frameId.value) {
		cancelAnimationFrame(frameId.value)
	}
})
</script>

<style lang="scss" scoped>
.multiLineText {
	display: flex;
	.baseBtn {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}
	.baseText {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: theme('colors.gray.700');
		word-break: break-word;
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
	}
}
.contentBox {
	line-height: 1.5;
	max-height: 4.5em;
	overflow: hidden;
}
.baseContentBox {
	font-size: 12px;
}
[line-clamp='1'] {
	max-height: 1.5em;
}
[line-clamp='2'] {
	max-height: 3em;
}
[line-clamp='3'] {
	max-height: 4.5em;
}
[line-clamp='4'] {
	max-height: 48px;
}
[line-clamp='5'] {
	max-height: 7.5em;
}
[line-clamp='9999'] {
	max-height: 9999em;
}
.contentBox::before {
	content: '';
	float: right;
	height: 100%;
	margin-bottom: -1.5em;
}
.btn {
	float: right;
	clear: both;
}
.ellipsis::before {
	content: '...';
	color: #333;
	transform: translateX(-30%);
}
</style>
