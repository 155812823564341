import { ref, markRaw, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { BtnItem, useOperationBtnList, OpeationBtnType } from '@/hooks/useOperationBtnList'
import { channelInventoryRecordStatusMap } from '@/config/Constantconfig'
import {
	deleteDistributorStockByIds,
	submitDisStock,
	exportRuleRecord,
	exportCancelRecord,
	exportRuleDetail,
	exportCancelDetail,
} from '@/api/serveApi/inventoryManagement/channelInventoryRecord'

import { StockTypeText } from './config'

import writeInfo from './components/writeInfo.vue'
import readInfo from './components/readInfo/index.vue'
import Cancel from './components/cancel.vue'

import { DrawerType } from '@/hooks/usePageDrawer/types'
export const useChannelInventoryRecord = () => {
	const selectionList = ref([])
	const selectIds = ref([])

	const currentStockType = ref<number>(null)

	const initelectList = (value) => {
		selectionList.value = value
		selectIds.value = value?.map((item) => item.ID)
	}

	const initStockType = (type) => {
		currentStockType.value = type
	}

	/**
	 * 删除
	 * @param {Array<number>} dataIds id数组
	 */
	const handleDelete = async (ids?: number[]): Promise<void> => {
		const res = await deleteDistributorStockByIds({ ids })
		if (res && res.code === 0) {
			ElMessage.success('删除成功')
		}
	}

	// 提交
	const handleSubmit = async (ids?: number[]): Promise<void> => {
		if (ids?.length > 0) {
			const params = {
				ids,
				type: 2, // 2:提交，3:作废
			}
			const res = await submitDisStock(params)
			if (res && res.code === 0) {
				ElMessage.success('已提交')
			}
		}
	}

	// 按钮方法
	const buttonParams = computed(() => {
		return {
			[OpeationBtnType.ADD]: {
				title: `新增${StockTypeText[currentStockType.value]}`,
				component: writeInfo,
				props: {
					buttonInfo: {
						type: OpeationBtnType.ADD,
					},
					stockType: currentStockType.value,
				},
			},
			[OpeationBtnType.EDIT]: {
				title: `编辑${StockTypeText[currentStockType.value]}`,
				component: writeInfo,
				props: {
					buttonInfo: {
						type: OpeationBtnType.EDIT,
					},
					rowId: selectIds.value?.[0],
					stockType: currentStockType.value,
				},
				preOpenValidation: () => {
					const validMsg = profeessionalStatusValid(selectionList.value, {
						valid: [1],
						type: DrawerType.CHANNELINVENTORYRECORD,
						statusName: 'status',
					})
					if (typeof validMsg == 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.DELETE]: {
				showButton: true,
				content: '上报记录删除后数据不可恢复，请确认是否删除?',
				onConfirm: () => handleDelete(selectionList.value.map((o) => o.ID)),
				preOpenValidation: () => {
					const validMsg = profeessionalStatusValid(selectionList.value, {
						valid: [1],
						type: DrawerType.CHANNELINVENTORYRECORD,
						statusName: 'status',
					})
					if (typeof validMsg == 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.CANCEL]: {
				component: Cancel,
				props: {
					ids: selectionList.value?.map((o) => o.ID),
				},
				preOpenValidation: () => {
					const validMsg = profeessionalStatusValid(selectionList.value, {
						valid: [1, 2],
						type: DrawerType.CHANNELINVENTORYRECORD,
						statusName: 'status',
					})
					if (typeof validMsg == 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.SUBMIT]: {
				showButton: true,
				content: '库存上报记录提交后不可修改，确认是否继续提交上报?',
				onConfirm: () => handleSubmit(selectionList.value.map((o) => o.ID)),
				preOpenValidation: () => {
					const validMsg = profeessionalStatusValid(selectionList.value, {
						valid: [1],
						type: DrawerType.CHANNELINVENTORYRECORD,
						statusName: 'status',
					})
					if (typeof validMsg == 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.EXPORTINVENTORYRECORDS]: {
				onConfirm: currentStockType.value === 1 ? exportRuleRecord : exportCancelRecord,
			},
			[OpeationBtnType.EXPORTINVENTORYRECORDDETAILS]: {
				onConfirm: currentStockType.value === 1 ? exportRuleDetail : exportCancelDetail,
			},
		}
	})

	// 按钮列表
	const { btnList, btnPermissionFilter, profeessionalStatusValid } = useOperationBtnList(
		DrawerType.CHANNELINVENTORYRECORD
	)
	// const buttonList = computed(() =>
	// 	[
	// 		OpeationBtnType.ADD,
	// 		OpeationBtnType.EDIT,
	// 		OpeationBtnType.DELETE,
	// 		OpeationBtnType.CANCEL,
	// 		OpeationBtnType.SUBMIT,
	// 		OpeationBtnType.EXPORTINVENTORYRECORDS,
	// 		OpeationBtnType.EXPORTINVENTORYRECORDDETAILS,
	// 	].reduce((list, type) => {
	// 		const btn: BtnItem = btnList.find((item) => item.type === type)
	// 		if (btn) {
	// 			list.push(markRaw({ ...btn, ...buttonParams.value[type] }))
	// 		}
	// 		return list
	// 	}, [])
	// )
	const buttonList = computed(() =>
		btnPermissionFilter([
			OpeationBtnType.ADD,
			OpeationBtnType.EDIT,
			OpeationBtnType.DELETE,
			OpeationBtnType.CANCEL,
			OpeationBtnType.SUBMIT,
			OpeationBtnType.EXPORTINVENTORYRECORDS,
			OpeationBtnType.EXPORTINVENTORYRECORDDETAILS,
		])?.map((item) => {
			return {
				...item,
				...buttonParams.value[item.type],
			}
		})
	)
	// 抽屉数据
	const drawerConfig = computed(() => {
		return {
			component: readInfo,
			buttonList: buttonList.value?.filter((item) => {
				return [OpeationBtnType.SUBMIT, OpeationBtnType.EDIT, OpeationBtnType.DELETE].includes(item.type)
			}),
		}
	})

	return markRaw({
		buttonList,
		initelectList,
		initStockType,
		drawerConfigChannelInventoryRecord: drawerConfig,
	})
}
