<template>
	<div class="flex items-center">
		<el-date-picker
			v-model="startDate"
			@change="handleChange"
			type="year"
			placeholder="开始年份"
			:disabled-date="disabledStartDate"
		/>
		<span class="mx-3">-</span>
		<el-date-picker
			v-model="endDate"
			@change="handleChange"
			type="year"
			placeholder="结束年份"
			:disabled-date="disabledEndDate"
		/>
	</div>
</template>

<!-- 年份范围选择器 -->
<script setup lang="ts">
import { ref, watch, watchEffect, nextTick } from 'vue'
import { dayjs } from 'element-plus'
import { useVModel } from '@vueuse/core'

interface Props {
	modelValue: Array<Date | string>
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'change'])
const data = useVModel(props, 'modelValue', emit)

const startDate = ref<Date | string>('')
const endDate = ref<Date | string>('')

watch(
	() => props.modelValue,
	(newVal) => {
		if (newVal?.length > 0) {
			startDate.value = newVal[0]
			endDate.value = newVal[1]
		} else {
			startDate.value = endDate.value = ''
		}
	},
	{ deep: true, immediate: true }
)

const handleChange = () => {
	const firstDate = startDate.value ? dayjs(startDate.value).format('YYYY') : ''
	const lastDate = endDate.value ? dayjs(endDate.value).format('YYYY') : ''
	data.value = [firstDate, lastDate]
	nextTick(() => {
		emit('change', data.value)
	})
}

const disabledStartDate = (time: Date) => {
	if (endDate.value && new Date(dayjs(time).format('YYYY')) > new Date(dayjs(endDate.value).format('YYYY'))) {
		return true
	}
	return false
}

const disabledEndDate = (time: Date) => {
	if (startDate.value && new Date(dayjs(time).format('YYYY')) < new Date(dayjs(startDate.value).format('YYYY'))) {
		return true
	}
	return false
}
</script>
