<template>
	<el-collapse-item v-bind="$attrs">
		<template #title>
			<div class="collapse-item-title">
				<span class="icon cursor-pointer" v-if="!attrs.disabled || showDisableTag">
					<SvgIcon name="icon-retract" :size="16" />
				</span>
				<span>
					<slot name="title">{{ title }}</slot>
				</span>
			</div>
		</template>
		<slot></slot>
	</el-collapse-item>
</template>

<script setup lang="ts">
import { ref, useAttrs } from 'vue'
const props = defineProps({
	title: String,
	/**当要展示icon时设置为ture */
	showDisableTag: Boolean,
})
const attrs = useAttrs()
console.log(`output->attrs`, attrs, props)
</script>
