import service from '@/utils/request'

// 新增组织
export const createOrgDepartment = (data) => {
	return service({
		url: '/orgDepartment/createOrgDepartment',
		method: 'post',
		data,
	})
}

// 编辑组织
export const updateOrgDepartment = (data) => {
	return service({
		url: '/orgDepartment/updateOrgDepartment',
		method: 'put',
		data,
	})
}
// 根据部门ID查询部门信息
export const getOrgDepartment = (params) => {
	return service({
		url: '/orgDepartment/findOrgDepartment',
		method: 'get',
		params,
	})
}

// 启用
export const enableStatus = (data) => {
	return service({
		url: '/orgDepartment/enableStatus',
		method: 'put',
		data,
	})
}

// 停用
export const disabledStatus = (data) => {
	return service({
		url: '/orgDepartment/disabledStatus',
		method: 'put',
		data,
	})
}

// 设置应用职位
export const setApplyPosition = (data) => {
	return service({
		url: '/orgDepartment/setApplyPosition',
		method: 'put',
		data,
	})
}

// 查询应用职位
export const getApplyPosition = (params) => {
	return service({
		url: '/orgDepartment/getApplyPosition',
		method: 'get',
		params,
	})
}

// 新版本部门树状结构-兼容人员管理数据权限
export const getOrgEmpDepartmentList = (params?: any) => {
	return service({
		url: '/orgDepartment/getOrgEmpDepartmentList',
		method: 'get',
		params,
	})
}

/**获取部门树状结构 */
export const getOrgDepartmentTreeList = (params?: any) => {
	return service({
		url: '/orgDepartment/getOrgDepartmentTreeList',
		method: 'get',
		params,
	})
}

// 获取人员的部门名称列表
export const getAppOrgEmpDepartmentList = (params?: any) => {
	return service({
		url: '/orgDepartment/getAppOrgEmpDepartmentList',
		method: 'get',
		params,
	})
}

// 导入
export const importApi = (data: any) => {
	return service({
		url: '/orgDepartment/import',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}

// 获取组织类型
export const getOrgDepartmentList = (data) => {
	return service({
		url: '/orgDepartment/getOrgDepartmentList',
		method: 'post',
		data,
	})
}

// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/orgDepartment/getOrgDepartmentList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}

// 选人组件-获取部门列表
export const searchOrgDepartmentList = (data) => {
	return service({
		url: '/orgDepartment/searchOrgDepartmentList',
		method: 'post',
		data,
	})
}
