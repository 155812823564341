<template>
	<div v-if="flowData?.approvalStatus in ApprovalStatus" class="flow-status-box">
		<img :src="flowStatusMap[flowData.approvalStatus]" alt="" />
	</div>
</template>

<script setup lang="ts">
import { emitter, MittType } from '@/utils/bus'
import { ApprovalStatus } from '@/view/workflow/types'
import { InitFlowData, type FlowDataType } from './types'
import { ref, onMounted, onUnmounted } from 'vue'

import waitcommitImage from '@/assets/images/flowStatus/waitcommit.png'
import approvalwaitImage from '@/assets/images/flowStatus/approvalwait.png'
import approvalingImage from '@/assets/images/flowStatus/approvaling.png'
import agreeImage from '@/assets/images/flowStatus/agree.png'
import rejectImage from '@/assets/images/flowStatus/reject.png'
import withdrawedImage from '@/assets/images/flowStatus/withdrawed.png'

const props = defineProps<{
	drawerKey: string
}>()

const flowStatusMap: { [key in ApprovalStatus]: string } = {
	[ApprovalStatus.WAITCOMMIT]: waitcommitImage,
	[ApprovalStatus.APPROVALWAIT]: approvalwaitImage,
	[ApprovalStatus.APPROVALING]: approvalingImage,
	[ApprovalStatus.AGREE]: agreeImage,
	[ApprovalStatus.REJECT]: rejectImage,
	[ApprovalStatus.WITHDRAWED]: withdrawedImage,
}

const flowData = ref<FlowDataType>(new InitFlowData())

onMounted(() => {
	emitter.on(MittType.WorkflowData, (res: { module: string; data: FlowDataType }) => {
		res.module === props.drawerKey && (flowData.value = res.data)
	})
})

onUnmounted(() => {
	emitter.off(MittType.WorkflowData)
})
</script>

<style lang="scss" scoped>
.flow-status-box {
	width: 60px;
	height: 60px;
	img {
		width: 100%;
		height: 100%;
	}
}
</style>
