import { useAsyncState } from '@vueuse/core'
import { DrawerType } from './types'
import { checkAuthDistributor, getUserDistributorInfo } from '@/api/serveApi/common/infoVerification'

// 校验是否有权限访问-渠道客户
const { execute: fetchCheckAuthDistributor } = useAsyncState(
	checkAuthDistributor,
	{ code: 0, msg: '', data: '' },
	{
		immediate: false,
	}
)
// 校验是否有权限访问-活动方案
const { execute: fetchUserDistributorInfo } = useAsyncState(
	getUserDistributorInfo,
	{ code: 0, msg: '', data: '' },
	{
		immediate: false,
	}
)

/**
 * @description 对相应操作页是否可展开进行前置校验
 * @param type 详情页面名称
 */
export const checkServiceFn = async (type: DrawerType, rowId: string | number = '') => {
	switch (type) {
		case DrawerType.DEALERMGR:
			const res = await fetchCheckAuthDistributor(0, { id: rowId })
			return res.data ?? false
		case DrawerType.PROPOSALAPPLICATION:
			const { data } = await fetchUserDistributorInfo(0, {})
			return !data.IsDistributor ?? false
		default:
			return true
	}
}
