<template>
	<div class="h-full">
		<div v-if="errorMessage" class="flex flex-col justify-center h-full">
			<slot name="error">
				<img class="error-img" src="@/assets/images/empty/error.png" />
				<div class="error-text">抱歉，无法加载所需资源。请稍后重试或联系网站管理员。</div>
			</slot>
		</div>
		<Suspense v-else>
			<template #default>
				<slot name="default"></slot>
			</template>
			<template #fallback>
				<slot name="fallback">
					<el-skeleton :rows="10" animated />
				</slot>
			</template>
		</Suspense>
	</div>
</template>
<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue'

const errorMessage = ref(false)
onErrorCaptured((error, instance, code: any) => {
	console.log(error, instance, code, 'errrrrrrrrrrrrrror')
	// 生产环境下code是数字，  https://cn.vuejs.org/error-reference/#runtime-errors
	if ([0, 13, 'setup function', 'async component loader', 'render function'].includes(code)) {
		errorMessage.value = true
		return false
	}
})
</script>

<style scoped lang="scss">
.error-img {
	margin: 0 auto;
	width: 126px;
	height: 126px;
}
.error-text {
	text-align: center;
}
</style>
