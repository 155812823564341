import service from '@/utils/request'

//获取费用科目列表
export const getBudgetTableList = (data: any) => {
	return service({
		url: '/expenseBudget/getExpenseBudgetList',
		method: 'post',
		data,
	})
}
// 模拟费用管理-费用科目数据
// export const getFeeDimensionList = (data: any) => {
// 	return Promise.resolve({data:{ searchField: [{
//         Field:
//         "feecode",
//         FromType:
//         "text",
//         Name
//         :
//         "餐补",
//         Operator: "[{\"包含\": \"LIKE\"}, {\"不包含\": \"NOT LIKE\"}, {\"属于\": \"IN\"}, {\"不属于\": \"NOT IN\"}, {\"等于\": \"=\"}, {\"不等于\": \"<>\"}, {\"为空(未填写)\": \"IS NULL\"}, {\"不为空\": \"IS NOT NULL\"}, {\"开始于\": \"STARTWITH\"}, {\"结束于\": \"ENDWITH\"}]",
//         SelectType:
//         0,
//         Setting
//         :
//         "null"}],
//         list:[
//               { id: 44, isNext: true, categoryCode: 'XT4444', categoryName: '部门', system: 0, notes: '第一期使用维度', status: 1, categoryValueCount: 245, feeSubjects:[{ ID: 12, name: '个人消费', children: [{labe: '吃饭'}, {label: '睡觉'}]}, { ID: 14, label:'群体消费', children: [{ label: '凳子消费'}, { label: '桌子消费'}]}]},
//               { id: 45, isNext: false, categoryCode: 'XT4545', categoryName: '人员', system: 1, notes: '第二期使用维度', status: 0, categoryValueCount: 25, feeSubjects:[{ ID: 12, name: '个人消费', children: [{labe: '吃饭'}, {label: '睡觉'}]}, { ID: 14, label:'群体消费', children: [{ label: '凳子消费'}, { label: '桌子消费'}]}]},
//         ],
//         nextFeeSubject: [{ label: '消费', value: 1,}, { label: '使用', value: 2}],
//   }});
// }

// 费用管理-费用科目(启动接口)

export const enableUse = (data: any) => {
	return service({
		url: '/expenseBudget/enable',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(禁用接口)

export const disableUse = (data: any) => {
	return service({
		url: '/expenseBudget/disable',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(删除接口)

export const deleteBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/deleteExpenseBudgetByIds',
		method: 'DELETE',
		data,
	})
}

//费用管理-费用科目(新增)

export const createBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/createExpenseBudget',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(编辑)

export const updateBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/updateExpenseBudget',
		method: 'post',
		data,
	})
}

//(获取所有启用科目列表)

export const getPreviousFeeSubjects = (data: any) => {
	return service({
		url: '/expenseAccount/getAllParentAccount',
		method: 'post',
		data,
	})
}

//费用管理-费用科目(获取所有启用维度列表)

export const getPreviousFeeDimensions = (data: any) => {
	return service({
		url: '/expenseCategory/getAllCategory',
		method: 'post',
		data,
	})
}

// 费用管理-预算表(通过id查询当前数据详情)

export const getBudgetTableById = (data: any) => {
	return service({
		url: '/expenseBudget/findExpenseBudget',
		method: 'post',
		data,
	})
}
// 费用管理-预算表动态生成编号

export const getSystenBudgetCode = (data: any) => {
	return service({
		url: '/expenseBudget/generateCode',
		method: 'get',
		data,
	})
}
