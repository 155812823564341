/**
 * 筛选配置项类型
 * @param name 字段名称
 * @param fieldName 字段key
 * @param operator 筛选条件
 * @param operatorList 筛选条件列表
 * @param fieldValues 筛选值
 * @param valueList 筛选值列表
 * @param fromType 筛选类型
 * @param isExposed 是否外露
 * @param selectType 下拉类型
 * @param objectName 暂时只有费用管理使用，例如：费用管理 - 费用预算 - 年度预算申请（销售区域）
 */
export interface FieldItemType {
	name: string
	fieldName: string
	operator: OperatorType
	operatorList: OptionType[]
	fieldValues: any
	valueList: OptionType[]
	fromType: FromType
	isExposed: boolean
	selectType: SelectType
	objectName: string
}

/**
 * 初始化一个配置项
 */
export class InitFieldItem implements FieldItemType {
	name = ''
	fieldName = ''
	operator = null
	operatorList = []
	fieldValues = []
	valueList = []
	fromType = null
	isExposed = false
	selectType = 0
	objectName = ''
}

/**
 * 筛选时接口需要的值类型
 */
export type FilterType = Pick<FieldItemType, 'fieldName' | 'fieldValues' | 'fromType' | 'operator' | 'objectName'>

/**
 * 条件列表项、值列表项
 */
export interface OptionType {
	label: string
	value: OperatorType
}

/**
 * 筛选类型
 * @param text 文本
 * @param select 下拉选择
 * @param date 日期
 * @param number 数字
 * @param image 图片
 */
export enum FromType {
	text = 'text',
	select = 'select',
	date = 'date',
	number = 'number',
	image = 'image',
}

/**
 * 时间筛选类型
 * @param Day 日
 * @param Month 月
 * @param Year 年
 */
export enum DateTimeType {
	Day = 0,
	Month = 2,
	Year = 1,
}

export const DateTimeMap: { [key in DateTimeType]: string } = {
	[DateTimeType.Day]: 'daterange',
	[DateTimeType.Month]: 'monthrange',
	[DateTimeType.Year]: 'yearrange',
}

/**
 * 下拉类型
 * @param user 负责人 => 1
 * @param depart 组织 => 3
 * @param province 行政区域 => 4
 * @param salesArea 销售区域 => 5
 * @param terminalMgrTag 终端标签 => 6
 * @param position 职位 => 7
 * @param dealer 渠道客户 => 8
 * @param refrigeratortype 冰箱类型 => 9
 * @param sticker 广告贴画 => 10
 * @param refrigeratorBrand 冰箱品牌 => 11
 * @param refrigeratorModel 冰箱型号 => 12
 * @param dealerMgrTag 渠道标签 => 13
 * @param feeSubject 费用科目 => 14
 * @param feeDimension 费用维度 => 15
 * @param affiliationKA 所属KA => 16
 * @param refrigeratorProtocolTemplate 冰箱协议模板
 * @param terminal 终端模块
 * @param planningManagementChannelType 需求计划渠道类型
 */
export enum SelectType {
	user = 1,
	depart = 3,
	province = 4,
	salesArea = 5,
	terminalMgrTag = 6,
	position = 7,
	dealer = 8,
	refrigeratortype = 9,
	sticker = 10,
	refrigeratorBrand = 11,
	refrigeratorModel = 12,
	dealerMgrTag = 13,
	feeSubject = 14,
	feeDimension = 15,
	affiliationKA = 16,
	refrigeratorProtocolTemplate = 17,
	terminal = 18,
	planningManagementChannelType = 19,
}

/**
 * 下拉值类型
 * @param userList 人员列表
 * @param departList 组织列表
 * @param salesAreaList 销售区域列表
 * @param dealerList 渠道客户列表
 * @param refrigeratortypeList 冰箱类型列表
 * @param stickerList 广告贴画列表
 * @param refrigeratorBrandList 冰箱品牌列表
 * @param refrigeratorModelList 冰箱型号列表
 * @param terminalMgrList 终端列表
 * @param positionList 职位列表
 */
export enum FormValueType {
	userList = 'userList',
	departList = 'departList',
	salesAreaList = 'salesAreaList',
	dealerList = 'dealerList',
	refrigeratortypeList = 'refrigeratortypeList',
	stickerList = 'stickerList',
	refrigeratorBrandList = 'refrigeratorBrandList',
	refrigeratorModelList = 'refrigeratorModelList',
	refrigeratorProtocolTemplateList = 'refrigeratorProtocolTemplateList',
	terminalMgrList = 'terminalMgrList',
	positionList = 'positionList',
}

/**
 * 筛选条件值枚举
 * @description  -----------------常规条件-----------------
 * @param like 包含
 * @param notLike 不包含
 * @param in 属于
 * @param notIn 不属于
 * @param equal 等于
 * @param notEqual 不等于
 * @param lt 小于/早于
 * @param lte 小于等于/不晚于
 * @param gt 大于/晚于
 * @param gte 大于等于/不早于
 * @param between 介于/时间段
 * @param isNull 为空
 * @param isNotNull 不为空
 * @param startwith 开始于
 *
 * @description -----------------特殊条件-----------------
 * @param jsonLike 包含
 * @param jsonNotLike 不包含
 * @param jsonIsNull 为空
 * @param jsonIsNotNull 不为空
 */
export enum OperatorType {
	// 常规
	like = 'LIKE',
	notLike = 'NOT LIKE',
	in = 'IN',
	notIn = 'NOT IN',
	equal = '=',
	notEqual = '<>',
	lt = '<',
	lte = '<=',
	gt = '>',
	gte = '>=',
	between = 'BETWEEN',
	isNull = 'IS NULL',
	isNotNull = 'IS NOT NULL',
	startwith = 'STARTWITH',

	// 特殊
	jsonLike = 'JSON_CONTAINS',
	jsonNotLike = 'NOT JSON_CONTAINS',
	jsonIsNull = 'JSON_LENGTH IS NULL',
	jsonIsNotNull = 'JSON_LENGTH NOT NULL',
}
