/*
 * @Author: yoler 979700942@qq.com
 * @LastEditors: yoler 979700942@qq.com
 * @Date: 2024-01-08 18:16:34
 * @FilePath: \digitizing_web\src\components\Drawer\icon.tsx
 * @Description:
 */
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import { CircleCloseFilled, WarningFilled } from '@element-plus/icons-vue'
import { defineComponent } from 'vue'
import { ElIcon } from 'element-plus'

interface IProps {
	type: string
}

const Icon = defineComponent((props) => {
	const createIcon = () => {
		switch (props.type) {
			case OpeationBtnType.DELETE:
			case OpeationBtnType.TAGDELETE:
			case OpeationBtnType.CLEARDEVICEBIND:
			case OpeationBtnType.DISABLEDDEVICEBIND:
			case OpeationBtnType.RESIGN:
			case OpeationBtnType.CLOSE:
				return (
					<ElIcon size="24" color="#F6685D">
						<CircleCloseFilled />
					</ElIcon>
				)
			case OpeationBtnType.ENABLE:
			case OpeationBtnType.ENABLEDEVICEBIND:
			case OpeationBtnType.READ:
			case OpeationBtnType.TAGENABLE:
			case OpeationBtnType.PUBLIC:
			case OpeationBtnType.UPDATESCHEDULE:
			case OpeationBtnType.CONFIRM:
				return (
					<ElIcon size="24" color="#366EF4">
						<WarningFilled />
					</ElIcon>
				)
			case OpeationBtnType.DISABLE:
			case OpeationBtnType.CANCEL:
			case OpeationBtnType.TOPPING:
			case OpeationBtnType.CANCELTOPPING:
			case OpeationBtnType.TAGDISABLE:
			case OpeationBtnType.REVOKE:
			case OpeationBtnType.SETVISITEDUNUSUAL:
				return (
					<ElIcon size="24" color="#FA9550">
						<WarningFilled />
					</ElIcon>
				)
		}
	}

	const icon = createIcon()

	return () => icon
})

Icon.props = {
	type: '',
} as IProps

export default Icon
