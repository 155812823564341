import service from '@/utils/request'

// 获取渠道库存调拨记录
export const getTabelData = (data: any) => {
	return service({
		url: '/stockDeploy/getStockDeployList',
		method: 'post',
		data,
	})
}
// 获取渠道客户库存调拨记录详情
export const findStockDeploy = (params: any) => {
	return service({
		url: '/stockDeploy/findStockDeploy',
		method: 'get',
		params,
	})
}
// 删除渠道客户库存调拨
export const deleteStockDeployByIds = (data: any) => {
	return service({
		url: '/stockDeploy/deleteStockDeployByIds',
		method: 'delete',
		data,
	})
}
// 作废
export const voidStockDeployByIds = (data: any) => {
	return service({
		url: '/stockDeploy/voidStockDeployByIds',
		method: 'put',
		data,
	})
}
// 批量提交审核
export const submitStockDeploy = (data: any) => {
	return service({
		url: '/stockDeploy/submitStockDeploy',
		method: 'put',
		data,
	})
}
// 渠道客户下拉框
export const getBaseDistributorList = (data: any) => {
	return service({
		// url: '/distributorManage/getBaseDistributorList',
		url: '/distributorManage/getBaseDistributorListV1',
		method: 'post',
		data,
	})
}
// 渠道客户仓库地址
export const getWarehouseList = (params: any) => {
	return service({
		url: '/distributorManage/getWarehouseList',
		method: 'get',
		params,
	})
}
// 添加 调拨记录
export const createStockDeploy = (data: any) => {
	return service({
		url: '/stockDeploy/createStockDeploy',
		method: 'post',
		data,
	})
}

// 更新 调拨记录
export const updateStockDeploy = (data: any) => {
	return service({
		url: '/stockDeploy/updateStockDeploy',
		method: 'put',
		data,
	})
}
// 确认还库
export const confirmReturnStock = (data: any) => {
	return service({
		url: '/stockDeploy/confirmReturnStock',
		method: 'put',
		data,
	})
}
// 导出上报记录
export const exportRecord = (data: any) => {
	return service({
		url: '/stockDeploy/getStockDeployList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 1,
		},
	})
}

// 导出上报记录明细
export const exportRecordDetail = (data: any) => {
	return service({
		url: '/stockDeploy/getStockDeployList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 2,
		},
	})
}
