<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'node',
})
</script>
<template>
	<!-- 本节点 -->
	<div
		v-if="nodeData && [NodeType.START, NodeType.APPROVAL].includes(nodeData.nodeType)"
		class="node-warper"
		:class="{
			'is-approved': isApproved || nodeData.nodeId === currentNodeId,
			'first-node': nodeData.nodeType === NodeType.START,
		}"
	>
		<div :class="{ 'is-many-user': isManyUser, 'is-active': nodeData.nodeId === currentNodeId && !isApproved }">
			<div
				v-if="nodeData.nodeType !== NodeType.START"
				class="triangle"
				:class="{ approved: isApproved || nodeData.nodeId === currentNodeId }"
			></div>
			<div v-if="isManyUser" class="box-title">
				{{ ApprovalMethodTypeText[nodeData.approvalMethod] }}
			</div>
			<!-- 当前节点有审批人时 -->
			<template v-if="userList.length > 0">
				<NodeBox
					v-for="(item, index) in userList"
					:key="index"
					:userInfo="item"
					:statusName="getApprovalStatus(item, nodeData.nodeType)"
				/>
			</template>

			<!-- 当前节点无审批人时 -->
			<NodeBox v-else empty="暂无审批人" />

			<!-- 当前节点审批人超过【maxShowNum】时 -->
			<template v-if="overflowList.length > 0">
				<!-- 超出的节点只有一个人 -->
				<NodeBox
					v-if="overflowList.length === 1"
					:userInfo="overflowList[0]"
					:statusName="getApprovalStatus(overflowList[0], nodeData.nodeType)"
				/>
				<PopoverBox
					v-else
					:nodeData="nodeData"
					:overflowList="overflowList"
					:isApproved="isApproved"
					:currentNodeId="currentNodeId"
				/>
			</template>

			<div v-if="nodeData?.child?.nodeType === NodeType.ROUTER" class="is-merge"></div>
		</div>
	</div>

	<!-- 分支下没有节点时 -->
	<div v-if="nodeData.nodeType === NodeType.BRANCH && !nodeData.child && !nodeData.conditions" class="node-warper">
		<div class="triangle" :class="{ approved: isApproved || nodeData.nodeId === currentNodeId }"></div>
		<NodeBox empty="未配置审批节点" />
	</div>

	<!-- 分支 -->
	<template v-if="nodeData.conditions?.length > 0">
		<div class="node-branch" :class="{ 'not-child': !hasChild && !nodeData.child }">
			<div class="node-branch-box" v-for="(item, index) in nodeData.conditions" :key="item.nodeId">
				<div v-if="index === 0" class="cover">
					<div class="cover-top-line"></div>
				</div>

				<div v-if="index === nodeData.conditions.length - 1" class="cover">
					<div class="cover-bottom-line"></div>
				</div>

				<div
					v-if="item.child?.nodeType === NodeType.ROUTER && item.child?.conditions?.length > 0"
					class="is-router"
					:class="{ 'not-child': !hasChild && !nodeData.child }"
				></div>

				<node :nodeData="item" :currentNodeId="currentNodeId" :hasChild="!!nodeData.child" />
			</div>
		</div>
	</template>

	<!-- 子节点 -->
	<node v-if="nodeData.child" :nodeData="nodeData.child" :currentNodeId="currentNodeId" :hasChild="hasChild" />
</template>

<script setup lang="ts" name="node">
import { ref, watch, computed } from 'vue'
import { slice, drop, partition, compact } from 'lodash-es'
import { ApprovalMethodType, ApprovalMethodTypeText, NodeType } from '@/view/workflow/workflowConfig/interface'
import { ApprovalStatus, ApprovalStatusText } from '@/view/workflow/types'
import { FlowChatType, ApprovalUserType } from '../types'
import NodeBox from './nodeBox.vue'
import PopoverBox from './popoverBox.vue'

interface Props {
	nodeData: FlowChatType
	currentNodeId: string
	hasChild?: boolean
}

const props = defineProps<Props>()
const maxShowNum: number = 2 // 每个审批节点最大显示人员数量

const userList = ref<ApprovalUserType[]>([])
const overflowList = ref<ApprovalUserType[]>([])

// 判断当前节点是否审批完成
const isApproved = computed<boolean>(() => {
	// 如果为开始节点，已撤回状态也是审批完成
	if (
		props.nodeData.nodeType === NodeType.START &&
		props.nodeData.approvers.some((item) => item.approvalStatus === ApprovalStatus.WITHDRAWED)
	) {
		return true
	}

	// 如果当前节点无审批人(审批人已停用或离职)，则通过当前节点的approvalStatus判断
	if (props.nodeData.approvers.length === 0 && props.nodeData.approvalStatus === ApprovalStatus.AGREE) {
		return true
	}

	let status: boolean = false
	// 会签需要所有节点审批完成。tips: 如果会签节点有一个被驳回了，那么该审批节点同样是已完成
	if (props.nodeData.approvalMethod === ApprovalMethodType.COUNTERSIGN) {
		status =
			props.nodeData.approvers?.some((item) => {
				return item.approvalStatus === ApprovalStatus.REJECT // 只要有一个节点驳回，当前节点就审批完成
			}) ||
			props.nodeData.approvers?.every((item) => {
				return item.approvalStatus === ApprovalStatus.AGREE // 否则必须所有节点为已通过，当前节点才审批完成
			})
	} else {
		status = props.nodeData.approvers?.some((item) =>
			[ApprovalStatus.AGREE, ApprovalStatus.REJECT].includes(item.approvalStatus)
		)
	}
	return status
})

// 是否多人审批
const isManyUser = computed<boolean>(() => {
	return userList.value.length > 1 || overflowList.value.length > 0
})

watch(
	() => props.nodeData.approvers,
	(newVal) => {
		// 当前节点审批已通过
		if (isApproved.value) {
			// 审批类型为会签时
			if (props.nodeData.approvalMethod === ApprovalMethodType.COUNTERSIGN) {
				userList.value = slice(newVal, 0, maxShowNum)
				overflowList.value = drop(newVal, maxShowNum)
			} else {
				// 审批类型不为会签时
				userList.value = overflowList.value = newVal.filter((item) => {
					return [ApprovalStatus.AGREE, ApprovalStatus.REJECT, ApprovalStatus.WITHDRAWED].includes(item.approvalStatus)
				})
				overflowList.value = newVal.filter((item) => {
					return ![ApprovalStatus.AGREE, ApprovalStatus.REJECT, ApprovalStatus.WITHDRAWED].includes(item.approvalStatus)
				})
				overflowList.value.forEach((item) => {
					item.approvalStatus = null
				})
			}
			console.log(props.nodeData.nodeName, overflowList.value)
		} else {
			// 当前节点审批未通过
			if (props.nodeData.approvalMethod === ApprovalMethodType.COUNTERSIGN) {
				// 将已审批与未审批的拆分为两个数组
				const [approvedList, notApprovedList] = partition(newVal, (item) =>
					[ApprovalStatus.AGREE, ApprovalStatus.REJECT].includes(item.approvalStatus)
				)
				// 如果有部分人员审批通过
				if (approvedList.length > 0) {
					// const firstData = approvedList.shift()
					// const secordData = notApprovedList.shift()
					// userList.value = compact([firstData, secordData])
					// overflowList.value = compact([...approvedList, ...notApprovedList])

					if (notApprovedList.length >= maxShowNum) {
						userList.value = slice(notApprovedList, 0, maxShowNum)
						overflowList.value = compact([...drop(notApprovedList, maxShowNum), ...approvedList])
					} else {
						userList.value = compact([
							...notApprovedList,
							...slice(approvedList, 0, maxShowNum - notApprovedList.length),
						])
						overflowList.value = drop(approvedList, maxShowNum - notApprovedList.length)
					}
				} else {
					userList.value = slice(newVal, 0, maxShowNum)
					overflowList.value = drop(newVal, maxShowNum)
				}
			} else {
				userList.value = slice(newVal, 0, maxShowNum)
				overflowList.value = drop(newVal, maxShowNum)
			}
		}
	},
	{ deep: true, immediate: true }
)

const getApprovalStatus = (user: ApprovalUserType, nodeType: NodeType) => {
	// 判断当前节点是否审批完成 true: 审批完成，false: 审批未完成

	if (props.nodeData.nodeId === props.currentNodeId || isApproved.value) {
		let statusName: string
		switch (user.approvalStatus) {
			case ApprovalStatus.WAITCOMMIT:
			case ApprovalStatus.APPROVALWAIT:
			case ApprovalStatus.REJECT:
			case ApprovalStatus.WITHDRAWED:
				statusName = ApprovalStatusText[user.approvalStatus]
				break
			case ApprovalStatus.APPROVALING:
				if (isApproved.value) {
					statusName = ''
				} else {
					statusName = ApprovalStatusText[user.approvalStatus]
				}
				break
			case ApprovalStatus.AGREE:
				if (nodeType === NodeType.START) {
					statusName = '提交审批'
				} else {
					statusName = ApprovalStatusText[user.approvalStatus]
				}
				break
			default:
		}
		return statusName
	}
	return ''
}
</script>

<style lang="scss" scoped>
$wraperHeight: 70px;
$nodeBoxWidth: 140px;
$borderWidth: 42px;
$borderOffset: 50%;
$borderColor: #ccc;
$avatarSize: 36px;

.node-warper {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	row-gap: 12px;
	column-gap: 16px;
	padding-right: $borderWidth;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: $borderOffset;
		left: -$borderWidth;
		width: $borderWidth - 7px;
		border-top: 1px dashed $borderColor;
	}

	.triangle {
		position: absolute;
		top: 50%;
		left: -6px;
		transform: translate(0, -50%);
		width: 0;
		height: 0;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 6px solid $borderColor;
		&.approved {
			border-left-color: var(--el-color-primary);
		}
	}

	.node-box {
		width: $nodeBoxWidth;
	}
}

.node-branch {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	padding-left: $borderWidth;
	margin-right: $borderWidth;
	gap: 30px;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		height: 100%;
		border-left: 1px dashed $borderColor;
	}

	&::after {
		content: '';
		position: absolute;
		right: -1px;
		height: 100%;
		border-left: 1px dashed $borderColor;
	}

	.node-warper {
		position: relative;
		display: inline-flex;
		flex-direction: column;

		&::after {
			position: absolute;
			content: '';
			top: $borderOffset;
			right: 0;
			width: $borderWidth;
			border-top: 1px dashed $borderColor;
		}

		.node-box {
			width: $nodeBoxWidth;
		}
	}

	.node-branch-box {
		position: relative;
		display: inline-flex;
		align-items: center;

		&::after {
			content: '';
			width: 100%;
			border-top: 1px dashed $borderColor;
			margin-bottom: -1px;
		}

		.is-router {
			position: absolute;
			width: 100%;

			&::before {
				content: '';
				position: absolute;
				top: -($borderOffset * 2);
				left: -$borderWidth;
				width: $borderWidth;
				border-top: 1px dashed $borderColor;
			}

			&::after {
				content: '';
				position: absolute;
				top: -($borderOffset * 2);
				right: -1px;
				width: $borderWidth;
				border-top: 1px dashed $borderColor;
			}
		}

		.node-empty {
			width: 100%;
			height: $wraperHeight;

			&::before {
				content: '';
				position: absolute;
				top: $borderOffset;
				width: $nodeBoxWidth;
				border-top: 1px dashed $borderColor;
			}
		}

		.cover {
			.cover-top-line {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;

				&::before {
					content: '';
					position: absolute;
					bottom: calc(100% - $borderOffset);
					left: -$borderWidth;
					height: 100%;
					width: 2px;
					background: #fff;
				}

				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - ($borderOffset - 1px));
					right: -1px;
					height: 100%;
					width: 2px;
					background: #fff;
					z-index: 1;
				}
			}

			.cover-bottom-line {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;

				&::before {
					content: '';
					position: absolute;
					top: calc($borderOffset + 1px);
					left: -$borderWidth;
					height: 100%;
					width: 2px;
					background: #fff;
				}

				&::after {
					content: '';
					position: absolute;
					top: calc($borderOffset + 1px);
					right: -1px;
					height: 100%;
					width: 2px;
					background: #fff;
					z-index: 1;
				}
			}
		}
	}
}

.node-popover {
	.node-box {
		width: $nodeBoxWidth;
	}
}

.not-child {
	&.node-branch {
		&::after {
			display: none;
		}

		.node-branch-box,
		.node-warper {
			&::after {
				display: none;
			}
		}
	}

	&.is-router {
		&::after {
			display: none;
		}
	}
}

.is-many-user {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	padding: 16px 12px;
	border-radius: 8px;
	background-color: #f4f9fe;
	border: 1px solid #dff0ff;
	.box-title {
		font-weight: 600;
		color: #1a1a1a;
		text-align: center;
		margin-bottom: 2px;
	}
}

.is-active {
	border: 2px solid var(--el-color-primary);
	border-radius: 8px;
}

.is-approved {
	&::before {
		border-color: var(--el-color-primary);
	}
}

.is-merge {
	position: absolute;
	top: $borderOffset;
	right: 0;
	width: $borderWidth;
	border-top: 1px dashed $borderColor;
}

.first-node {
	&::before {
		width: 0;
	}
}
</style>
