<template>
	<el-tag :color="tagData?.tag?.bgColor" :style="{ color: tagData?.tag?.color, borderColor: tagData?.tag?.bgColor }">{{
		tagData?.label
	}}</el-tag>
</template>

<!-- 列表状态（启用、停用、待启用） -->
<script setup lang="ts">
import { statusList, planStatus } from '@/config/Constantconfig'
import { computed } from 'vue'

interface Props {
	// 默认状态: 1、启用 2、停用
	// 计划状态: 1、待启用 2、启用 3、停用
	status?: number

	// 类型: plan 计划状态（有待启用），默认为空（无待启用）
	type?: string
}

const props = withDefaults(defineProps<Props>(), {
	type: '',
})

const tagData = computed(() => {
	if (props.type === 'plan') {
		return planStatus.find((item) => item.value === props.status)
	}
	return statusList.find((item) => item.value === props.status)
})
</script>
