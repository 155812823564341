import { BtnItem, ModalWidthType } from '../useOperationBtnList'
import { Ref, Component, VNode } from 'vue'

/**
 * @description 统一弹窗宽度百分比
 * @param PERCENTAGE20  20%
 * @param PERCENTAGE30  30%
 * @param PERCENTAGE40  40%
 * @param PERCENTAGE60  60%
 * @param PERCENTAGE80  80%
 * @param PERCENTAGE100  100%
 */
export enum DrawerWidthType {
	PERCENTAGE20 = '20%',
	PERCENTAGE30 = '30%',
	PERCENTAGE40 = '40%',
	PERCENTAGE60 = '60%',
	PERCENTAGE80 = '80%',
	PERCENTAGE100 = '100%',
}

/**
 * 按钮应用场景类型，若为空则默认在列表显示
 * @param ALL 所有场景: 包括列表、抽屉、流程
 * @param LIST 只需要在列表中展示
 * @param DRAWER 只需要在抽屉中展示
 * @param FLOW 只需要在流程中展示
 */
export enum ApplyType {
	ALL = 'ALL',
	LIST = 'LIST',
	DRAWER = 'DRAWER',
	FLOW = 'FLOW',
}

// 抽屉按钮类型
export type DrawerButtonType = Pick<BtnItem, 'type' | 'width'> & {
	path?: string // 按钮所属模块，根据模块以及类型获取按钮权限。若为空则默认为所有人可见
	label?: string // 按钮名称
	isHide?: boolean // 是否隐藏按钮
	onClick?: () => void // 按钮点击方法
}

// 抽屉参数类型
export type DrawerDataType = {
	title: string // 抽屉标题
	isShow: boolean // 用于自定义关闭时销毁子组件（影响component销毁-无法执行相应生命周期）
	width?: DrawerWidthType | string // 抽屉宽度
	buttonList?: DialogDataType[] // 抽屉按钮
	component?: Component // 抽屉组件
	props?: {
		//传递组件需要绑定及向下传递的数据
		[key: string]: any
	} // 抽屉组件属性
	selectList?: Record<string, any>[] // 选中的数据-按钮校验时可能需要使用到
	refresh?: boolean // 标识当前对象是否需要重载
	uuid?: string //是否重新加载的key
	isDialog?: boolean // 弹窗标识
	listRef?: Ref //关联列表ref
	isHiddenBtnList?: boolean //是否隐藏顶部按钮组（审批流程、消息、操作日志不需要展示，拿不到对应的审批状态）
	levelUpUuid?: string // 层级提升时，增加uuuid刷新页面 提升层级
}

// 弹窗参数类型
export type DialogDataType = BtnItem & {
	isDialog: boolean // 弹窗标识
	isShow: boolean // 用于自定义关闭时销毁子组件（影响component销毁-无法执行相应生命周期）
	isSelect?: boolean
	selectionArr?: []
	selectedValue?: number
	title?: string // 弹窗标题，默认为按钮名称
	content?: string | VNode // 弹窗内容
	contentAlign?: 'left' | 'center' | 'right' // 弹窗内容显示位置
	width?: ModalWidthType | string // 弹窗宽度，默认为 useOperationBtnList.ts 定义的宽度
	component?: Component // 弹窗内容组件
	refresh?: any // 为布尔值时，刷新抽屉组件，否则执行刷新方法
	showButton?: boolean // 是否显示底部按钮
	align?: 'start' | 'center' | 'end' // 按钮显示位置
	cancelText?: string // 取消按钮文案
	confirmText?: string // 确认按钮文案
	onConfirm?: () => void // 确认按钮回调
	selectKey?: string //props中传递id的key键 默认rowId
	props?: {
		[key: string]: any
	} // 弹窗组件属性
	events?: {
		[key: string]: () => {} // 组件内部事件 （关闭弹框）
	}
	selectList?: Record<string, any>[] // 选中的数据
	listRef?: Ref //关联列表ref
	preOpenValidation?: () => void //点击按钮前置校验方法
	apply?: ApplyType | ApplyType[] // 按钮应用场景
}

/** 抽屉类型枚举 */
export enum DrawerType {
	/**VISITLIST  拜访列表 */
	VISITLIST = 'VISITLIST',
	/**VISITRECORD    拜访记录 */
	VISITRECORD = 'VISITRECORD',
	/**ROUTEMANAGEMENTS    路线列表 */
	ROUTEMANAGEMENTS = 'ROUTEMANAGEMENTS',
	/**ROUTESUMMARYS    路线汇总 */
	ROUTESUMMARYS = 'ROUTESUMMARYS',
	/**WORKTRAJECTORYS    工作轨迹 */
	WORKTRAJECTORYS = 'WORKTRAJECTORYS',
	/**VISITWORKFLOWCONFIG    拜访流程配置 */
	VISITWORKFLOWCONFIG = 'VISITWORKFLOWCONFIG',
	/**MISSEDVISIT    失访原因 */
	MISSEDVISIT = 'MISSEDVISIT',
	/**LIVELYCHECK    生动化检查 */
	LIVELYCHECK = 'LIVELYCHECK',
	/**ASSETSINSPECT    资产巡检 */
	ASSETSINSPECT = 'ASSETSINSPECT',
	/**STOREINSPECT    门店检查 */
	STOREINSPECT = 'STOREINSPECT',
	/**COMPETITORACQUISITION    竞品采集 */
	COMPETITORACQUISITION = 'COMPETITORACQUISITION',
	/**EXECUTEACTIVITY   活动执行*/
	EXECUTEACTIVITY = 'EXECUTEACTIVITY',
	/**ATTENDANCERECORD    考勤记录 */
	ATTENDANCERECORD = 'ATTENDANCERECORD',
	/**SALESAREA    销售区域 */
	SALESAREA = 'SALESAREA',
	/**TERMINALMGR    终端管理 */
	TERMINALMGR = 'TERMINALMGR',
	/**DEALERMGR    渠道客户管理 */
	DEALERMGR = 'DEALERMGR',
	/**KASYSTEM KA系统管理 */
	KASYSTEM = 'KASYSTEM',
	/**ACCOUNTMGR    账号管理 */
	ACCOUNTMGR = 'ACCOUNTMGR',
	/**CLEARBINDING    强制清除设备绑定 */
	CLEARBINDING = 'CLEARBINDING',
	/**PERSONNELMGR    人员管理 */
	PERSONNELMGR = 'PERSONNELMGR',
	/**POSITIONMGR    职位管理 */
	POSITIONMGR = 'POSITIONMGR',
	/**ORGANIZATIONMGR    组织管理 */
	ORGANIZATIONMGR = 'ORGANIZATIONMGR',
	/**WORKHANDOVER    工作交接 */
	WORKHANDOVER = 'WORKHANDOVER',
	/**CUSTOMERLABEL 客户标签 */
	CUSTOMERLABEL = 'CUSTOMERLABEL',
	/**UNIVERSALCUSTOMERTAGS 客户标签-通用 */
	UNIVERSALCUSTOMERTAGS = 'UNIVERSALCUSTOMERTAGS',
	/**PERSONALCUSTOMERTAGS  客户标签-个人 */
	PERSONALCUSTOMERTAGS = 'PERSONALCUSTOMERTAGS',
	/**REFRIGERATORMODEL    冰箱型号 */
	REFRIGERATORMODEL = 'REFRIGERATORMODEL',
	/**REFRIGERATORDRULES    冰箱陈列规则 */
	REFRIGERATORDRULES = 'REFRIGERATORDRULES',
	/**PRODUCTLIST 产品管理 */
	PRODUCTLIST = 'PRODUCTLIST',
	/**PRODUCTPRICE    产品价格表管理 */
	PRODUCTPRICE = 'PRODUCTPRICE',
	/**DATAPERMISSIONSMANAGEMENT    数据权限管理 */
	DATAPERMISSIONSMANAGEMENT = 'DATAPERMISSIONSMANAGEMENT',
	/**CONTRACTTEMPLATEMGR 模版管理 */
	CONTRACTTEMPLATEMGR = 'CONTRACTTEMPLATEMGR',
	/**FEECANCELLATIONAPPROVE 核销审批 */
	FEECANCELLATIONAPPROVE = 'FEECANCELLATIONAPPROVE',
	/**CONTRACTLIST    合同列表 */
	CONTRACTLIST = 'CONTRACTLIST',
	/**REFRIGERATORAGREEMENT    冰箱协议 */
	REFRIGERATORAGREEMENT = 'REFRIGERATORAGREEMENT',
	/**ACTIVITYAGREEMENT    活动协议 */
	ACTIVITYAGREEMENT = 'ACTIVITYAGREEMENT',
	/**SUBJECTMANAGEMENT     主体管理 */
	SUBJECTMANAGEMENT = 'SUBJECTMANAGEMENT',
	/**SEALTYPE     印章类型 */
	SEALTYPE = 'SEALTYPE',
	/**SEAL     印章 */
	SEAL = 'SEAL',
	/**REFRIGERATORASSETCARD    冰箱资产卡 */
	REFRIGERATORASSETCARD = 'REFRIGERATORASSETCARD',
	/**FEEAPPLICATIONAPPROVE 费用申请单审批 */
	FEEAPPLICATIONAPPROVE = 'FEEAPPLICATIONAPPROVE',
	/**BUSINESSASSETMANAGEMENT    资产管理-业务方 */
	BUSINESSASSETMANAGEMENT = 'BUSINESSASSETMANAGEMENT',
	/**ASSETINSPECTIONFORM    资产巡检统计表 */
	ASSETINSPECTIONFORM = 'ASSETINSPECTIONFORM',
	/**REFRIGERATORMAINTENANCERECORD    冰箱维修记录 */
	REFRIGERATORMAINTENANCERECORD = 'REFRIGERATORMAINTENANCERECORD', //冰箱资产管理-冰箱维修表-故障详情
	/**STATISTICSONSCRAPPEDASSETS    报废资产统计 */
	STATISTICSONSCRAPPEDASSETS = 'STATISTICSONSCRAPPEDASSETS',
	/**REFRIGERATORPUTTINGRECORD    冰箱投放记录表 */
	REFRIGERATORPUTTINGRECORD = 'REFRIGERATORPUTTINGRECORD',
	/**REFRIGERATORSHIPPINGPLAN    年度冰箱发货计划详情 */
	REFRIGERATORSHIPPINGPLAN = 'REFRIGERATORSHIPPINGPLAN',
	/**REFRIGERATORSHIPPINGPLANADJUST    年度冰箱发货计划调整记录详情 */
	REFRIGERATORSHIPPINGPLANADJUST = 'REFRIGERATORSHIPPINGPLANADJUST',
	/**REFRIGERATORADJUSTMENTDETAILS    计划调整明细 */
	REFRIGERATORADJUSTMENTDETAILS = 'REFRIGERATORADJUSTMENTDETAILS',
	/**REFRIDGERATORORDER    冰箱订单 */
	REFRIDGERATORORDER = 'REFRIDGERATORORDER',
	/**REFRIGERATORTYPESHIPMENTSUMMARY    冰箱类型发货汇总表 */
	REFRIGERATORTYPESHIPMENTSUMMARY = 'REFRIGERATORTYPESHIPMENTSUMMARY',
	/**RECEIPTSCHEDULE    签收明细表 */
	RECEIPTSCHEDULE = 'RECEIPTSCHEDULE',
	/**AREASHIPMENTSUMMARY    区域发货汇总表 */
	AREASHIPMENTSUMMARY = 'AREASHIPMENTSUMMARY',
	/**REFRIGERATORAPPLICATIONORDERDETAILS    冰箱申请订单明细表 */
	REFRIGERATORAPPLICATIONORDERDETAILS = 'REFRIGERATORAPPLICATIONORDERDETAILS',
	/**REFRIGERATORSIGNATURESUMMARY    冰箱签收汇总表 */
	REFRIGERATORSIGNATURESUMMARY = 'REFRIGERATORSIGNATURESUMMARY',
	/**PROTOCOLMANAGEMENTDETAILS    冰箱协议管理明细表 */
	PROTOCOLMANAGEMENTDETAILS = 'PROTOCOLMANAGEMENTDETAILS',
	/**DIRECTORDERS    直营订单 */
	DIRECTORDERS = 'DIRECTORDERS',
	/**TERMINALORDERS    终端订单 */
	TERMINALORDERS = 'TERMINALORDERS',
	/**CHANNELINVENTORY    渠道库存查询 */
	CHANNELINVENTORY = 'CHANNELINVENTORY',
	/**CHANNELTRANSFERRECORD  渠道调库申请-查看 */
	CHANNELTRANSFERRECORD = 'CHANNELTRANSFERRECORD',
	/**TERMINALINVENTORY    终端库存查询 */
	TERMINALINVENTORY = 'TERMINALINVENTORY',
	/**TERMINALINVENTORYRECORD    终端库存上报 */
	TERMINALINVENTORYRECORD = 'TERMINALINVENTORYRECORD',
	/**CUSTOMERMONTHPICK    客户月提货汇总表 */
	CUSTOMERMONTHPICK = 'CUSTOMERMONTHPICK',
	/**CUSTOMERMONTHSALES    渠道客户月销量汇总表 */
	CUSTOMERMONTHSALES = 'CUSTOMERMONTHSALES',
	/**PLANIMPORTRECORD    需求计划导入记录 */
	PLANIMPORTRECORD = 'PLANIMPORTRECORD',
	/**PLANSUMMARY    需求计划汇总表 */
	PLANSUMMARY = 'PLANSUMMARY',
	/**CHANNELRELATIONMAINTENANCE 渠道关系维护表 */
	CHANNELRELATIONMAINTENANCE = 'CHANNELRELATIONMAINTENANCE',
	/**MESSAGECENTER    消息中心 */
	MESSAGECENTER = 'MESSAGECENTER',
	/**FEESUBJECT    费用科目 */
	FEESUBJECT = 'FEESUBJECT',
	/**FEEDIMENSION    费用维度 */
	FEEDIMENSION = 'FEEDIMENSION',
	/**BUDGETTABLE    预算表 */
	BUDGETTABLE = 'BUDGETTABLE',
	/**FEEAPPLICATIONTYPE    费用申请类型 */
	FEEAPPLICATIONTYPE = 'FEEAPPLICATIONTYPE',
	/**FEEAPPLICATIONODD    费用申请单 */
	FEEAPPLICATIONODD = 'FEEAPPLICATIONODD',
	/**CANCELLATIONBYACTIVITY    按活动方案核销 */
	CANCELLATIONBYACTIVITY = 'CANCELLATIONBYACTIVITY',
	/**CANCELLATIONBYAPPLICATIONODD    按费用申请单核销 */
	CANCELLATIONBYAPPLICATIONODD = 'CANCELLATIONBYAPPLICATIONODD',
	/**按费用核销发起审批 */
	APPROVECANCELLATIONBYAPPLICATIONODD = 'APPROVECANCELLATIONBYAPPLICATIONODD',
	/**CANCELLATIONLOGVIEW    按费用申请单核销-费用核定记录 */
	CANCELLATIONLOGVIEW = 'CANCELLATIONLOGVIEW',
	/** CANCELLATIONPRODUCTS 按申请单核销-产品明细*/
	CANCELLATIONPRODUCTS = 'CANCELLATIONPRODUCTS',
	/**FEEEXPENSEDETAILS   费用明细详情 */
	FEEEXPENSEDETAILS = 'FEEEXPENSEDETAILS',
	/**CANCELLATIONBYPROMOTIONPOLICY    按促销政策核销 */
	CANCELLATIONBYPROMOTIONPOLICY = 'CANCELLATIONBYPROMOTIONPOLICY',
	/**CANCELLATIONBYCUSTOMIZE    自定义核销（旧的） */
	CANCELLATIONBYCUSTOMIZE = 'CANCELLATIONBYCUSTOMIZE',
	/**CUSTOMVERIFICATION    自定义核销详情 */
	CUSTOMVERIFICATION = 'CUSTOMVERIFICATION',
	/**VERIFICATIONNUMBEDETAILS    核销编号-详情里面的详情 */
	VERIFICATIONNUMBEDETAILS = 'VERIFICATIONNUMBEDETAILS',
	/**FEEDIMENSIONVALUE    维值 */
	FEEDIMENSIONVALUE = 'FEEDIMENSIONVALUE',
	/**ACTIVITYAIMODEL    活动AI模型 */
	ACTIVITYAIMODEL = 'ACTIVITYAIMODEL',
	/**ACTIVITYEXECUTEPROJECT    活动执行项目 */
	ACTIVITYEXECUTEPROJECT = 'ACTIVITYEXECUTEPROJECT',
	/**ACTIVITYTYPE    活动类型 */
	ACTIVITYTYPE = 'ACTIVITYTYPE',
	/**ACTIVITYSCHEMEAPPROVAL    活动方案审批 */
	ACTIVITYSCHEMEAPPROVAL = 'ACTIVITYSCHEMEAPPROVAL',
	/**ACTIVITYAPPLYFOR    活动审批 */
	ACTIVITYAPPLYFOR = 'ACTIVITYAPPLYFOR',
	/**ACTIVITYEXECUTION    活动列表 */
	ACTIVITYEXECUTION = 'ACTIVITYEXECUTION',
	/**EXECUTEAPPROVAL    执行审批 */
	EXECUTEAPPROVAL = 'EXECUTEAPPROVAL',
	/**RECTIFICATIONAPPROVAL    整改审批 */
	RECTIFICATIONAPPROVAL = 'RECTIFICATIONAPPROVAL',
	/**ACTIVITYOVERAPPLICATION    结案申请 */
	ACTIVITYOVERAPPLICATION = 'ACTIVITYOVERAPPLICATION',
	/**ACTIVITYOVERAPPROVAL    结案审批 */
	ACTIVITYOVERAPPROVAL = 'ACTIVITYOVERAPPROVAL',
	/**PROMOTIONTYPE    促销类型 */
	PROMOTIONTYPE = 'PROMOTIONTYPE',
	/**POLICYAPPLICATION    政策申请 */
	POLICYAPPLICATION = 'POLICYAPPLICATION',
	/**PROMOTIONSALESREPORT    促销出货上报 */
	PROMOTIONSALESREPORT = 'PROMOTIONSALESREPORT',
	/**FEEBUDGETARYAPPROVE  预算申请审批 */
	FEEBUDGETARYAPPROVE = 'FEEBUDGETARYAPPROVE',
	/**PROCESSDETAILS 活动管理-活动进程-进程管理 */
	PROCESSDETAILS = 'PROCESSDETAILS',
	/**IDENTIFICATIONDETAILS 活动管理-活动进程-进程管理-识别详情 */
	IDENTIFICATIONDETAILS = 'IDENTIFICATIONDETAILS',
	/**WORKFLOWCONFIG 流程配置 */
	WORKFLOWCONFIG = 'WORKFLOWCONFIG',
	/**PROPOSALAPPLICATION 活动管理-活动方案-方案申请 */
	PROPOSALAPPLICATION = 'PROPOSALAPPLICATION',
	/**ACTIVITYEXCUTEPROJECT // 活动管理活动执行项目 */
	ACTIVITYEXCUTEPROJECT = 'ACTIVITYEXCUTEPROJECT',
	/**CHANNELINVENTORYRECORD 渠道库存上报(new) */
	CHANNELINVENTORYRECORD = 'CHANNELINVENTORYRECORD',
	/**FACTORYMANAGEMENT 工厂管理 */
	FACTORYMANAGEMENT = 'FACTORYMANAGEMENT',
	/**REFRIGERATORBRAND 冰箱品牌 */
	REFRIGERATORBRAND = 'REFRIGERATORBRAND',
	/**REFRIGERATORTYPE 冰箱类型 */
	REFRIGERATORTYPE = 'REFRIGERATORTYPE',
	/**ADVERTISINGSTICKERS 广告贴画 */
	ADVERTISINGSTICKERS = 'ADVERTISINGSTICKERS',
	/**COMPETITORS 竞品管理 */
	COMPETITORS = 'COMPETITORS',
	/**GIFT 礼品管理 */
	GIFT = 'GIFT',
	/**OPERATIONLOG 操作日志 */
	OPERATIONLOG = 'OPERATIONLOG',
	/**DICTIONARYMANAGE 字典管理 */
	DICTIONARYMANAGE = 'DICTIONARYMANAGE',
	/**POLICYAPPROVE   政策审批 */
	POLICYAPPROVE = 'POLICYAPPROVE',
	/**REFRIGERATORRETURNRECORD  冰箱退回记录表（APP) */
	REFRIGERATORRETURNRECORD = 'REFRIGERATORRETURNRECORD',
	/**REFRIGERATORSCRAPRECORD 冰箱报废记录表（APP) */
	REFRIGERATORSCRAPRECORD = 'REFRIGERATORSCRAPRECORD',
	/**REFRIGERATORLOSSRECORD  冰箱遗失记录表（APP) */
	REFRIGERATORLOSSRECORD = 'REFRIGERATORLOSSRECORD',
	/**REGIONALFACTORIES 区域工厂关系表 */
	REGIONALFACTORIES = 'REGIONALFACTORIES',
	/**AIPICTURE  生动化检查ai识别 */
	AIPICTURE = 'AIPICTURE',
	/**VIEWREPORTDATA //需求计划-查看上报数据 */
	VIEWREPORTDATA = 'VIEWREPORTDATA',

	/**TERINVENTORYDETAILS 终端库存-库存明细 */
	TERINVENTORYDETAILS = 'TERINVENTORYDETAILS',
	/**CLOSELOG 冰箱订单管理-关闭明细 */
	CLOSELOG = 'CLOSELOG',
	/**BUSINESSDETAIL 冰箱资产管理-资产管理业务方详情 */
	BUSINESSDETAIL = 'BUSINESSDETAIL', //资产管理-业务员 查看编辑记录（自定义名）
	/**RECYCLINGDETAILS 冰箱资产管理-报废资产统计-回收详情 */
	RECYCLINGDETAILS = 'RECYCLINGDETAILS',
	/**FEEBUDGETARYODD     预算申请单 */
	FEEBUDGETARYODD = 'FEEBUDGETARYODD', // 预算申请单
	/**WARRANTYRECORDDETAILS 冰箱资产卡-保修期修改详情 */
	WARRANTYRECORDDETAILS = 'WARRANTYRECORDDETAILS', //资产卡-保修卡（自定义名）
	/**ANNOUNCEMENT //通知公告-详情 */
	ANNOUNCEMENT = 'ANNOUNCEMENT', //通知公告-详情
	/**ANNOUNCEMENTVIEWRATE //通知公告-浏览占比弹窗 */
	ANNOUNCEMENTVIEWRATE = 'ANNOUNCEMENTVIEWRATE', //通知公告-浏览占比
	/**ANNOUNCEMENTCENTER //首页通知公告中心详情 */
	ANNOUNCEMENTCENTER = 'ANNOUNCEMENTCENTER', //首页通知公告中心详情
	/**MYAPPROVAL //待我审批 */
	MYAPPROVAL = 'MYAPPROVAL',
	/**ALLAPPROVAL //全部审批 */
	ALLAPPROVAL = 'ALLAPPROVAL',
}
