<template>
	<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="104px">
		<el-form-item label="加签方式" prop="addSignType">
			<el-radio-group class="w-full gap-y-2" v-model="ruleForm.addSignType">
				<el-radio class="w-full !mr-0" :label="1">
					前加签<span class="text-gray-400">（先经过被加签人，再回到当前审批人处理）</span>
				</el-radio>
				<el-radio class="w-full !mr-0" :label="2">
					后加签<span class="text-gray-400">（当前节点审批人同意后流转至被加签人处理）</span>
				</el-radio>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="加签审批人" prop="userIds">
			<pick-user v-model="ruleForm.userIds" :isAuth="false" :unSelectable="currentApprovers" :uniqueUser="false" />
		</el-form-item>
		<el-form-item label="备注(选填)" prop="remark">
			<XZLInput v-model="ruleForm.remark" type="textarea" rows="3" show-word-limit maxlength="200" />
		</el-form-item>
	</el-form>

	<div class="text-center">
		<el-button @click="emit('close')">取消</el-button>
		<el-button type="primary" :loading="loading" @click="handleConfirm">确定</el-button>
	</div>
</template>

<script setup lang="ts">
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { addSign } from '@/api/serveApi/workflow/workflowOperation'
import { reactive, ref } from 'vue'

interface Props {
	code: string
	currentApprovers: number[]
}

const props = defineProps<Props>()

const emit = defineEmits(['refresh', 'close'])

interface RuleForm {
	addSignType: number
	userIds: number[]
	remark: string
}

const loading = ref<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
	addSignType: null,
	userIds: [],
	remark: '',
})
const rules = reactive<FormRules>({
	addSignType: [{ required: true, message: '请选择加签方式', trigger: 'change' }],
	userIds: [{ required: true, message: '请选择加签审批人', trigger: 'change' }],
})

const handleConfirm = async () => {
	await ruleFormRef.value?.validate(async (valid) => {
		if (valid) {
			loading.value = true
			const res = await addSign({
				...ruleForm,
				approvalRequestCode: props.code,
			})
			if (res && res.code === 0) {
				ElMessage.success(res.msg || '加签成功')
				emit('refresh', true)
				emit('close')
			}
			loading.value = false
		}
	})
}
</script>

<style lang="scss" scoped>
:deep(.el-radio__label) {
	@apply flex-1 w-0 leading-[1.2] whitespace-normal;
}
</style>
