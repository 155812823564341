<template>
	<el-button style="padding: 0" link :icon="Tools" @click="openDrawer">设置</el-button>

	<el-drawer
		v-if="setting.show"
		v-model="setting.show"
		class="drawer-setting-box"
		title="设置"
		size="300"
		append-to-body
	>
		<div class="px-lg">
			<div class="text-md text-gray-700">表格设置</div>
			<div class="flex items-center justify-between mt-md">
				<span class="text-gray-500">每页显示条数</span>
				<el-radio-group v-model="setting.pageSize" size="small">
					<el-radio-button v-for="size in pageSizes" :key="size" :label="size" />
				</el-radio-group>
			</div>
		</div>

		<el-divider class="my-2xl" />

		<div class="px-lg">
			<div class="text-md text-gray-700">字段设置</div>
			<XZLInput class="my-sm" v-model="setting.keyword" :prefix-icon="Search" maxlength="10" placeholder="请输入" />

			<VueDraggable
				v-model="setting.columns"
				:animation="250"
				ghostClass="ghost"
				filter=".ignore-column"
				:onMove="onMove"
			>
				<div
					v-for="(item, idx) in setting.columns"
					:key="item.prop"
					:class="{
						'cursor-move': !item.isLock && !item.fixed,
						'ignore-column': item.isLock || item.fixed,
					}"
				>
					<DragItem
						v-model="setting.columns[idx]"
						:keyword="setting.keyword"
						@handleToping="handleToping(idx)"
						@handleLock="handleLock(idx, item)"
					/>
				</div>
			</VueDraggable>
		</div>

		<template #footer>
			<el-button type="primary" :loading="saveLoading" @click="handleConfirm">应用</el-button>
			<el-button plain @click="handleReset">重置</el-button>
		</template>
	</el-drawer>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue'
import { Tools, Search } from '@element-plus/icons-vue'
import { useVModel } from '@vueuse/core'
import { VueDraggable } from 'vue-draggable-plus'
import { pick } from 'lodash-es'
import { TablePropType } from '@/components/proxyComponents/XZLTable/types'
import { PageConfig, PageConfigSetting } from '@/components/proTableV2/common/hooks/usePageConfig/types'
import DragItem from './item.vue'

interface Props extends Pick<TablePropType, 'columns'> {
	modelValue: PageConfig
	pageSizes: number[]
}

interface StateType {
	show: boolean // 是否显示弹出层
	keyword: string // 搜索关键字
	pageSize: number // 分页条数
	columns: PageConfigSetting[] // 表格字段列表
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'column-apply'])
const pageConfig = useVModel(props, 'modelValue', emit)

const setting = reactive<StateType>({
	show: false,
	keyword: '',
	pageSize: 20,
	columns: [],
})
const settingCache = reactive<Pick<StateType, 'pageSize' | 'columns'>>({
	pageSize: 0,
	columns: [],
})

const saveLoading = ref<boolean>(false) // 保存loading状态
const isReady = ref<boolean>(false) // 判断是否第一次打开弹出

watch(
	() => props.columns,
	() => {
		pageConfig.value.settings = props.columns
			?.filter((o) => !o.type && !o.isDyField)
			.map((item) => pick(item, ['label', 'prop', 'fixed', 'isShow', 'isLock', 'isHideAuth']))
	},
	{ deep: true }
)

// 打开
const openDrawer = (): void => {
	setting.show = true
	setting.pageSize = pageConfig.value.pageSize
	setting.columns = JSON.parse(JSON.stringify(pageConfig.value.settings))

	// 第一次打开抽屉，将settings缓存一次
	if (!isReady.value) {
		isReady.value = true
		settingCache.pageSize = setting.pageSize
		settingCache.columns = JSON.parse(JSON.stringify(pageConfig.value.settings))
	}
}

// 关闭
const closeDrawer = (): void => {
	setting.show = false
}

// 置顶/取消置顶
const handleToping = (idx: number): void => {
	if (idx === 0) return

	const c = setting.columns[idx]
	setting.columns.splice(idx, 1)

	const index = setting.columns.findIndex((item) => {
		return !(['left', 'right'].includes(item.fixed as string) || item.fixed === true) && !item.isLock
	})

	if (index >= 0) {
		setting.columns.splice(index, 0, c)
	} else {
		setting.columns.push(c)
	}
}

/**
 * 锁定、解锁
 * @param idx 索引
 * @param item 当前项
 */
const handleLock = (idx: number, item: PageConfigSetting): void => {
	item.isLock = !item.isLock

	handleToping(idx)
}

// 拖动
const onMove = (evt) => {
	if (evt.related.className.includes('ignore-column')) return false
}

// 应用
const handleConfirm = async (): Promise<any> => {
	const isRefresh = pageConfig.value.pageSize !== setting.pageSize // 是否刷新列表
	const value = Object.assign(pageConfig.value, {
		pageSize: setting.pageSize,
		settings: setting.columns,
	})
	emit('update:modelValue', value)
	emit('column-apply', value, isRefresh)
	closeDrawer()
}

// 重置
const handleReset = (): void => {
	setting.pageSize = settingCache.pageSize
	setting.columns = settingCache.columns
}
</script>

<style lang="scss">
.drawer-setting-box {
	.el-drawer__header {
		height: 66px;
		padding: 0 16px;
		margin-bottom: 0;
		border-bottom: 1px solid theme('colors.line.1');
		.el-drawer__title {
			font-size: 16px;
			font-weight: 600;
			color: theme('colors.gray.700');
		}
	}
	.el-drawer__body {
		padding: 20px 0 10px 0;
	}
	.el-drawer__footer {
		padding: 12px 16px;
		border-top: 1px solid theme('colors.line.1');
	}
}
</style>
