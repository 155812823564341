import {
	nodeTypeList,
	TriggeringFields,
	NodeItemType,
	NodeType,
	NodePropertyType,
	ApprovalNullRuleType,
} from '@/view/workflow/workflowConfig/interface'
import { v4 as uuidv4 } from 'uuid'

/**
 * @description 创建触发条件
 */
export const createCondition = (): TriggeringFields => {
	return {
		fieldName: '',
		fieldValues: [],
		fromType: null,
		operator: null,
	}
}

/**
 * @description 创建流程节点
 * @param value 流程节点数据
 */
export const createFlowNode = (value: Partial<NodeItemType>): NodeItemType => {
	return {
		name: value.name ?? nodeTypeList.find((o) => o.type === value.type)?.label ?? '',
		nodeId: uuidv4(),
		menuId: value.menuId,
		prevId: value.prevId,
		type: value.type,
		properties: getProperty(value.type),
		childNode: value.childNode ?? null,
		conditionNodes: value.conditionNodes ?? [],
	}
}

/**
 * 根据节点类型获取默认属性
 * @param type 节点类型
 */
export const getProperty = (type: NodeType): NodePropertyType => {
	let property = null
	switch (type) {
		case NodeType.START:
		case NodeType.ROUTER:
			break
		case NodeType.APPROVAL:
			property = {
				approverIsNullRuleType: ApprovalNullRuleType.Auto,
				approverIsNullDealUser: null,
				approvalAgreeNeedComment: 1,
				approvalRejectNeedComment: 1,
				addSign: 0,
				approverRules: {},
				copyToRules: {},
				approvalContent: [],
			}
			break
		case NodeType.BRANCH:
			property = {
				triggeringConditions: [],
			}
			break
		case NodeType.MESSAGE:
			property = {
				notifyMessage: {
					contentList: [],
				},
			}
			break
		default:
	}
	return property
}

// 位运算生成审批策略
export const setStrategy: (...number) => number = (a: number, b: number, c: number) => {
	let strategy
	strategy = strategy | a
	strategy = strategy | (b << 1)
	strategy = strategy | (c << 2)
	return strategy
}

// 位运算反解析审批策略
export const getStrategy = (strategy: number): [number, number, number] => {
	let a = strategy & 1
	let b = (strategy >> 1) & 1
	let c = (strategy >> 2) & 1
	return [a, b, c]
}
