export enum StatusType {
	waitcommit = 1,
	committed = 2,
	cancelted = 3,
}

export const StatusTypeText: { [key in StatusType] } = {
	[StatusType.waitcommit]: '待提交',
	[StatusType.committed]: '已提交',
	[StatusType.cancelted]: '已作废',
}

export const statusList = [
	{ id: StatusType.waitcommit, name: StatusTypeText[StatusType.waitcommit] },
	{ id: StatusType.committed, name: StatusTypeText[StatusType.committed] },
	{ id: StatusType.cancelted, name: StatusTypeText[StatusType.cancelted] },
]

/**
 * @description 库存上报类型
 * @param REGULAR 常规
 * @param CANCEL 核销
 */
export enum StockType {
	REGULAR = 1,
	CANCEL = 2,
}

/**
 * @description 库存上报类型text
 * @param key 1: 常规库存上报
 * @param key 2: 核销库存上报
 */
export const StockTypeText: { [key in StockType]: string } = {
	[StockType.REGULAR]: '常规库存上报',
	[StockType.CANCEL]: '核销库存上报',
}
