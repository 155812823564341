<template>
	<el-cascader
		v-model="tags"
		:options="options"
		@change="handleChange"
		collapse-tags
		collapse-tags-tooltip
		:max-collapse-tags="2"
		clearable
		style="width: 100%"
		:props="{ value: 'ID', label: 'name', children: 'tagList', emitPath: false, multiple: true }"
		placeholder="请选择客户标签"
	/>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { getTagGroupList, getTagInfoList } from '@/api/serveApi/basicInformation/customerManagement'
const emit = defineEmits(['update:modelValue', 'change'])
const props = defineProps({
	modelValue: {
		type: Array<Number>,
		required: true,
	},
	selectedTags: {
		type: Array,
		default: () => [],
	},
	tagType: {
		type: Number,
		default: 1,
	},
	systemTags: {
		//0--不显示系统标签，1是显示系统标签
		type: Number,
		default: 0,
	},
})
const options = ref([])
const tags = ref([])

// 监听赋值操作
watch(
	() => props.modelValue,
	() => {
		tags.value = props.modelValue ?? []
	},
	{
		immediate: true,
		deep: true,
	}
)

onMounted(() => {
	getTagGroupData()
})

// //获取所有标签
// const getTagGroupData = async () => {
// 	const res = await getTagGroupList({ customerType: props.tagType })
// 	//过滤停用的标签组
// 	let filtertagGroup = res.data.list?.filter((item) => item.status !== 2) ?? []
// 	//过滤停用的标签
// 	filtertagGroup = filtertagGroup
// 		.map((item) => {
// 			item.tagList = item.tagList?.filter((val) => val.status !== 2) ?? []
// 			return item
// 		})
// 		.filter((item) => item.tagList?.length > 0) //具体标签没有或都停用的，标签组不展示
// 	options.value = filtertagGroup
// 	// 处理已选字段回显问题-需要主动执行一次
// 	handleChange()
// }
/**
 * 过滤停用的标签组和标签
 * @param {Object} data
 * @param {Number} sceneType 场景类型 1个人 2通用
 */
const filtertTag = (list: Array<any>, sceneType: number) => {
	if (!list) return []
	let filterData = list?.filter((item) => item.status !== 2 && item.sceneType === sceneType)
	if (props.systemTags) {
		return filterData.filter((item) => item.tagList?.length > 0) //具体标签没有或都停用的，标签组不展示
	} else {
		return filterData
			.map((item) => {
				item.tagList = item.tagList?.filter((val) => val.status !== 2) ?? []
				return item
			})
			.filter((item) => item.tagList?.length > 0) //具体标签没有或都停用的，标签组不展示
	}
}
/**
 * 获取所有标签 通用、个人
 */
const getTagGroupData = async () => {
	const res = await getTagGroupList({ customerType: props.tagType, isShow: props.systemTags })
	let tagGroupData = [
		{
			name: '个人',
			tagList: filtertTag(res.data.list, 1),
		},
		{
			name: '通用',
			tagList: filtertTag(res.data.list, 2),
		},
	]
	if (props.systemTags) {
		tagGroupData.push({
			name: '系统标签',
			tagList: filtertTag(res.data.list, 3),
		})
	}
	options.value = tagGroupData
	handleChange()
}
/**深度递归 拍平标签 */
const deepRecursiveTraversal = (data, callback) => {
	if (Array.isArray(data)) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].tagList?.length) {
				deepRecursiveTraversal(data[i].tagList, callback)
			} else {
				// 查找符合选中文件
				if (tags.value?.includes(data[i].ID)) {
					callback(data[i])
				}
			}
		}
	}
}

const handleChange = () => {
	emit('update:modelValue', tags.value)

	const list = []
	deepRecursiveTraversal(options.value, (value) => {
		list.push(value)
	})
	emit('change', list)
}
</script>

<style></style>
