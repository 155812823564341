<template>
	<AuthButton :type="ApplyType.LIST" :buttonList="buttonList" @click="handleBtnClick" />
	<!-- 旧逻辑，添加【更多】后修改，后期无问题可删除（24.7.17） -->
	<!-- <template v-for="(button, index) in buttonList" :key="button.type">
			<template v-if="!button.apply || button.apply?.includes(ApplyType.ALL) || button.apply?.includes(ApplyType.LIST)">
				<el-button
					class="!m-0"
					:type="button.buttonType ? button.buttonType : index === 0 ? 'primary' : ''"
					:plain="!!button.buttonType"
					@click="handleBtnClick(button)"
				>
					<template v-if="button.icon" #icon>
						<SvgIcon :name="button.icon" />
					</template>
					<component v-if="typeof button.label === 'function'" :is="button.label" />
					<span v-else>{{ button.label }}</span>
				</el-button>
			</template>
		</template> -->
</template>

<script setup lang="ts">
import { ref, toRef, unref, defineAsyncComponent, toValue } from 'vue'
import { ApplyType } from '@/hooks/usePageDrawer/types'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DialogDataType } from '@/hooks/usePageDrawer/types'
import { useOperationBtnList, OpeationBtnType } from '@/hooks/useOperationBtnList'
import { ResListProTable } from '@/components/proTableV2/common/hooks/useTable/types'
import AuthButton from '@/components/authButton/index.vue'

const Import = defineAsyncComponent(() => import('@/components/proTable/components/Dialog/import.vue'))
const Export = defineAsyncComponent(() => import('@/components/proTable/components/Dialog/export.vue'))

interface Props {
	buttonList: DialogDataType[]
	exposeMap: {
		[key: string]: any
	}
	state: ResListProTable | null
}

const props = defineProps<Props>()

const { drawerOpen } = usePageDrawer()
const { btnList, selectCommonBtnTypeFn } = useOperationBtnList()
// 导出非列表数据, 无需total
const exportNotListDataParams = computed(() => {
	const obj = { ...toValue(props.exposeMap?.searchParam), ...toValue(props.exposeMap?.totalParam) }
	if (obj) {
		delete obj.page
		delete obj.pageSize
	}
	return obj
})
// 导出需要total字段-后端暂时最大只支持5W
const exportSearchParam = computed(() => {
	const { total = 0 } = toValue(props.state)
	const obj = { ...toValue(exportNotListDataParams), total }
	return obj
})

// 打开弹窗或执行按钮方法
const handleBtnClick = async (btn) => {
	// 勾选长度
	const selectLength: number = props.exposeMap.selectionList.value?.length || 0

	// 使用装饰器来创建一个新的函数，带有默认逻辑
	const preOpenValidationFn = btn?.preOpenValidation

	const defaultPrevValidateFn = btn?.defaultPrevValidate
	const currentBtn = selectCommonBtnTypeFn(btn.type)

	// 找到原始按钮
	const sourceBtn = btnList.find((item) => item.type === btn.type)
	const label = typeof btn.label === 'function' ? sourceBtn?.label : btn.label

	// 校验-1.校验可操作数量
	let valid: boolean =
		(defaultPrevValidateFn ? defaultPrevValidateFn() : currentBtn?.defaultPrevValidate?.(selectLength, label)) ?? true
	if (!valid) return
	// 校验-2.校验自定义前置事件
	if (preOpenValidationFn) {
		valid = (await currentBtn?.preOpenValidation?.(preOpenValidationFn)) ?? true
	}
	valid && handleBtnItem(btn)
}

// 触发抽屉模式按钮
const handleBtnItem = async (btnInfo) => {
	const { type, label, title, props: itemProps, ...rest } = btnInfo
	const data = autoImportComponent(type, rest)
	const { conversionProps, ...defaultRest } = data ?? {}
	const refProps = toRef(itemProps, 'ref')

	const drawerInfo = {
		...defaultRest,
		...rest,
		title: title || label,
		listRef: ref(props.exposeMap),
		props: {
			// [selectKey]: ids.value?.[0], // 好像没啥用
			...itemProps,
			ref: refProps,
			...conversionProps,
		},
		isDialog: true,
	}

	drawerOpen(type, drawerInfo)
}

const autoImportComponent = (type, btnInfo) => {
	switch (type) {
		case OpeationBtnType.IMPORT:
		case OpeationBtnType.IMPORTMODIWARPER:
		case OpeationBtnType.IMPORTBATCHADJUST:
		case OpeationBtnType.IMPORTREPAIRCOSTS:
		case OpeationBtnType.IMPORTSHIP:
		case OpeationBtnType.IMPORTAPPROVEL:
		case OpeationBtnType.IMPORTBASICINFO:
		case OpeationBtnType.IMPORTPRICESETTING:
		case OpeationBtnType.IMPORTBILLINFO:
		case OpeationBtnType.IMPORTBILLHEAD:
		case OpeationBtnType.MUITILEADING:
		case OpeationBtnType.IMPORTUPDATE:
		case OpeationBtnType.IMPORTAPPROVAL:
		case OpeationBtnType.IMPORTINVOICEHEADER:
			return {
				component: Import,
				conversionProps: {
					importApi: btnInfo.onConfirm,
					templatePath: btnInfo.templatePath ?? props.state?.templateAddress,
				},
				events: {
					refresh: props.exposeMap?.getTableList,
				},
			}
		case OpeationBtnType.EXPORT:
		case OpeationBtnType.EXPORTCANCELLATIONRECORD:
		case OpeationBtnType.EXPORTINVENTORYRECORDS:
		case OpeationBtnType.EXPORTINVENTORYRECORDDETAILS:
		case OpeationBtnType.EXPORTSHIP:
		case OpeationBtnType.EXPORTALLOCATERECORDS:
		case OpeationBtnType.EXPORTALLOCATERETAILS:
		case OpeationBtnType.EXPORTFACTORYDEMANDSUMMARY:
		case OpeationBtnType.EXPORTDEMANDDETAILS:
		case OpeationBtnType.EXPORTDETAILS:
			return {
				component: Export,
				conversionProps: {
					exportApi: btnInfo.onConfirm,
					searchParams: unref(exportSearchParam),
					...btnInfo.props, // 暂时需要使用到 searchFilterList 属性
				},
			}
		case OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY:
			return {
				component: Export,
				conversionProps: {
					exportApi: btnInfo.onConfirm,
					searchParams: unref(exportNotListDataParams),
					...btnInfo.props, // 暂时需要使用到 searchFilterList 属性
				},
			}
		default:
			return {
				conversionProps: {},
			}
	}
}
</script>
