import service from '@/utils/request'

// * KA列表
export const getCusKaManageList = (data: any) => {
	return service({
		url: '/cusKaManage/getCusKaManageList',
		method: 'post',
		data,
	})
}

// * KA详情
export const findCusKaManage = (params: any) => {
	return service({
		url: '/cusKaManage/findCusKaManage',
		method: 'get',
		params,
	})
}

// * 添加
export const createKa = (data: any) => {
	return service({
		url: '/cusKaManage/createCusKaManage',
		method: 'post',
		data,
	})
}

// * 编辑
export const updateKa = (data: any) => {
	return service({
		url: '/cusKaManage/updateCusKaManage',
		method: 'put',
		data,
	})
}

// * 删除
export const deleteKa = (data: any) => {
	return service({
		url: '/cusKaManage/deleteCusKaManageByIds',
		method: 'delete',
		data,
	})
}

// * 设置标签
export const setCusKaManageTag = (data: any) => {
	return service({
		url: '/cusKaManage/setCusKaManageTag',
		method: 'put',
		data,
	})
}

// * 启用
export const enableKa = (data: any) => {
	return service({
		url: '/cusKaManage/enableStatus',
		method: 'put',
		data,
	})
}

// * 停用
export const disableKa = (data: any) => {
	return service({
		url: '/cusKaManage/disableStatus',
		method: 'put',
		data,
	})
}

// * 导入
export const importApi = (data: any) => {
	return service({
		url: '/cusKaManage/importCusKaManage',
		method: 'post',
		data,
	})
}

// * 导出
export const exportApi = (data) => {
	return service({
		url: '/cusKaManage/getCusKaManageList',
		method: 'post',
		data,
	})
}
