<template>
	<el-popover
		:popper-style="{ padding: 0 }"
		placement="bottom-start"
		:width="320"
		trigger="click"
		@show="openDialog"
		@hide="closeDialog"
		:disabled="disabled"
	>
		<template #reference>
			<div class="el-input" :class="{ 'is-disabled': disabled }">
				<div class="el-input__wrapper w-full" :class="{ 'is-focus': showPopover }" style="justify-content: flex-start">
					<template v-if="checkList && checkList.length > 0">
						<el-scrollbar max-height="103" class="flex-1">
							<div>
								<!-- 将选中的标签合并 -->
								<div v-if="collapseTags" class="inline-flex w-full overflow-hidden">
									<template v-for="(tag, index) in checkList" :key="tag.id">
										<el-tag
											v-if="index === 0"
											class="mr-1 overflow-hidden"
											type="info"
											:closable="!disabled"
											@close="removeTag(tag)"
										>
											<el-tooltip effect="dark" v-if="tag.name" :content="tag.name" placement="bottom">
												{{ tag.name }}
											</el-tooltip>
										</el-tag>
									</template>
									<el-popover
										placement="bottom"
										:popper-style="{ maxHeight: '280px', overflow: 'auto' }"
										:width="300"
										trigger="hover"
									>
										<template #reference>
											<el-tag v-if="checkList.length > 1" type="info">+{{ checkList?.length - 1 }}</el-tag>
										</template>
										<template v-for="(tag, index) in checkList" :key="tag.id">
											<el-tag
												v-if="index !== 0"
												class="mr-1 mb-1"
												type="info"
												:closable="!disabled"
												@close="removeTag(tag)"
											>
												{{ tag.name }}
											</el-tag>
										</template>
									</el-popover>
								</div>
								<!-- 不合并标签 -->
								<template v-else>
									<el-tag
										v-for="tag in checkList"
										:key="tag.id"
										class="mr-1 max-w-full"
										type="info"
										:closable="!disabled"
										@close="removeTag(tag)"
									>
										{{ tag.name }}
									</el-tag>
								</template>
							</div>
						</el-scrollbar>

						<el-button v-if="!disabled" link>
							<el-icon>
								<CircleClose @click="removeAllTag" />
							</el-icon>
						</el-button>
					</template>
					<div v-else class="text-gray-400">{{ props.placeholder }}</div>
				</div>
			</div>
		</template>
		<template #default>
			<div class="user-content-header">
				<XZLInput v-model="keyword" :prefix-icon="Search" placeholder="搜索" />
			</div>
			<div class="user-content-wrapper">
				<div class="user-content">
					<div v-bind="containerProps" class="h-full">
						<div v-bind="wrapperProps">
							<div v-for="item in list" :key="item.index" style="height: 32px">
								<div v-if="item.data.type === 'letter'" style="line-height: 32px">
									{{ item.data.letter }}
								</div>
								<div v-else>
									<el-checkbox
										v-model="item.data.isChecked"
										:label="item.data.name"
										:disabled="disabled || disabledUser.includes(item.data.id)"
										@change="handleSelect(item.data, item.data.isChecked)"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tag-bar">
					<ul>
						<li
							v-for="(item, index) in shortCut1"
							:class="[curIndex === index && index !== 0 ? 'selected' : '']"
							:key="item"
							@click="goIndex(item)"
						>
							{{ item }}
						</li>
					</ul>
					<ul>
						<li
							v-for="(item, index) in shortCut2"
							:class="[curIndex === index + 14 ? 'selected' : '']"
							:key="item"
							@click="goIndex(item)"
						>
							{{ item }}
						</li>
					</ul>
				</div>
			</div>
			<div class="user-content-footer">
				<span class="mr-1">已选</span>
				<span>{{ checkList.length }}</span>
			</div>
		</template>
	</el-popover>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, computed } from 'vue'
import { useTrigger } from '@/hooks/useTrigger'
import { Search, CircleClose } from '@element-plus/icons-vue'

import { useVirtualList } from '@vueuse/core'
import { sortBy } from 'lodash-es'

import { getUserNameList } from '@/api/serveApi/basicInformation/personnelMgr'

export interface UserType {
	id: number
	empId: string
	name: string
	nameInitials: string
	isChecked?: boolean | undefined
}

const showPopover = ref<boolean>(false)
const shortCut1: string[] = '★ABCDEFGHIJKLM'.split('')
const shortCut2: string[] = 'NOPQRSTUVWXYZ#'.split('')

const keyword = ref<string>('')
const userList = ref<Array<UserType>>([] as UserType[])
const checkList = ref<Array<UserType>>([] as UserType[])
const curIndex = ref<number>(0)

const props = defineProps({
	modelValue: {
		type: Array,
		required: true,
		default: () => [],
	},
	// 占位符
	placeholder: {
		type: String,
		required: false,
		default: '请选择',
	},
	// 是否多选
	multiple: {
		type: Boolean,
		required: false,
		default: true,
	},
	// 是否禁用
	disabled: {
		type: Boolean,
		default: false,
	},
	// 禁用的用户：需要禁用用户的id
	disabledUser: {
		type: Array<number>,
		default: () => [],
	},
	// 将选中值合并为一段文字，参考el-select多选
	collapseTags: {
		type: Boolean,
		default: false,
	},
	//具体职位下的用户：传入positionId
	jobPositionId: {
		type: String,
		default: '',
	},
	data: {
		type: Array,
		default: () => [],
	},
	remote: {
		type: Boolean,
		default: true,
	},
})

const emits = defineEmits(['update:modelValue', 'change'])

// 组装用户数据
const makeUserList = (list: Array<UserType>): any[] => {
	return list.reduce((data, next) => {
		const letterIndex = data.findIndex((item) => item.letter === next.nameInitials?.toUpperCase())
		if (letterIndex === -1) {
			data.push({
				type: 'letter',
				letter: next.nameInitials?.toUpperCase(),
			})
		}
		data.push(next)
		return data
	}, [])
}

// 渲染列表
const renderList = computed(() => {
	if (keyword.value) {
		console.log('containerProps', containerProps)
		if (containerProps.ref?.value?.scrollTop !== 0) {
			scrollTo(0)
		}
		const arr = userList.value.filter((item) => {
			return item.name?.indexOf(keyword.value) !== -1
		})
		return makeUserList(arr)
	}
	return makeUserList(userList.value)
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(renderList, {
	// Keep `itemHeight` in sync with the item's row.
	itemHeight: 32,
})

const isCheckedItem = (itemId) => {
	//区分 数组与字符串类型
	return Array.isArray(props.modelValue) ? props.modelValue?.includes(itemId) : Number(props.modelValue) === itemId
}

watch(userList, (newVal) => {
	if (newVal?.length > 0 && props.modelValue?.length > 0 && checkList.value.length === 0) {
		checkList.value = newVal.filter((item) => {
			//区分 数组与字符串类型
			let isChecked = isCheckedItem(item.id)
			// 是否被选中
			item.isChecked = isChecked

			return isChecked
		})
	}
})

const { emitTrigger } = useTrigger()
watch(
	() => props.modelValue,
	() => {
		checkList.value = userList.value?.filter((item) => {
			//区分 数组与字符串类型
			let isChecked = isCheckedItem(item.id)
			// 是否被选中
			item.isChecked = isChecked

			return isChecked
		})

		emitTrigger()
	},
	{
		deep: true,
	}
)

watch(
	() => props.data,
	(newVal) => {
		if (newVal.length > 0 && userList.value.length === 0) {
			userList.value = formatUserList(newVal)
		}
	},
	{ deep: true }
)

onMounted(async () => {
	if (props.remote) {
		userList.value = await getUserList()
	} else {
		userList.value = formatUserList(props.data ?? [])
	}
})

// 获取用户列表
const getUserList = async () => {
	const params = {
		page: 1,
		pageSize: 99999,
		nameSort: 1,
	}
	props.jobPositionId ? Object.assign(params, { jobPositionId: props.jobPositionId }) : ''
	const res = await getUserNameList(params)
	if (res && res.code === 0) {
		return formatUserList(res.data.list ?? [])
	}
	return []
}

// 格式化userList
const formatUserList = (data) => {
	data?.forEach((item) => {
		if (!item.nameInitials) {
			item.nameInitials = '#'
		}
		item.sort = shortCut1.concat(shortCut2).findIndex((str: string) => item.nameInitials.toUpperCase() === str)
	})
	return sortBy(data, 'sort')
}

// 弹窗显示时，初始化数据
const openDialog = () => {
	showPopover.value = true
}

// 弹窗关闭时，重置数据
const closeDialog = () => {
	showPopover.value = false
	scrollTo(0)
}

// 点击右侧索引，滚动至相应位置
const goIndex = (value: string) => {
	let allList = []
	if (keyword.value) {
		const filterlist = userList.value.filter((item) => {
			return item.name?.indexOf(keyword.value) !== -1
		})
		allList = makeUserList(filterlist)
	} else {
		allList = makeUserList(userList.value)
	}
	const index = allList.findIndex((item) => item.letter === value)
	if (index !== -1) {
		const letterIndex = shortCut1.concat(shortCut2).findIndex((item) => item === value)
		curIndex.value = letterIndex
		scrollTo(index)
	}
}

// 选择人员
const handleSelect = (item: UserType, checked: boolean) => {
	if (checked) {
		// 如果是多选，则直接添加。如果是单选，则清空之前所选的用户
		if (props.multiple) {
			checkList.value.push(item)
		} else {
			// 将之前选择的用户勾选状态清除
			checkList.value?.forEach((el: UserType) => {
				el.isChecked = false
			})
			checkList.value = [item]
		}
	} else {
		checkList.value = checkList.value.filter((el: UserType) => {
			return el.empId !== item.empId
		})
	}
	const userIds: number[] = checkList.value?.map((item) => item.id)
	emits('update:modelValue', userIds)
	emits('change', userIds)
}

// 删除单个
const removeTag = (value: any) => {
	value.isChecked = false
	checkList.value = checkList.value.filter((item: any) => {
		return item.id !== value.id
	})
	const userIds: number[] = checkList.value?.map((item) => item.id)
	emits('update:modelValue', userIds)
	emits('change', userIds)
}

// 删除全部
const removeAllTag = () => {
	checkList.value.forEach((item: UserType) => {
		item.isChecked = false
	})
	checkList.value = []
	emits('update:modelValue', [])
	emits('change', [])
}

defineExpose({
	userList: userList,
	checkList: checkList,
})
</script>

<style lang="scss" scoped>
.el-tag {
	border-width: 0;
	:deep(.el-tag__content) {
		@apply truncate;
	}
}

.user-content-header {
	padding: 5px;
	border-bottom: 1px solid var(--el-border-color);

	:deep(.el-input__wrapper) {
		box-shadow: none;
	}
}

.user-content-wrapper {
	position: relative;
	padding: 10px;

	.user-content {
		height: 280px;
		overflow: auto;

		:deep(.el-checkbox__label) {
			font-size: 12px;
		}
	}

	.tag-bar {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0, -50%);
		display: flex;
		gap: 4px;
		font-weight: normal;
		color: #ccd0d8;
		text-align: center;

		li {
			font-size: 12px;
			line-height: 17px;
		}

		.selected {
			color: var(--el-color-primary);
		}
	}
}

.user-content-footer {
	height: 32px;
	line-height: 32px;
	font-size: 12px;
	padding-left: 18px;
	color: #545861;
	background-color: #f4f6f9;
}
</style>
