<template>
	<el-select
		class="w-full"
		v-model="value"
		multiple
		collapse-tags
		collapse-tags-tooltip
		:max-collapse-tags="3"
		filterable
		clearable
		placeholder="请选择"
		v-bind="$attrs"
	>
		<el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script lang="ts" setup>
import { ref, onMounted, watchEffect } from 'vue'
import { useVModel } from '@vueuse/core'
import { getAllDemandPlanChannels } from '@/api/serveApi/planningManagement/planImportRecord'

interface Props {
	modelValue: number[]
	data?: any[]
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const dataList = ref([])

watchEffect(() => {
	if (props.data) {
		dataList.value = props.data
	}
})

onMounted(() => {
	if (!props.data) {
		getData()
	}
})

const getData = async () => {
	const res = await getAllDemandPlanChannels()
	if (res && res.code === 0) {
		dataList.value = res.data
	}
}
</script>
