import service from '@/utils/request'

// 审批 - 同意
export const agree = (data: any) => {
	return service({
		url: '/approvalProcessOperation/agree',
		method: 'put',
		data,
	})
}

// 审批 - 驳回
export const reject = (data: any) => {
	return service({
		url: '/approvalProcessOperation/reject',
		method: 'put',
		data,
	})
}

// 抄送
export const copyTo = (data: any) => {
	return service({
		url: '/approvalProcessOperation/copyTo',
		method: 'put',
		data,
	})
}

// 沟通
export const msgNotify = (data: any) => {
	return service({
		url: '/approvalProcessOperation/msgNotify',
		method: 'put',
		data,
	})
}

// 转批
export const transfer = (data: any) => {
	return service({
		url: '/approvalProcessOperation/transfer',
		method: 'put',
		data,
	})
}

// 撤回
export const revoke = (data: any) => {
	return service({
		url: '/approvalProcessOperation/revoke',
		method: 'put',
		data,
	})
}

// 加签
export const addSign = (data: any) => {
	return service({
		url: '/approvalProcessOperation/addSign',
		method: 'put',
		data,
	})
}

// 批量转批
export const batchTransferApi = (data: any) => {
	return service({
		url: '/approvalProcessOperation/batchTransfer',
		method: 'put',
		data,
	})
}

// 批量审批
export const batchApprovalApi = (data: any) => {
	return service({
		url: '/approvalProcessOperation/batchApproval',
		method: 'put',
		data,
	})
}
