import service from '@/utils/request'

/** 分页获取合同信息列表 */
export const getContractManageList = (data: any) => {
	return service({
		url: '/contractManage/getContractManageList',
		method: 'post',
		data,
	})
}

/** 创建合同信息 */
export const createContractManage = (data: any) => {
	return service({
		url: '/contractManage/createContractManage',
		method: 'post',
		data,
	})
}

/** 获取合同编号 */
export const getContractManageCode = (params?: any) => {
	return service({
		url: '/contractManage/getContractManageCode',
		method: 'get',
		params,
	})
}

/** 单个删除合同信息 */
export const deleteContractManage = (data: any) => {
	return service({
		url: '/contractManage/deleteContractManage',
		method: 'DELETE',
		data: data,
	})
}

/** 更新合同信息其他信息 */
export const updateOther = (data: any) => {
	return service({
		url: '/contractManage/updateOther',
		method: 'put',
		data: data,
	})
}

/** 更新合同信息 */
export const updateContractManage = (data: any) => {
	return service({
		url: '/contractManage/updateContractManage',
		method: 'put',
		data: data,
	})
}

/** 续签合同信息 */
export const continueContractManage = (data: any) => {
	return service({
		url: '/contractManage/continueContractManage',
		method: 'post',
		data,
	})
}

/** 用id查询合同信息 */
export const findContractManage = (params: any) => {
	return service({
		url: '/contractManage/findContractManage',
		method: 'get',
		params,
	})
}

/**
 * @description 获取经销商
 * @param params
 */
export const findCusDistributor = (params: any) => {
	console.log(`output->cancelToken`, params)
	const { cancelToken, ...rest } = params
	return service({
		url: '/distributorManage/findCusDistributor',
		method: 'get',
		params: rest,
		cancelToken,
	})
}

/** 合同提交审批 */
export const submitApprovalContractManage = (data: any) => {
	return service({
		url: '/contractManage/submitApprovalContractManage',
		method: 'put',
		data: data,
	})
}

/** 合同作废 */
export const nullifyContractManage = (data: any) => {
	return service({
		url: '/contractManage/nullifyContractManage',
		method: 'put',
		data: data,
	})
}

/** 生成电子合同 */
export const generateContractManage = (data: any) => {
	return service({
		url: '/contractManage/generateContractManage',
		method: 'put',
		data: data,
	})
}

/** 获取合同下拉列表 */
export const getContractModelAll = (data?: any) => {
	return service({
		url: '/contractModel/getContractModelAll',
		method: 'post',
		data,
	})
}

/**根据用户Id获取用户信息 */
export const getUserInfoById = (params: any) => {
	return service({
		url: '/user/getUserInfoById',
		method: 'get',
		params,
	})
}

/** 获取契约锁认证URL */
export const getCompanyAuthUrl = (data: any) => {
	return service({
		url: '/contractManage/getCompanyAuthUrl',
		method: 'post',
		data,
	})
}

/** 获取合同替换参数内容 */
export const getParamContent = (data: any) => {
	return service({
		url: '/contractManage/getParamContent',
		method: 'post',
		data,
	})
}

export const getContractViewUrl = (params: any) => {
	return service({
		url: '/contractManage/getContractViewUrl',
		method: 'get',
		params,
	})
}

/** 获取契约签署URL */
export const getContractShortUrl = (data: any) => {
	return service({
		url: '/contractManage/getContractShortUrl',
		method: 'post',
		data,
	})
}

/**合同下载-记录埋点 */
export const getDownloadContract = (data?: any) => {
	return service({
		url: '/contractManage/getDownloadContract',
		method: 'post',
		data,
	})
}

/**获取预览服务的临时地址 */
export const getFileUrlParam = (params?: any) => {
	return service({
		url: '/contractManage/getFileUrlParam',
		method: 'get',
		params,
	})
}

/**合同签章-记录埋点 */
export const signatureContract = (data?: any) => {
	return service({
		url: '/contractManage/signatureContract',
		method: 'post',
		data,
	})
}
