<template>
	<template v-for="(item, index) in list" :key="index">
		<XZLAvatar
			v-if="index < maxNumber"
			class="z-[1]"
			:class="{ 'ml-[-18px]': index !== 0 }"
			style="border: 1px solid #fff"
			:size="size"
			:src="item.headerImg"
			:name="item.userName"
			:default-icon-size="18"
		/>
	</template>
	<XZLAvatar
		v-if="list.length > maxNumber"
		class="ml-[-18px] z-[1]"
		style="background-color: #a5beff; border: 1px solid #fff"
		:size="size"
	>
		+{{ list.length > 999 ? 999 : list.length }}
	</XZLAvatar>
</template>

<script setup lang="ts">
import { ApprovalUserType } from '../types'
import XZLAvatar from '@/components/proxyComponents/XZLAvatar/index.vue'

interface Props {
	size: number
	list: ApprovalUserType[]
}

const props = defineProps<Props>()
const maxNumber = 5 // 最多显示5个头像
</script>
