import service from '@/utils/request'

/**
 * @description 经销商列表
 * @param data
 */
export const getCusDistributorList = (data: any) => {
	return service({
		url: '/distributorManage/getCusDistributorList',
		method: 'post',
		data,
	})
}

/**
 * @description 经销商列表-无权限控制
 * @param data
 */
export const getDistributorListByUser = (data: any) => {
	return service({
		url: '/distributorManage/getDistributorListByUser',
		method: 'post',
		data,
	})
}

/**
 * @description 经销商弹窗筛选列表
 * @param data
 */
export const getBaseDistributorList = (data: any) => {
	return service({
		url: '/distributorManage/getBaseDistributorList',
		method: 'post',
		data,
	})
}

//批量更新负责人和销售区域
export const updateDutyAreaByIds = (data) => {
	return service({
		url: '/distributorManage/updateDutyAreaByIds',
		method: 'put',
		data,
	})
}

/**
 * @description 经销商弹窗筛选列表销售区域向下查找，不需要废弃
 * @param data
 */
export const getBaseDistributorListV6 = (data: any) => {
	return service({
		url: '/distributorManage/getBaseDistributorListV6',
		method: 'post',
		data,
	})
}

/**
 * @description 经销商弹窗筛选列表销售区域向上查找
 * @param data
 */
export const getBaseDistributorListV7 = (data: any) => {
	return service({
		url: '/distributorManage/getBaseDistributorListV7',
		method: 'post',
		data,
	})
}
/**
 * @description 获取渠道客户列表
 * @param data
 */
export const getDistributorListV2 = (data: any) => {
	return service({
		url: '/distributorManage/getDistributorListV2',
		method: 'post',
		data,
	})
}

/**
 * @description 工厂列表
 * @param data
 */
export const getBaseFactoryList = (data: any) => {
	return service({
		url: '/factoryManage/getBaseFactoryList',
		method: 'post',
		data,
	})
}

/**
 * @description 添加经销商
 * @param data
 */
export const createCusDistributor = (data: any) => {
	return service({
		url: '/distributorManage/createCusDistributor',
		method: 'post',
		data,
	})
}

/**
 * @description 编辑渠道客户
 * @param data
 */
export const updateCusDistributor = (data: any) => {
	return service({
		url: '/distributorManage/updateCusDistributor',
		method: 'put',
		data,
	})
}

/**
 * @description 获取经销商
 * @param params
 */
export const findCusDistributor = (params: any) => {
	return service({
		url: '/distributorManage/findCusDistributor',
		method: 'get',
		params,
	})
}

/**
 * @description 删除经销商
 * @param data
 */
export const deleteCusDistributorByIds = (data: any) => {
	return service({
		url: '/distributorManage/deleteCusDistributorByIds',
		method: 'delete',
		data,
	})
}

/**
 * @description 启用经销商
 * @param data
 */
export const enableStatus = (data: any) => {
	return service({
		url: '/distributorManage/enableStatus',
		method: 'put',
		data,
	})
}

/**
 * @description 停用经销商
 * @param data
 */
export const disableStatus = (data: any) => {
	return service({
		url: '/distributorManage/disableStatus',
		method: 'put',
		data,
	})
}

/**
 * @description 修改负责人
 * @param data
 */
export const updateDistributorDuty = (data: any) => {
	return service({
		url: '/distributorManage/updateDistributorDuty',
		method: 'put',
		data,
	})
}

/**
 * @description 修改销售区域
 * @param data
 */
export const updateDistributorArea = (data: any) => {
	return service({
		url: '/distributorManage/updateDistributorArea',
		method: 'put',
		data,
	})
}

/**
 * @description 分页获取用户名称信息
 * @param data
 */
export const getUserNameList = (data: any) => {
	return service({
		url: '/user/getUserNameList',
		method: 'post',
		data,
	})
}

/**
 * @description 分页获取CustomSaleArea列表
 * @param data
 */
export const getCustomSaleAreaList = (data: any) => {
	return service({
		url: '/saleArea/getCustomSaleAreaList',
		method: 'post',
		data,
	})
}

/**
 * @description 导入经销商数据
 * @param data
 */
export const importDistributor = (data: any) => {
	return service({
		url: '/distributorManage/importDistributor',
		method: 'post',
		data,
	})
}
/**
 * @description 导出经销商数据
 * @param data
 */
export const exportCusDistributorList = (data) => {
	return service({
		url: '/distributorManage/getCusDistributorList',
		method: 'post',
		data,
		// responseType: 'blob',
	})
}

/**
 * @description 获取金蝶云区域编码
 * @param data
 */
export const getAreaCode = (data: any) => {
	return service({
		url: '/distributorManage/getAreaCode',
		method: 'post',
		data,
	})
}

/**
 * @description 获取仓库是否能删除
 * @param data
 */
export const warehouseStockById = (data: any) => {
	return service({
		url: '/distributorManage/warehouseStockById',
		method: 'post',
		data,
	})
}
/**
 * @description 获取渠道客户仓库列表
 * @param params
 */
export const getWarehouseList = (params: any) => {
	return service({
		url: '/distributorManage/getWarehouseList',
		method: 'get',
		params,
	})
}

/**
 * @description 获取渠道客户仓库列表
 * @param params
 */
export const operationModeList = (params?: any) => {
	return service({
		url: '/distributorManage/operationModeList',
		method: 'get',
		params,
	})
}

/**
 * @description 获取库存明细
 * @param data
 */
export const getDistributorStockProductList = (data: any) => {
	return service({
		url: '/distributorStockProduct/getDistributorStockProductList',
		method: 'post',
		data,
	})
}

/**
 * @description 获取盘点记录
 * @param data
 */
export const distributorStockList = (data: any) => {
	return service({
		url: '/distributorManage/distributorStockList',
		method: 'post',
		data,
	})
}
/**
 * @description 获取调拨记录
 * @param data
 */
export const getStockDeployList = (data: any) => {
	return service({
		url: '/distributorManage/getStockDeployList',
		method: 'post',
		data,
	})
}
/**
 * @description 设置标签
 * @param data
 */
export const updateDistributorTag = (data: any) => {
	return service({
		url: '/distributorManage/updateDistributorTag',
		method: 'put',
		data,
	})
}

