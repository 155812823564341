<template>
	<el-cascader
		v-model="value"
		:options="options"
		collapse-tags
		collapse-tags-tooltip
		clearable
		filterable
		@change="handleChange"
		style="width: 100%"
		:props="{ value: 'id', label: 'area_name', emitPath: emitPath, multiple: multiple, checkStrictly }"
		placeholder="请选择行政区域"
		v-bind="$attrs"
	/>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { getAreaList } from '@/api/serveApi/basicInformation/terminalMgr'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
	modelValue: {
		type: [Array<Number>, Number, null],
		required: true,
	},
	multiple: {
		type: Boolean,
		require: false,
	},
	checkStrictly: {
		type: Boolean,
		require: false,
	},
	level: {
		type: Number,
		require: false,
	},
	emitPath: {
		type: Boolean,
		require: false,
		default: false,
	},
})
const options = ref([])
const value = ref()
onMounted(() => {
	getRegionData()
})
//监听赋值操作
watch(
	() => props.modelValue,
	(val) => {
		value.value = val
	},
	{
		deep: true,
		immediate: true,
	}
)
//获取行政区域
const getRegionData = async () => {
	const res = await getAreaList({ level: props.level })
	options.value = !Array.isArray(res.data) ? [] : res.data
}
//数组转化成树状结构数组
// function treeList(arr, pid = 0) {
// 	var tree = []
// 	var temp
// 	for (let i = 0; i < arr.length; i++) {
// 		if (arr[i].parent_id == pid) {
// 			var obj = arr[i]
// 			temp = treeList(arr, arr[i].ID)
// 			if (temp.length > 0) {
// 				obj.children = temp
// 			}
// 			tree.push(obj)
// 		}
// 	}
// 	return tree
// }
const handleChange = () => {
	emit('update:modelValue', value.value)
}
</script>

<style></style>
