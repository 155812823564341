import { toUpper } from 'lodash-es'
import { useUserStore } from '@/pinia/modules/user'
import { PageConfigSetting } from '@/components/proTableV2/common/hooks/usePageConfig/types'

/**
 * @description 根据传入的按钮列表或字段列表，返回有权限的按钮列表和字段列表
 * @param btnList 按钮列表
 * @param defaultColumns 默认字段列表
 * @param fields 配置字段列表
 * @param moduleName 模块名称
 * @returns 当前模块的最终权限集合
 */
export const usePageAuth = (btnList, defaultColumns: PageConfigSetting[], fields: PageConfigSetting[], moduleName?) => {
	// 注意: 下面的配置不要更改，如需跳过权限请使用 npm run test -- --d
	if (import.meta.env.VITE_NOPERMISSION === 'true') {
		return { buttonList: btnList, fieldList: fields }
	}

	const userStore = useUserStore()
	const pageRouterName = moduleName ?? toUpper(sessionStorage.getItem('activeRouter'))
	const rolseList = userStore.userPermissions?.find((item) => toUpper(item?.name) == pageRouterName)

	// 获取有权限的字段
	const getFieldAuth = (data: PageConfigSetting[]) => {
		if (!data) return []

		return data?.reduce((list, current) => {
			const item = rolseList?.fieldList?.find((el) => current.prop === el.name)
			// 筛选有权限的字段，或者不需要权限的字段
			if (item?.status === 1 || current.isHideAuth) {
				list.push(current)
			}
			return list
		}, [])
	}

	// 字段权限
	let settings = []
	if (rolseList?.fieldList?.length === 0) {
		settings = fields
	} else {
		settings = getFieldAuth(fields)
	}

	// 如果字段权限为空：可能会出现fields有值，但里面的字段都没有权限了，但还有别的有权限的字段
	if (settings.length === 0) {
		settings = getFieldAuth(defaultColumns)
	}

	// 按钮权限
	const buttonList = btnList?.reduce((list, current) => {
		const item = rolseList?.buttonList?.find((el) => current.type === toUpper(el.name))
		if (item?.status === 1) {
			list.push(current)
		}
	}, [])

	return { buttonList, fieldList: settings }
}
