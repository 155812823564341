import { dayjs } from 'element-plus'
import { isFinite, toNumber } from 'lodash-es'
import { multiply, divide } from './bigNumber'
import { ApprovalStatus, ApprovalStatusText } from '@/view/workflow/types'
import { OperatorType, FromType } from '@/components/proTable/components/SearchForm/types'

const WEEKS = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

/** 定义全局时间类型 */
export enum DateType {
	/**四位数的年份 */
	'Y' = 'YYYY',
	/**月份，两位数 */
	'M' = 'MM',
	/**月份里的一天，两位数 */
	'D' = 'DD',
	/**最简写的星期几 */
	'Dd' = 'dd',
	/**四位数的年份-两位数的月份 */
	'YM' = 'YYYY-MM',
	/**四位数的年份-两位数的月份-两位数的天 */
	'YMD' = 'YYYY-MM-DD',
	/**四位数的年份-两位数的月份-两位数的天  24小时制两位数 */
	'YMD_H' = 'YYYY-MM-DD HH',
	/**四位数的年份-两位数的月份-两位数的天  24小时制两位数 两位数的分钟 */
	'YMD_HM' = 'YYYY-MM-DD HH:mm',
	/**四位数的年份-两位数的月份-两位数的天  24小时制两位数 两位数的分钟 两位数的秒 */
	'YMD_HMS' = 'YYYY-MM-DD HH:mm:ss',
	/**24小时制两位数 两位数的分钟 */
	'HM' = 'HH:mm',
	/**24小时制两位数 两位数的时分秒 */
	'HMS' = 'HH:mm:ss',
}
export const formatBoolean = (bool?: string) => {
	if (bool !== null) {
		return bool ? '是' : '否'
	} else {
		return ''
	}
}
/**
 * @description 全局统一默认展示时间格式
 * @param value 需要转换的字符串
 * @param timeFormat 需要转换的格式
 * @returns 返回符合格式的字符串或为空
 */
export const formatDate = (value: string | Date, timeFormat?: string) => {
	return value && !['0001-01-01', '0001-01-01T00:00:00Z'].includes(value)
		? dayjs(value).format(timeFormat ? timeFormat : DateType.YMD_HMS)
		: ''
}

export const formatTimestamp = (timestamp: number, timeFormat?: string) => {
	return timestamp ? dayjs(timestamp * 1000).format(timeFormat ? timeFormat : DateType.YMD_HMS) : ''
}

export const getWeek = (value: string | Date) => {
	return value && !['0001-01-01', '0001-01-01T00:00:00Z'].includes(value) ? WEEKS[dayjs(value).day()] : ''
}
/**
 * 格式化审批状态
 * @param status 审批状态
 * @returns {string} 审批状态中文
 */
export const formatFlowStatus = (status: ApprovalStatus) => {
	return ApprovalStatusText[status] ?? ''
}

// TODO 暂未使用
export const filterDict = (value, options) => {
	const rowLabel = options && options.filter((item) => item.value === value)
	return rowLabel && rowLabel[0] && rowLabel[0].label
}

// 单个价格保存时
export const setFormatPrice = (price: number | string): Number | null => {
	const num: number = typeof price === 'number' ? price : Number(price)
	if (isFinite(num)) {
		return toNumber(multiply(num, 100))
	}
	return null
}

// 单个价格获取时
export const getFormatPrice = (price: number | string): Number | null => {
	const num: number = typeof price === 'number' ? price : Number(price)
	if (isFinite(num)) {
		return toNumber(divide(num, 100))
	}
	return null
}

/**
 * 多个价格保存时
 * @param {Object} row 需要处理的对象
 * @param {Array} fields 需要处理的字段数组
 * @returns 新的对象
 */
export const setFormatPriceField = (row: any, fields: string[]) => {
	const params = JSON.parse(JSON.stringify(row))
	fields?.forEach((field) => {
		const num: number = typeof row[field] === 'number' ? row[field] : Number(row[field])
		if (isFinite(num)) {
			console.log('aaa', typeof multiply(num, 100))
			params[field] = multiply(num, 100)
		}
	})
	return params
}

/**
 * 多个价格获取时
 * @param {Object} row 需要处理的对象
 * @param {Array} fields 需要处理的字段数组
 * @returns 新的对象
 */
export const getFormatPriceField = (row: any, fields: string[]) => {
	const params = JSON.parse(JSON.stringify(row))
	fields?.forEach((field) => {
		const num: number = typeof row[field] === 'number' ? row[field] : Number(row[field])
		if (isFinite(num)) {
			params[field] = divide(num, 100)
		}
	})
	return params
}

/**
 * 格式化字段规则
 * @param data 字段规则数据
 * @returns
 */
export const formatSearchField = (data: any[]) => {
	if (!data) {
		return []
	}
	return data?.map((item) => {
		// 格式化字段条件规则
		const Operator = JSON.parse(item?.Operator) ?? []
		const OperatorList = []
		Operator.forEach((obj) => {
			for (const key in obj) {
				OperatorList.push({
					label: key,
					value: obj[key],
				})
			}
		})

		// 格式化字段下拉数据
		const Setting = item.Setting && item.Setting !== 'null' ? JSON.parse(item.Setting) : []
		const SettingList = []
		Setting.forEach((obj) => {
			for (const key in obj) {
				const value = Number(obj[key])
				SettingList.push({
					label: key,
					value: Number.isNaN(value) ? obj[key] : value,
				})
			}
		})

		return {
			...item,
			Operator: OperatorList,
			Setting: SettingList.length ? SettingList : null,
		}
	})
}
