import service from '@/utils/request'

//分页获取冰箱品牌列表
export const getRefFridgeBrandList = (data: any) => {
	return service({
		url: '/refFridgeBrand/getRefFridgeBrandList',
		method: 'post',
		data,
	})
}

//获取所有冰箱品牌列表
export const getAllRefFridgeBrand = (params?: any) => {
	return service({
		url: '/refFridgeBrand/getAllRefFridgeBrand',
		method: 'get',
		params,
	})
}

//创建冰箱品牌
export const createRefFridgeBrand = (data: any) => {
	return service({
		url: '/refFridgeBrand/createRefFridgeBrand',
		method: 'post',
		data,
	})
}

//删除冰箱品牌
export const deleteRefFridgeBrand = (data: any) => {
	return service({
		url: '/refFridgeBrand/deleteRefFridgeBrand',
		method: 'delete',
		data,
	})
}

//批量删除冰箱品牌
export const deleteRefFridgeBrandByIds = (data: any) => {
	return service({
		url: '/refFridgeBrand/deleteRefFridgeBrandByIds',
		method: 'delete',
		data,
	})
}

//停用冰箱品牌
export const disabledStatus = (data: any) => {
	return service({
		url: '/refFridgeBrand/disabledStatus',
		method: 'put',
		data,
	})
}

//启用冰箱品牌
export const enableStatus = (data: any) => {
	return service({
		url: '/refFridgeBrand/enableStatus',
		method: 'put',
		data,
	})
}

//用id查询冰箱品牌
export const findRefFridgeBrand = (params: any) => {
	return service({
		url: '/refFridgeBrand/findRefFridgeBrand',
		method: 'get',
		params,
	})
}

//更新冰箱品牌
export const updateRefFridgeBrand = (data: any) => {
	return service({
		url: '/refFridgeBrand/updateRefFridgeBrand',
		method: 'put',
		data,
	})
}

//文件导出
export const exportFile = (data: any) => {
	return service({
		url: '/refFridgeBrand/getRefFridgeBrandList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
