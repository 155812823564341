import service from '@/utils/request'

// 分页获取人员信息
export const getUserList = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/getOrgEmployeeBaseInfoList',
		method: 'post',
		data,
	})
}

// 分页获取人员名称信息
export const getUserNameList = (data: any) => {
	return service({
		url: '/user/getUserNameList',
		method: 'post',
		data,
	})
}

// 分页获取用户基本信息
export const getUserBaseInfoList = (data: any) => {
	return service({
		url: '/user/getUserBaseInfoList',
		method: 'post',
		data,
	})
}

// 通过人员id获取人员信息（批量）
export const getUserInfoList = (data: any) => {
	return service({
		url: '/user/queryUserBaseInfoByUserID',
		method: 'post',
		data,
	})
}

// 通过人员id获取人员信息
export const getUserListByDepartmentID = (data: any) => {
	return service({
		url: '/user/getUserListByDepartmentID',
		method: 'post',
		data,
	})
}

// 通过人员id获取人员信息
export const getUserInfo = (params: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/findOrgEmployeeBaseInfo',
		method: 'get',
		params,
	})
}

// 通过手机号获取人员信息
export const getUserInfoByPhone = (params: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/findOrgEmployeeCommonInfoByPhone',
		method: 'get',
		params,
	})
}

// 创建人员
export const createUser = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/createOrgEmployeeBaseInfo',
		method: 'post',
		data,
	})
}

// 更新人员信息
export const updateUser = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/updateOrgEmployeeBaseInfo',
		method: 'put',
		data,
	})
}

// 启用账号
export const enableUser = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/enableStatus',
		method: 'put',
		data,
	})
}

// 启用账号
export const deactivateUser = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/disabledStatus',
		method: 'put',
		data,
	})
}

// 生成账号
export const createAccount = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/createAccount',
		method: 'put',
		data,
	})
}

// 获取职位
export const getPosition = (params: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/getPosition',
		method: 'get',
		params,
	})
}

// 设置职位
export const setPosition = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/setPosition',
		method: 'put',
		data,
	})
}

// 设置负责组织
export const setChargeDepart = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/setChargeDept',
		method: 'put',
		data,
	})
}

// 关联所属上级
export const setLeader = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/setLeader',
		method: 'put',
		data,
	})
}

// 离职
export const resign = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/resign',
		method: 'put',
		data,
	})
}

// 工作交接
export const workHandoverApi = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/workHandover',
		method: 'post',
		data,
	})
}

// 导入
export const importApi = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/import',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}

// 导入更新
export const importUpdateApi = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/importEdit',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}

// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/orgEmployeeBaseInfo/getOrgEmployeeBaseInfoList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}

/**切换当前账号的职位 */
export const changeAccountJobPosition = (data: any) => {
	return service({
		url: '/user/changeAccountJobPosition',
		method: 'post',
		data,
	})
}

/**获取职位的数据权限列表 */
export const getByPosition = (params: any) => {
	return service({
		url: '/orgDataAuthorities/getByPosition',
		method: 'get',
		params,
	})
}

