import { reactive, computed, toRefs, watchEffect } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { compact, concat, uniqWith, isEqual } from 'lodash-es'
import type { UseAsyncStateOptions } from '@vueuse/core/index'
import {
	UseTableState,
	ResListProTable,
	Pageable,
	ReqParam,
	UseTableOptions,
	ReqTableList,
	TableApiOptions,
} from './types'

export const useTable = (service: any, options: UseTableOptions<ResultType<ResListProTable>>) => {
	const state = reactive<UseTableState>({
		// api返回的结果
		tableState: null,
		// 分页参数
		pageable: {
			page: 1,
			pageSize: 20,
		},
		// 初始化请求参数，一般不会发生变化
		initParam: {},
		// 搜索参数，跟随筛选组件变化（一般用来初始化筛选参数使用）
		searchParam: {},
		// 动态请求参数，实时变化（外部调用getTableList时传入）
		dynamicParam: {},
		// 已勾选的表格数据
		selectionList: [],
		// 当前表格类型
		tableType: options.tableType,
	})

	// 更新分页信息
	const updatePageable = (pageable: Partial<Pageable>): void => {
		Object.assign(state.pageable, pageable)
	}

	// 更新其他参数
	const updateParam = (proxy: ReqParam, params: ReqParam): void => {
		Object.assign(proxy, params)
	}

	// 总请求参数，根据以上参数实时变化
	const totalParam = computed<ReqParam>(() => {
		// 合并动态筛选条件
		const list = compact(concat(state.initParam?.filters, state.searchParam?.filters, state.dynamicParam?.filters))
		const filters = uniqWith(list, isEqual)
		const params: ReqParam = Object.assign({}, state.pageable, state.initParam, state.searchParam, state.dynamicParam, {
			filters,
		})
		if (!filters?.length) {
			delete params.filters
		}
		return params
	})

	watchEffect(() => {
		if (options?.pageSize) {
			updatePageable({ pageSize: options.pageSize })
		}
		if (options?.initParam) {
			updateParam(state.initParam, options.initParam)
		}
		if (options?.searchParam) {
			updateParam(state.searchParam, options.searchParam)
		}
	})

	/**
	 * 搜索方法
	 * @param arg 动态请求参数
	 * @param tableApiOptions 接口请求类型选项
	 */
	const _service = async (
		arg?: ReqTableList,
		tableApiOptions?: TableApiOptions
	): Promise<ResultType<ResListProTable | null>> => {
		if (!service) return

		// 更新分页参数
		if (arg?.pageable) {
			updatePageable(arg.pageable)
		}

		// 手动点击筛选，更新搜索参数
		if (state.tableType === 'listProTable' && tableApiOptions?.isHandleClickFilter) {
			const filters = arg?.params?.filters ?? []
			updateParam(state.searchParam, { filters })
			delete arg?.params?.filters
		}

		// 更新动态请求参数
		if (arg?.params) {
			// 通过外部调用getTableList时传入的参数，先清空动态参数
			if (state.tableType === 'listProTable' && !tableApiOptions?.isHandleClickFilter) {
				state.dynamicParam = {}
			}
			updateParam(state.dynamicParam, arg?.params)
		}

		return await service(totalParam.value)
	}

	const _options: UseAsyncStateOptions<boolean> = {
		...options,
		onSuccess: (res: ResultType) => {
			if (res && res.code === 0) {
				let data = res.data
				// 更新分页信息
				const { page, pageSize } = data
				updatePageable({ page, pageSize })

				// 数据回调，外部可以处理返回数据
				if (options.dataCallback && typeof options.dataCallback === 'function') {
					const result = options.dataCallback(data)
					result && (data = result)
				}
				state.tableState = data
			}
		},
	}

	const {
		isLoading: tableLoading,
		isReady: tableReady,
		execute: fetchList,
	} = useAsyncState(
		_service,
		{
			code: 0,
			msg: '',
			data: null,
		},
		_options
	)

	// 多选
	const handleSelectionChange = (val: any) => {
		state.selectionList = val
	}

	// 单选
	const handleCurrentChange = (row: any) => {
		state.selectionList = row ? [row] : []
	}

	// 分页
	const handlePageChange = async (val: number) => {
		state.pageable.page = val
		await getTableList()
	}

	// 分页
	const handleSizeChange = async (val: number) => {
		state.pageable.pageSize = val
		await getTableList()
	}

	/**
	 * 获取列表数据
	 * @param params 接口请求参数
	 * @param tableApiOptions 接口请求类型选项
	 */
	const getTableList = async (params?: ReqTableList, tableApiOptions?: TableApiOptions) => {
		await fetchList(0, params, tableApiOptions)
	}

	return {
		...toRefs(state),
		totalParam,
		tableLoading,
		tableReady,
		getTableList,
		handlePageChange,
		handleSizeChange,
		handleSelectionChange,
		handleCurrentChange,
	}
}
