<template>
	<transition name="el-fade-in-linear">
		<div class="wi-table h-full" :class="class">
			<el-table
				ref="tableRef"
				style="width: 100%"
				:data="modelValue"
				:row-key="rowKey"
				:scrollbar-always-on="true"
				v-bind="$attrs"
				@sortChange="sortChange"
				@selectionChange="selectionChange"
				@expandChange="expandChange"
				:show-summary="showSummary"
				:summary-method="summaryMethod"
				:expand-row-keys="expandRowKeys"
			>
				<el-table-column
					v-if="selectFlag && modelValue.length > 0"
					align="center"
					type="selection"
					width="50"
					fixed="left"
				/>
				<template v-for="item in columns" :key="item.prop">
					<el-table-column v-if="item.isShow" v-bind="item" :sortable="item.sortable">
						<template v-if="item.children && item.children.length > 0">
							<el-table-column v-bind="child" v-for="child in item.children" :key="child.id">
								<template #default="{ row, column }" v-if="$slots[child.slotName]">
									<slot
										:name="child.slotName"
										:row="row"
										:column="column"
										:key="child.prop"
										:data="row[column.property]"
										:cellValue="row[child.prop] || '-'"
										:list="modelValue"
										:valType="child.valType"
									>
									</slot>
								</template>
							</el-table-column>
						</template>
						<template #default="{ row, column, $index }" v-if="$slots[item.slotName]">
							<slot
								:name="item.slotName"
								:row="row"
								:column="column"
								:key="item.prop"
								:index="$index"
								:cellValue="row[item.prop] || '-'"
								:list="modelValue"
								:valType="item.valType"
							>
							</slot>
						</template>
						<template #header="{ column, $index }" v-if="$slots[item.prop + ':header']">
							<slot :column="column" :index="$index" :name="item.prop + ':header'"> </slot>
						</template>
					</el-table-column>
				</template>
			</el-table>
		</div>
	</transition>
</template>

<script setup lang="ts">
import { ref, onUpdated } from 'vue'
const props = defineProps({
	modelValue: {
		type: Array,
		default: () => [],
	},
	rowKey: {
		type: String,
		default: 'id',
	},
	height: {
		type: String,
		default: '',
	},
	// 是否显示多选
	selectFlag: {
		type: Boolean,
		default: false,
	},
	// 单选状态
	radioFlag: {
		type: String,
		default: 'block',
	},
	columns: {
		type: Array,
		default: () => {},
	},
	params: {
		type: Object,
		default: () => {},
	},
	request: {
		type: Function,
		default: () => {},
	},
	processData: {
		type: Function,
		default: (data) => {
			return data
		},
	},
	data: {
		type: Array,
		default: () => [],
		required: false,
	},
	dataKey: {
		type: String,
		default: () => '',
	},
	//是否在表尾显示合计行
	showSummary: {
		type: Boolean,
		default: () => false,
	},
	//自定义的合计计算方法
	summaryMethod: {
		type: Function,
		default: () => {},
	},
	expandRowKeys: {
		type: Array,
		default: () => [],
	},
	// 是否显示分页
	showPagination: {
		type: Boolean,
		default: true,
	},
	// 分页参数
	pagination: {
		type: Object,
		default: () => {},
	},
	// 传入的类名
	class: {
		type: Object || String || Array,
		default: '',
	},
})

const check = props.radioFlag // 单选状态
const emit = defineEmits(['update:data', 'sortChange', 'selectionChange', 'expandChange', 'pageChange'])
const theadStatus = ref('hidden')
onUpdated(() => {
	if (props.modelValue?.length > 0) {
		theadStatus.value = 'block'
	} else {
		theadStatus.value = 'hidden'
	}
})
const tableRef = ref()
// 排序
function sortChange(column, prop, order) {
	emit('sortChange', column, prop, order)
}
// 多选
const multipleSelection = ref([])
function selectionChange(multiple, row) {
	if (props.radioFlag == 'hidden') {
		// 表格单选
		if (multiple.length > 1) {
			let del_row = multiple.shift()
			tableRef.value.toggleRowSelection(del_row, false) // 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
			multipleSelection.value = multiple[multiple.length - 1]
		} else if (multiple.length === 1) {
			multipleSelection.value = multiple[0]
		}
	} else {
		// 表格多选
		multipleSelection.value = multiple
	}
	emit('selectionChange', multipleSelection)
}
const expandChange = (row, rowList) => {
	emit('expandChange', row, rowList)
}
function reset() {}

// function update() {}
// 分页多选保持 multipleTableRef.value!.clearSelection() 清空

async function fetchData() {}

// 当前页码发生变化
// const handlePageChange = (val) => {
// 	emit('pageChange', val)
// }

defineExpose({
	tableRef,
	reset,
	fetchData,
})
</script>

<style lang="scss" scoped>
.wi-table {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow-y: hidden;

	:deep(.el-table) {
		flex: 1;
		overflow-y: hidden;

		th .cell,
		td.cell {
			line-height: 20px;
			min-height: 20px;
		}
	}

	:deep(.el-table--border) {
		border-radius: unset;
		margin: 0;
	}

	:deep(.el-checkbox__inner) {
		width: 20px;
		height: 20px;
	}

	:deep(.el-checkbox__inner::after) {
		position: absolute;
		top: 20%;
		left: 40%;
	}

	:deep(.el-table__body) {
		border-collapse: separate;
	}

	:deep(thead) {
		tr {
			.el-table__cell {
				color: #333;
				font-weight: normal;
				background: #eef1f6;
			}
		}
	}

	:deep(.el-table__empty-block) {
		overflow-y: auto;
		min-height: 350px;

		.el-table__row {
			height: 88px;

			&.hover-row {
				position: relative;
				z-index: 11;
				overflow: hidden;
				box-sizing: border-box;
			}
		}
	}

	:deep(.el-scrollbar__bar.is-horizontal) {
		height: 10px;
		z-index: 9999;
	}

	:deep(.el-scrollbar__bar.is-vertical) {
		width: 10px;
		z-index: 9999;
	}
}

:deep(.caret-wrapper) {
	height: 18px !important;
}

// 隐藏全选按钮
:deep(.el-table th.el-table__cell:nth-child(1) .cell) {
	visibility: v-bind(check);
}
</style>
<style>
.admin-box .el-table thead {
	visibility: v-bind(theadStatus.value);
}
</style>

