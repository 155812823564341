<template>
	<div>
		<div v-if="renderButtonList.length > 0 || isApproval" class="flow-operation" v-bind="$attrs">
			<div class="flex justify-between">
				<div>
					<el-button
						v-for="button in renderButtonList"
						:key="button.value"
						:icon="button.icon"
						@click="handleClick(button.value)"
						>{{ button.label }}
					</el-button>
				</div>
				<approve
					v-if="isApproval"
					:code="currentFlowCode"
					:isRequiredField="isRequiredField"
					:agreeNeed="flowData.approvalAgreeNeedComment === 1"
					:rejectNeed="flowData.approvalRejectNeedComment === 1"
					:drawerKey="drawerKey"
					:onConfirm="onConfirm"
					@refresh="handleRefresh"
				/>
			</div>
		</div>

		<el-dialog
			v-model="dialogVisible"
			:title="currentButton?.label"
			width="30%"
			:close-on-click-modal="false"
			append-to-body
		>
			<component
				v-if="dialogVisible"
				:is="currentButton.comp"
				:code="currentFlowCode"
				:currentApprovers="flowData.currentApprovers"
				:userInfo="userInfo"
				@close="dialogVisible = false"
				@refresh="handleRefresh"
			/>
		</el-dialog>
	</div>
</template>

<script setup lang="ts">
import { isRef, ref, computed } from 'vue'
import { Switch, ChatSquare, User, Edit, RefreshLeft, SetUp } from '@element-plus/icons-vue'
import { storeToRefs } from 'pinia'
import { useDrawer } from '@/pinia/modules/drawer'
import { useUserStore } from '@/pinia/modules/user'
import { emitter, MittType } from '@/utils/bus'
import { getStrategy } from '@/utils/workflow'

import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { ApplyType } from '@/hooks/usePageDrawer/types'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import { ApprovalStatus } from '@/view/workflow/types'
import { InitFlowData, type FlowDataType, type FlowChatType, OperateType, OperateTypeText } from './types'
import { ApprovalFieldType } from '@/view/workflow/workflowConfig/interface'

import transfer from './dialog/transfer.vue' // 转批
import copyTo from './dialog/copyTo.vue' // 抄送
import interact from './dialog/interact.vue' // 沟通
import withdraw from './dialog/withdraw.vue' // 撤回
import addSign from './dialog/addSign.vue' // 加签
import approve from './approve/index.vue' // 审批

interface Props {
	flowCode?: string
	flowData?: FlowDataType
	drawerKey?: any
	onConfirm?: () => any
}

interface BtnType {
	label: string
	value: OperateType
	icon: Component
	comp?: Component
}

const props = defineProps<Props>()

const { userInfo } = useUserStore() // 当前用户
const drawerStore = useDrawer()
const { drawerMap } = storeToRefs(drawerStore)
const { drawerOpen, drawerClose } = usePageDrawer()

/**
 * web端没有撤回的模块
 * @param STOREINSPECT 门店检查
 * @param ASSETSINSPECT 资产巡检
 * @param MISSEDVISIT 失访原因
 * @param ACTIVITYAPPLYFOR 活动审批
 * @param EXECUTEAPPROVAL 执行审批
 * @param RECTIFICATIONAPPROVAL 整改审批
 */
const notWithdrawPage = [
	DrawerType.STOREINSPECT,
	DrawerType.ASSETSINSPECT,
	DrawerType.MISSEDVISIT,
	DrawerType.ACTIVITYAPPLYFOR,
	DrawerType.EXECUTEAPPROVAL,
	DrawerType.RECTIFICATIONAPPROVAL,
]

const buttonList: BtnType[] = [
	{ label: OperateTypeText[OperateType.TRANSFER], value: OperateType.TRANSFER, icon: Switch, comp: transfer },
	{ label: OperateTypeText[OperateType.INTERACT], value: OperateType.INTERACT, icon: ChatSquare, comp: interact },
	{ label: OperateTypeText[OperateType.COPYTO], value: OperateType.COPYTO, icon: User, comp: copyTo },
	{ label: OperateTypeText[OperateType.EDIT], value: OperateType.EDIT, icon: Edit },
	{ label: OperateTypeText[OperateType.WITHDRAW], value: OperateType.WITHDRAW, icon: RefreshLeft, comp: withdraw },
	{ label: OperateTypeText[OperateType.ADDSIGN], value: OperateType.ADDSIGN, icon: SetUp, comp: addSign },
]

const flowData = ref<FlowDataType>(props.flowData ?? new InitFlowData())
const dialogVisible = ref(false)
const currentButton = ref()

emitter.on(MittType.WorkflowData, (res: { module: string; data: FlowDataType }) => {
	res.module === props.drawerKey && (flowData.value = res.data)
})

/**
 * 当前审批编码
 */
const currentFlowCode = computed(() => {
	return props.flowCode || flowData.value?.approvalRequestCode
})

/**
 * 是否发起人以及
 * 状态是否为【待审批、审批中】
 */
const isRequestUser = computed(() => {
	return (
		flowData.value?.approvalRequester === userInfo.ID &&
		[ApprovalStatus.APPROVALWAIT, ApprovalStatus.APPROVALING].includes(flowData.value?.approvalStatus)
	)
})

/**
 * 是否审批人以及
 * 当前审批人是否已审批
 */
const isApproval = computed(() => {
	const currentNode = getCurrentNode(flowData.value?.approvalFlow, flowData.value?.currentApprovalNodeID)
	if (currentNode?.approvers) {
		// 判断当前用户是否已审批 true: 是，false: 否
		const myApproved = !!currentNode.approvers.find(
			(item) => item.userId === userInfo.ID && item.approvalStatus === ApprovalStatus.AGREE
		)
		return flowData.value?.currentApprovers.includes(userInfo.ID) && !myApproved
	}
	return flowData.value?.currentApprovers.includes(userInfo.ID)
})

/**
 * 是否可编辑（需要在各自buttonList中配置apply属性）
 * 以及需要在审批步骤中勾选编辑字段
 */
const isEdit = computed(() => {
	const buttonList = drawerMap.value?.get(props.drawerKey)?.buttonList ?? []
	const buttons = isRef(buttonList) ? buttonList.value : buttonList
	const editItem = buttons.find((item) => item.type === OpeationBtnType.EDIT)
	return editItem?.apply?.includes(ApplyType.FLOW) || editItem?.apply?.includes(ApplyType.ALL)
})

/**
 * 是否有编辑字段配置
 */
const isEditFieldConfig = computed(() => {
	const list = flowData.value?.approvalFieldConfigs?.filter((item) => item.editAuth === 1 || item.requiredAuth === 1)
	return !!list.length
})

/**
 * 编辑配置是否有必填字段
 */
const isRequiredField = computed(() => {
	console.log(`output->flowData.value?.approvalFieldConfigs`, flowData.value?.approvalFieldConfigs)
	const list = flowData.value?.approvalFieldConfigs?.filter((item) => item.requiredAuth === 1)
	return list?.length > 0
})

// 审批操作按钮
const renderButtonList = computed(() => {
	const strategyList: number[] = getStrategy(flowData.value?.strategy) ?? []
	const isConfigWithdraw: boolean = strategyList[1] === 1 // 是否配置【允许发起人撤回审批】
	const isConfigTransfer: boolean = strategyList[2] === 1 // 是否配置【流程审批过程中允许审批人转批】

	return buttonList.filter((item) => {
		if ([OperateType.TRANSFER].includes(item.value)) {
			return isApproval.value && isConfigTransfer
		}
		if ([OperateType.COPYTO].includes(item.value)) {
			return isApproval.value
		}
		if ([OperateType.EDIT].includes(item.value)) {
			return isApproval.value && isEdit.value && isEditFieldConfig.value
		}
		if ([OperateType.INTERACT].includes(item.value)) {
			return isApproval.value || isRequestUser.value || flowData.value?.msgNotifyUserIdList?.includes(userInfo.ID)
		}
		if ([OperateType.WITHDRAW].includes(item.value)) {
			return isRequestUser.value && isConfigWithdraw && !notWithdrawPage.includes(props.drawerKey)
		}
		if ([OperateType.ADDSIGN].includes(item.value)) {
			return isApproval.value && flowData.value?.addSign === 0 && !flowData.value?.isAddSignNode
		}
	})
})

// 获取当前审批节点
const getCurrentNode = (data: FlowChatType, nodeId: string) => {
	if (!data) {
		return null
	}
	if (data?.nodeId === nodeId) {
		return data
	} else if (data.conditions?.length > 0) {
		let findNode: FlowChatType = null
		for (let i = 0; i < data.conditions.length; i++) {
			const value = getCurrentNode(data.conditions[i], nodeId)
			if (value) {
				findNode = value
				break
			}
		}
		return findNode
	} else if (data.child) {
		return getCurrentNode(data.child, nodeId)
	}
}

// 处理后端返回的字段配置，返回每个字段的可编辑以及禁用状态
const getFieldAuth = (fieldConfigs: Array<ApprovalFieldType>, type: OpeationBtnType) => {
	if (type !== OpeationBtnType.EDIT) {
		return {}
	}

	const flowField: { [key: string]: Record<'edit' | 'disabled' | 'required', boolean> } = {}
	fieldConfigs?.forEach((item) => {
		flowField[item.fieldName] = {
			edit: item.editAuth === 1 ? true : false,
			disabled: item.editAuth === 1 ? false : true,
			required: item.requiredAuth === 1 ? true : false,
		}
	})

	return flowField
}

// 点击按钮
const handleClick = (type: OperateType) => {
	currentButton.value = buttonList.find((item) => item.value === type)
	switch (type) {
		case OperateType.TRANSFER:
		case OperateType.COPYTO:
		case OperateType.INTERACT:
		case OperateType.WITHDRAW:
		case OperateType.ADDSIGN:
			dialogVisible.value = true
			break
		case OperateType.EDIT:
			const mapValue = drawerMap.value?.get(props.drawerKey)
			const editItem = mapValue.buttonList?.find((item) => item.type === OpeationBtnType.EDIT)
			if (editItem) {
				// 处理字段配置
				const flowFieldProp = getFieldAuth(flowData.value.approvalFieldConfigs, OpeationBtnType.EDIT) ?? {}
				drawerOpen(props.drawerKey + OpeationBtnType.EDIT, {
					title: editItem.label,
					...editItem,
					props: {
						...editItem.props,
						...mapValue.props,
						flowFieldProp,
						flowData: flowData.value,
					},
					isDialog: true,
				})
			}
			break
		default:
	}
}

/**
 * @function 刷新
 * @description 审批通过或驳回、以及转批需要刷新列表, 其他所有审批操作刷新抽屉
 * @param refreshList 是否刷新列表
 */
const handleRefresh = (refreshList?: boolean) => {
	// 刷新列表
	if (props.drawerKey && refreshList) {
		drawerClose(true, props.drawerKey)
	} else {
		// 刷新抽屉
		emitter.emit(MittType.RefreshFlowRecord, true)
	}
}
</script>

<style lang="scss" scoped>
.flow-operation {
	padding: 16px;
	border-top: 1px solid theme('colors.line.1');
}
</style>
