import service from '@/utils/request'

/** 分页获取职位列表 */
export const getOrgPositionList = (data: any) => {
	return service({
		url: '/orgPosition/getOrgPositionList',
		method: 'post',
		data,
	})
}

/** 获取所有职位名称 */
export const getOrgPositionNameList = (params?: any) => {
	return service({
		url: '/orgPosition/getOrgPositionNameList',
		method: 'get',
		params,
	})
}

/** 创建职位 */
export const createOrgPosition = (data: any) => {
	return service({
		url: '/orgPosition/createOrgPosition',
		method: 'post',
		data,
	})
}

/** 批量删除职位 */
export const deleteOrgPositionByIds = (data: any) => {
	return service({
		url: '/orgPosition/deleteOrgPositionByIds',
		method: 'delete',
		data,
	})
}

/** 停用职位 */
export const disabledStatus = (data: any) => {
	return service({
		url: '/orgPosition/disabledStatus',
		method: 'put',
		data,
	})
}

/** 启用职位 */
export const enableStatus = (data: any) => {
	return service({
		url: '/orgPosition/enableStatus',
		method: 'put',
		data,
	})
}

/** 根据职位id查询职位信息 */
export const getOrgPositionInfo = (params: any) => {
	return service({
		url: '/orgPosition/getOrgPositionInfo',
		method: 'get',
		params,
	})
}
/** 更新职位 */
export const updateOrgPosition = (data: any) => {
	return service({
		url: '/orgPosition/updateOrgPosition',
		method: 'put',
		data,
	})
}

/** 根据职位id查询职位权限 */
export const getOrgPositionPermissions = (params: any) => {
	return service({
		url: '/orgPosition/getOrgPositionPermissions',
		method: 'get',
		params,
	})
}

/** 查询职位权限配置信息 */
export const getOrgPositionPermissionContent = (params?: any) => {
	return service({
		url: '/orgPosition/getOrgPositionPermissionContent',
		method: 'get',
		params,
	})
}

/** 获取职位下的所有用户ID */
export const getPositionUserID = (data: any) => {
	return service({
		url: '/orgPosition/getPositionUserID',
		method: 'post',
		data,
	})
}

/** 设置应用组织 */
export const setApplyDepartment = (data: any) => {
	return service({
		url: '/orgPosition/setApplyDepartment',
		method: 'put',
		data,
	})
}

/** 获取应用组织 */
export const getApplyDepartment = (params: any) => {
	return service({
		url: '/orgPosition/getApplyDepartment',
		method: 'get',
		params,
	})
}
