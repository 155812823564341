<template>
	<el-select
		v-model="values"
		:multiple="multiple"
		:placeholder="placeholder"
		style="width: 100%"
		@change="handleChange"
		collapse-tags
		collapse-tags-tooltip
		filterable
		v-bind="$attrs"
	>
		<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script setup lang="ts">
import { getBaseDistributorList, getCusDistributorList } from '@/api/serveApi/basicInformation/dealerMgr'
import { onMounted, ref, watch } from 'vue'
const emit = defineEmits(['update:modelValue', 'change'])
const props = defineProps({
	modelValue: {
		type: [Array<Number>, Number, null],
		required: true,
	},
	multiple: {
		type: Boolean,
		default: true,
	},
	placeholder: {
		type: String,
		default: '请选择客户',
	},
	remote: {
		type: Boolean,
		default: true,
	},
	data: {
		type: Array,
		default: () => [],
	},
	areaId: {
		type: Number,
		default: 0,
	},
	channelType: {
		type: Number,
		default: 0,
	},
})
const options = ref([])
const filters = ref([])
const values = ref<Number[] | number | null>()
const filtersData = ref([])
watch(
	() => props.data,
	(newVal) => {
		if (newVal.length > 0 && options.value.length === 0) {
			options.value = props.data
		}
	},
	{ deep: true }
)
watch(
	() => props.areaId,
	(newVal) => {
		if (newVal) {
			getBaseDistributor()
		}
	},
	{ deep: true }
)
watch(
	() => props.channelType,
	(newVal) => {
		if (newVal) {
			if (!props.areaId) {
				getButorList()
			}
		}
	},
	{ deep: true }
)
onMounted(() => {
	if (props.remote) {
		getButorList()
	} else {
		options.value = props.data
	}
})

//监听赋值操作
watch(
	() => props.modelValue,
	() => {
		values.value = props.modelValue
	},
	{
		deep: true,
		immediate: true,
	}
)
//根据销售区域向下找到渠道客户
const getBaseDistributor = async () => {
	let data = []
	console.log(props.areaId, 'props.areaId')

	if (props.areaId) {
		switch (props.channelType) {
			case 0:
				data = []
				break
			case 1:
				data = [
					{ fieldName: 'saleAreaIds', fieldValues: [`${props.areaId}`], operator: 'IN', fromType: 'select' },
					{ fieldName: 'channel', fieldValues: ['1'], operator: 'IN', fromType: 'select' },
				]
				break
			case 2:
				data = [
					{ fieldName: 'saleAreaIds', fieldValues: [`${props.areaId}`], operator: 'IN', fromType: 'select' },
					{ fieldName: 'channel', fieldValues: ['2', '3'], operator: 'IN', fromType: 'select' },
				]
				break
		}
	}
	console.log(data, 'data')
	filters.value = data
	const res = await getCusDistributorList({ filters: filters.value })
	options.value = res.data.list
}
//获取经销商
const getButorList = async () => {
	switch (props.channelType) {
		case 0:
			filtersData.value = []
			break
		case 1:
			filtersData.value = [
				{
					fieldName: 'channel',
					fieldValues: ['1'],
					operator: 'IN',
					fromType: 'select',
				},
				{
					fieldName: 'status',
					fieldValues: ['1'],
					operator: 'IN',
					fromType: 'select',
				},
			]
			break
		case 2:
			filtersData.value = [{ fieldName: 'channel', fieldValues: ['2', '3'], operator: 'IN', fromType: 'select' }]
			break
	}
	const res = await getBaseDistributorList({ filters: filtersData.value })
	options.value = res.data.list
}

const handleChange = () => {
	console.log(`output->change`, values.value)
	emit('update:modelValue', values.value)
	emit('change', values.value)
}
</script>

<style></style>
