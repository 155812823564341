import service from '@/utils/request'

//分页获取冰箱类型列表
export const getRefFridgeTypeList = (data: any) => {
	return service({
		url: '/refFridgeType/getRefFridgeTypeList',
		method: 'post',
		data,
	})
}

//查询所有冰箱类型
export const getAllRefFridgeType = (params?: any) => {
	return service({
		url: '/refFridgeType/getAllRefFridgeType',
		method: 'get',
		params,
	})
}

//创建RefFridgeType
export const createRefFridgeType = (data: any) => {
	return service({
		url: '/refFridgeType/createRefFridgeType',
		method: 'post',
		data,
	})
}

//删除冰箱类型
export const deleteRefFridgeType = (data: any) => {
	return service({
		url: '/refFridgeType/deleteRefFridgeType',
		method: 'delete',
		data,
	})
}

//批量删除冰箱类型
export const deleteRefFridgeTypeByIds = (data: any) => {
	return service({
		url: '/refFridgeType/deleteRefFridgeTypeByIds',
		method: 'delete',
		data,
	})
}

//停用冰箱类型
export const disabledStatus = (data: any) => {
	return service({
		url: '/refFridgeType/disabledStatus',
		method: 'put',
		data,
	})
}

//启用冰箱类型
export const enableStatus = (data: any) => {
	return service({
		url: '/refFridgeType/enableStatus',
		method: 'put',
		data,
	})
}

//用id查询冰箱类型
export const findRefFridgeType = (params: any) => {
	return service({
		url: '/refFridgeType/findRefFridgeType',
		method: 'get',
		params,
	})
}

//更新冰箱类型
export const updateRefFridgeType = (data: any) => {
	return service({
		url: '/refFridgeType/updateRefFridgeType',
		method: 'put',
		data,
	})
}

//文件导出
export const exportFile = (data: any) => {
	return service({
		url: '/refFridgeType/getRefFridgeTypeList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
