<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'imagePreview',
})
</script>
<template>
	<el-image
		class="w-full"
		:class="{ 'cursor-pointer': disabled }"
		:src="src"
		fit="cover"
		@click="emit('click')"
		loading="lazy"
	>
		<template #placeholder>
			<div class="image-slot w-full h-full flex justify-center items-center cursor-default">
				<el-icon :size="22" color="#d0d0d0">
					<Loading />
				</el-icon>
			</div>
		</template>
		<template #error>
			<div class="image-slot w-full h-full flex justify-center items-center cursor-default">
				<el-icon :size="22" color="#d0d0d0">
					<Picture />
				</el-icon>
			</div>
		</template>
	</el-image>
</template>

<script setup lang="ts" name="imagePreview">
import { Loading, Picture } from '@element-plus/icons-vue'

defineProps<{
	src?: string
	disabled?: boolean
}>()
const emit = defineEmits(['click'])
</script>

<style scoped></style>
