<template>
	<div class="xzl-tooltip-box">
		<el-tooltip :disabled="!showTooltips" v-bind="$attrs" effect="dark" :placement="placement">
			<template #content>
				<div class="tooltip__tip" :style="{ maxWidth: maxWidth + 'px' }">{{ content }}</div>
			</template>
			<div class="cursor-pointer w-full" :style="ellipsisStyle" @mouseenter="enterEvents">
				<slot>
					{{ content }}
				</slot>
			</div>
			<!-- <slot :enterEvents="enterEvents">
				<div class="cursor-pointer w-full " :style="ellipsisStyle"  @mouseenter="enterEvents">{{ content }}</div>
			</slot> -->
		</el-tooltip>
	</div>
</template>
<script setup lang="ts">
import { ref, useSlots, useAttrs, h, computed } from 'vue'

const slots = useSlots()
const attrs = useAttrs()
const props = defineProps({
	content: {
		type: String,
		default: '',
	},
	maxWidth: {
		type: [Number, String],
		default: 500,
	},
	placement: {
		type: String,
		default: 'top',
	},
	isHideEllipsis: {
		type: Boolean,
		default: false,
	},
	style: {
		type: Object,
		default: () => {},
	},
	//最大行数
	lineClamp: {
		type: Number,
		default: 1,
	},
})
let showTooltips = ref(false)
const ellipsisStyle = computed(() => {
	if (props.lineClamp === 1) {
		return {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			...props.style,
		}
	} else {
		return {
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: props.lineClamp,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			...props.style,
		}
	}
})
const enterEvents = (e) => {
	let { width: tableContentBoxWidth, height: tableContentBoxHeight } = e.target.getBoundingClientRect()
	let { width: tableContentWidth, height: tableContentHeight } = getElementTextWidth(e.target)
	// console.log(tableContentBoxWidth,'tableContentBoxWidth')
	if (
		Number(tableContentWidth) - Number(tableContentBoxWidth) > 1 ||
		Number(tableContentHeight) - Number(tableContentBoxHeight) > 1
	) {
		showTooltips.value = true
		// console.log('计算结果：存在省略号')
	} else {
		showTooltips.value = false
		// console.log('计算结果：容器宽度足够，没有省略号了')
	}
}
const getElementTextWidth = (el) => {
	const range = new Range()
	range.selectNodeContents(el)
	const { width, height } = range.getBoundingClientRect()
	return { width, height }
}
</script>
<style lang="scss" scoped>
.xzl-tooltip-box {
	min-width: 16px;
}
.xzl-tooltip-box:active {
	animation-play-state: running;
}
.tooltip__tip {
	max-height: 300px;
	overflow-y: auto;
	white-space: pre-line;
}
.tooltip__tip::-webkit-scrollbar-thumb {
	background: #ccc; // 滑块颜色
	border-radius: 3px; // 滑块圆角
}

.tooltip__tip::-webkit-scrollbar-thumb:hover {
	background: #fff; // 鼠标移入滑块颜色
}

.tooltip__tip::-webkit-scrollbar-track {
	border-radius: 3px; // 轨道圆角
	background-color: #888; // 轨道颜色 ;
}
</style>
