<template>
	<el-popover :placement="placement" :width="popoverWidth" :popper-style="{ padding: '8px' }" trigger="hover">
		<template #reference>
			<div class="avatar-box" @mouseover.self="handleMouseOver">
				<AvatarBox :list="overflowList" :size="avatarSize" />
			</div>
		</template>

		<div v-bind="containerProps" :style="{ maxHeight: maxHeight + 'px' }">
			<div v-bind="wrapperProps">
				<div v-for="list in renderList" :key="list.index" class="grid grid-cols-3 gap-4 p-[8px]">
					<NodeBox
						v-for="(item, index) in list.data"
						:key="index"
						:class="[`col-span-${3 / rowLength}`]"
						:userInfo="item"
						:statusName="getApprovalStatus(item, nodeData?.nodeType)"
					/>
				</div>
			</div>
		</div>
	</el-popover>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Placement } from 'element-plus'
import { useVirtualList } from '@vueuse/core'
import { chunk } from 'lodash-es'
import { NodeType } from '@/view/workflow/workflowConfig/interface'
import { ApprovalStatus, ApprovalStatusText } from '@/view/workflow/types'
import { FlowChatType, ApprovalUserType } from '../types'
import AvatarBox from './avatarBox.vue'
import NodeBox from './nodeBox.vue'

interface Props {
	type: 'approval' | 'copy'
	overflowList: ApprovalUserType[]
	nodeData?: FlowChatType
	isApproved?: boolean
	currentNodeId?: string
}

const props = withDefaults(defineProps<Props>(), {
	type: 'approval',
})

const placement = ref<Placement>('top')
const popoverWidth = ref<number>(0)
const maxHeight = ref<number | null>(null)

const avatarSize: number = 36 // 头像尺寸
const nodeBoxWidth: number = 140

// 超出时每行显示个数
const rowLength = computed(() => {
	if (props.overflowList.length > 3) {
		return 3
	}
	return 1
})

const dataList = computed(() => {
	return chunk(props.overflowList, rowLength.value)
})

const itemHeight = computed(() => {
	if (props.type === 'approval') {
		if (typeof props.isApproved === 'boolean') {
			return !props.isApproved && props.currentNodeId === props.nodeData.nodeId ? 89 : 68
		}
		return 68
	}
	return 68
})

const {
	list: renderList,
	containerProps,
	wrapperProps,
} = useVirtualList(dataList, {
	// Keep `itemHeight` in sync with the item's row.
	itemHeight: itemHeight.value,
})

// 超出个数时样式控制
const handleMouseOver = (event) => {
	const clientHeight = document.documentElement.clientHeight
	const target = event.target as HTMLElement
	if (event.clientY - target.clientHeight < clientHeight / 2) {
		placement.value = 'bottom'
		maxHeight.value = clientHeight - event.clientY + event.offsetY - target.clientHeight - 52
	} else {
		placement.value = 'top'
		maxHeight.value = event.clientY - event.offsetY - target.clientHeight
	}
	// 计算popover宽度
	popoverWidth.value = nodeBoxWidth * rowLength.value + 16 * (rowLength.value + 1)
}

const getApprovalStatus = (user: ApprovalUserType, nodeType: NodeType) => {
	if (props.type !== 'approval') return ''

	// 判断当前节点是否审批完成 true: 审批完成，false: 审批未完成
	if (props.nodeData.nodeId === props.currentNodeId || props.isApproved) {
		let statusName: string
		switch (user.approvalStatus) {
			case ApprovalStatus.WAITCOMMIT:
			case ApprovalStatus.APPROVALWAIT:
			case ApprovalStatus.REJECT:
			case ApprovalStatus.WITHDRAWED:
				statusName = ApprovalStatusText[user.approvalStatus]
				break
			case ApprovalStatus.APPROVALING:
				if (props.isApproved) {
					statusName = ''
				} else {
					statusName = ApprovalStatusText[user.approvalStatus]
				}
				break
			case ApprovalStatus.AGREE:
				if (nodeType === NodeType.START) {
					statusName = '提交审批'
				} else {
					statusName = ApprovalStatusText[user.approvalStatus]
				}
				break
			default:
		}
		return statusName
	}
	return ''
}
</script>

<style lang="scss" scoped>
$nodeBoxWidth: 140px;

.avatar-box {
	display: flex;
	width: $nodeBoxWidth;
	padding: 8px;
	box-shadow: 0px 1px 8px 0px #e1e7f4;
	border-radius: 8px;
	background-color: #fff;
	cursor: pointer;
}
</style>
