<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TableColumn',
})
</script>
<template>
	<RenderColumn v-bind="props.column" />
</template>

<script setup lang="tsx" name="TableColumn">
import { useSlots } from 'vue'
import { TableColumnProps, HeaderRenderScope, RenderScope, FieldWidthValue } from './types'

interface Props {
	column: TableColumnProps
}

const props = defineProps<Props>()
const slots = useSlots()

const RenderColumn = (item: TableColumnProps) => {
	return (
		<>
			{
				<el-table-column
					fixed={typeof item.isLock === 'boolean' ? item.isLock : undefined}
					{...item}
					minWidth={item.widthType ? FieldWidthValue[item.widthType] : item.minWidth}
				>
					{{
						default: (scope: RenderScope<any>) => {
							{
								/* 优先级 _children(多级表头) > render函数 > slots插槽 > prop渲染数据 */
							}
							if (item._children) return item._children?.map((child) => RenderColumn(child))
							if (item.render) return item.render(scope)
							if (slots[item.prop]) return slots[item.prop]!(scope)
							return scope.row[item.prop]
						},
						header: (scope: HeaderRenderScope<any>) => {
							{
								/* 优先级 render函数 > slots插槽 > label */
							}
							if (item.headerRender) return item.headerRender(scope)
							if (slots[`${item.prop}:header`]) return slots[`${item.prop}:header`]!(scope)
							return item.label
						},
					}}
				</el-table-column>
			}
		</>
	)
}
</script>
