import service from '@/utils/request'

//分页获取广告贴画列表
export const getRefFridgeAdList = (data: any) => {
	return service({
		url: '/refFridgeAd/getRefFridgeAdList',
		method: 'post',
		data,
	})
}

//获取所有广告贴画
export const getAllRefFridgeAd = (params?: any) => {
	return service({
		url: '/refFridgeAd/getAllRefFridgeAd',
		method: 'get',
		params,
	})
}

//创建RefFridgeAd
export const createRefFridgeAd = (data: any) => {
	return service({
		url: '/refFridgeAd/createRefFridgeAd',
		method: 'post',
		data,
	})
}

//删除广告贴画
export const deleteRefFridgeAd = (data: any) => {
	return service({
		url: '/refFridgeAd/deleteRefFridgeAd',
		method: 'delete',
		data,
	})
}

//批量删除广告贴画
export const deleteRefFridgeAdByIds = (data: any) => {
	return service({
		url: '/refFridgeAd/deleteRefFridgeAdByIds',
		method: 'delete',
		data,
	})
}

//停用广告贴画
export const disabledStatus = (data: any) => {
	return service({
		url: '/refFridgeAd/disabledStatus',
		method: 'put',
		data,
	})
}

//启用广告贴画
export const enableStatus = (data: any) => {
	return service({
		url: '/refFridgeAd/enableStatus',
		method: 'put',
		data,
	})
}

//用id查询广告贴画
export const findRefFridgeAd = (params: any) => {
	return service({
		url: '/refFridgeAd/findRefFridgeAd',
		method: 'get',
		params,
	})
}

//更新广告贴画
export const updateRefFridgeAd = (data: any) => {
	return service({
		url: '/refFridgeAd/updateRefFridgeAd',
		method: 'put',
		data,
	})
}

//文件导出
export const exportFile = (data: any) => {
	return service({
		url: '/refFridgeAd/getRefFridgeAdList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
