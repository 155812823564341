// 页面导出为pdf格式 //title表示为下载的标题，html表示document.querySelector('#myPrintHtml')
// import html2canvas from 'html2canvas'
// import { jsPDF } from 'jspdf'

/**选择输出pdf内容方式 */
const outPutFileType = (pdf, resolve, { title = '', isReture = false }) => {
	if (isReture) {
		resolve(
			pdf.output('arraybuffer', {
				type: 'application/pdf',
			})
		)
		return
	}
	pdf.save(title + '.pdf')
	resolve()
}

/**
 * @description 将html文件转成pdf，
 */
const htmlPdf = {
	getPdf: async (title, html, isReture = false) => {
		// 按需加载而不是代码拆分
		const html2canvas = (await import('html2canvas')).default
		const { jsPDF } = await import('jspdf')
		return new Promise((resolve, reject) => {
			// 创建一个虚拟的 DOM 元素以便操作
			const parser = new DOMParser()
			const doc = parser.parseFromString(html, 'text/html')
			// 获取所有拥有 mceEditableBorder 类名的元素
			// const editableElements = doc.documentElement.outerHTML

			let editableElements = doc.querySelector('html')
			editableElements.style.width = '752px' // 设置为752像素宽度，你可以根据需要更改这个值

			const tableList = editableElements.querySelectorAll('table')
			// 解决表格渲染后行高问题
			tableList.forEach((item) => {
				item.style.lineHeight = '2'
			})
			editableElements = editableElements.outerHTML

			// 节点需要有具体落地位置（使用iframe）
			const iframe = document.createElement('iframe')
			iframe.id = 'ediorIframe'
			document.body.appendChild(iframe)
			iframe.contentWindow.document.open()
			iframe.contentWindow.document.write(editableElements)
			iframe.contentWindow.document.close()

			console.log(`output->iframe`, iframe, document.body)
			// 开始canvas 绘画成图片
			html2canvas(iframe.contentWindow.document.body, {
				allowTaint: true,
				useCORS: true,
				dpi: window.devicePixelRatio * 4, // 将分辨率提高到特定的DPI 提高四倍
				background: '#FFFFFF',
				onclone: (document) => {
					// 本地html转pdf时，删除分页符
					document.querySelectorAll('.mce-pagebreak').forEach((el) => {
						el.remove() // 或者 el.parentNode.removeChild(el);
					})
				},
			})
				.then((canvas) => {
					console.log(`output->canvas`, canvas)
					//未生成pdf的html页面高度
					var leftHeight = canvas.height
					// 426尺寸是调试冰箱协议带表格样式后确定的
					var a4Width = 426
					var a4Height = 612 // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277 //一页pdf显示html页面生成的canvas高度;
					var a4HeightRef = Math.floor((canvas.width / a4Width) * a4Height) //pdf页面偏移

					var position = 0

					var pageData = canvas.toDataURL('image/jpeg', 1.0)
					var pdf = new jsPDF('p', 'px', 'a4') //A4纸，纵向
					var index = 1,
						canvas1 = document.createElement('canvas'),
						height
					pdf.setDisplayMode('fullwidth', 'continuous', 'FullScreen')

					function createImpl(canvas) {
						console.log(leftHeight, a4HeightRef)
						if (leftHeight > 0) {
							index++

							var checkCount = 0
							if (leftHeight > a4HeightRef) {
								var i = position + a4HeightRef
								for (i = position + a4HeightRef; i >= position; i--) {
									var isWrite = true
									for (var j = 0; j < canvas.width; j++) {
										// willReadFrequently 属性用于提示浏览器，该上下文将会频繁调用 getImageData 或类似方法，从而允许浏览器对性能进行优化。
										var c = canvas.getContext('2d', { willReadFrequently: true }).getImageData(j, i, 1, 1).data

										if (c[0] != 0xff || c[1] != 0xff || c[2] != 0xff) {
											isWrite = false
											break
										}
									}
									if (isWrite) {
										checkCount++
										if (checkCount >= 10) {
											break
										}
									} else {
										checkCount = 0
									}
								}
								height = Math.round(i - position) || Math.min(leftHeight, a4HeightRef)
								if (height <= 0) {
									height = a4HeightRef
								}
							} else {
								height = leftHeight
							}

							canvas1.width = canvas.width
							canvas1.height = height

							var ctx = canvas1.getContext('2d', { willReadFrequently: true })
							ctx.drawImage(canvas, 0, position, canvas.width, height, 0, 0, canvas.width, height)

							if (position != 0) {
								pdf.addPage()
							}
							pdf.addImage(
								canvas1.toDataURL('image/jpeg', 1.0),
								'JPEG',
								10,
								10,
								a4Width,
								(a4Width / canvas1.width) * height
							)
							leftHeight -= height
							position += height
							if (leftHeight > 0) {
								setTimeout(createImpl, 100, canvas)
							} else {
								outPutFileType(pdf, resolve, { title, isReture })
							}
						}
					} //当内容未超过pdf一页显示的范围，无需分页

					if (leftHeight < a4HeightRef) {
						pdf.addImage(pageData, 'JPEG', 0, 0, a4Width, (a4Width / canvas.width) * leftHeight)
						outPutFileType(pdf, resolve, { title, isReture })
					} else {
						pdf.deletePage(0)
						setTimeout(createImpl, 100, canvas)
					}
					// 移除 iframe 元素
					document.body.removeChild(iframe)
				})
				.catch((error) => {
					reject()
				})
				.finally(() => {
					// 在这里添加关闭iframe的操作
					iframe.contentWindow?.close()
					// 删除指定 id为ediorIframe的iframe
					const iframeContainer = document.body.querySelector('#ediorIframe')
					iframeContainer?.remove(iframe)
				})
		})
	},
}

export default htmlPdf
