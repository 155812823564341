<template>
	<el-table :data="tableData" style="width: 100%" :scrollbar-always-on="true" :border="true">
		<el-table-column type="index" label="序号" width="60" :align="'center'" fixed="left" />
		<el-table-column prop="code" label="产品编号" width="120" fixed="left">
			<template #default="{ row }">
				<el-link
					type="primary"
					@click="
						() =>
							drawerOpen(DrawerType.PRODUCTLIST, {
								title: row.name,
								props: {
									rowId: row.productId,
								},
								selectList: [{ ...row, ID: row.productId }],
							})
					"
					>{{ row.code }}
				</el-link>
			</template>
		</el-table-column>
		<el-table-column prop="name" label="产品名称" width="120" fixed="left" />
		<el-table-column prop="brandName" label="产品品牌" width="100" />
		<el-table-column prop="categoryName" label="产品品类" width="100" />
		<el-table-column prop="specification" label="规格型号" width="120" />
		<el-table-column prop="unitName" label="单位" width="60" :align="'center'" />
		<el-table-column prop="date" label="上报总数" width="100" :align="'center'">
			<template #default="{ row }">
				{{ getTotal(row) }}
			</template>
		</el-table-column>
		<!-- 展示仓库 -->
		<el-table-column v-for="(column, index) in formData.warehouses" :key="column.ID" width="100" :align="'center'">
			<template #header>
				<el-tooltip class="box-item" effect="dark" :content="column.WarehouseName" placement="top">
					<span class="truncate">{{ column.WarehouseName }}</span>
				</el-tooltip>
			</template>
			<template #default="{ row }">
				{{ row.stockDetailsList[index].stockNum !== -1 ? row.stockDetailsList[index].stockNum : '' }}
			</template>
		</el-table-column>
		<el-table-column prop="remark" label="产品备注" min-width="120" />
	</el-table>
</template>

<script setup lang="ts">
import { sumBy } from 'lodash-es'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { ChannelInventoryRecord } from '../../types'

defineProps<{
	tableData: unknown[]
	formData: ChannelInventoryRecord.ResInfo
}>()

const { drawerOpen } = usePageDrawer()

// 上报总数
const getTotal = (row: any): string | number => {
	// stockNum 为 -1，代表未填写，不参与计算
	const list = row.stockDetailsList?.filter((item) => typeof item.stockNum === 'number' && item.stockNum !== -1)
	if (list.length > 0) {
		return sumBy(list, 'stockNum')
	}
	return ''
}
</script>

<style scoped></style>

