<template>
	<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="84px" label-position="left">
		<el-form-item label="沟通对象" prop="userIds">
			<pick-user v-model="ruleForm.userIds" :isAuth="false" :unSelectable="[userInfo?.ID]" :uniqueUser="false" />
		</el-form-item>
		<el-form-item label="沟通内容" prop="msg">
			<XZLInput v-model="ruleForm.msg" type="textarea" rows="3" maxlength="200" show-word-limit />
		</el-form-item>
		<el-form-item>
			<div v-if="ruleForm.appendixList?.length > 0">
				<el-tag
					v-for="(item, index) in ruleForm.appendixList"
					:key="index"
					class="mr-2"
					closable
					@close="removeFile(index)"
					>{{ item.name }}</el-tag
				>
			</div>
			<el-upload
				v-model="ruleForm.appendixList"
				:http-request="
					(options) =>
						handleFile(options, {
							fileList: ruleForm.appendixList,
							fileType: 'appendix',
						})
				"
				:before-upload="beforeUpload"
				:show-file-list="false"
			>
				<el-button v-if="ruleForm.appendixList.length < maxFileCount" :icon="Upload" type="primary" link size="small"
					>上传附件</el-button
				>
			</el-upload>
		</el-form-item>
	</el-form>

	<div class="text-right">
		<el-button text @click="emit('close')">取消</el-button>
		<el-button type="primary" :loading="loading" @click="handleConfirm">确定</el-button>
	</div>
</template>

<script setup lang="ts">
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { Upload } from '@element-plus/icons-vue'
import { useWorkDoc, DocOperationBtnType } from '@/hooks/useWorkDoc'
import { msgNotify } from '@/api/serveApi/workflow/workflowOperation'
import { reactive, ref } from 'vue'

interface Props {
	code: string
	userInfo: any
}

const props = defineProps<Props>()

const emit = defineEmits(['refresh', 'close'])

interface RuleForm {
	userIds: number[]
	msg: string
	appendixList: Array<{ name: string; url: string }>
}

const maxFileCount: number = 20
const { beforeUpload, handleFile, handleIDoctem } = useWorkDoc(maxFileCount)
const loading = ref<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
	userIds: [],
	msg: '',
	appendixList: [],
})
const rules = reactive<FormRules>({
	userIds: [{ required: true, message: '请选择沟通对象', trigger: 'change' }],
	msg: [{ required: true, message: '请输入沟通内容', trigger: 'blur' }],
})

const removeFile = (index: number) => {
	handleIDoctem(DocOperationBtnType.DELETE, ruleForm.appendixList, index)
}

const handleConfirm = async () => {
	await ruleFormRef.value?.validate(async (valid) => {
		if (valid) {
			loading.value = true
			const res = await msgNotify({
				...ruleForm,
				appendixList: ruleForm.appendixList.map((o) => o.url),
				approvalRequestCode: props.code,
			})
			if (res && res.code === 0) {
				ElMessage.success(res.msg || '沟通成功')
				emit('refresh')
				emit('close')
			}
			loading.value = false
		}
	})
}
</script>
