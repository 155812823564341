/*
 * @Author: yoler 979700942@qq.com
 * @LastEditors: yoler 979700942@qq.com
 * @Date: 2023-12-11 15:42:42
 * @FilePath: \digitizing_web\src\view\dashboard\announcement\useDashboardAnnouncement.ts
 * @Description:
 */
import { ref } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { getPublicAnnouncementList } from '@/api/serveApi/systemSetting/announcement'

const list = ref([])
const total = ref(0)
const currentPage = ref(1)
const pageSize = 4

export const useDashboardAnnouncement = () => {
	//发布
	const { isLoading, execute: fetchGetPublicAnnouncementList } = useAsyncState(
		(arg = {}) => getPublicAnnouncementList({ page: currentPage.value, pageSize, ...arg }),
		{ code: 0, msg: '', data: { list: [], total: 0, page: 1 } },
		{
			immediate: false,
			onSuccess(res) {
				if (res.code !== 0) return
				list.value = res.data.list
				total.value = Math.ceil(res.data.total / pageSize)
				currentPage.value = res.data.page
			},
		}
	)

	return {
		list,
		total,
		currentPage,
		isLoading,
		fetchGetPublicAnnouncementList,
	}
}
