/**
 * @description 审批状态
 * @param WAITCOMMIT   待提交 => 1
 * @param APPROVALWAIT 待审批 => 2
 * @param APPROVALING  审批中 => 3
 * @param AGREE        已通过 => 4
 * @param REJECT       已驳回 => 5
 * @param WITHDRAWED   已撤回 => 6
 */
export enum ApprovalStatus {
	WAITCOMMIT = 1,
	APPROVALWAIT,
	APPROVALING,
	AGREE,
	REJECT,
	WITHDRAWED,
}

/**
 * @description 审批状态中文名称
 * @param WAITCOMMIT   待提交 => 1
 * @param APPROVALWAIT 待审批 => 2
 * @param APPROVALING  审批中 => 3
 * @param AGREE        已通过 => 4
 * @param REJECT       已驳回 => 5
 * @param WITHDRAWED   已撤回 => 6
 *
 */
export const ApprovalStatusText: { [key in ApprovalStatus]: string } = {
	[ApprovalStatus.WAITCOMMIT]: '待提交',
	[ApprovalStatus.APPROVALWAIT]: '待审批',
	[ApprovalStatus.APPROVALING]: '审批中',
	[ApprovalStatus.AGREE]: '已通过',
	[ApprovalStatus.REJECT]: '已驳回',
	[ApprovalStatus.WITHDRAWED]: '已撤回',
}
