import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { WebSocketMessageType } from '@/hooks/useWebSocket/types'
import { DialogDataType, DrawerDataType, DrawerType } from '@/hooks/usePageDrawer/types'

export const useDrawer = defineStore('drawer', () => {
	/**记录已打开的抽屉数据和弹窗数据 */
	const drawerMap = reactive<Map<DrawerType, DrawerDataType | DialogDataType | any>>(new Map())
	return {
		drawerMap,
	}
})
