/*
 * @Author: yoler 979700942@qq.com
 * @LastEditors: yoler 979700942@qq.com
 * @Date: 2023-12-14 18:37:43
 * @FilePath: \digitizing_web\src\api\serveApi\systemSetting\announcement.ts
 * @Description:
 */
import service from '@/utils/request'

// 用id查询公告
export const findSysAnnouncement = (params: any) => {
	return service({
		url: '/sysAnnouncement/findSysAnnouncement',
		method: 'get',
		params,
	})
}

// 用id查询已发布公告-首页点击使用
export const findPublicAnnouncementByID = (params: any) => {
	return service({
		url: '/sysAnnouncement/findPublicAnnouncementByID',
		method: 'get',
		params,
	})
}

// 分页获取公告列表
export const getSysAnnouncementList = (data: any) => {
	return service({
		url: '/sysAnnouncement/getSysAnnouncementList',
		method: 'post',
		data,
	})
}

// 分页获取公告浏览历史列表
export const getViewHistoryList = (data: any) => {
	return service({
		url: '/sysAnnouncement/getViewHistoryList',
		method: 'post',
		data,
	})
}

// 分页获取已发布公告列表
export const getPublicAnnouncementList = (data: any) => {
	return service({
		url: '/sysAnnouncement/getPublicAnnouncementList',
		method: 'post',
		data,
	})
}

// 新增公告
export const createSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/createSysAnnouncement',
		method: 'post',
		data,
	})
}

// 更新公告
export const updateSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/updateSysAnnouncement',
		method: 'put',
		data,
	})
}

// 发布公告
export const publicSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/public',
		method: 'put',
		data,
	})
}

// 撤回公告
export const revokeSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/revoke',
		method: 'put',
		data,
	})
}

// 置顶公告
export const setTopSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/setTop',
		method: 'put',
		data,
	})
}

// 取消置顶公告
export const cancelTopSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/cancelTop',
		method: 'put',
		data,
	})
}

// 删除公告
export const deleteSysAnnouncement = (data: any) => {
	return service({
		url: '/sysAnnouncement/deleteByIds',
		method: 'delete',
		data,
	})
}

// 已阅公告
export const setHasRead = (data: any) => {
	return service({
		url: '/sysAnnouncement/setHasRead',
		method: 'put',
		data,
	})
}

// 设置已阅时长
export const setReadTime = (data: any) => {
	return service({
		url: '/sysAnnouncement/setReadTime',
		method: 'put',
		data,
	})
}
