import { reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useAsyncState } from '@vueuse/core'
import { pick } from 'lodash-es'
import { UsePageConfigState, UsePageConfigOptions } from './types'
import { useCreatePageId } from '@/hooks/useCreatePageId'
import { usePageAuth } from '../userPageAuth'
import { getPageConf, savePageConf } from '@/api/serveApi/common/tableFilter'

export const usePageConfig = (options: UsePageConfigOptions) => {
	// 默认列表
	const defaultColumns = options.columns
		?.filter((o) => !o.type && !o.isDyField)
		.map((item) => pick(item, ['label', 'prop', 'fixed', 'isShow', 'isLock', 'isHideAuth']))

	const state = reactive<UsePageConfigState>({
		pageId: useCreatePageId(options.pageId, options.data),
		pageConfig: {
			pageSize: 20,
			fieldList: [],
			settings: defaultColumns,
		},
		searchParam: null,
	})

	// 获取配置
	const getPageConfig = async (): Promise<void> => {
		const isNeedConfig: boolean = options.isNeedConfig ?? true
		if (isNeedConfig) {
			const res = await getPageConf({ pageId: state.pageId })
			if (res && res.code === 0 && res.data?.fields) {
				const config = JSON.parse(res.data.fields)
				for (const key in config) {
					if ((config[key] || config[key]?.length > 0) && state.pageConfig.hasOwnProperty(key)) {
						state.pageConfig[key] = config[key]
					}
				}
			}
		}

		// 字段权限处理
		state.pageConfig.settings = usePageAuth([], defaultColumns, state.pageConfig.settings)?.fieldList ?? []
	}

	// 保存配置
	const savePageConfig = async (): Promise<boolean> => {
		if (!state.pageId) {
			ElMessage.warning('缺少pageId, 请先配置！')
			return
		}

		const params = {
			pageId: state.pageId,
			fields: JSON.stringify(state.pageConfig),
		}

		const res = await savePageConf(params)
		return res?.code === 0
	}

	const _service = async (isReload: boolean = false) => {
		return Promise.all([getPageConfig(), !isReload && options.beforeCallback?.()]).then((res) => {
			if (res && res[1]) {
				state.searchParam = res[1]
			}
			return state.searchParam
		})
	}

	const _options = {
		...options,
		onSuccess: (data) => {
			options?.onSuccess?.(data)
		},
		onError: (data) => {
			options?.onError?.(data)
		},
	}

	const { isLoading: pageLoading, isReady: pageReady, execute: fetchData } = useAsyncState(_service, null, _options)

	// 重新加载配置
	const fetchPageConfig = async () => {
		await fetchData(0, true)
	}

	return {
		...toRefs(state),
		pageReady,
		pageLoading,
		fetchPageConfig,
		savePageConfig,
	}
}
