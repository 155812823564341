import { login, getUserInfo, setSelfInfo, getUserPositionPermissions } from '@/api/user'
import { jsonInBlacklist } from '@/api/jwt'
import router from '@/router/index'
import { ElLoading, ElMessage } from 'element-plus'
import { defineStore } from 'pinia'
import { ref, computed, watch, nextTick } from 'vue'
import { useRouterStore } from './router'
import { useWebsocketStore } from '@/pinia/modules/websocket'
import { useWebSocket } from '@/hooks/useWebSocket'
export const useUserStore = defineStore('user', () => {
	const loadingInstance = ref(null)

	const userInfo = ref({
		uuid: '',
		nickName: '',
		headerImg: '',
		authority: {},
		sideMode: 'dark',
		activeColor: 'var(--el-color-primary)',
		baseColor: '#fff',
	})
	const userPermissions = ref([]) // web用户权限
	const userAppPermissions = ref([]) // app用户权限
	const token = ref(window.localStorage.getItem('token') || '')
	const routerStore = useRouterStore()
	const websocketStore = useWebsocketStore()

	/**启用websocket */
	const restartWebsocket = () => {
		if (!websocketStore.instanceWebSocket) {
			//确保用户信息存储完成
			const { open, close } = useWebSocket()
			// 用户数据存储完成，调用websocket，传递相关身份信息
			open && open()
		}
	}
	/**清除websocket  */
	const clearWebsocket = () => {
		if (websocketStore.instanceWebSocket) {
			const { open, close } = useWebSocket()
			close && close()
		}
	}

	const setUserInfo = async (val) => {
		userInfo.value = val
	}

	const setToken = (val) => {
		token.value = val
	}

	const NeedInit = () => {
		token.value = ''
		window.localStorage.removeItem('token')
		localStorage.clear()
		router.push({ name: 'Init', replace: true })
	}

	const ResetUserInfo = (value = {}) => {
		userInfo.value = {
			...userInfo.value,
			...value,
		}
	}
	const setPermissions = (val) => {
		userPermissions.value = val
	}
	/* 数组的扁平化*/
	function readNodes(nodes = [], arr = []) {
		for (let item of nodes) {
			arr.push(item)
			if (item.subMenuList && item.subMenuList.length) {
				readNodes(item.subMenuList, arr)
			}
			if (item.appMenuContent?.length) {
				readNodes(item.appMenuContent, arr)
			}
		}
		return arr
	}
	/* 获取用户所有的权限*/
	const GetUserPower = async () => {
		try {
			const power = await getUserPositionPermissions()
			if (power.code == 0) {
				setPermissions(readNodes(power.data.menuList))
				// 获取所有app权限
				userAppPermissions.value = readNodes(power.data?.appMenuList)
			}
		} catch (error) {}
	}
	/* 获取用户信息*/
	const GetUserInfo = async () => {
		const res = await getUserInfo()
		if (res.code === 0) {
			setUserInfo(res.data.userInfo)
			restartWebsocket()
		}
		return res
	}
	/**清空当前用户信息 */
	const clearUserInfo = () => {
		userInfo.value = {}
	}
	/* 登录*/
	const LoginIn = async (loginInfo) => {
		loadingInstance.value = ElLoading.service({
			fullscreen: true,
			text: '登录中，请稍候...',
		})
		try {
			const res = await login(loginInfo)
			if (res.code === 0) {
				setUserInfo(res.data.user)
				setToken(res.data.token)
				restartWebsocket()
				await routerStore.SetAsyncRouter()
				const asyncRouters = routerStore.asyncRouters
				asyncRouters.forEach((asyncRouter) => {
					router.addRoute(asyncRouter)
				})
				// 如果登录完成但未调整，请检查是否defaultRouter没有默认值
				await router.replace({ name: userInfo.value.authority.defaultRouter })
				console.log(router, '用户登录后，初始化router配置------')
				loadingInstance.value.close()
				await GetUserPower()
				const isWin = ref(/windows/i.test(navigator.userAgent))
				if (isWin.value) {
					window.localStorage.setItem('osType', 'WIN')
				} else {
					window.localStorage.setItem('osType', 'MAC')
				}
				return true
			}
		} catch (e) {
			loadingInstance.value.close()
		}
		loadingInstance.value.close()
	}
	/* 登出*/
	const LoginOut = async () => {
		// 如果账号异地登录，返回信息code===9
		const res = await jsonInBlacklist()
		if (res?.code === 0 || res?.code === 9) {
			clearWebsocket()
			// 等待websocket 关闭完成，否则会引起上一个自动重新连接
			await nextTick()
			token.value = ''
			sessionStorage.clear()
			localStorage.removeItem('token')
			// 清空消息Map
			// 清空pinia 数据
			clearUserInfo()
			routerStore.clearAsyncRouters()

			await websocketStore.clearSocketStoreMap()

			await router.push({ name: 'Login', replace: true })
		}
	}
	/* 清理数据 */
	const ClearStorage = async () => {
		token.value = ''
		sessionStorage.clear()
		localStorage.clear()
	}
	/* 设置侧边栏模式*/
	const changeSideMode = async (data) => {
		const res = await setSelfInfo({ sideMode: data })
		if (res.code === 0) {
			userInfo.value.sideMode = data
			ElMessage({
				type: 'success',
				message: '设置成功',
			})
		}
	}

	const mode = computed(() => userInfo.value.sideMode)
	const sideMode = computed(() => {
		return '#242424' // 默认写死为黑色
		// if (userInfo.value.sideMode === 'dark') {
		// 	return '#242424'
		// } else if (userInfo.value.sideMode === 'light') {
		// 	return '#fff'
		// } else {
		// 	return userInfo.value.sideMode
		// }
	})
	const baseColor = computed(() => {
		if (userInfo.value.sideMode === 'dark') {
			return '#fff'
		} else if (userInfo.value.sideMode === 'light') {
			return '#242424'
		} else {
			return userInfo.value.baseColor
		}
	})
	const activeColor = computed(() => {
		return 'var(--el-color-primary)'
	})

	watch(
		() => token.value,
		() => {
			window.localStorage.setItem('token', token.value)
		},
		{
			immediate: true,
		}
	)

	return {
		userInfo,
		token,
		userPermissions,
		userAppPermissions,
		NeedInit,
		ResetUserInfo,
		GetUserInfo,
		GetUserPower,
		clearUserInfo,
		LoginIn,
		LoginOut,
		changeSideMode,
		mode,
		sideMode,
		setToken,
		baseColor,
		activeColor,
		loadingInstance,
		ClearStorage,
	}
})
