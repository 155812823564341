// 权限按钮展示指令
import { useUserStore } from '@/pinia/modules/user'
export default {
	install: (app) => {
		const userStore = useUserStore()
		app.directive('auth', {
			// 当被绑定的元素插入到 DOM 中时……
			mounted: function (el, binding) {
				const userInfo = userStore.userInfo
				let type = ''
				switch (Object.prototype.toString.call(binding.value)) {
					case '[object Array]':
						type = 'Array'
						break
					case '[object String]':
						type = 'String'
						break
					case '[object Number]':
						type = 'Number'
						break
					default:
						type = ''
						break
				}
				if (type === '') {
					el.parentNode.removeChild(el)
					return
				}
				const waitUse = binding.value.toString().split(',')
				let flag = waitUse.some((item) => Number(item) === userInfo.authorityId)
				if (binding.modifiers.not) {
					flag = !flag
				}
				if (!flag) {
					el.parentNode.removeChild(el)
				}
			},
		})
		app.directive('el-select-loadmore', {
			mounted: function (el, binding) {
				// 根据el获取子节点el-input__inner
				const wrapEl = el.querySelector('.el-input__inner')
				if (wrapEl) {
					const id = wrapEl.attributes['aria-controls']?.nodeValue
					const selectUl = document.querySelector(`#${id}`)
					const selectWarp = selectUl?.parentNode
					selectWarp?.addEventListener('scroll', function () {
						// 设置滚动底部阈值为20，
						if (this.scrollTop + this.clientHeight >= this.scrollHeight - 20) {
							binding?.value()
						}
					})
				}
			},
		})
	},
}
