<template>
	<template v-if="item.type === Category.Depart">
		<div class="content">
			<XZLAvatar :size="38" style="background-color: #fff; border: 1px solid #7142e1">
				<SvgIcon name="icon-org" color="#7142e1" :size="20" />
			</XZLAvatar>
			<div class="content-title">
				<div class="h-full truncate" :title="item.departmentName">
					{{ item.departmentName }}
				</div>
				<span v-if="item.employeeNum" class="text-gray-400 ml-1">({{ item.employeeNum }})</span>
			</div>
		</div>
		<el-icon><ArrowRight /></el-icon>
	</template>

	<template v-else>
		<XZLAvatar :size="38" :src="item.headerImg" :name="item.userName" :default-icon-size="18" />
		<div class="user-item-box">
			<div class="user-title">
				<div class="truncate" style="max-width: 120px" :title="item.userName">{{ item.userName }}</div>
				<el-tag class="ml-2" size="small" disable-transitions>{{ item.jobPositionName }}</el-tag>
			</div>
			<div class="text-gray-400 truncate" style="font-size: 12px">
				<span :title="item.departmentNameList?.join(' / ')">{{ item.departmentNameList?.join(' / ') }}</span>
			</div>
		</div>
		<el-icon v-if="closeable" @click="emit('removeUser')"><Close /></el-icon>
	</template>
</template>

<script setup lang="ts">
import { Close, ArrowRight } from '@element-plus/icons-vue'
import { ListItem, Category } from './types'
import XZLAvatar from '@/components/proxyComponents/XZLAvatar/index.vue'

interface Props {
	item: ListItem
	closeable?: boolean
}

defineProps<Props>()
const emit = defineEmits(['removeUser'])
</script>

<style lang="scss" scoped>
.content {
	flex: 1;
	display: flex;
	align-items: center;
	height: 38px;
	line-height: 38px;
	overflow: hidden;
	.content-title {
		display: flex;
		flex: 1;
		height: 100%;
		overflow: hidden;
		margin-left: 10px;
		margin-right: 4px;
	}
}
.user-item-box {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 10px;
	row-gap: 6px;
	overflow: hidden;
	.user-title {
		display: flex;
		align-items: center;
		line-height: 18px;
		color: var(--el-text-color-regular);
	}
}
</style>
