import service from '@/utils/request'

// 获取渠道客户库存列表
export const getDistributorStockProductList = (data: any) => {
	return service({
		url: '/distributorStockProduct/getDistributorStockProductList',
		method: 'post',
		data,
	})
}

// 获取渠道客户库存详情
export const findDistributorStockProduct = (params: any) => {
	return service({
		url: '/distributorStockProduct/findDistributorStockProduct',
		method: 'get',
		params,
	})
}

// 获取渠道客户库存详情
export const distributorStockCount = (data: any) => {
	return service({
		url: '/distributorStockProduct/distributorStockCount',
		method: 'post',
		data,
	})
}

// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/distributorStockProduct/getDistributorStockProductList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
