import service from '@/utils/request'

// 获取终端
export const getTerminalManageList = (data: any) => {
	return service({
		url: '/terminalManage/getTerminalManageList',
		method: 'post',
		data,
	})
}

// 获取终端-无权限控制
export const getHandoverTerminalManageList = (data: any) => {
	return service({
		url: '/terminalManage/getHandoverTerminalManageList',
		method: 'post',
		data,
	})
}

// 根据ids获取终端
export const findTerminalManageByIds = (data: any) => {
	return service({
		url: '/terminalManage/findTerminalManageByIds',
		method: 'post',
		data,
	})
}

// // 新增终端
export const createTerminalManage = (data: any) => {
	return service({
		url: '/terminalManage/createTerminalManage',
		method: 'post',
		data,
	})
}

// // 编辑终端
export const updateTerminalManage = (data: any) => {
	return service({
		url: '/terminalManage/updateTerminalManage',
		method: 'put',
		data,
	})
}

//删除终端
export const deleteTerminalManage = (data: any) => {
	return service({
		url: '/terminalManage/deleteTerminalManage',
		method: 'DELETE',
		data,
	})
}
//批量删除终端
export const deleteTerminalManageByIds = (data: any) => {
	return service({
		url: '/terminalManage/deleteTerminalManageByIds',
		method: 'DELETE',
		data,
	})
}
// //根据ID查询终端信息
export const findTerminalManage = (params: any) => {
	return service({
		url: '/terminalManage/findTerminalManage',
		method: 'get',
		params,
	})
}

//启用终端
export const enableTerminalManage = (data) => {
	return service({
		url: '/terminalManage/enableTerminalManage',
		method: 'put',
		data,
	})
}

//停用终端
export const deactivateTerminalManage = (data) => {
	return service({
		url: '/terminalManage/deactivateTerminalManage',
		method: 'put',
		data,
	})
}
//更新经销商
export const setTerminalManageDistributor = (data) => {
	return service({
		url: '/terminalManage/setTerminalManageDistributor',
		method: 'put',
		data,
	})
}

//更新负责人
export const setTerminalManageMaster = (data) => {
	return service({
		url: '/terminalManage/setTerminalManageMaster',
		method: 'put',
		data,
	})
}

//更新销售区域
export const setTerminalManageSaleArea = (data) => {
	return service({
		url: '/terminalManage/setTerminalManageSaleArea',
		method: 'put',
		data,
	})
}
//设置标签
export const setTerminalManageTag = (data) => {
	return service({
		url: '/terminalManage/setTerminalManageTag',
		method: 'put',
		data,
	})
}

//批量更新负责人和销售区域
export const setTerminalManageMasterAndSaleArea = (data) => {
	return service({
		url: '/terminalManage/setTerminalManageMasterAndSaleArea',
		method: 'put',
		data,
	})
}

//获取行政区域
export const getAreaList = (data) => {
	return service({
		url: '/distributorManage/areaList',
		method: 'post',
		data: { parent_id: '0', ...data },
	})
}
// 导入
export const importApi = (data: any) => {
	return service({
		url: '/terminalManage/importTerminalManage',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}
//导入更新
export const importUpdateApi = (data: any) => {
	return service({
		url: '/terminalManage/importTerminalManageUpdate',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}
// 导出
export const exportApi = (data: any) => {
	return service({
		url: '/terminalManage/getTerminalManageList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
// 获取终端库存列表
export const getTerminalStockProductList = (data: any) => {
	return service({
		url: '/terminalStockProduct/getTerminalStockProductList',
		method: 'post',
		data,
	})
}
// 获取终端客户上报记录列表
export const getTerminalStockList = (data: any) => {
	return service({
		url: '/terminalStock/getTerminalStockList',
		method: 'post',
		data,
	})
}

//  APP拜访-拜访记录
export const appVisitedList = (data: any) => {
	return service({
		url: '/visitedDetails/appVisitedList',
		method: 'get',
		params: data,
	})
}
// APP拜访-拜访详情
export const appVisitedDetails = (data: any) => {
	return service({
		url: '/visitedDetails/appVisitedDetailsV1',
		method: 'post',
		data,
	})
}
//获取ActivityApply列表
export const getActivityApplyList = (data: any) => {
	return service({
		url: '/activityApply/getActivityApplyListInProcess',
		method: 'post',
		data,
	})
}

