import { ref } from 'vue'

type weekListType = {
	label: string
	value: number
}

type dateType = {
	label: string | number
	status: boolean
}

export const frequencyList = [
	{ id: 1, name: '每周' },
	{ id: 2, name: '每月' },
]

// 周
export const weekList = ref<weekListType[]>([
	{
		label: '周一',
		value: 1,
	},
	{
		label: '周二',
		value: 2,
	},
	{
		label: '周三',
		value: 3,
	},
	{
		label: '周四',
		value: 4,
	},
	{
		label: '周五',
		value: 5,
	},
	{
		label: '周六',
		value: 6,
	},
	{
		label: '周日',
		value: 7,
	},
])

// 日
export const datelist = ref([] as dateType[])
datelist.value = Array.from(Array(31), (value, index) => ({ label: index + 1, status: false }))
