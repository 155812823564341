<template>
	<TransitionGroup
		ref="dragRef"
		type="transition"
		tag="ul"
		name="fade"
		style="width: 100%; min-height: 60px; max-height: 360px; overflow: auto"
		:class="{ 'mb-2': isShowMbCss }"
	>
		<li
			v-for="(item, index) in props.list"
			:key="item.ID + item[name]"
			:class="`${drag ? 'handle  cursor-move' : ''} flex items-center justify-between p-2`"
			style="border: 1px solid #dcdfe6; margin: 10px 0; border-radius: 5px; width: 100%"
		>
			<div class="flex items-center flex-1 overflow-hidden">
				<div class="flex items-center overflow-hidden pr-6">
					<el-image
						style="width: 25px; height: 25px; margin: 0 5px"
						v-if="item[name]"
						:src="renderFileIcon(item, name)"
						fit="fill"
					/>
					<XZLTooltip
						maxWidth="360"
						:content="item.size ? `${item[name]}(${sizeConversion(item.size)})` : item[name]"
					/>
				</div>
			</div>
			<div>
				<el-button
					v-for="button in operationList"
					:key="button.value"
					link
					class="mx-1"
					:type="button.type || 'primary'"
					:disabled="disabled || button.isDisabled"
					@click="() => handleIDoctem(button.value, props.list, index, urlKey, parentId, name)"
				>
					{{ button.label }}
				</el-button>
			</div>
		</li>
	</TransitionGroup>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useWorkDoc } from '@/hooks/useWorkDoc'
import XZLTooltip from '@/components/proxyComponents/XZLTooltip/index.vue'
import { useDraggable } from 'vue-draggable-plus'
const props = defineProps({
	isShowMbCss: {
		type: Boolean,
		default: true,
	},
	disabledOperationList: {
		type: Array as () => string[],
		default: () => [],
	},
	/**渲染的文件列表 */
	list: {
		type: Array as () => Record<string, any>[],
		default: () => [],
	},
	/**需要隐藏的操作 数组类型 */
	hiddenOperationList: {
		type: Array as () => string[],
		default: () => [],
	},
	/**文件url字段 */
	urlKey: {
		type: String,
		default: 'url',
	},
	parentId: {
		type: String,
		default: '',
	},
	/**文件名称变量 */
	name: {
		type: String,
		default: 'name',
	},
	maxLength: {
		type: Number,
		default: 20,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	/** 是否使用拖拽 */
	drag: {
		type: Boolean,
		default: false,
	},
})
const { renderFileIcon, beforeUpload, handleFile, handleIDoctem, DocOperationBtnList, sizeConversion, loading } =
	useWorkDoc(props.maxLength)
const dragRef = ref<HTMLElement>()

watch(
	() => props.drag,
	(val) => {
		if (val) {
			useDraggable(dragRef, props.list)
		}
	},
	{
		immediate: true,
	}
)
const operationList = computed(() => {
	const notDisabledOperation = DocOperationBtnList.filter((item) => !props?.hiddenOperationList?.includes(item.value))
	notDisabledOperation.forEach((item) => {
		if (props?.disabledOperationList?.includes(item.value)) {
			item.isDisabled = true
		}
	})
	return notDisabledOperation
})
</script>
