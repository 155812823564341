<template>
	<div class="node-box">
		<div v-if="!userInfo" style="line-height: 36px" class="m-auto font-semibold">
			{{ empty || '节点为空' }}
		</div>
		<template v-else>
			<XZLAvatar :src="userInfo?.headerImg" :name="userInfo?.userName" :size="36" />
			<div class="node-content">
				<el-tooltip
					effect="dark"
					:disabled="userInfo.userName?.length <= 5"
					:content="userInfo.userName"
					placement="bottom"
				>
					<div class="w-full truncate leading-[20px] text-[14px]">{{ userInfo.userName }}</div>
				</el-tooltip>
				<el-tooltip
					v-if="userInfo.jobPositionName"
					effect="dark"
					:disabled="userInfo.jobPositionName?.length <= 6"
					:content="userInfo.jobPositionName"
					placement="bottom"
				>
					<div class="w-full truncate leading-[16px] text-[12px] text-gray-400">{{ userInfo.jobPositionName }}</div>
				</el-tooltip>
				<el-tag
					v-if="statusName"
					:color="tagColor[userInfo.approvalStatus]?.bgColor"
					:style="{
						borderColor: tagColor[userInfo.approvalStatus]?.bgColor,
						color: tagColor[userInfo.approvalStatus]?.textColor,
						height: '18px',
						padding: '2px 4px',
						marginTop: '3px',
					}"
					effect="dark"
					disable-transitions
					>{{ statusName }}
				</el-tag>
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { ApprovalUserType } from '../types'
import { ApprovalStatus } from '@/view/workflow/types'
import XZLAvatar from '@/components/proxyComponents/XZLAvatar/index.vue'

interface Props {
	userInfo?: ApprovalUserType
	statusName?: string
	empty?: string
}

defineProps<Props>()

const tagColor: { [key in ApprovalStatus]: Record<'textColor' | 'bgColor', string> } = {
	[ApprovalStatus.WAITCOMMIT]: {
		textColor: '#F86F00',
		bgColor: '#FFEFDD',
	},
	[ApprovalStatus.APPROVALWAIT]: {
		textColor: '#CA9600',
		bgColor: '#FFFBBE',
	},
	[ApprovalStatus.APPROVALING]: {
		textColor: '#3783FF',
		bgColor: '#EBF0FC',
	},
	[ApprovalStatus.AGREE]: {
		textColor: '#00AF71',
		bgColor: '#E3F9E9',
	},
	[ApprovalStatus.REJECT]: {
		textColor: '#FF5447',
		bgColor: '#FFEDEA',
	},
	[ApprovalStatus.WITHDRAWED]: {
		textColor: '#494D68',
		bgColor: '#F2F2F2',
	},
}
</script>

<style lang="scss" scoped>
.node-box {
	display: inline-flex;
	flex-direction: row;
	user-select: none;
	padding: 8px;
	box-shadow: 0px 1px 8px 0px #e1e7f4;
	border-radius: 8px;
	column-gap: 6px;
	background-color: #fff;

	.node-content {
		flex: 1;
		width: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
}
</style>
