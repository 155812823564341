import service from '@/utils/request'
import { categoryList } from './common'

// 获取筛选项配置
export const getPageConf = (params: any) => {
	return service({
		url: '/Visited/getPageConf',
		method: 'get',
		params,
	})
}

// 保存筛选项配置
export const savePageConf = (data: any) => {
	return service({
		url: '/Visited/savePageConf',
		method: 'post',
		data: data,
	})
}
