import service from '@/utils/request'

/** 校验是否有权限访问该渠道客户 */
export const checkAuthDistributor = (params?: any) => {
	return service({
		url: '/distributorManage/checkAuthDistributor',
		method: 'get',
		params,
	})
}
/** 校验是否有权限访问该渠道客户的方案详情 */
export const getUserDistributorInfo = (params?: any) => {
	return service({
		url: '/distributorManage/getUserDistributorInfo',
		method: 'post',
		params,
	})
}
