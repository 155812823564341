<template>
	<el-select
		class="w-full"
		v-model="checks"
		@change="handleChange"
		multiple
		collapse-tags
		collapse-tags-tooltip
		filterable
		clearable
		placeholder="请选择"
	>
		<el-option v-for="item in renderList" :key="item.ID" :label="item.name" :value="item.ID" />
	</el-select>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, reactive, computed } from 'vue'
import { SelectType } from '../types'
import { getAllRefFridgeAd } from '@/api/serveApi/refrigeratorManage/refrigeratorAdvertising'
import { getAllRefFridgeBrand } from '@/api/serveApi/refrigeratorManage/refrigeratorBrand'
import { getAllRefFridgeModel } from '@/api/serveApi/refrigeratorManage/refrigeratorModel'
import { getAllRefFridgeType } from '@/api/serveApi/refrigeratorManage/refrigeratorType'

interface Props {
	modelValue: Array<number>
	typeid: SelectType
	remote?: boolean // 是否远程搜索
	data?: DateType
}

interface DateType {
	typeList: Array<any>
	stickerList: Array<any>
	brandList: Array<any>
	modelList: Array<any>
}

const props = withDefaults(defineProps<Props>(), {
	remote: true,
})
const emit = defineEmits(['update:modelValue'])

const checks = ref<Array<number>>([])

const listData = reactive<DateType>({
	typeList: [],
	stickerList: [],
	brandList: [],
	modelList: [],
})

const renderList = computed(() => {
	if (props.typeid === SelectType.refrigeratortype) {
		return listData.typeList
	} else if (props.typeid === SelectType.sticker) {
		return listData.stickerList
	} else if (props.typeid === SelectType.refrigeratorBrand) {
		return listData.brandList
	}
	return listData.modelList
})

watch(
	() => props.modelValue,
	(newVal) => {
		checks.value = newVal ?? []
	},
	{
		deep: true,
		immediate: true,
	}
)

watch(
	() => props.data,
	(newVal) => {
		if (newVal) {
			for (const key in listData) {
				listData[key] = newVal[key]
			}
		}
	},
	{ deep: true }
)

watch(
	() => props.typeid,
	(newVal) => {
		if (newVal) {
			initData()
		}
	}
)

onMounted(async () => {
	initData()
})

const initData = () => {
	if (props.remote) {
		getRefrigeratorType()
	} else {
		if (props.data) {
			for (const key in listData) {
				listData[key] = props.data?.[key]
			}
		}
	}
}

const handleChange = () => {
	emit('update:modelValue', checks.value)
}

const getRefrigeratorType = async () => {
	let res: ResultType
	switch (props.typeid) {
		case SelectType.refrigeratortype:
			res = await getAllRefFridgeType()
			listData.typeList = res.data ?? []
			break
		case SelectType.sticker:
			res = await getAllRefFridgeAd()
			listData.stickerList = res.data ?? []
			break
		case SelectType.refrigeratorBrand:
			res = await getAllRefFridgeBrand()
			listData.brandList = res.data ?? []
			break
		case SelectType.refrigeratorModel:
			res = await getAllRefFridgeModel()
			listData.modelList = res.data ?? []
			break
		default:
	}
}
</script>
