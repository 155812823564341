import service from '@/utils/request'

// 获取活动列表
export const getActivityExeItemList = (data: any) => {
	return service({
		url: '/activityExeItem/getActivityExeItemList',
		method: 'post',
		data,
	})
}
// 创建活动执行项目
export const createActivityExeItem = (data: any) => {
	return service({
		url: '/activityExeItem/createActivityExeItem',
		method: 'post',
		data,
	})
}
// 更新活动执行项目
export const updateActivityExeItem = (data: any) => {
	return service({
		url: '/activityExeItem/updateActivityExeItem',
		method: 'put',
		data,
	})
}

// 用id查询活动执行项目
export const findActivityExeItem = (params: any) => {
	return service({
		url: '/activityExeItem/findActivityExeItem',
		method: 'get',
		params,
	})
}
// 更新活动执行项目
export const switchStatusByIds = (data: any, type) => {
	return service({
		url: `/activityExeItem/${type ? 'enableStatusByIds' : 'disabledStatusByIds'}`,
		method: 'put',
		data,
	})
}
// 删除活动执行项目
export const deleteActivityExeItem = (data: any) => {
	return service({
		url: '/activityExeItem/deleteActivityExeItemByIds',
		method: 'DELETE',
		data,
	})
}
