<template>
	<el-cascader
		:options="dataList"
		v-model="value"
		:props="{
			multiple: true,
			emitPath: false,
			checkStrictly: true,
			value: 'ID',
			label: 'accountName',
			children: 'children',
		}"
		clearable
		filterable
		collapse-tags-tooltip
		collapse-tags
		style="width: 100%"
	/>
</template>

<!-- 费用科目下拉 -->
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import { getPreviousFeeSubjects } from '@/api/serveApi/feeManagement/budgetTable'

interface Props {
	modelValue: number[]
}
const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const dataList = ref([])

onMounted(() => {
	getData()
})

const getData = async () => {
	const res = await getPreviousFeeSubjects({})
	if (res && res.code === 0) {
		dataList.value = res.data
	}
}
</script>
