import { getFullPath } from './../utils/common'
import { useAsyncState } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { upload, FileType } from '@/api/serveApi/common/common'
import docx from '@/assets/images/fileicon/doc.png'
import pdf from '@/assets/images/fileicon/pdf.png'
import png from '@/assets/images/fileicon/png.png'
import txt from '@/assets/images/fileicon/txt.png'
import xls from '@/assets/images/fileicon/xls.png'
import { ElMessage, ElMessageBox, UploadProps, UploadRequestOptions } from 'element-plus'
import { previewFileUrl } from '@/utils/common'
import { getContractViewUrl } from '@/api/serveApi/contractManagement/contractList'
import { downloadUrl } from '@/utils/downloadTool'
import { ref } from 'vue'
export enum DocOperationBtnType {
	/**查看 */
	VIEW = 'VIEW',
	/**重命名 */
	RESETNAME = 'RESETNAME',
	/**下载 */
	DOWNLOAD = 'DOWNLOAD',
	/**删除 */
	DELETE = 'DELETE',
}
const MAX_LENGTH = 5
type Type = '' | 'default' | 'text' | 'danger' | 'success' | 'warning' | 'info' | 'primary'
interface BtnItem {
	label: string
	value: DocOperationBtnType
	isDisabled: boolean
	type?: Type
}
export const useWorkDoc = (maxLength = MAX_LENGTH, excludeTypeList = []) => {
	const currentFileList = ref([])
	const loading = ref(false)

	const fileType = ['doc', 'docx', 'xls', 'xlsx', 'pdf']
	const pictureType = ['jpeg', 'png', 'jpg']
	const DocOperationBtnList: BtnItem[] = [
		{
			label: '查看',
			value: DocOperationBtnType.VIEW,
			isDisabled: false,
		},
		{
			label: '重命名',
			value: DocOperationBtnType.RESETNAME,
			isDisabled: false,
		},
		{
			label: '下载',
			value: DocOperationBtnType.DOWNLOAD,
			isDisabled: false,
		},
		{
			label: '删除',
			value: DocOperationBtnType.DELETE,
			isDisabled: false,
			type: 'danger',
		},
	]
	//给文件添加图标
	const renderFileIcon = (file: any, key: string = 'name') => {
		let fileType = file[key]?.substring(file[key].lastIndexOf('.') + 1)
		switch (fileType) {
			case 'txt':
				return txt
			case 'pdf':
				return pdf
			case 'doc':
			case 'docx':
			case 'DOC':
			case 'DOCX':
				return docx
			case 'jpg':
			case 'jpeg':
			case 'png':
				return png
			case 'xls':
			case 'xlsx':
			case 'XLS':
			case 'XLSX':
				return xls
		}
		return docx
	}
	// 文件大小转换为MB单位
	const MBSize = (size: number) => {
		return size / 1024 / 1024
	}
	/** 文件大小转换 */
	const sizeConversion = (size) => {
		let isMB = MBSize(size) >= 1
		return isMB ? MBSize(size).toFixed(2) + 'MB' : (size / 1024).toFixed(2) + 'KB'
	}
	// 获取合同预览地址
	const { execute: fetchGetContractViewUrl } = useAsyncState(
		(arg) => getContractViewUrl({ ...arg }),
		{
			code: 0,
			msg: '',
			data: '',
		},
		{
			immediate: false,
		}
	)
	/**获取文件尾缀 */
	const fileExtFn = (value = '') => value.substring(value.lastIndexOf('.') + 1)
	/**
	 * @description 对上传前 图片、文件格式和大小验证
	 * @param rawFile 需要上传的文件
	 * @param type 模块类型
	 * @param isPicture 是否可以上传图片
	 * @param callback 回调函数，编辑器自动上传图片时需要将错误信息返回
	 */
	const beforeUpload: UploadProps['beforeUpload'] = (rawFile, type = '', isPicture = true, callback = null) => {
		console.log(`output->rawFile-beforeUpload`, rawFile, maxLength)

		const fileExt = fileExtFn(rawFile.name)
		let fileType = ['doc', 'docx', 'xls', 'xlsx', 'pdf']
		let pictureType = ['jpeg', 'png', 'jpg']

		fileType = isPicture ? fileType.concat(pictureType) : fileType
		// 过滤出符合业务需求的文件类型
		fileType = fileType.filter((item) => !excludeTypeList.includes(item))
		const isTypeOk = fileType.length ? fileType.indexOf(fileExt) >= 0 : []
		if (!isTypeOk) {
			if (callback && typeof callback === 'function') {
				callback(`请上传${pictureType.join('/')}格式文件，已帮您清除错误格式图片`)
			} else {
				ElMessage.error(`文件格式不正确, 请上传${fileType.join('/')}格式文件!`)
			}
			return false
		}
		if (isPicture && pictureType.includes(fileExt) && MBSize(rawFile.size) > 10) {
			if (callback && typeof callback === 'function') {
				callback(`图片大小不能超过10M!`)
			} else {
				ElMessage.error('图片大小不能超过10M!')
			}
			return false
		} else {
			if (MBSize(rawFile.size) > 20) {
				ElMessage.error('文件大小不能超过20M!')
				return false
			}
		}

		return true
	}

	const initFileList = (fileList = []) => {
		currentFileList.value = fileList
	}

	/** 文件上传成功 */
	const handleFile = async (
		fileData: UploadRequestOptions,
		options: {
			fileList: Record<string, any>[]
			fileType: FileType
		}
	): Promise<any> => {
		const { fileList, ...rest } = options
		currentFileList.value = fileList || []

		try {
			const formdata = new FormData()
			formdata.append('file', fileData.file)
			loading.value = true
			const res: ResultType = await upload(formdata, { fileType: 'appendix', ...rest })
			if (res && res.code === 0) {
				if (currentFileList.value.length < maxLength) {
					currentFileList.value.push({
						...res.data.file,
						size: fileData.file.size,
						ID: uuidv4(),
					})
				} else {
					ElMessage.error(`最多可上传最多${maxLength}个文件！！！`)
				}
			}
		} finally {
			loading.value = false
		}
	}
	/**
	 * 下载，模拟一个点击事件进行下载
	 * @param row 获取当前行的name和url
	 */
	const downloadFile = (row) => {
		console.log(`output->row`, row)
		const { name, url } = row
		if (!url) return

		const fileExt = fileExtFn(url)
		let type = 1
		// 使用下载地址
		if (pictureType.includes(fileExt)) type = 3
		downloadUrl(url, name, type)
	}

	/** 删除附件 */
	const handleDelete = (list, index) => {
		list?.splice(index, 1)
		return list
	}
	/**
	 * 重命名
	 * @param row 获取当前行的name
	 */
	const resetFileName = (row, name = 'name') => {
		const fileExt = fileExtFn(row[name])
		const fileName = row[name].substring(0, row[name].lastIndexOf('.'))
		console.log(`output->fileName`, fileName)
		ElMessageBox.prompt('', '文件重命名', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			// inputPattern: /\S/,
			// inputErrorMessage: '不能为空',
			inputValue: fileName.substr(0, 20), // 截取前 20 个字符
			inputPattern: /^.{1,20}$/, // 使用正则表达式限制输入长度为1到20个字符
			inputErrorMessage: '长度必须在1到20个字符之间', // 自定义错误消息
		})
			.then(({ value }) => {
				row[name] = `${value}.${fileExt}`
			})
			.catch(() => {})
	}

	/**
	 * @description 整合所有附件操作方法
	 * @param item 当前选择项数据
	 * @param type 当前点击的类型
	 * @param index 当前选择项下标
	 * @param urlKey url别名
	 * @param parentId 外部链接关联父id
	 * @param name 名称字段
	 */
	const handleIDoctem = async (
		type: string,
		list: Record<string, any>[],
		index: number,
		urlKey?: string,
		name?: string
	) => {
		let resetName = name || 'name'
		let resetUrl = urlKey || 'url'
		console.log(`output->name`, resetName)
		currentFileList.value = list ?? []
		const item = currentFileList.value?.filter((item, ind) => ind === index)?.[0]
		let url = item?.[resetUrl]
		// 如果没有找到对应的链接
		if (!url) {
			// 传递备选方案
			let urlParams = urlKey.split(',')
			url = item?.[urlParams?.[0]] ? item?.[urlParams?.[0]] : item?.[urlParams?.[1]] ?? ''
			url = getFullPath(url)
		}
		switch (type) {
			case DocOperationBtnType.VIEW:
				previewFileUrl(url)
				break
			case DocOperationBtnType.RESETNAME:
				resetFileName(item, resetName)
				break
			case DocOperationBtnType.DOWNLOAD:
				downloadFile({ ...item, name: item[resetName], url })
				break
			case DocOperationBtnType.DELETE:
				handleDelete(currentFileList.value, index)
				break
			default:
				break
		}
	}

	return {
		renderFileIcon,
		beforeUpload,
		handleFile,
		handleIDoctem,
		DocOperationBtnList,
		loading,
		state: currentFileList,
		sizeConversion,
	}
}
