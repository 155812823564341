import service from '@/utils/request'

// 获取字典列表
export const getDictionariesList = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/getSysSettingDictionariesList',
		method: 'post',
		data,
	})
}
/** 批量删除字典 */
export const deleteDictionariesByIds = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/deleteSysSettingDictionariesByIds',
		method: 'delete',
		data,
	})
}

/** 停用字典 */
export const disabledStatus = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/disabledStatus',
		method: 'put',
		data,
	})
}

/** 启用字典 */
export const enableStatus = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/enableStatus',
		method: 'put',
		data,
	})
}

/** 根据id查询字典 */
export const findDictionaries = (params: any) => {
	return service({
		url: '/sysSettingDictionaries/findSysSettingDictionaries',
		method: 'get',
		params,
	})
}
/** 更新字典 */
export const updateDictionaries = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/updateSysSettingDictionaries',
		method: 'put',
		data,
	})
}

/** 创建字典 */
export const createDictionaries = (data: any) => {
	return service({
		url: '/sysSettingDictionaries/createSysSettingDictionaries',
		method: 'post',
		data,
	})
}

/** 查询所有字典配置Map */
export const getSysSettingDictionaries = (params?: any) => {
	return service({
		url: '/sysSettingDictionaries/getSysSettingDictionaries',
		method: 'get',
		params,
	})
}
