import { VNode } from 'vue'
import { TableProps, TableColumnCtx } from 'element-plus'

export interface TablePropType<T = any> extends Partial<TableProps<T>> {
	columns: TableColumnProps[]
}

export type RenderScope<T> = {
	row: T
	$index: number
	column: TableColumnCtx<T>
	[key: string]: any
}

export type HeaderRenderScope<T> = {
	$index: number
	column: TableColumnCtx<T>
	[key: string]: any
}

/**
 * @interface TableColumnType 列的类型
 * @description 除了radio是新增的单选类型，其他的都是 element-plus table-column 中的类型
 */
export type TableColumnType = 'selection' | 'radio' | 'index' | 'expand'

// 列宽类型
export type FieldWidthType = keyof typeof FieldWidthValue

// 列宽（常用字段）
export enum FieldWidthValue {
	mobile = 114, // 手机号码
	status = 70, // 状态
	approvalStatus = 90, // 审批状态
	tag = 162, // 标签
	image = 108, // 图片
	date_ymd_hms = 162, // yyyy-mm-dd hh:mm:ss
	date_ymd_hm = 141, // yyyy-mm-dd hh:mm
	date_ymd_h = 130, // yyyy-mm-dd hh
	date_ymd = 108, // yyyy-mm-dd
	date_ym = 83, // yyyy-mm
	date_y = 58, // yyyy
	date_m = 58, // mm
}

// 列的属性
export interface TableColumnProps<T = any> extends Partial<TableColumnCtx<T>> {
	isShow?: boolean // 是否显示，默认true
	isLock?: boolean // 是否固定，默认false
	isHidden?: boolean // 是否隐藏（隐藏后列表、列设置均不显示），默认false
	isDyField?: boolean // 是否动态字段（动态字段不做权限限制，并且在列设置中不显示），默认false
	isHideAuth?: boolean // 是否取消字段权限控制，有些字段不需要权限的可以配置此属性为true，默认false
	type?: TableColumnType // 原生属性未做限制，手动限制一下
	widthType?: FieldWidthType // 列宽类型
	headerRender?: (scope: HeaderRenderScope<T>) => VNode // 自定义表头内容渲染（tsx语法）
	render?: (scope: RenderScope<T>) => VNode | string // 自定义内容渲染（tsx语法）
	_children?: TableColumnProps<T>[] // 多级表头
}
