import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { WebSocketMessageType } from '@/hooks/useWebSocket/types'
interface SetItemValue {
	ID: number
	[key: string]: any
}

export const useWebsocketStore = defineStore('websocket', () => {
	/**websocket数据集合 */
	const socketStoreMap = reactive<Map<WebSocketMessageType, any>>(new Map())
	// 消息总数
	const socketStoreTotal = ref(0)
	// 通知消息总数
	const notifyTotal = ref(0)
	// 审批消息总数
	const approvalTotal = ref(0)
	// 是否有新的下载文件
	const isNewDownLoad = ref(false)
	// 最新推送的消息
	const currentItem = ref()
	const MAXLENGTH = 8 // 各种状态最大数组长度
	let instanceWebSocket = null
	/**设置当前类响应数据 */
	const setItem = (key: WebSocketMessageType, value: SetItemValue) => {
		// console.log(`output->value-setItem`, key, value)
		if (socketStoreMap.has(key)) {
			const current = socketStoreMap.get(key)
			// 过滤出不重复的消息
			const oldList = current?.filter((item) => item?.ID !== (value as SetItemValue)?.ID) ?? []
			if (oldList.length < MAXLENGTH) {
				//追加最新消息到队列尾部
				socketStoreMap.set(key, [...oldList, value])
			} else {
				oldList.shift() //队列满了，移除队列头部元素
				socketStoreMap.set(key, [...oldList, value])
			}
		} else {
			console.log(`output->key`, key, value)
			socketStoreMap.set(key, [value])
		}
		currentItem.value = getItemLast(key, socketStoreMap)
		return true
	}
	/**获取当前类所有消息 */
	const getItem = (key: WebSocketMessageType) => {
		return socketStoreMap.get(key)
	}
	/**获取当前类最新消息 */
	const getItemLast = (key: WebSocketMessageType, valueMap: Map<WebSocketMessageType, any>) => {
		let length = valueMap.get(key)?.length - 1
		return length > 0 ? valueMap.get(key)?.[length] : valueMap.get(key)?.[0]
	}
	/**清除当前类所有消息 */
	const removeItem = (key: WebSocketMessageType) => {
		currentItem.value = {}
		return socketStoreMap.delete(key)
	}
	/**清除当前类部分子项 */
	const removeItemSubclass = (key: WebSocketMessageType, ids: number[] = []) => {
		if (socketStoreMap.has(key)) {
			const current = socketStoreMap.get(key)
			// 过滤出有用的消息
			const needList = current?.filter((item) => !ids?.includes(item?.ID)) ?? []
			socketStoreMap.set(key, needList)
		}
	}
	/**获取所有类型的消息总和 */
	const getAllValues = (key: WebSocketMessageType) => {
		if (key) {
			return socketStoreMap.get(key)
		} else {
			return [...socketStoreMap?.values()].flat(1) ?? []
		}
	}
	const clearSocketStoreMap = () => {
		socketStoreMap.clear()
		socketStoreTotal.value = 0
		notifyTotal.value = 0
		approvalTotal.value = 0
		isNewDownLoad.value = false
	}
	return {
		socketStoreMap,
		setItem,
		getItem,
		currentItem,
		getItemLast,
		removeItem,
		removeItemSubclass,
		getAllValues,
		clearSocketStoreMap,
		instanceWebSocket,
		socketStoreTotal,
		notifyTotal,
		approvalTotal,
		isNewDownLoad,
	}
})
