<template>
	<el-form :model="formData" label-width="130px" ref="formRef">
		<el-form-item
			label="请填写作废原因"
			:rules="[{ required: true, message: '请填写作废原因', trigger: 'blur' }]"
			prop="discardDesc"
		>
			<XZLInput v-model="formData.discardDesc" placeholder="请填写作废原因" />
		</el-form-item>
		<div class="dialog-footer">
			<el-button text @click="handleClose">取消</el-button>
			<el-button type="primary" @click="handleCancel">确定</el-button>
		</div>
	</el-form>
</template>
<script setup lang="ts">
import { ElMessage } from 'element-plus'
import { cancelDisStock } from '@/api/serveApi/inventoryManagement/channelInventoryRecord'
import { ref } from 'vue'
interface Props {
	ids?: number[]
}
const props = defineProps<Props>()

const emit = defineEmits(['close', 'refresh'])

// 作废
const formData = ref({
	discardDesc: '',
})
const formRef = ref()

const handleClose = () => {
	emit('close')
}
const handleCancel = () => {
	formRef.value.validate(async (validate) => {
		try {
			if (!validate) return
			const params = {
				ids: props.ids,
				type: 3, // 2:提交，3:作废
				discardDesc: formData.value.discardDesc,
			}
			const res = await cancelDisStock(params)
			if (res && res.code === 0) {
				ElMessage.success('已作废')
				emit('refresh')
			}
		} catch (error) {
			console.log(error)
		}
	})
}
</script>
