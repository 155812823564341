<template>
	<div v-if="nodeData" ref="warper" class="flow-wraper">
		<div class="flow-chart" @mousedown.stop.prevent="onMouseDown" @mousemove.stop.prevent="onMouseMove">
			<node :nodeData="nodeData" :currentNodeId="currentNodeId" />
		</div>
	</div>
</template>

<script setup lang="ts">
import node from './node.vue'
import { FlowChatType } from '../types'
import { onMounted, reactive, ref } from 'vue'

interface Props {
	nodeData?: FlowChatType
	currentNodeId: string
}

defineProps<Props>()

const warper = ref<HTMLElement | null>(null)

const ismove = ref(false) // 是否处于拖拽状态
const mouseDownPoint = reactive({
	x: null,
	y: null,
})
const mouseDownScrollPosition = reactive({
	scrollLeft: null,
	scrollTop: null,
})

onMounted(() => {
	document.onmouseup = () => {
		ismove.value = false
	}
})

const onMouseDown = (e) => {
	ismove.value = true

	//鼠标按下那一刻，滚动条的位置
	mouseDownScrollPosition.scrollLeft = warper.value.scrollLeft
	mouseDownScrollPosition.scrollTop = warper.value.scrollTop

	//鼠标按下的位置坐标
	mouseDownPoint.x = e.clientX
	mouseDownPoint.y = e.clientY
}

const onMouseMove = (e) => {
	if (!ismove.value) return

	//鼠标滑动的实时距离
	let dragMoveDiff = {
		x: mouseDownPoint.x - e.clientX,
		y: mouseDownPoint.y - e.clientY,
	}

	warper.value.scrollLeft = mouseDownScrollPosition.scrollLeft + dragMoveDiff.x
	warper.value.scrollTop = mouseDownScrollPosition.scrollTop + dragMoveDiff.y
}
</script>

<style lang="scss" scoped>
.flow-wraper {
	max-height: 800px;
	padding: 20px 0;
	overflow: hidden;
	user-select: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.flow-chart {
		display: flex;
		align-items: center;
		cursor: grab;
		padding: 8px;
	}
}
</style>
