<template>
	<div v-if="flowData" v-loading="loading.init">
		<XZLCollapse v-model="activeNames">
			<XZLCollapseItem title="审批流程" name="chart">
				<flowChart :nodeData="flowData.approvalFlow" :currentNodeId="flowData.currentApprovalNodeID" />
				<copyUser :users="flowData.copyUsers" />
			</XZLCollapseItem>

			<XZLCollapseItem title="审批记录" name="table">
				<el-table :data="tableData" style="width: 100%" :scrollbar-always-on="true" :border="true">
					<el-table-column prop="CreatedAt" label="时间">
						<template #default="{ row }">
							{{ formatDate(row.CreatedAt, DateType.YMD_HMS) }}
						</template>
					</el-table-column>
					<el-table-column prop="nodeName" label="节点名称" />
					<el-table-column prop="operatorName" label="操作者" />
					<el-table-column prop="operation" label="操作" />
					<el-table-column prop="comments" label="处理意见">
						<template #default="{ row }">
							<div>
								{{ row.comments }}
							</div>

							<el-popover placement="bottom" :width="400" trigger="hover" v-if="row.appendixList.length > 0">
								<template #reference>
									<el-button type="primary" link>查看附件</el-button>
								</template>
								<DocUrlList
									:list="row.appendixList"
									:hiddenOperationList="[DocOperationBtnType.RESETNAME, DocOperationBtnType.DELETE]"
								/>
							</el-popover>
						</template>
					</el-table-column>
				</el-table>
			</XZLCollapseItem>
		</XZLCollapse>
	</div>
	<empty-state v-else type="list" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, reactive, watch } from 'vue'
import { useUserStore } from '@/pinia/modules/user'
import { emitter, MittType } from '@/utils/bus'
import { formatDate, DateType } from '@/utils/format'
import { InitFlowData, type FlowDataType } from './types'

import flowChart from './flowChart/index.vue'
import copyUser from './copyUser/index.vue'
import DocUrlList from '@/components/docUrlList/index.vue'
import { DocOperationBtnType } from '@/hooks/useWorkDoc'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'
import XZLCollapse from '@/components/proxyComponents/XZLCollapse/index.vue'
import XZLCollapseItem from '@/components/proxyComponents/XZLCollapseItem/index.vue'

import { findByCode, getApprovalRecord } from '@/api/serveApi/workflow/workflowRecord'

interface Props {
	flowCode?: string
	rowId?: number
	drawerKey?: any
}

const props = defineProps<Props>()
const { userPermissions } = useUserStore() // 所有菜单

const loading = reactive<Record<string, boolean>>({
	init: false,
})
const activeNames = ref<string[]>(['chart', 'table'])
const flowData = ref<FlowDataType>(new InitFlowData())
const tableData = ref([])

watch(
	() => flowData.value?.approvalProcessHistory,
	(newVal) => {
		if (newVal?.length > 0) {
			tableData.value = newVal.map((item) => {
				return { ...item, appendixList: getFileList(item.appendix) }
			})
		} else {
			tableData.value = []
		}
	},
	{ deep: true }
)

onMounted(() => {
	initData()
})

onUnmounted(() => {
	emitter.off(MittType.RefreshFlowRecord)
})

emitter.on(MittType.RefreshFlowRecord, (refresh: boolean) => {
	if (refresh) {
		initData()
	}
})

// 初始化数据
const initData = async () => {
	loading.init = true
	if (props.flowCode) {
		await getApprovalRecordByCode()
	} else {
		await getApprovalRecordById()
	}
	loading.init = false
}

// 根据流程编号获取流程记录
const getApprovalRecordByCode = async () => {
	const res = await findByCode({ approvalRequestCode: props.flowCode })
	if (res && res.code === 0) {
		flowData.value = res.data
		emitter.emit(MittType.WorkflowData, { module: props.drawerKey, data: res.data })
	}
}

// 根据rowId和菜单id获取流程记录
const getApprovalRecordById = async () => {
	const menuId: number = userPermissions?.find((item) => item.name?.toUpperCase() === props.drawerKey)?.id
	const params = {
		approvalContentId: props.rowId,
		menuId,
	}
	if (!params.approvalContentId || !params.menuId) {
		return
	}
	const res = await getApprovalRecord(params)
	if (res && res.code === 0) {
		flowData.value = res.data
		emitter.emit(MittType.WorkflowData, { module: props.drawerKey, data: res.data })
	}
}

// 转换附件
const getFileList = (appendix: string) => {
	if (!appendix) {
		return []
	}
	const list: Array<{ ID: number; name: string; url: string }> = appendix
		.split(',')
		?.map((item, index) => {
			return {
				ID: index,
				name: item.substring(item.lastIndexOf('/') + 1),
				url: item,
			}
		})
		.filter((item) => item.name?.indexOf('.') > -1)
	return list
}
</script>

