<template>
	<el-cascader
		ref="cascader"
		style="width: 100%"
		:options="salesData"
		v-model="checkIds"
		@change="handleChange"
		:props="prop"
		:show-all-levels="showAllLevels"
		:disabled="disabled"
		:placeholder="props.placeholder"
		clearable
		filterable
		collapse-tags
		collapse-tags-tooltip
	/>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch, toValue } from 'vue'
import { isEqual } from 'lodash-es'
import { findCustomSaleAreaAll, getSaleAreaByMasterId } from '@/api/serveApi/basicInformation/salesArea'
import { useUserStore } from '@/pinia/modules/user'
import { findSelectData } from '@/utils/percent'
const userStore = useUserStore()
interface Props {
	modelValue: number[] | string[] | any
	remote?: boolean // 是否远程获取数据
	data?: any // 数据
	emitPath?: boolean // 	在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值，默认false
	multiple?: boolean // 是否多选, 默认true
	checkStrictly?: boolean // 是否严格的遵守父子节点不互相关联，默认false
	showAllLevels?: boolean // 输入框中是否显示选中值的完整路径, 默认true
	disabled?: boolean // 是否禁用
	placeholder?: string
	masterIds?: any[] //负责人id
	isAllOption?: boolean //是否展示全部勾选项
	isScreen?: boolean
}
const cascader = ref()
const props = withDefaults(defineProps<Props>(), {
	remote: true,
	multiple: true,
	showAllLevels: true,
	placeholder: '可输入名称搜索销售区域',
	isAllOption: false,
	masterIds: () => [useUserStore().userInfo?.ID],
	isScreen: false,
})
const emit = defineEmits(['update:modelValue', 'change'])

const checkIds = ref<Array<number | string>>([])
const salesData = ref([])
const prop = reactive({
	checkStrictly: props.checkStrictly,
	multiple: props.multiple,
	emitPath: props.emitPath,
	value: 'ID',
	label: 'areaName',
})
watch(
	() => props.modelValue,
	(newVal, oldVal) => {
		if (!isEqual(newVal, oldVal)) {
			checkIds.value = props.modelValue
		}
	},
	{
		deep: true,
		immediate: true,
	}
)

watch(
	() => props.data,
	(newVal) => {
		if (newVal?.length > 0) salesData.value = newVal
	},
	{ deep: true }
)
//根据负责人获取销售区域---终端和渠道客户
const getSaleArea = async () => {
	let saleArealist = []
	if (props.remote) {
		const res = await getSaleAreaByMasterId({ ids: props.isScreen ? [] : toValue(props.masterIds) })
		const filterList = res.data?.filter((item) => item.status === 1)
		saleArealist = filterList ? removeNestedObjects(filterList) : []
	} else {
		saleArealist = props.data
	}

	// 判断是否展示全部选项
	if (!props.isAllOption) return (salesData.value = saleArealist)
	salesData.value = [
		{
			ID: 0,
			areaName: '全部',
			level: 1,
			children: [],
		},
	]
	salesData.value[0].children = saleArealist ?? []
}
watch(
	() => props.masterIds,
	(newVal) => {
		getSaleArea()
	},
	{ immediate: true, deep: true }
)

watch(
	() => checkIds,
	(val) => {
		console.log(`output->val-checkIds`, val)
	},
	{
		immediate: true,
		deep: true,
	}
)

const removeNestedObjects = (arr) => {
	// 用于查找给定ID是否在数组或其子数组中
	function isIdPresent(arr, id) {
		for (let item of arr) {
			if (item.ID === id) return true
			if (Array.isArray(item.children) && isIdPresent(item.children, id)) return true
		}
		return false
	}

	// 主递归函数，用于遍历数组和子数组并删除匹配的对象
	function traverse(arr = []) {
		return arr?.reduce((acc, item) => {
			if (!isIdPresent(arr, item.parentId)) {
				acc.push(item)
				if (Array.isArray(item.children)) {
					item.children = traverse(item.children)
				}
			}
			return acc
		}, [])
	}

	return traverse(arr)
}
//获取到销售区域
// const getCustomSaleAreaAll = async () => {
// 	prop.value = 'id'
// 	prop.label = 'area_name'
// 	if (props.remote) {
// 		const res = await findCustomSaleAreaAll()
// 		salesData.value = res.data.reCSA.filter((item) => item.status === 1)
// 	} else {
// 		salesData.value = props.data
// 	}
// }
const handleChange = (value) => {
	let paramData = null
	if (Array.isArray(value)) {
		paramData = value
	} else {
		paramData = [value]
	}
	const findArr = findSelectData(paramData, salesData.value, 'ID', 'children')
	console.log(findArr, value, salesData.value, 'zhessssssss')
	emit('change', findArr)
	emit('update:modelValue', value)
}
</script>
