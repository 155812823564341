<template>
	<div class="el-input" @click="openDialog">
		<div
			class="el-input__wrapper w-full"
			:style="{ 'justify-content': 'flex-start', 'background-color': disabled ? '#f5f7fa' : '' }"
		>
			<div v-if="showTagList && showTagList.length > 0" class="overflow-hidden">
				<!-- 合并标签 -->
				<div v-if="collapseTags" class="overflow-hidden">
					<template v-for="(tag, index) in showTagList" :key="tag[defaultLabel]">
						<el-tag
							v-if="index === 0"
							class="mr-1 overflow-hidden w-full truncate"
							type="info"
							:closable="!disabled"
							@close="removeTag(tag)"
						>
							<el-tooltip effect="dark" v-if="tag[defaultLabel]" :content="tag[defaultLabel]" placement="bottom">
								{{ tag[defaultLabel] }}
							</el-tooltip>
						</el-tag>
					</template>
					<el-popover
						v-if="showTagList.length > 1"
						placement="bottom"
						width="auto"
						trigger="hover"
						:popper-style="{ minWidth: 'unset' }"
					>
						<template #reference>
							<el-tag v-if="showTagList.length > 1" type="info">+{{ showTagList?.length - 1 }}</el-tag>
						</template>
						<div style="max-width: 300px; max-height: 280px; overflow: auto">
							<template v-for="(tag, index) in showTagList" :key="tag[defaultLabel]">
								<el-tag v-if="index !== 0" class="mr-1 mb-1" type="info" :closable="!disabled" @close="removeTag(tag)">
									{{ tag[defaultLabel] }}
								</el-tag>
							</template>
						</div>
					</el-popover>
				</div>

				<!-- 不合并标签 -->
				<template v-else>
					<el-tag
						v-for="tag in showTagList"
						:key="tag[defaultLabel]"
						class="mr-1 max-w-full truncate"
						:closable="!disabled"
						type="info"
						@close="removeTag(tag)"
					>
						{{ tag[defaultLabel] }}
					</el-tag>
				</template>
			</div>
			<div v-else class="text-gray-400">{{ placeholder }}</div>
		</div>
	</div>
	<template>
		<el-dialog
			v-model="dialogVisible"
			:title="dialogTitle"
			width="60%"
			:modal="true"
			:append-to-body="true"
			:close-on-click-modal="false"
			@close="closeDialog"
			destroy-on-close
			align-center
		>
			<el-row style="padding: 0" class="h-full">
				<el-col :span="4">
					<div class="custom-box">
						<XZLInput
							v-model="keyword"
							:prefix-icon="Search"
							type="text"
							placeholder="搜索"
							size="large"
							clearable
							:disabled="disabled"
						/>
						<ul class="flex-1 overflow-auto">
							<li
								v-for="(item, index) in optionList"
								:key="index"
								class="custom-item"
								style="display: block; display: flex; justify-content: space-around"
							>
								<el-tooltip class="box-item" effect="dark" :content="item[defaultLabel]" placement="left-end">
									<span style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{
										item[defaultLabel]
									}}</span>
								</el-tooltip>
								<el-icon
									style="cursor: pointer; height: 32px; line-height: 32px"
									@click="deleteSelect(item)"
									v-if="!disabled || multiple"
								>
									<Close />
								</el-icon>
							</li>
						</ul>
					</div>
				</el-col>
				<el-col :span="20" class="pl-4">
					<FormProTable
						ref="proTable"
						v-model:columns="formatDefaultColumns"
						:highlight-current-row="!multiple"
						:rowKey="defaultValue"
						@select="selectChange"
						@select-all="selectAll"
						@current-change="currentChange"
						:request="{
							api: API,
							params: {
								immediate: true,
								initParam: getFilterStatusData,
								dataCallback: responseFn,
							},
						}"
						v-bind="$attrs"
					>
						<template #queryForm>
							<QueryForm @search="onSubmit" @reset="onReset(true)">
								<template v-for="item in searchForm" :key="item.fieldName">
									<QueryFormItem :prop="item.fieldName" style="width: 100%">
										<XZLInput
											v-if="item.fromType === 'text'"
											v-model="item.fieldValues"
											:placeholder="item.placeholder"
											@keydown.enter="onSubmit"
											clearable
										></XZLInput>
										<div style="width: 100%" v-else-if="item.selectType === SelectType.user">
											<pick-user
												v-model="item.fieldValues"
												:isIncludeDisabled="true"
												:placeholder="item.placeholder"
												collapseTags
											/>
										</div>

										<!-- 销售区域 -->
										<comSalesArea
											v-else-if="item.selectType === SelectType.salesArea"
											v-model="item.fieldValues"
											:multiple="true"
											:checkStrictly="true"
											:placeholder="item.placeholder"
											:isScreen="true"
										/>
										<!-- 渠道客户 -->
										<comDealer
											v-else-if="item.selectType === SelectType.dealer"
											v-model="item.fieldValues"
											supplyWholesalers
											:channelType="item.type === 'distributor' ? 1 : 0"
										/>

										<!-- 默认下拉 -->
										<el-select
											v-else-if="item.fromType === 'select'"
											class="w-full"
											v-model="item.fieldValues"
											:placeholder="item.placeholder"
											multiple
											collapse-tags
											collapse-tags-tooltip
											filterable
											clearable
										>
											<el-option v-for="val in item.valueList" :key="val.value" :label="val.label" :value="val.value" />
										</el-select>
									</QueryFormItem>
								</template>
							</QueryForm>
						</template>
						<!-- 销售区域 -->
						<template #saleAreaNames="{ row }">
							{{ row.saleAreaNames?.toString() }}
						</template>
						<!-- 负责人 -->
						<template #dutys="{ row }">
							{{ row.dutys?.toString() }}
						</template>
						<!-- 负责人 -->
						<template #masterNames="{ row }">
							{{ row.masterNames?.toString() }}
						</template>
					</FormProTable>
				</el-col>
			</el-row>

			<template #footer>
				<el-row class="justify-end">
					<el-button text @click="resetForm">取消</el-button>
					<el-button @click="submitForm" type="primary">保存</el-button>
				</el-row>
			</template>
		</el-dialog>
	</template>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, onMounted, watch, nextTick, toValue, reactive, computed } from 'vue'
import { useAsyncState, useVModel } from '@vueuse/core'
import { useDictionaryType, DictionaryType } from '@/hooks/useDictionaryType'
import { Search, Close } from '@element-plus/icons-vue'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import { useTrigger } from '@/hooks/useTrigger'

import QueryForm from '@/components/queryForm/index.vue'
import QueryFormItem from '@/components/queryForm/item.vue'

import { selectTableTypeOptionsBase, selectTableTypeOptions } from './baseTypeOptions'
import { chanelList } from '@/api/serveApi/common/common'
import {
	InitFieldItem,
	OperatorType,
	SelectType,
	FromType,
	type FieldItemType,
	type FilterType,
} from '@/components/proTable/components/SearchForm/types.ts'
import { BaseType } from '@/config/Constantconfig'
interface Options {
	defaultLabel?: string
	defaultValue?: string
	disabledName?: string
	searchAllStatus?: boolean
}

interface RequestParams {
	page?: number
	pageSize?: number
	filters?: [] | {}
	initialFilters?: Record<string, any>[] // 接口初始化参数
	filterLabel?: string
	searchProp?: string // 搜索变更值字段
	searchName?: string // 搜索需变更字段名
	[key: string]: any
}
interface Props {
	/**外部w-model绑定的值 */
	modelValue: number | number[] | null

	/**用于编辑回显的数据(数组对象) */
	tranmitData?: any[]
	/**是否是多选(默认多选) */
	multiple?: boolean
	/**是否整体禁用 */
	disabled?: boolean
	/**弹窗标题 */
	dialogTitle?: string
	/**操作类型 区分是否 新增情景 */
	type?: OpeationBtnType // 操作类型(ADD|Pick(OpeationBtnType,ADD))
	/** 对常用类型做一个内置处理，通过类型调用，减少重复代码负担 */
	baseType?: BaseType
	api?: (data: any) => any // 请求接口
	/** options 字段属性配置*/
	options: Options
	/**请求参数配置 */
	requestParams?: RequestParams //请求参数(默认分页请求)
	moreParams?: {} // 接口请求的需要的更多参数
	/**展示框的默认占位信息 */
	placeholder?: string
	/**多选时是否将选中值按文字的形式展示 */
	collapseTags?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	/**默认折叠 */
	collapseTags: true,
	/**默认多选 */
	multiple: true,
	/**控制整个组件是否可交互 */
	disabled: false,
	placeholder: '可输入名称搜索',
	dialogTitle: '添加客户',
	type: OpeationBtnType.ADD,

	/**基础配置类型 */
	baseType: '',
	options: {} as Options,
	api: undefined,
	requestParams: {} as RequestParams,
})

const emits = defineEmits(['update:modelValue', 'change', 'current-change'])
const { emitTrigger } = useTrigger()
/**双向绑定字段 modelValue */
const selectTableValue = useVModel(props, 'modelValue', emits)
const { execute: fetchChanelList } = useAsyncState(
	chanelList,
	{
		code: 0,
		data: [],
	},
	{
		immediate: false,
	}
)
const proTable = ref()
const dialogVisible = ref(false)
// 侧边栏搜索字段
const keyword = ref('')
// 左侧选中菜单数组
const checkedList = ref([])
// 默认展示tags列表
const showTagList = ref([])

const searchFilters = ref([])
/**弹窗筛选表单 */
const searchForm = ref()

const options = computed(() => {
	return {
		defaultLabel: 'label',
		defaultValue: 'value',
		disabledName: 'status',
		searchAllStatus: false,
		...props.options,
	}
})

/**默认数据项 key= (默认label) */
const defaultLabel = computed(() => {
	return options.value?.defaultLabel
})

/**默认数据项 value= (默认value) */
const defaultValue = computed(() => {
	return options.value?.defaultValue
})
/**默认数据项 disabled= (默认 status: 1启用, 2禁用) */
const disabledName = computed(() => {
	return options.value?.disabledName
})
/** 是否获取所有状态数据 */
const searchAllStatus = computed(() => {
	return options.value?.searchAllStatus
})

const API = computed(() => {
	return props.api ? props.api : selectTableTypeOptionsBase(props.baseType)?.api
})

/**请求参数来源有三种
 * 1.defaultRequestParams 默认
 * 2.filters 配置表中的定义
 * 3.props?.requestParams 组件主动传入
 */
const requestParams = computed(() => {
	const defaultRequestParams = { immediate: false, page: 1, pageSize: 20 }
	const propsRequestParams = props?.requestParams ? toValue(props?.requestParams) : {}
	// 如果使用内置配置
	if (props.baseType && dialogVisible.value) {
		let filters = searchFilters.value ?? []
		// 继续使用外部初始值
		if (Object.keys(propsRequestParams)?.length > 0) {
			return { ...defaultRequestParams, filters, ...propsRequestParams }
		}
		return { ...defaultRequestParams, filters }
	}
	return { ...defaultRequestParams, ...propsRequestParams }
})

console.log(props, 'label')

/**外部主动定义好的筛选条件，不可用于弹窗筛选，存在必选 */
const requestParamsFilters = computed(() => {
	const needFillterTypeList =
		(requestParams.value?.initialFilters &&
			toValue(requestParams.value?.initialFilters)?.map((item) => item.fieldName)) ??
		[]
	return requestParams.value?.filters?.filter((item) => !needFillterTypeList?.includes(item.fieldName)) ?? []
})
watch(
	() => requestParamsFilters.value,
	(val) => {
		searchForm.value = JSON.parse(JSON.stringify(val))
	},
	{
		immediate: true,
		deep: true,
	}
)

/**根据新增|其他状态，进行是否增加启用条件及null值过滤 */
const getFilterStatusData = computed(() => {
	let params = toValue(requestParams.value?.initialFilters) ?? []
	params = JSON.parse(JSON.stringify(params))
	// 列表接口新增(返回状态1)
	if (props.type == OpeationBtnType.ADD) {
		// 判断是否拥有状态搜索参数
		const findStatus = params.find((item) => item.fieldName == disabledName.value)
		//如果没有，默认增加 只筛选启用数据参数
		!findStatus &&
			params.push({
				fieldName: disabledName.value,
				fieldValues: ['1'],
				fromType: 'select',
				operator: 'IN',
			})

		params = filterNull(params)
		return { filters: params }
	} else {
		// 编辑接口(只选启用状态)
		params.push({
			fieldName: disabledName.value,
			fieldValues: ['1'],
			fromType: 'select',
			operator: 'IN',
		})
		params = filterNull(params, false)
		return { filters: params }
	}
})
/**监听默认选中 回显数据 */
watch(
	() => props.tranmitData,
	(val) => {
		const data = toValue(val)
		if (!data?.length) return
		// 外部展示固定值-用于编辑|操作取消后重新操作等情况回显
		showTagList.value = JSON.parse(JSON.stringify(data))
	},
	{
		deep: true,
		immediate: true,
	}
)

/**根据是否多选，修改modelValue过滤checkedList与tranmitData的数组结构，返回选中的tag信息 */
const filterTagList = (multiple, modelValue, tranmitData) => {
	// 转换选中数据类型
	let filterList = []
	if (multiple) {
		filterList = (modelValue ?? []) as number[]
	} else {
		const filterNumber = modelValue as number
		filterList = [filterNumber]
	}

	// 获取默认需回显数据-存在动态返回
	const data = toValue(tranmitData)

	let tempCheckedList = new Map()
	// 防止modelValue 为空时，直接重置掉checkedList，需一直与默认回显数据进行重组然后过滤
	// 重组当前选中与 默认回显数据与外部展示数据为一体（外部展示数据，在单选时，checkedList可能并不是想要的，所以比较重要）
	const list = [].concat(checkedList.value, data, showTagList.value)
	// 去重
	list?.map((item) => {
		if (item?.[defaultValue.value] && !tempCheckedList.has(item[defaultValue.value])) {
			tempCheckedList.set(item[defaultValue.value], item)
		}
	})
	// 获取唯一数据组
	tempCheckedList = [...tempCheckedList.values()]
	// 根据已有数据组过滤契合选中ID数据
	return tempCheckedList?.filter((item) => filterList?.includes(item[defaultValue.value])) ?? []
}
watch(
	() => [props.multiple, props.modelValue, props.tranmitData],
	([multiple, modelValue, tranmitData]) => {
		const tempCheckedList = filterTagList(multiple, modelValue, tranmitData)
		// 更新最新最干净的选中数据
		checkedList.value = JSON.parse(JSON.stringify(tempCheckedList))
		// 同步外部固定展示值---使用tempCheckedList
		showTagList.value = JSON.parse(JSON.stringify(tempCheckedList))
	},
	{
		immediate: true,
		deep: true,
	}
)

/**通过监听table数据已渲染完成，来赋值已勾选状态 */
watch(
	() => proTable?.value?.tableState?.list,
	async (val) => {
		if (!val?.length) return
		// 获取左侧数组唯一值
		const checkedIdList = checkedList.value?.map((item) => item[defaultValue.value])
		// 等待table数据渲染完成
		await nextTick()
		// 设置符合左侧选中值的 table项
		val?.forEach((item) => {
			if (checkedIdList.includes(item[defaultValue.value])) {
				props.multiple && proTable.value?.element!.XZLTable.toggleRowSelection(item, true)
				if (!props.multiple) {
					proTable.value.element.XZLTable.setCurrentRow(item)
				}
			}
		})
	},
	{
		immediate: true,
		deep: true,
	}
)

// 打开弹窗
const openDialog = async (val) => {
	if (props.disabled) return
	// 重组筛选条件
	const filters = selectTableTypeOptionsBase(props.baseType)?.filters ?? []
	searchFilters.value = JSON.parse(JSON.stringify(filters))
	// 渠道客户 中渠道类型通过接口获取
	if (props.baseType === BaseType.CHANNEL) {
		const res = await fetchChanelList()
		searchFilters.value =
			searchFilters.value?.map((item) => {
				// 为渠道类型增加数据
				if (item.fieldName === 'channel') {
					item.valueList =
						res?.data?.map((item) => {
							return {
								label: item.name,
								value: item.id,
							}
						}) ?? []
				}
				return item
			}) ?? []
	}
	dialogVisible.value = true
}
// 关闭弹窗
const closeDialog = () => {
	// 清空筛选条件
	resetForm()
}

// 取消
const resetForm = () => {
	keyword.value = ''
	dialogVisible.value = false
	// 取消操作 需要重置到外部选中项数据
	checkedList.value = filterTagList(props.multiple, props.modelValue, props.tranmitData)
	onReset(false)
	proTable.value?.element!.XZLTable.clearSelection()
}
// 确认
const submitForm = () => {
	updateFormItem()
	keyword.value = ''
	dialogVisible.value = false
	onReset(false)
	proTable.value?.element!.XZLTable.clearSelection()
	// resetForm()
}

// 全选当前页
const selectAll = (data) => {
	const ids = checkedList.value.map((item) => item[defaultValue.value])
	if (data.length) {
		data.forEach((item) => {
			if (!ids.includes(item[defaultValue.value])) {
				checkedList.value.push(item)
			}
		})
	} else {
		// 当前页数据
		const currentPageData = proTable?.value?.tableState?.list
		const idArr = currentPageData.reduce((prev, item) => {
			if (ids.includes(item[defaultValue.value])) {
				prev.push(item[defaultValue.value])
			}
			return prev
		}, [])
		checkedList.value = checkedList.value.filter((item) => !idArr.includes(item[defaultValue.value]))
	}
}
/**
 * @description 多选勾选的数据(设置到侧边栏)
 * @param data 选中的数据
 * @param row 当前点击的数据（取消时，作为反选依据）
 *  */
const selectChange = (data, row) => {
	let isSelect = data.length && data.indexOf(row) !== -1
	// 选中
	if (isSelect) {
		checkedList.value.push(row)
	} else {
		//取消
		checkedList.value = checkedList.value.filter((item) => item[defaultValue.value] !== row[defaultValue.value])
	}
}
// 单选选中的数据
const currentChange = (data) => {
	// 多选不触发
	if (props.multiple) return false
	// 设置侧边栏
	if (data && typeof data !== 'number') {
		// 未主动设置可搜索全部时，不可勾选失效数据
		if (!searchAllStatus.value && data?.[disabledName.value] == 2) {
			return false
		}
		checkedList.value = [data]
	}
}

// 每次获取新数据前 清空之前勾选框
const responseFn = (data) => {
	proTable.value.element!.XZLTable.clearSelection()
	return data
}

// 判断是否可以勾选(设置禁用)
const selectable = (item) => {
	// 未主动设置可搜索全部时，不可勾选失效数据
	if (!searchAllStatus.value && item[disabledName.value] == 2) return null
	return item
}

// 侧边栏删除
const deleteSelect = async (deleteItem) => {
	// 删除找item,
	checkedList.value = checkedList.value.filter((item) => item[defaultValue.value] !== deleteItem[defaultValue.value])
	// 单选
	if (!props.multiple) {
		proTable.value.element!.XZLTable.setCurrentRow()
		return
	}
	// 多选
	const findItem = proTable.value?.tableState.list.find(
		(item) => item[defaultValue.value] == deleteItem[defaultValue.value]
	)
	props.multiple && proTable.value.element!.XZLTable.toggleRowSelection(findItem, false)
}
// TODO 暂时未知使用场景，可以在组件销毁时清空
// 清除选择
const clearSelect = () => {
	// if (proTable.value) {
	// 	proTable.value.radio = ''
	// }
}

/**更新表单值，用于提交 */
const updateFormItem = () => {
	const ids = checkedList.value.map((item) => item[defaultValue.value]) || []
	const result = props.multiple ? ids : ids[0]
	// 同步更新外部字段，然后监听值的变化引导 checkedList 过滤
	// 更新v - model
	console.log(`output->result`, result)
	selectTableValue.value = result
	emits('change', checkedList)

	emitTrigger()
}

// 删除tag(选择框)
const removeTag = async (tag) => {
	checkedList.value = checkedList.value.filter((item) => item[defaultValue.value] !== tag[defaultValue.value])
	updateFormItem()
}

/**排除初始化参数值为null 并且根据新增或编辑 移除只筛选启用项 条件 */
const filterNull = (params, isFilterStatus = false) => {
	//筛选项中searchAllStatus为true，不做启用区分
	if (searchAllStatus.value) {
		params = params.filter((item) => item.fieldName !== disabledName.value)
	}
	params = JSON.parse(JSON.stringify(params))
	return params.filter((item) => {
		// 是否不删除 只过滤启用项筛选条件
		const isNotDeleteStatus = !isFilterStatus ? true : item.fieldName !== disabledName.value
		const searchProp = selectTableTypeOptionsBase(props.baseType)?.searchProp ?? []
		return isNotDeleteStatus && item[searchProp]?.[0] !== 'null' && item[searchProp]?.[0] !== 'undefined'
	})
}
// 格式化defaultClumn
const formatDefaultColumns = computed(() => {
	// 都来源与配置文件中的defaultColumns 后续尽量也不在页面引入存在较大篇幅，统一使用配置文件
	let defaultColumns = selectTableTypeOptionsBase(props.baseType)?.defaultColumns ?? []
	const typeItem = { type: props.multiple ? 'selection' : 'radio', reserveSelection: true, selectable }
	// 重写勾选类型
	defaultColumns = [typeItem, ...defaultColumns]
	defaultColumns?.forEach((item) => {
		Object.assign(item, { showOverflowTooltip: true, isShow: true, isLock: false, slotName: item.prop })
	})
	return defaultColumns
})

// 侧边栏搜索变化后展示菜单
const optionList = computed(() => {
	const filterKeyword = toValue(keyword.value).replace(/\s+/g, '')
	return checkedList.value.filter((item) => item[defaultLabel.value].includes(filterKeyword))
})
/**调整搜索所需用的 filter字段内容结构 */
const adjustFilterParams = (filters) => {
	return filters
		?.map((item) => {
			// 文本类型 值改为数组类型
			if (item.fromType === 'text' && !Array.isArray(item.fieldValues)) {
				item.fieldValues = item.fieldValues ? [item.fieldValues] : []
			}
			//数组类型中 值需要是字符串类型
			item.fieldValues = item.fieldValues.map((val) => {
				val = '' + val
				return val
			})
			return item
		})
		.filter((item) => {
			return item.fieldValues.length !== 0
		})
}

// 搜索条件点击-搜索
const onSubmit = () => {
	let filters = JSON.parse(JSON.stringify(searchForm.value))
	// 修改筛选条件格式，用于满足接口需求
	filters = adjustFilterParams(filters)
	proTable.value.getTableList({
		params: {
			filters,
		},
		pageable: { page: 1 },
	})
}
// 搜索条件点击-重置|取消
const onReset = async (isReset) => {
	// 使用简易深度遍历-防止对初始数据污染
	searchForm.value = JSON.parse(JSON.stringify(requestParamsFilters.value))

	await nextTick()
	// 重置使用初始数据
	if (isReset) {
		onSubmit()
	}
}

defineExpose({
	clearSelect,
})
</script>

<style lang="scss" scoped>
.el-tag {
	border-width: 0;

	:deep(.el-tag__content) {
		@apply truncate;
	}
}

.custom-box {
	display: flex;
	flex-direction: column;
	height: calc(100% - 44px);
	border: 1px solid #ebeef5;
	border-radius: var(--el-border-radius, var(--el-border-radius-base));

	:deep(.el-input__wrapper) {
		margin-bottom: 10px;
		box-shadow: none;
		border-bottom-left-radius: unset;
		border-bottom-right-radius: unset;
		border-bottom: 1px solid #ebeef5;
	}

	.custom-item {
		line-height: 32px;
		font-size: 14px;
		padding: 0 10px;
	}

	.is-active,
	.custom-item:hover {
		background: #f5f7fa;
	}
}

.disabled {
	background-color: text-gray-400;
}
</style>

