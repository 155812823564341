import { getDictionariesList, getSysSettingDictionaries } from '@/api/serveApi/systemSetting/dictionaryManage'
import { useAsyncState } from '@vueuse/core'
import { OpeationBtnType } from './useOperationBtnList'
import { ref } from 'vue'
/**
 * @description 所有的字典字段名称，后面对应的是接口的code唯一值
 */
export enum DictionaryType {
	/**组织类型 */
	ORGANIZATIONNAME = 'Jm0GAGBTgI2GWhb',
	/**渠道类型 */
	CHANNELTYPES = 'ReNYRG3foCZIfka',
	/**终端类型 */
	TERMINALTYPES = '8C98GvgyA5bz3WT',
	/**终端级别 */
	TERMINALLEVELS = 'UNEAbUdtCREOhVF',
	/**形态 */
	SHAPETYPE = 'F0R417xjGJkS2FO',
	/**产品单位 */
	PRODUCTUNIT = 'i67WIsID1xZtTwz',
	/**辅助单位 */
	AUXILIARYUNIT = 'beAiDaEQRqhc4cE',
	/**产品品牌 */
	BRANDING = '23KbXYoPn6MR8bp',
	/**产品品类 */
	PRODUCTCATEGORY = 'gTHNWDBtW5q6JWc',
	/**竞品品类 */
	COMPETITORCATEGORY = '4eXZDgFwgbPJCyT',
	/**竞品品牌 */
	COMPETITORBRANDS = 'JVpMF8iQc7niqdr',
	/**失访原因 */
	LOSSREASON = 'vgVufvy56SGCCq7',
	/**维修类型 */
	REPAIRTYPE = 'D95sagj0X4JxR6h',
	/**故障类型 */
	FAULTTYPE = 't1HH5IJ8ghCU1qk',
	/**摆放位置 */
	PLACEMENTPOSITION = 'htr7OIKSvMoXJPD',
	/**模板类型 */
	TEMPLATETYPE = 'CEwaB1cRtMYOQyN',
	/**合同类型 */
	CONTRACTTYPE = 'Uhq3YZytNuPAzAJ',
	/**KA系统类型 */
	KASYSTEMTYPE = 'C07dbd0def5d9cK',
	/**通知公告类型 */
	ANNOUNCEMENTTYPE = 'iSq3osk30dKAzIK',
	/**作废类型 */
	CANCELTYPE = 'iSq3osk30dKgbk',
}

// /**
//  * @deprecated 已弃用，请使用useDictionaryType
//  * @description  根据code来过滤出相对于的选项
//  * @param types  字典对应的code码数组
//  */
// export const filterDictionaryData = async (types: string[]): Promise<any> => {
// 	const res = await getDictionariesList({ page: 1, pageSize: 1000 })
// 	const options = []
// 	for (const type of types) {
// 		const optionData = res.data.list.find((item) => item.code === type)
// 		if (!optionData) {
// 			options.push([])
// 			continue
// 		}
// 		//使用reduce提取optionId和optionValue属性，避免使用map操作
// 		const optionList = optionData.dicDetailList.reduce((acc, { optionId, optionValue, status }) => {
// 			acc.push({ optionId, optionValue, status })
// 			return acc
// 		}, [])
// 		options.push(optionList)
// 	}
// 	return options
// }

export const useDictionaryType = (isFilter = false) => {
	const dictionaryMap = ref<Record<DictionaryType, any>>({} as Record<DictionaryType, any>)
	// 查询所有字典配置Map
	const { state, execute: fetchGetContractViewUrl } = useAsyncState(
		getSysSettingDictionaries,
		{
			code: 0,
			msg: '',
			data: {} as Record<DictionaryType, any>,
		},
		{
			onSuccess: (res) => {
				if (res.code !== 0) return
				dictionaryMap.value = res.data
				console.log(`output->dictionaryMap`, dictionaryMap)
			},
		}
	)

	/**
	 * @description 获取关联枚举映射字典
	 * @param mappingId 关联的id
	 * @param dictionaryType 当前字典的枚举key
	 * @returns 数组类型的当前类字典值
	 */
	const getMappingDictionary = (mappingId: number | string, dictionaryType: DictionaryType, isEnable = true) => {
		// 通过字典枚举key，找到相应的数组
		const sourceMap = dictionaryMap.value?.[dictionaryType] ? [...dictionaryMap.value?.[dictionaryType]] : []

		// 过滤出关联字典数据并且需要是启用
		const filterList = sourceMap?.filter((item) => item.parentOptionId === +mappingId)
		return filterList ?? []
	}
	//获取终端名字
	const getTerminalType = (channelType: number, terminalType: number, dictionaryType: DictionaryType) => {
		// 用于列表展示 获取全部字典字段
		const terminalConfig = getMappingDictionary(channelType, dictionaryType, false)
		const terminalName = terminalConfig.find((item) => item.optionId === terminalType)?.optionValue
		return terminalName ?? ''
	}

	/**
	 * @description 获取关联枚举映射字典
	 * @param mappingId 关联的id
	 * @param dictionaryType 当前字典的枚举key
	 * @returns 当前字典的值
	 */
	const getDictionaryValueById = (mappingId: number | string, dictionaryType: DictionaryType) => {
		// 通过字典枚举key，找到相应的数组
		const sourceMap = dictionaryMap.value?.[dictionaryType] ? [...dictionaryMap.value?.[dictionaryType]] : []

		// 过滤出关联字典数据
		const filterList = sourceMap?.filter((item) => item.optionId === +mappingId) ?? []
		return filterList?.[0]?.optionValue
	}
	return { dictionaryMap, getMappingDictionary, getTerminalType, getDictionaryValueById }
}
