<template>
	<!-- 弹窗(dialog) -->
	<template v-if="viewType === 'dialog'">
		<div v-if="$slots.reference" @click="handleOpen">
			<slot name="reference"></slot>
		</div>
		<inputBox
			v-else
			:userList="checkList"
			:active="showDialog"
			:collapseTags="collapseTags"
			:placeholder="placeholder"
			:disabled="disabled"
			@remove="removeUser"
			@removeAll="removeAll"
			@click="handleOpen"
		/>
		<el-dialog v-model="showDialog" :title="dialogTitle" width="840" append-to-body>
			<userBox
				v-if="showDialog"
				v-model="checkList"
				:isAuth="isAuth"
				:isIncludeDisabled="isIncludeDisabled"
				:unSelectable="unSelectable"
				:uniqueUser="uniqueUser"
				:multiple="multiple"
				@conform="handleConfirm"
				@cancel="handleCancel"
			/>
		</el-dialog>
	</template>

	<!-- 弹出层(popover) -->
	<template v-else-if="viewType === 'popover'">
		<el-popover
			v-model:visible="showPopover"
			placement="bottom-start"
			width="auto"
			trigger="click"
			:popper-style="{ padding: '20px' }"
			:disabled="disabled"
		>
			<template #reference>
				<div v-if="$slots.reference" @click="handleOpen">
					<slot name="reference"></slot>
				</div>
				<inputBox
					v-else
					:userList="checkList"
					:active="showPopover"
					:collapseTags="collapseTags"
					:placeholder="placeholder"
					:disabled="disabled"
					@remove="removeUser"
					@removeAll="removeAll"
				/>
			</template>

			<userBox
				v-if="showPopover"
				v-model="checkList"
				:isAuth="isAuth"
				:isIncludeDisabled="isIncludeDisabled"
				:unSelectable="unSelectable"
				:uniqueUser="uniqueUser"
				:multiple="multiple"
				@conform="handleConfirm"
				@cancel="handleCancel"
			/>
		</el-popover>
	</template>

	<!-- 面板(panel) -->
	<template v-else-if="viewType === 'panel'">
		<userBox
			v-model="checkList"
			:isAuth="isAuth"
			:isIncludeDisabled="isIncludeDisabled"
			:unSelectable="unSelectable"
			:uniqueUser="uniqueUser"
			:multiple="multiple"
			@conform="handleConfirm"
			@cancel="handleCancel"
		/>
	</template>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { isEqual } from 'lodash-es'
import { useVModel } from '@vueuse/core'
import { pick } from 'lodash-es'
import { useTrigger } from '@/hooks/useTrigger'
import { ListItem, Category, UserType } from './types'
import inputBox from './inputBox.vue'
import userBox from './userBox.vue'
import { getUserInfoList } from '@/api/serveApi/basicInformation/personnelMgr'

interface Props {
	// 绑定值，类型为 number 或 number[]
	// 默认返回类型: 多选返回数组，单选返回number
	modelValue: number | number[] | null

	// 是否需要数据权限，默认 true
	isAuth?: boolean

	// 是否需要停用的数据，默认false
	isIncludeDisabled?: boolean

	// 视图类型，默认 dialog
	viewType?: 'popover' | 'dialog' | 'panel'

	// 弹窗标题
	dialogTitle?: string

	// 不可选择的用户【userId】集合，默认为空
	// 默认该用户所有身份不可选择，可以通过【uniqueUser】属性控制是否可以选择用户其他身份
	unSelectable?: number[]

	// 与【unSelectable】配合使用，默认 true
	// 若【uniqueUser】为 true 时，则表示用户所有身份不可选择，反之则用户其他身份可以被选择
	uniqueUser?: boolean

	// 多选，默认 true
	multiple?: boolean

	// 多选时是否将选中值按文字的形式展示，默认 false
	collapseTags?: boolean

	// 单选的返回值类型，默认 number
	singleValueType?: 'number' | 'array'

	// 是否禁用，默认 false
	disabled?: boolean

	// 占位符
	placeholder?: string
}

const props = withDefaults(defineProps<Props>(), {
	unSelectable: () => [],
	uniqueUser: true,
	isAuth: true,
	isIncludeDisabled: false,
	viewType: 'dialog',
	multiple: true,
	collapseTags: false,
	singleValueType: 'number',
	disabled: false,
	dialogTitle: '选择人员',
	placeholder: '请选择',
})
const emit = defineEmits(['update:modelValue', 'change', 'confirm', 'cancel'])
const userData = useVModel(props, 'modelValue', emit)

const showDialog = ref<boolean>(false)
const showPopover = ref<boolean>(false)
const checkList = ref<ListItem[]>([])
const { emitTrigger } = useTrigger()

// 监听绑定值变化，如果有值则获取对应人员信息
watch(
	() => props.modelValue,
	() => {
		initUserList()
		emitTrigger()
	},
	{ deep: true }
)

onMounted(() => {
	initUserList()
})

// 初始化绑定数据(已有绑定值时)
const initUserList = () => {
	const values = props.modelValue
	const hasValue: boolean = typeof values === 'number' ? !!values : Array.isArray(values) && values.length > 0
	if (hasValue) {
		const checkedIds = checkList.value.map((item) => item.userId)
		const userIDList: number[] = Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
		if (!isEqual(checkedIds, userIDList)) {
			getUserList(userIDList)
		}
	} else {
		checkList.value = []
	}
}

// 根据用户ID获取用户信息
const getUserList = async (userIDList: number[]): Promise<void> => {
	const res = await getUserInfoList({
		userIDList,
	})
	if (res && res.code === 0) {
		checkList.value = res.data?.map((item) => {
			return {
				type: Category.User,
				empId: item.empId,
				userId: item.userId,
				userName: item.name,
				departmentId: item.departmentId,
				departmentName: item.departmentName,
				departmentNameList: item.departmentNameList,
				jobPositionId: item.jobPositionId,
				jobPositionName: item.jobPositionName,
				headerImg: item.headerImg,
			}
		})
	}
}

// 删除用户
const removeUser = (index: number) => {
	checkList.value.splice(index, 1)
	handleConfirm()
}

// 删除全部
const removeAll = () => {
	checkList.value = []
	handleConfirm()
}

// 打开
const handleOpen = () => {
	if (props.disabled) return
	if (props.viewType === 'dialog') {
		showDialog.value = true
	}
}

// 关闭弹窗或popover
const handleClose = () => {
	switch (props.viewType) {
		case 'dialog':
			showDialog.value = false
			break
		case 'popover':
			showPopover.value = false
			break
		default:
	}
}

// 点击取消
const handleCancel = () => {
	switch (props.viewType) {
		case 'dialog':
			showDialog.value = false
			break
		case 'popover':
			showPopover.value = false
			break
		case 'panel':
			emit('cancel')
			break
		default:
	}
}

// 点击确定
const handleConfirm = () => {
	let updateValue: number | number[] = null
	// 多选默认返回数组类型，单选默认返回number类型，单选时也可以指定返回数组类型
	if (!props.multiple && props.singleValueType === 'number') {
		updateValue = checkList.value?.length > 0 ? checkList.value?.[0].userId : null
	} else {
		updateValue = checkList.value?.map((item) => item.userId)
	}
	userData.value = updateValue
	emit('change', updateValue)
	emit('confirm', getCheckedUser())
	handleClose()
}

// 获取已选中联系人列表
const getCheckedUser = (): UserType[] => {
	return checkList.value.map((item: ListItem) => {
		return pick(item, [
			'empId',
			'userId',
			'userName',
			'departmentId',
			'departmentName',
			'departmentNameList',
			'jobPositionId',
			'jobPositionName',
			'headerImg',
		])
		// return {
		// 	empId: item.empId,
		// 	userId: item.userId,
		// 	userName: item.userName,
		// 	departmentId: item.departmentId,
		// 	departmentName: item.departmentName,
		// 	departmentNameList: item.departmentNameList,
		// 	jobPositionId: item.jobPositionId,
		// 	jobPositionName: item.jobPositionName,
		// 	headerImg: item.headerImg,
		// }
	})
}

defineExpose({
	getCheckedUser,
})
</script>
