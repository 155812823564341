import mitt from 'mitt'

/**
 * 事件类型
 * @param ShowLoading // 显示加载中
 * @param CloseLoading // 关闭加载中
 * @param CloseAllPage // 关闭所有导航tab
 * @param SetKeepAlive // 设置保持活动状态
 * @param Reload // 重新加载当前页
 * @param WorkflowData // 工作流数据
 * @param RefreshFlowRecord // 刷新流程记录
 * @param FlowFieldList // 流程字段列表
 * @param FlowFieldRules // 流程字段规则
 * @param SelectValue // 流程字段规则下拉值
 * @param DOWNLOADCENTER 下载中心
 * @param MessageCenter 消息中心
 */

export enum MittType {
	ShowLoading = 'showLoading',
	CloseLoading = 'closeLoading',
	CloseAllPage = 'closeAllPage',
	SetKeepAlive = 'setKeepAlive',
	Reload = 'reload',
	WorkflowData = 'workflowData',
	RefreshFlowRecord = 'refreshFlowRecord',
	FlowFieldList = 'flowFieldList',
	FlowFieldRules = 'flowFieldRules',
	SelectValue = 'selectValue',
	DOWNLOADCENTER = 'DOWNLOADCENTER',
	MessageCenter = 'MessageCenter',
}

export const emitter = mitt()
