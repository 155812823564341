import service from '@/utils/request'

//分页获取CustomSaleArea列表
export const getCustomSaleAreaList = (data: any) => {
	return service({
		url: '/saleArea/getCustomSaleAreaList',
		method: 'post',
		data,
	})
}

//根据父级区域ID返回树状数据

export const getSaleAreaByParentIdAll = (params: any) => {
	return service({
		url: '/saleArea/getSaleAreaByParentIdAll',
		method: 'get',
		params,
	})
}

//用id查询CustomSaleArea
export const findCustomSaleArea = (params: any) => {
	return service({
		url: '/saleArea/findCustomSaleArea',
		method: 'get',
		params,
	})
}

/**创建销售区域 */
export const createCustomSaleArea = (data: any) => {
	return service({
		url: '/saleArea/createCustomSaleArea',
		method: 'post',
		data,
	})
}

//批量删除CustomSaleArea
export const deleteCustomSaleAreaByIds = (data: any) => {
	return service({
		url: '/saleArea/deleteCustomSaleAreaByIds',
		method: 'delete',
		data,
	})
}

/**更新销售区域 */
export const updateCustomSaleArea = (data: any) => {
	return service({
		url: '/saleArea/updateCustomSaleArea',
		method: 'put',
		data,
	})
}

//启用
export const enableSaleArea = (data: any) => {
	return service({
		url: '/saleArea/enableSaleArea',
		method: 'put',
		data,
	})
}
//停用
export const deactivateSaleArea = (data: any) => {
	return service({
		url: '/saleArea/deactivateSaleArea',
		method: 'put',
		data,
	})
}

//销售区域文件导入
export const importFile = (data: any) => {
	return service({
		url: '/saleArea/importSaleArea',
		headers: { 'Content-Type': 'multipart/form-data' },
		method: 'post',
		data,
	})
}

//销售区域文件导出
export const exportFile = (data: any) => {
	return service({
		url: '/saleArea/getCustomSaleAreaList',
		method: 'post',
		// responseType: 'blob',
		data,
	})
}
//获取id获取到最上级的销售区域id
export const getSaleAreaParentInfo = (params) => {
	return service({
		url: '/saleArea/getSaleAreaParentInfo',
		method: 'get',
		params,
	})
}

//获取负责人查找销售区域
export const getSaleAreaByMasterId = (data) => {
	return service({
		url: '/saleArea/getSaleAreaByMasterId',
		method: 'post',
		data,
	})
}

//获取所有销售区域tree结构数据
export const findCustomSaleAreaAll = () => {
	return service({
		url: '/saleArea/findCustomSaleAreaAll',
		method: 'get',
	})
}

//根据父级ID查询销售区域信息
export const findCustomSaleAreaByParentId = (params: any) => {
	return service({
		url: '/saleArea/findCustomSaleAreaByParentId',
		method: 'get',
		params,
	})
}

/**获取所有销售区域tree结构数据 */
export const getCustomSaleAreaAllToTree = (params?: any) => {
	return service({
		url: '/saleArea/getCustomSaleAreaAllToTree',
		method: 'get',
		params,
	})
}

/**销售区域设置主负责人 */
export const setSaleAreaMaster = (data: any) => {
	return service({
		url: '/saleArea/setSaleAreaMaster',
		method: 'put',
		data,
	})
}

/**获取区域编号随机数 */
export const getXSCode = (params: any) => {
	return service({
		url: '/saleArea/getXSCode',
		method: 'get',
		params,
	})
}
