import service from '@/utils/request'

/** impor-导入 avatar-头像 appendix-(附件|图片) system-系统文件  template-模版文件*/
export type FileType = 'import' | 'avatar' | 'appendix' | 'system' | 'template'
interface UploadType {
	fileType: FileType
}

// 上传头像
export const upload = (data: any, params: UploadType) => {
	return service({
		url: '/fileUploadAndDownload/upload',
		method: 'post',
		headers: {
			'Content-type': 'multipart/form-data',
		},
		data,
		params,
	})
}
interface delPageConfType {
	pageId: string
}
//删除筛选设置pageId
export const delPageConf = (data: delPageConfType) => {
	return service({
		url: '/Visited/delPageConf',
		method: 'delete',
		data,
	})
}

// 获取渠道客户类型列表
export const chanelList = (params?: any) => {
	return service({
		url: '/distributorManage/chanelList',
		method: 'get',
		params,
	})
}

// 获取经营品类列表
export const categoryList = (params?: any) => {
	return service({
		url: '/distributorManage/categoryList',
		method: 'get',
		params,
	})
}

// 获取经营品牌列表
export const brandList = (params?: any) => {
	return service({
		url: '/distributorManage/brandList',
		method: 'get',
		params,
	})
}

// 获取地区列表
export const areaList = (params?: any) => {
	return service({
		url: '/distributorManage/areaList',
		method: 'get',
		params,
	})
}

// 获取所有的经销商
export const getAllDealer = (data: any) => {
	return service({
		url: '/distributorManage/getDistributorListV3',
		method: 'post',
		data: data,
	})
}
export const changePassword = (data) => {
	return service({
		url: '/user/changePassword',
		method: 'post',
		data: data,
	})
}

//获取所有客户列表接口
export const getCustomerList = (params?: any) => {
	return service({
		url: '/customerMiddle/getCustomerMiddleList',
		method: 'get',
		params,
	})
}
//获取工厂列表信息
export const getFactoryList = (data: any) => {
	return service({
		url: '/factoryManage/getCusFactoryList',
		method: 'post',
		data,
	})
}

//(获取所有启用科目列表)

export const getPreviousFeeSubjects = (data: any) => {
	return service({
		url: '/expenseAccount/getAllParentAccount',
		method: 'post',
		data,
	})
}
/**
 * 根据详细地址获取行政区域id
 * @param data
 * @returns
 */
export const getAreaId = (data: any) => {
	return service({
		url: '/distributorManage/getAreaId',
		method: 'post',
		data,
	})
}

/** 单条日志埋点接口 */
export const createSysFrontLog = (data: any) => {
	return service({
		url: '/sysFrontLog/createSysFrontLog',
		method: 'post',
		data,
	})
}
/**多条日志埋点接口 */
export const createSysFrontLogList = (data: any) => {
	return service({
		url: '/sysFrontLog/createSysFrontLogList',
		method: 'post',
		data,
	})
}

/**动态url，减少service向外暴露
 * get 形式
 */
export const fetchGetDynamicUrl = (dynamicUrl: string) => {
	return service({
		url: dynamicUrl,
		method: 'get',
	})
}
/**动态url，减少service向外暴露
 * post 形式
 */
export const fetchPostDynamicUrl = (dynamicUrl: string, data: any) => {
	return service({
		url: '/sysFrontLog/createSysFrontLog',
		method: 'post',
		data,
	})
}
