import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/notification/style/css'
import 'element-plus/es/components/message-box/style/css'
import './style/element_visiable.scss'
import 'element-plus/theme-chalk/src/index.scss'
import { createApp } from 'vue'

// 引入封装的router
import router from '@/router/index'
import '@/permission'
import mainDirective from '@/directive/main'
import auth from '@/directive/auth'
import Drawer from '@/components/Drawer/index.vue'
import tableList from '@/components/tableList/index.vue'
import DateSelet from '@/components/DateSelet/index.vue'
import selectUser from '@/components/selectUser/index.vue'
import pickUser from '@/components/pickUser/index.vue'
import provinceLinkage from '@/components/provinceLinkage/index.vue'
import comSalesArea from '@/components/comSalesArea/index.vue'
import comTagTree from '@/components/comTagTree/index.vue'
import comDealer from '@/components/comDealer/index.vue'
import comOrganiz from '@/components/comOrganiz/index.vue'
import comPosition from '@/components/comPosition/index.vue'
import imagePreview from '@/components/imagePreview/index.vue'
import flowRecord from '@/components/flowRecord/index.vue'
import DetailsTemplate from '@/components/detailsTemplate/index.vue'
import XZLPagination from '@/components/proxyComponents/XZLPagination/index.vue'
import XZLSelect from '@/components/proxyComponents/XZLSelect/index.vue'
import XZLSelectV2 from '@/components/proxyComponents/XZLSelectV2/index.vue'
import ListStatus from '@/components/listStatus/index.vue'
import ListApprovalStatus from '@/components/listApprovalStatus/index.vue'
import ListProTable from '@/components/proTableV2/listProTable/index.vue'
import FormProTable from '@/components/proTableV2/formProTable/index.vue'
import mapPreview from '@/components/mapPreview/index.vue'
import XZLInput from '@/components/proxyComponents/XZLInput/index.vue'
import { store } from '@/pinia'
import App from './App.vue'
import other from '@/utils/other'

/**
 * @description 导入加载进度条，防止首屏加载时间过长，用户等待
 *
 * */
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

Nprogress.configure({ showSpinner: false, ease: 'ease', speed: 500 })
Nprogress.start()

/**
 * 无需在这块结束，会在路由中间件中结束此块内容
 * */

const app = createApp(App)
app.config.productionTip = false
other.elSvg(app)
app.use(store).use(mainDirective).use(auth).use(router).mount('#app')

// 注册全局组件
app.component('Drawer', Drawer) // 图片预览
app.component('tableList', tableList) // 表格
app.component('DateSelet', DateSelet) // 拜访频率
app.component('selectUser', selectUser) // 人员选择器
app.component('pickUser', pickUser) // 人员选择器 - 新版
app.component('provinceLinkage', provinceLinkage) //行政区域
app.component('comSalesArea', comSalesArea) //销售区域组件
app.component('comTagTree', comTagTree) //客户标签组件
app.component('comDealer', comDealer) //渠道客户组件
app.component('comOrganiz', comOrganiz) //组织组件
app.component('comPosition', comPosition) //组织组件
app.component('imagePreview', imagePreview) // 图片预览
app.component('flowRecord', flowRecord) // 审批记录
app.component('DetailsTemplate', DetailsTemplate) //详情页结构聚合
app.component('XzlPagination', XZLPagination) // 分页组件
app.component('XZLSelect', XZLSelect) // 下拉组件
app.component('XZLSelectV2', XZLSelectV2) // 下拉组件（虚拟列表）
app.component('ListStatus', ListStatus) // 列表状态
app.component('ListApprovalStatus', ListApprovalStatus) // 列表审批状态
app.component('ListProTable', ListProTable) // 列表高级表格
app.component('FormProTable', FormProTable) // 表单高级表格
app.component('mapPreview', mapPreview) // 地址预览
app.component('XZLInput', XZLInput) // 输入框组件

export default app
