import axios, { CancelToken as CancelTokenObj, isCancel } from 'axios' // 引入axios
import { ElMessage, ElMessageBox } from 'element-plus'
import { useUserStore } from '@/pinia/modules/user'
import { emitter, MittType } from '@/utils/bus'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router/index'
import { useWebSocket } from '@/hooks/useWebSocket'
import { useWebsocketStore } from '@/pinia/modules/websocket'
export const CancelToken = CancelTokenObj

const interfaceAllowlist = ['/Visited/getPageConf', '/sysFrontLog/createSysFrontLog']
const service = axios.create({
	baseURL: import.meta.env.VITE_BASE_API,
	timeout: 9e5, // 15分钟的超时时间
})
let acitveAxios = 0
let timer
const showLoading = () => {
	acitveAxios++
	if (timer) {
		clearTimeout(timer)
	}
	timer = setTimeout(() => {
		if (acitveAxios > 0) {
			emitter.emit(MittType.ShowLoading)
		}
	}, 400)
}

const closeLoading = () => {
	acitveAxios--
	if (acitveAxios <= 0) {
		clearTimeout(timer)
		emitter.emit(MittType.CloseLoading)
	}
}
// http request 拦截器
service.interceptors.request.use(
	(config) => {
		if (!config.donNotShowLoading) {
			showLoading()
		}
		const userStore = useUserStore()
		config.headers = {
			'Content-Type': 'application/json',
			'x-token': userStore.token,
			'x-user-id': userStore.userInfo.ID,
			'x-trace-id': uuidv4(),
			...config.headers,
		}
		return config
	},
	(error) => {
		if (!error.config?.donNotShowLoading) {
			closeLoading()
		}
		ElMessage({
			showClose: true,
			message: error,
			type: 'error',
		})
		return error
	}
)

// http response 拦截器
service.interceptors.response.use(
	(response) => {
		console.log(`output->response123`, response)
		const userStore = useUserStore()

		// 筛选出不校验报错的接口
		const isAllow = interfaceAllowlist.some((item) => response.config?.url?.includes(item))
		// console.log(`output->isAllow`, isAllow)
		if (!response.config?.donNotShowLoading) {
			closeLoading()
		}
		if (response.headers['new-token']) {
			userStore.setToken(response.headers['new-token'])
		}
		// 白名单交互-不校验报错
		if (isAllow) {
			return (
				response.data ?? {
					data: {},
				}
			)
		}
		if (response.data.code === 0 || response.headers.success === 'true') {
			if (response.headers.msg) {
				response.data.msg = decodeURI(response.headers.msg)
			}
			return response.data
			// TODO 后续看有没有想过需求点 去除
		}
		if (response.statusText == 'OK' && response.request.responseType === 'blob') {
			return response
		}
		// 下载zip包使用
		if (response.request.responseType === 'arraybuffer') {
			return response
		}
		if ([8, 9, 10].includes(response.data.code)) {
			// 定义一个不可能的初始值（去除Badge
			const repeatNum = -999
			ElMessage({
				showClose: true,
				grouping: true,
				repeatNum,
				message: response.data.msg || decodeURI(response.headers.msg),
				type: 'error',
				// duration: 0,
			})
			if (response.data.data && response.data.data.reload) {
				userStore.token = ''
				localStorage.clear()
				router.push({ name: 'Login', replace: true })
			}
		}
		//code=8 为多端登录状态，token失败
		if (response.data.code === 8) {
			// 防止刷新后直接调用websocket 先判断存在 再进行终止
			const websocketStore = useWebsocketStore()
			if (websocketStore.instanceWebSocket) {
				const { status, data, send, open, close } = useWebSocket()
				// token失效 主动关闭当前websocket
				close(1000)
			}
			return response.data
		}

		//异地登录抢占
		if (response.data.code === 9) {
			//上面做了操作公共处理
			throw new Error(response.data.msg)
		}
		// token被动失效（到了失效时间）
		if (response.data.code === 10) {
			//上面做了操作公共处理
			return
		}
		// html资源获取
		console.log(`output->response.headers`, response.headers['content-type'])
		if (response.headers?.['content-type']?.includes('text/html')) {
			return response.data
		}
		/** 高德地图返回无code 是否成功status */
		if (response.data.status === '1') {
			return response
		}
		if (response?.config?.url === '/v3/geocode/geo?parameters' && response?.data?.status === '0') {
			return response
		}
		/**其他状态操作 */
		ElMessage({
			showClose: true,
			message: response.data.msg || decodeURI(response.headers.msg),
			type: 'error',
		})

		return response.data.msg ? response.data : response
	},
	(error) => {
		// 定义一个不可能的初始值（去除Badge
		const repeatNum = -999

		if (isCancel(error)) {
			return {
				code: 0,
				msg: '',
			}
		}
		if (!error?.config?.donNotShowLoading) {
			closeLoading()
		}
		if (error.request?.responseType === 'arraybuffer') {
			return error.response
		}
		if (!error.response) {
			ElMessage({
				showClose: true,
				grouping: true,
				repeatNum,
				message: `-抱歉，系统发生了一些问题，请稍后再试! 
					${error}
					`,
				type: 'error',
				// duration: 0,
			})
			return
		}
		// const errorStatus = error?.response?.status ?? ''
		// switch (errorStatus) {
		// 	case 404:
		// 		ElMessage({
		// 			showClose: true,
		// 			grouping: true,
		// 			repeatNum,
		// 			message: `-抱歉，系统发生了一些问题，请稍后再试!  (${errorStatus})
		// 			`,
		// 			type: 'error',
		// 			// duration: 0,
		// 		})
		// 		break
		// 	case 500:
		// 		ElMessage({
		// 			showClose: true,
		// 			grouping: true,
		// 			repeatNum,
		// 			message: `-抱歉，系统发生了一些问题，请稍后再试!  (${errorStatus})
		// 			`,
		// 			type: 'error',
		// 			// duration: 0,
		// 		})
		// 		break
		// 	case 502:
		// 		ElMessage({
		// 			showClose: true,
		// 			grouping: true,
		// 			repeatNum,
		// 			message: `-抱歉，系统发生了一些问题，请稍后再试!  (${errorStatus})
		// 			`,
		// 			type: 'error',
		// 			// duration: 0,
		// 		})
		// 		break
		// }

		return error
	}
)
export default service
