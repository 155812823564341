import { FlowUserRuleType, ReceiveUserRuleType } from '../components/drawer/components/flowUser/index'
import { FromType, OperatorType } from '@/components/proTable/components/SearchForm/types'
import nodeImage from '@/assets/images/workflow/node.png'
import messageImage from '@/assets/images/workflow/message.png'
import branchImage from '@/assets/images/workflow/branch.png'

/**
 * @description 节点类型
 * @param START 开始 => 1
 * @param APPROVAL 审批 => 2
 * @param BRANCH 分支 => 3
 * @param MESSAGE 消息提醒 => 4
 * @param ROUTER 路由 => 5
 */
export enum NodeType {
	START = 1,
	APPROVAL,
	BRANCH,
	MESSAGE,
	ROUTER,
}

/**
 * @description 审批方式
 * @param COUNTERSIGN 会签 => 1
 * @param ORSIGN 或签 => 2
 */
export enum ApprovalMethodType {
	COUNTERSIGN = 1,
	ORSIGN,
}

/**
 * @description 审批方式中文名称
 * @param COUNTERSIGN 会签 => 1
 * @param ORSIGN 或签 => 2
 */
export const ApprovalMethodTypeText: { [key in ApprovalMethodType]: string } = {
	[ApprovalMethodType.COUNTERSIGN]: '会签',
	[ApprovalMethodType.ORSIGN]: '或签',
}

/**
 * @description 审批人为空时
 * @param Auto 自动审批 => 1
 * @param Assign 指定审批人 => 2
 */
export enum ApprovalNullRuleType {
	Auto = 1,
	Assign,
}

/**
 * @description 编辑字段
 * @param fieldName    字段名称
 * @param value        字段值
 * @param editAuth     是否有编辑权限【1: 是，2: 否】
 * @param requiredAuth 是否必填【1: 是，2: 否】
 */
export interface ApprovalFieldType {
	fieldName: string
	value: string
	editAuth: number
	requiredAuth: number
}

export interface MessageContentType {
	fieldName?: string
	fieldValue?: string
	msgPart?: string
}

/**
 * @description 节点属性配置
 * @param addSign 是否开启加签 0-是 1-否 (默认0-是,开启加签)
 * @param approvalMethod 审批方式 会签: 1, 或签: 2
 * @param approvalAgreeNeedComment 同意审批意见是否必填 1-是 2-否 (审批节点必须)
 * @param approvalRejectNeedComment 拒绝审批意见是否必填 1-是 2-否 (审批节点必须)
 * @param approverIsNullRuleType 审批人为空时 1-自动通过 2-指定人员审批 (审批节点必须)
 * @param approverIsNullDealUser 指定审批人
 * @param copyToRules 审批人规则 (审批节点必须)
 * @param approverRules 抄送规则
 * @param triggeringConditions 触发条件(条件节点必须)
 * @param approvalContent 审批内容
 * @param notifyMessage 消息提醒
 */
export interface NodePropertyType {
	addSign: 0 | 1
	approvalMethod: ApprovalMethodType
	approvalAgreeNeedComment?: number
	approvalRejectNeedComment?: number
	approverIsNullRuleType?: ApprovalNullRuleType
	approverIsNullDealUser?: number
	approverRules?: FlowUserRuleType
	copyToRules?: FlowUserRuleType
	triggeringConditions?: ConditionsType
	approvalContent?: Array<ApprovalFieldType>
	notifyMessage?: ReceiveUserRuleType & {
		contentList: Array<MessageContentType>
	}
}

/**
 * @description 流程节点类型
 * @param name 节点名称
 * @param nodeId 节点ID
 * @param menuId 菜单ID
 * @param prevId 前置节点ID
 * @param type 节点类型
 * @param properties 节点属性配置
 * @param childNode 子节点
 * @param conditionNodes 条件分支子节点
 */
export interface NodeItemType {
	name: string
	nodeId: string
	menuId: number
	prevId: string
	type: NodeType
	properties: NodePropertyType | null
	childNode: NodeItemType
	conditionNodes: NodeItemType[]
}

/**
 * @description 添加节点时
 * @param label 节点名称
 * @param type 节点类型
 * @param bgColor 背景色
 * @param icon 图标
 */
export interface AddNodeType {
	label: string
	type: NodeType
	bgColor: string
	icon: string
}

/**
 * @description 触发方式
 * @param ADD 新增
 * @param EDIT 编辑
 */
export enum TriggerActionType {
	ADD = 1,
	EDIT = 2,
}

/**
 * @description 触发方式中文名
 * @param 新增 => 1
 * @param 编辑 => 2
 */
export const TriggerActionText: { [key in TriggerActionType]: string } = {
	[TriggerActionType.ADD]: '新增',
	[TriggerActionType.EDIT]: '编辑',
}

/**
 * @description 流程状态
 * @param ENABLE 启用 => 1
 * @param ENABLE 停用 => 2
 *
 */
export enum StatusType {
	ENABLE = 1,
	DISABLE = 2,
}

/**
 * @description 流程管理表单类型
 * @param id 审批流id
 * @param name 审批流名称
 * @param status 状态【1: 启用, 2: 停用】
 * @param menuId 菜单ID
 * @param triggerAction 触发方式【1: 新增, 2: 编辑】
 * @param triggeringFieldNames 触发字段
 * @param triggeringConditions 触发条件
 * @param strategy 审批策略
 * @param node 审批节点
 */
export interface FlowFormType {
	id?: number
	name: string
	status: number
	menuId: number
	triggerAction: TriggerActionType
	triggeringFieldNames: Array<string>
	triggeringConditions: ConditionsType
	strategy: number
	node: NodeItemType
}

/**
 * @description 触发条件字段类型
 * @param fieldName 字段名称
 * @param fieldValues 字段值
 * @param fromType 操作类型
 * @param operator 筛选条件
 * @param labelList 中文描述（如人员名称等）
 */
export interface TriggeringFields {
	fieldName: string
	fieldValues: any
	fromType: FromType
	operator: OperatorType
	labelList?: string[]
}

/**
 * 条件配置类型
 */
export type ConditionsType = Array<{ triggeringFields: TriggeringFields[] }>

/**
 * @description 节点移动类型
 * @param LEFT 左移
 * @param LEFTMOST 移至最左
 * @param RIGHT 右移
 * @param RIGHTMOST 移至最右
 */
export enum MoveType {
	LEFT = 'LEFT',
	LEFTMOST = 'LEFTMOST',
	RIGHT = 'RIGHT',
	RIGHTMOST = 'RIGHTMOST',
}

/**
 * 新增节点数据
 */
export const nodeTypeList: Array<AddNodeType> = [
	{ label: '审批', type: NodeType.APPROVAL, bgColor: '#a248f5', icon: nodeImage },
	{ label: '消息提醒', type: NodeType.MESSAGE, bgColor: '#40a9ff', icon: messageImage },
	{ label: '分支', type: NodeType.BRANCH, bgColor: '#faa54a', icon: branchImage },
]
