import service from '@/utils/request'

// 获取渠道客户库存上报记录
export const getDistributorStockList = (data: any) => {
	return service({
		url: '/distributorStock/getDistributorStockList',
		method: 'post',
		data,
	})
}

// 获取渠道客户库存上报详情
export const findDistributorStock = (params: any) => {
	return service({
		url: '/distributorStock/findDistributorStock',
		method: 'get',
		params,
	})
}

// 添加渠道客户库存上报
export const createDistributorStock = (data: any) => {
	return service({
		url: '/distributorStock/createDistributorStock',
		method: 'post',
		data,
	})
}

// 修改渠道客户库存上报
export const updateDistributorStock = (data: any) => {
	return service({
		url: '/distributorStock/updateDistributorStock',
		method: 'put',
		data,
	})
}

// 删除渠道客户库存上报
export const deleteDistributorStockByIds = (data: any) => {
	return service({
		url: '/distributorStock/deleteDistributorStockByIds',
		method: 'delete',
		data,
	})
}

// 提交
export const submitDisStock = (data: any) => {
	return service({
		url: '/distributorStock/submitDistributorStockByIds',
		method: 'put',
		data,
	})
}

// 作废
export const cancelDisStock = (data: any) => {
	return service({
		url: '/distributorStock/cancelDistributorStockByIds',
		method: 'put',
		data,
	})
}

// 获取渠道客户库存明细
export const distributorStockDetails = (data: any) => {
	return service({
		url: '/distributorStockProduct/distributorStockDetails',
		method: 'post',
		data,
	})
}

// 导出常规上报记录
export const exportRuleRecord = (data: any) => {
	return service({
		url: '/distributorStock/getDistributorStockList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 1,
			stockType: 1,
		},
	})
}

// 导出常规上报记录明细
export const exportRuleDetail = (data: any) => {
	return service({
		url: '/distributorStock/getDistributorStockList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 2,
			stockType: 1,
		},
	})
}

// 导出核销上报记录
export const exportCancelRecord = (data: any) => {
	return service({
		url: '/distributorStock/getDistributorStockList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 1,
			stockType: 2,
		},
	})
}

// 导出核销上报记录明细
export const exportCancelDetail = (data: any) => {
	return service({
		url: '/distributorStock/getDistributorStockList',
		method: 'post',
		// responseType: 'blob',
		data,
		params: {
			exportType: 2,
			stockType: 2,
		},
	})
}
