<template>
	<div>
		<div class="draw-modal" v-if="isShowModal" :style="{ 'z-index': modalZIndex }"></div>
		<template
			v-for="(
				{
					key,
					title,
					width,
					fullscreen,
					showClose,
					modalClass,
					buttonList,
					uuid,
					props,
					component,
					isDialog,
					isHiddenBtnList = false,
					levelUpUuid = '',
					isExpand = false,
					...rest
				},
				index
			) in drawerList"
			:key="key + levelUpUuid"
		>
			<div v-if="!isDialog">
				<el-drawer
					:model-value="isShow"
					:show-close="false"
					:modal-class="`draw-modal-bg ${key + uuid}`"
					:size="width"
					destroy-on-close
					@close="drawerClose"
					:close-on-press-escape="false"
					:before-close="(done: () => void) => handleClose(done, index)"
					class="drawerClass"
					ref="drawerRef"
				>
					<template #header>
						<div class="inline-flex items-center">
							<div class="drawer-icon"></div>
							<span class="mr-2">{{ title }}</span>
							<flowStatus :drawerKey="key" />
						</div>
						<div v-if="!isHiddenBtnList" class="flex-1 text-right">
							<AuthButton
								:type="ApplyType.DRAWER"
								:buttonList="buttonList"
								@click="(btn) => handleItemBtn(key, btn, props, rest?.validSelectList)"
							/>
							<!-- 旧逻辑，添加【更多】后修改，后期无问题可删除（24.7.17） -->
							<!-- <div
								v-if="
									buttonList?.length &&
									buttonList?.filter(
										(item) => item.apply?.includes(ApplyType.ALL) || item.apply?.includes(ApplyType.DRAWER)
									)?.length > 0
								"
								class="drawer-operator"
							>
								<template v-for="(item, index) in buttonList" :key="key + index + uuid">
									<el-button
										v-if="item.apply?.includes(ApplyType.ALL) || item.apply?.includes(ApplyType.DRAWER)"
										class="wl-0"
										:type="item.buttonType ? item.buttonType : index === 0 ? 'primary' : ''"
										:plain="!!item.buttonType"
										@click="() => handleItemBtn(key, item, props, rest?.validSelectList)"
									>
										<template v-if="item.icon" #icon>
											<SvgIcon :name="item.icon" />
										</template>
										{{ item.label }}</el-button
									>
								</template>
							</div> -->
						</div>
					</template>

					<!-- 弹窗组件无默认按钮(动态渲染的组件都会给组件传入(rest.props属性和close事件，组件内部可以接受属性或者触发事件))-->
					<SuspenseWithErrorHandling>
						<component :key="key + uuid" :is="component" v-bind="{ ...props, drawerKey: key }" />
						<template #fallback>
							<div></div>
						</template>
					</SuspenseWithErrorHandling>
					<template #footer>
						<div class="max-w-full">
							<flowOperation v-bind="{ ...props, drawerKey: key }" />
						</div>
						<div class="drawer-buttons">
							<div class="screenExpandClass" @click="handleExpandWidth(key)">
								<SvgIcon :name="isExpand ? 'icon-screen-shrink' : 'icon-screen-expand'" class="text-white" :size="16" />
							</div>
						</div>
					</template>
				</el-drawer>
			</div>
			<!-- 弹窗  对导入时 关闭方法做特殊处理，兼容完成导入时，关闭弹窗刷新页面 -->
			<el-dialog
				v-else
				:model-value="isShow"
				:width="width"
				:modal-class="'draw-modal-bg' + (modalClass ? ' ' + modalClass : '')"
				:close-on-click-modal="false"
				@close="handleCancel"
				destroy-on-close
				class="dialogStyle"
				:class="rest.showButton || props.flowData ? '' : 'is-natived'"
				:fullscreen="fullscreen"
				:show-close="showClose"
				ref="dialogRef"
			>
				<template #header="{ close, titleId, titleClass }">
					<div class="flex items-center">
						<Icon class="mr-2" :type="key" />
						<div class="text-base font-medium" style="font-size: 16px" :id="titleId">
							<component v-if="typeof title === 'function'" :is="title" />
							<span v-else>{{ title }}</span>
						</div>
					</div>
				</template>

				<!-- 弹窗组件无默认按钮(动态渲染的组件都会给组件传入(rest.props属性和close事件，组件内部可以接受属性或者触发事件))-->
				<SuspenseWithErrorHandling>
					<template v-if="component || rest.showButton">
						<component
							v-if="component"
							:is="component"
							:key="key + uuid"
							v-bind="getProps(props)"
							@close="handleCancel"
							@refresh="() => handleConfirm(rest?.onConfirm, !rest.showButton)"
							@finally="handleFinally"
						/>
					</template>
					<div v-else>组件加载失败</div>
					<template #fallback>
						<el-skeleton :rows="getRowByWidth(width)" animated />
					</template>
				</SuspenseWithErrorHandling>

				<!-- 弹窗内容 -->
				<template v-if="rest.content">
					<component v-if="typeof rest.content === 'function'" :is="rest.content" />
					<h3 v-else class="ml-8" :class="`text-${rest.contentAlign ?? 'left'}`" v-html="rest.content"></h3>
				</template>
				<template v-if="rest.showButton || props.flowData" #footer>
					<flowOperation
						v-if="props?.flowData"
						style="padding: 0; border: none"
						v-bind="{ ...props, drawerKey: key }"
						:onConfirm="dialogComRef?.onConfirm"
					/>
					<template v-else>
						<span class="flex dialog-footer" :class="`justify-${rest.align ?? 'center'}`" v-if="rest.btnList">
							<template v-for="(button, index) in rest.btnList" :key="index">
								<el-button
									v-if="button.isShow ?? true"
									:type="button.type"
									:text="button?.Text == '取消'"
									@click="
										button?.Text == '取消'
											? handleCancel()
											: handleConfirm(button?.onConfirm, true, props?.type, button?.needClose)
									"
									>{{ button.Text }}</el-button
								>
							</template>
						</span>
						<span class="flex" :class="`justify-${rest.align ?? 'end'}`" v-else>
							<el-button text @click="() => handleCancel()">{{ rest.cancelText ?? '取消' }}</el-button>
							<el-button type="primary" :loading="loading.save" @click="() => handleConfirm(rest?.onConfirm)">{{
								rest.confirmText ?? '确定'
							}}</el-button>
						</span>
					</template>
				</template>
			</el-dialog>
		</template>
	</div>
</template>

<script setup lang="ts">
import { reactive, watch, ref, computed, onUnmounted, toValue } from 'vue'
import { useRoute } from 'vue-router'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { ApplyType } from '@/hooks/usePageDrawer/types'
import { useOperationBtnList, ModalWidthType } from '@/hooks/useOperationBtnList'
import { v4 as uuidv4 } from 'uuid'
import { useDrawer } from '@/pinia/modules/drawer'
import { storeToRefs } from 'pinia'
import AuthButton from '@/components/authButton/index.vue'
import flowOperation from '@/components/flowRecord/operation.vue'
import flowStatus from '@/components/flowRecord/status.vue'
import SuspenseWithErrorHandling from '@/components/SuspenseWithErrorHandling/index.vue'
import Icon from './icon'

const route = useRoute()
const { drawerOpen, drawerClose, clearDrawerMap } = usePageDrawer()
const { selectCommonBtnTypeFn } = useOperationBtnList()

const DEFAULT_ZINDEX = 1200
const zindex = ref<number>(DEFAULT_ZINDEX)
// 处理第一次抽屉展开渲染时机导致 display：none 问题
const isShow = ref(false)

// 是否展示自定义遮罩层
const isShowModal = ref(false)
const modalZIndex = ref(0)
// 标识完成状态
const finallyStatus = ref(false)
const loading = reactive<{ [key: string]: boolean }>({
	save: false,
})
const drawerStore = useDrawer()
const { drawerMap } = storeToRefs(drawerStore)
const dialogComRef = ref()
const drawerRef = ref()
const dialogRef = ref()
// 监听抽屉data长度，提高蒙层的z-index

const drawerList = computed(() => {
	return [...drawerMap.value?.entries()].map(([key, value]) => {
		console.log(`output->key, value`, key, value)
		return {
			key,
			...value,
		}
	})
})

watch(
	() => route.fullPath,
	() => {
		if (drawerMap.value.size > 0) {
			drawerMap.value?.clear()
		}
	}
)

/**
 *  点击操作按钮事件
 * pageType 抽屉类型
 * item 当前按钮数据
 * props 抽屉参数
 * selectList 选中对象（抽屉默认选中项就是当前抽屉唯一值）
 */
const handleItemBtn = async (pageType, item, props, selectList: false | [] = false) => {
	let validSelectList = []
	// 主动赋值选中项
	if (selectList) {
		validSelectList = selectList ?? []
	} else {
		// 抽屉情景 是必有ID项
		validSelectList = [{ title: '抽屉情景-占位值' }]
	}
	const selectLength: number = validSelectList?.length || 0 // 勾选长度

	const currentBtn = selectCommonBtnTypeFn(item.type)

	const defaultPrevValidateFn = item?.defaultPrevValidate

	// 校验-1.校验可操作数量
	let valid: boolean =
		(defaultPrevValidateFn ? defaultPrevValidateFn() : currentBtn?.defaultPrevValidate?.(selectLength, item.label)) ??
		true
	if (!valid) return
	// 校验-2.校验自定义前置事件
	if (item?.preOpenValidation) {
		valid = (await currentBtn?.preOpenValidation?.(item?.preOpenValidation)) ?? true
	}
	if (!valid) return
	// 保存每个父子阶段传递的props
	item.props = item?.props ?? {}
	Object.assign(item.props, props)

	// 接下来渲染的是弹窗组件
	// pageType就是当前抽屉组件的唯一标志
	// 将当前抽屉的唯一标志加按钮类型来标志当前要在这个抽屉里渲染的弹窗标志
	const drawerInfo = {
		title: item.title || item.label,
		isDialog: true,
		...item,
	}
	console.log(drawerInfo, '我是否来到抽屉点击1111')
	drawerOpen(`${pageType}${item.type}`, drawerInfo)
}

// 用于解决 自定义遮罩层关闭延迟问题
const handleClose = (done: () => void, index: number) => {
	done()
	isShowModal.value = index === 0 ? false : true
}

// 弹窗 - 取消
const handleCancel = (isOk = false) => {
	const status = isOk || finallyStatus.value
	drawerClose(status)
	// 重置主动关闭
	finallyStatus.value = false
	loading.save = false
}

// 弹窗 - 确定
const handleConfirm = async (
	onConfirm: (Operationtype?: any) => Promise<any>,
	refresh = true,
	Operationtype?: String,
	needClose = true
) => {
	console.log(`output->onConfirm`, onConfirm)
	if (onConfirm) {
		// 增加try catch 用于onConfirm报错 可继续操作
		try {
			loading.save = true
			await onConfirm(Operationtype)
			loading.save = false
			// 判断是否执行完onConfirm后立即关闭弹窗(默认true)
			if (needClose) {
				handleCancel(refresh)
			}
		} catch (error) {
			// 发生错误时候, 不关闭弹窗
			console.log(`output->主要用于校验弹窗表单问题`, error)
			loading.save = false
		}
		return
	} else {
		handleCancel(refresh)
	}
}
const handleFinally = (value) => {
	if (value === true) {
		finallyStatus.value = value
	}
}
/**获取数组形式最顶层弹窗z-index值 */
const getZIndex = (element: Ref<HTMLElement | null>, type = 'drawer') => {
	if (Array.isArray(element?.value)) {
		const lastElement = element.value[element.value?.length - 1]

		if (type === 'drawer') {
			return parseInt(lastElement?.zIndex || '0', 10)
		}
		// dialog ref暴露出来的是子节点，导致获取不到z-index属性
		const lastDialog = lastElement?.dialogContentRef?.$el?.parentElement?.parentElement?.style?.zIndex ?? '0'

		return parseInt(lastDialog || '0', 10)
	}
	return 0
}

const checkZIndex = () => {
	const drawerZIndex = getZIndex(drawerRef, 'drawer')
	const dialogZIndex = getZIndex(dialogRef, 'dialog')
	modalZIndex.value = drawerZIndex > dialogZIndex ? drawerZIndex : dialogZIndex
}

watch(
	drawerMap,
	async (val) => {
		isShow.value = true
		isShowModal.value = val.size > 0
		// 等待ref挂载完成
		await nextTick()
		checkZIndex()
	},
	{
		deep: true,
		immediate: true,
	}
)

// 根据动态组件宽度计算出骨架屏高度
const getRowByWidth = (width: any): number => {
	switch (width) {
		case ModalWidthType.PERCENTAGE20:
		case ModalWidthType.PERCENTAGE30:
			return 3
		case ModalWidthType.PERCENTAGE40:
			return 6
		case ModalWidthType.PERCENTAGE60:
			return 10
		default:
			return 10
	}
}

const handleExpandWidth = (key) => {
	const value = drawerMap.value.get(key)
	// 保存初始值，用于收起时恢复
	if (!value.initWidth) {
		value.initWidth = value.width
	}
	value.isExpand = value.isExpand ? false : true
	drawerMap.value.set(key, {
		...value,
		width: value.isExpand ? ModalWidthType.PERCENTAGE85 : value.initWidth,
	})

	// const currentItem = drawerList.value.find((item) => item.key === key)
	// console.log(`output->currentItem`, currentItem)
	// currentItem.width = ModalWidthType.PERCENTAGE100
}

const getProps = (props) => {
	if (props.ref) {
		return props
	}
	return { ...props, ref: dialogComRef }
}

onUnmounted(() => {
	finallyStatus.value = false
	loading.save = false
	clearDrawerMap()
})
</script>

<style lang="scss" scoped>
:deep(.el-drawer.drawerClass) {
	min-width: 750px !important;
}
:deep(.el-drawer) {
	overflow: visible !important;

	.el-drawer__header {
		height: 66px;
		margin-bottom: 0;
		padding: 0 16px;
		border-bottom: 1px solid theme('colors.line.1');
		font-weight: 600;
		color: theme('colors.gray.700');

		.drawer-icon {
			width: 24px;
			height: 24px;
			margin-right: 4px;
			background-image: url('@/assets/modal_title.png');
			background-size: 100%;
			background-repeat: no-repeat;
		}
	}
	.el-drawer__body {
		overflow-x: hidden !important;
	}
	.el-drawer__footer {
		padding: 0;
		.drawer-buttons {
			position: absolute;
			left: -44px;
			top: 66px;
			display: flex;
			flex-direction: column;
			gap: theme('spacing.sm');

			.screenExpandClass {
				width: 44px;
				height: 42px;
				line-height: 42px;
				text-align: center;
				background: theme('colors.primary');
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: theme('borderRadius.md') 0 0 theme('borderRadius.md');
				cursor: pointer;
			}
		}
	}
}

.draw-modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	background: rgba(0, 0, 0, 0.5);
	z-index: v-bind(zindex);
	transition: all ease-in-out;
}

:deep(.draw-modal-bg) {
	transition: all 0.4s ease-out;
	background: transparent !important;
}
</style>
