<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
	<el-config-provider :locale="locale">
		<router-view />
		<Drawer />
	</el-config-provider>
</template>

<script lang="ts" setup name="App">
import { ElConfigProvider } from 'element-plus'
// eslint-disable-next-line import/no-unresolved
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Drawer from '@/components/Drawer/index.vue'
import { useEventTracking } from '@/hooks/useEventTracking'

const weeks = {
	mon: '周一',
	tue: '周二',
	wed: '周三',
	thu: '周四',
	fri: '周五',
	sat: '周六',
	sun: '周日',
}

const locale = { ...zhCn }
locale.el.datepicker.weeks = weeks
const { betchUpdateEventTracking, betchSendBeaconUpdate } = useEventTracking()

// 数据埋点 使用visibilitychange 来检测页面的可见性状态变化。当用户锁屏时，页面通常会变得不可见。
document.addEventListener('visibilitychange', function () {
	if (document.hidden) {
		// 页面隐藏时使用 sendBeacon 进行上报
		betchSendBeaconUpdate?.()
	} else {
		// 页面显示时全量进行上报
		betchUpdateEventTracking?.({ isForce: true })
	}
})

// 数据埋点 使用 navigator.sendBeacon 在页面卸载时发送数据
window.addEventListener('beforeunload', () => {
	betchSendBeaconUpdate?.()
})
</script>

<style lang="scss">
@import '@/style/main.scss';

#app {
	@apply bg-gray-200;
	height: 100vh;
	width: 100vw;
	overflow-y: hidden;
}

// 临时处理富文本下拉项层级过低问题
.tox-tinymce-aux {
	z-index: 3000 !important;

	.tox-dialog-wrap {
		.tox-dialog {
			width: 21.08cm;
			height: auto;
		}
	}
}
</style>
