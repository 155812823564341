/*
 * @Author: yoler 979700942@qq.com
 * @LastEditors: zouliang 535841308@qq.com
 * @Date: 2023-12-08 13:42:49
 * @FilePath: \digitizing_web\src\hooks\useWebSocket\types.ts
 * @Description:
 */
/**
 * @description websocket交互类型
 * MessageTypeNotify | 1-通知消息
 * MessageTypeApproval | 2-审批消息
 * MessageTypeWorkOrder | 3-工单消息
 * MessageTypeOperationRecord | 4-操作记录
 * MessageTypeSyncDownloadResultMsgRsp | 6-下载响应
 * MessageTypeNotifySetRead | 9-设置消息为已读
 * MessageTypeNotifyReadStatus | 10-已读消息通知
 * MessagetypeAnnouncement | 14 通知公告
 * MessageTypeAiResult  | 15 AI识别结果
 * MessageTypeWebUpgradeNotify | 16 Web端升级通知
 * MessageTypeBudgetarySummary | 17 预算汇总表 | 销售汇总表 | 经销商月度总结表通知更新
 * MessageTypeNotifyDelete | 18-设置消息为已删除
 */
export enum WebSocketMessageType {
	MessageTypeNotify = 'MessageTypeNotify',
	MessageTypeApproval = 'MessageTypeApproval',
	MessageTypeWorkOrder = 'MessageTypeWorkOrder',
	MessageTypeOperationRecord = 'MessageTypeOperationRecord',
	MessageTypeSyncDownloadResultMsgRsp = 'MessageTypeSyncDownloadResultMsgRsp',
	MessageTypeNotifySetRead = 'MessageTypeNotifySetRead',
	MessageTypeNotifyReadStatus = 'MessageTypeNotifyReadStatus',
	MessagetypeAnnouncement = 'MessagetypeAnnouncement',
	MessageTypeAiResult = 'MessageTypeAiResult',
	MessageTypeWebUpgradeNotify = 'MessageTypeWebUpgradeNotify',
	MessageTypeBudgetarySummary = 'MessageTypeBudgetarySummary',
	MessageTypeNotifyDelete = 'MessageTypeNotifyDelete',
}
