import { asyncRouterHandle } from '@/utils/asyncRouter'
import { emitter, MittType } from '@/utils/bus'
import { asyncMenu } from '@/api/menu'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const notLayoutRouterArr = []
const keepAliveRoutersArr = []
const nameMap = {}

const formatRouter = (routes, routeMap) => {
	routes &&
		routes.forEach((item) => {
			item.meta.btns = item.btns
			item.meta.hidden = item.hidden
			if (item.meta.defaultMenu === true) {
				notLayoutRouterArr.push({
					...item,
					path: `/${item.path}`,
				})
			} else {
				routeMap[item.name] = item
				if (item.children && item.children.length > 0) {
					formatRouter(item.children, routeMap)
				}
			}
		})
}

const KeepAliveFilter = (routes) => {
	routes &&
		routes.forEach((item) => {
			// 子菜单中有 keep-alive 的，父菜单也必须 keep-alive，否则无效。这里将子菜单中有 keep-alive 的父菜单也加入。
			if ((item.children && item.children.some((ch) => ch.meta.keepAlive)) || item.meta.keepAlive) {
				item.component &&
					item.component().then((val) => {
						keepAliveRoutersArr.push(val.default.name)
						nameMap[item.name] = val.default.name
					})
			}
			if (item.children && item.children.length > 0) {
				KeepAliveFilter(item.children)
			}
		})
}

export const useRouterStore = defineStore('router', () => {
	const keepAliveRouters = ref([])
	const asyncRouterFlag = ref(0)
	const setKeepAliveRouters = (history) => {
		const keepArrTemp = []
		history.forEach((item) => {
			if (nameMap[item.name]) {
				keepArrTemp.push(nameMap[item.name])
			}
		})
		keepAliveRouters.value = Array.from(new Set(keepArrTemp))
	}
	emitter.on(MittType.SetKeepAlive, setKeepAliveRouters)

	const asyncRouters = ref([])
	const routeMap = {}
	// 从后台获取动态路由
	const SetAsyncRouter = async () => {
		asyncRouterFlag.value++
		const baseRouter = [
			{
				path: '/layout',
				name: 'layout',
				component: 'view/layout/index.vue',
				meta: {
					title: '底层layout',
				},
				children: [],
			},
		]
		const asyncRouterRes = await asyncMenu()
		const asyncRouter = asyncRouterRes?.data?.menus ?? []
		asyncRouter &&
			asyncRouter.push({
				path: 'reload',
				name: 'Reload',
				hidden: true,
				meta: {
					title: '',
					closeTab: true,
				},
				component: 'view/error/reload.vue',
			})
		formatRouter(asyncRouter, routeMap)
		baseRouter[0].children = asyncRouter
		if (notLayoutRouterArr.length !== 0) {
			baseRouter.push(...notLayoutRouterArr)
		}
		asyncRouterHandle(baseRouter)
		KeepAliveFilter(asyncRouter)

		asyncRouters.value = baseRouter
		console.log(asyncRouters, '处理后的router列表----')
		console.log(routeMap, 'routeMap列表==')
		return true
	}
	// 清除当前职位账号的权限路由
	const clearAsyncRouters = () => {
		// asyncRouters.value = []
		asyncRouters.value[0].children = []
		keepAliveRouters.value = []
		asyncRouterFlag.value = 0
	}
	return {
		asyncRouters,
		keepAliveRouters,
		asyncRouterFlag,
		SetAsyncRouter,
		routeMap,
		clearAsyncRouters,
	}
})
