<template>
	<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px">
		<el-form-item label="抄送人" prop="userIds">
			<pick-user v-model="ruleForm.userIds" :isAuth="false" :unSelectable="[userInfo?.ID]" :uniqueUser="false" />
		</el-form-item>
		<el-form-item label="备注(选填)" prop="reason">
			<XZLInput v-model="ruleForm.reason" type="textarea" rows="3" show-word-limit maxlength="200" />
		</el-form-item>
	</el-form>

	<div class="text-right">
		<el-button text @click="emit('close')">取消</el-button>
		<el-button type="primary" :loading="loading" @click="handleConfirm">确定</el-button>
	</div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { copyTo } from '@/api/serveApi/workflow/workflowOperation'

interface Props {
	code: string
	userInfo: any
}

const props = defineProps<Props>()

const emit = defineEmits(['refresh', 'close'])

interface RuleForm {
	userIds: number[]
	reason: string
}

const loading = ref<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
	userIds: [],
	reason: '',
})
const rules = reactive<FormRules>({
	userIds: [{ required: true, message: '请选择抄送人', trigger: 'change' }],
})

const handleConfirm = async () => {
	await ruleFormRef.value?.validate(async (valid) => {
		if (valid) {
			loading.value = true
			const res = await copyTo({
				...ruleForm,
				approvalRequestCode: props.code,
			})
			if (res && res.code === 0) {
				ElMessage.success(res.msg || '抄送成功')
				emit('refresh')
				emit('close')
			}
			loading.value = false
		}
	})
}
</script>
