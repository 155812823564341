// 全局公共指令
export default {
	install: (app) => {
		// 限制输入值为整数，并且最多保留两位小数
		app.directive('limit-input', {
			mounted(el) {
				el.addEventListener('input', (event) => {
					const value = event.target.value
					// 将输入值转换为数字类型
					let temp: string | number = parseFloat(value)
					if (isNaN(temp)) {
						temp = ''
					} else {
						const pattern = /([1-9]+[\d]*(\.[0-9]{0,2})?)/
						const match = value.match(pattern)
						if (match) {
							temp = value.replace(value, match[0])
						} else {
							temp = ''
						}
					}
					event.target.value = temp
					event.target.dispatchEvent(new Event('input')) // 更新v-model绑定的值
				})

				// 使用 onBlur 事件处理值为空的情况
				el.addEventListener('blur', (event) => {
					if (event.target.value === '-') {
						event.target.value = ''
					}
				})
			},
		})
		app.directive('limit-amount', {
			mounted(el, binding) {
				function handleInput(event) {
					const inputValue = event.target.value.replace(/,/g, '').split('.')
					const integerPart = inputValue[0] || ''
					const decimalPart = inputValue[1] ? inputValue[1]?.slice(0, 2) : ''
					if (integerPart.length > 12) {
						event.target.value = decimalPart ? integerPart.slice(0, 12) + '.' + decimalPart : integerPart.slice(0, 12)
					}
				}
				function handleChange(event) {
					// 这里可以根据需要添加其他逻辑，比如验证最终值等
				}

				el.addEventListener('input', handleInput)
				el.addEventListener('change', handleInput)

				// 保存事件处理函数引用，以便稍后移除
				el._limitAmountInputHandler = handleInput
				el._limitAmountChangeHandler = handleChange
			},
			unmounted(el) {
				if (el && el._limitAmountInputHandler && el._limitAmountChangeHandler) {
					el.removeEventListener('input', el._limitAmountInputHandler)
					el.removeEventListener('change', el._limitAmountChangeHandler)
					// 清除保存的事件处理函数引用
					el._limitAmountInputHandler = null
					el._limitAmountChangeHandler = null
				}
			},
		})
	},
}
