import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import service from '@/utils/request'
import htmlPdf from '@/utils/htmlToPdf'
import { getFullPath } from '@/utils/common'
import { fetchGetDynamicUrl } from '@/api/serveApi/common/common'
import { ElMessage } from 'element-plus'
export const downloadImage = (imgsrc, name) => {
	// 下载图片地址和图片名
	var image = new Image()
	image.setAttribute('crossOrigin', 'anonymous')
	image.onload = function () {
		var canvas = document.createElement('canvas')
		canvas.width = image.width
		canvas.height = image.height
		var context = canvas.getContext('2d')
		context.drawImage(image, 0, 0, image.width, image.height)
		var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

		var a = document.createElement('a') // 生成一个a元素
		var event = new MouseEvent('click') // 创建一个单击事件
		a.download = name || 'photo' // 设置图片名称
		a.href = url // 将生成的URL设置为a.href属性
		a.dispatchEvent(event) // 触发a的单击事件
	}
	image.src = imgsrc
}

/**
 * @description 当您单击 <a> 元素时，浏览器会开始下载文件。在下载文件期间，浏览器的主线程可能会被占用，这可能会影响到其他并行运行的任务，包括 WebSocket 连接。
 * 处理下载与websocket冲突
 * */
export const downloadUrl = (url, name, type) => {
	let link = document.createElement('a')
	link.download = name //下载后的文件名称
	link.href = getFullPath(url, type)
	document.body.appendChild(link)
	link.setAttribute('download', name) // 设置 download 属性
	link.target = '_blank' // 强制在新标签页中打开
	link.click()
	// const newWindow = window.open(link.href, '_blank')
	// newWindow.focus()
	document.body.removeChild(link) // 下载完成移除元素
}
// 获取文件流
export const getFile = (url) => {
	return new Promise((resolve, reject) => {
		service({
			url,
			responseType: 'arraybuffer',
		})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.toString())
			})
	})
}

/**处理重名文件 */
const repeatNamehandlingFn = (fileName) => {
	const lastDotIndex = fileName.lastIndexOf('.')
	const frontPart = fileName.slice(0, lastDotIndex)
	const afterLastDot = fileName.slice(lastDotIndex + 1)
	return frontPart + '(1).' + afterLastDot
}

// zip压缩 基本规范进行统一
const zipRules = async (callback, { zipName = '' }) => {
	var zip = new JSZip()
	// promise对象
	const promises = []
	const options = {
		errorMsgList: [],
	}
	callback(promises, zip, options)

	// 异步处理所有资源请求交互
	await Promise.all(promises).then(() => {
		zip.generateAsync({ type: 'blob' }).then(function (content) {
			if (options.errorMsgList?.length) {
				ElMessage({
					message: `${options.errorMsgList?.toString()}合同文件下载失败，请重新下载！`,
					duration: 0,
					type: 'error',
					showClose: true,
				})
			}
			// see FileSaver.js
			saveAs(content, `${zipName}.zip`)
		})
	})
}

/**html转pdf */
export const downloadHtmlToPdf = async (url, name) => {
	if (!url) return
	const fullUrl = getFullPath(url)
	//eg edit.html
	let fileName = url.split('/').pop()
	// 可自定义名称
	fileName = name || fileName.split('.')?.[0]
	await fetchGetDynamicUrl(fullUrl).then(async (res) => {
		await htmlPdf.getPdf(fileName, res)
	})
}

/**
 * @description 打包成zip形式下载文件
 * */
export const downloadZip = async (urlList, { urlName, zipName = '文件压缩包', fileNameKey = '', htmlName = '' }) => {
	if (urlList?.length === 0) return
	/**添加文件形式 */
	const addfile = async (promises, zip, options) => {
		const repeatNameMap = new Map()
		// 遍历获取网络资源
		urlList.map(async (item) => {
			let url = urlName ? getFullPath(item[urlName]) : getFullPath(item)
			// 生成promise
			let promise
			let fileName
			// 如果是html前端转换成pdf
			if (url.includes('.html')) {
				promise = fetchGetDynamicUrl(url).then(async (res) => {
					//eg edit.html
					fileName = url.split('/').pop()
					// 可自定义名称
					fileName = item?.[fileNameKey] || htmlName || fileName.split('.html')?.[0]
					try {
						const pdf = await htmlPdf.getPdf(fileName, res, true)
						zip.file(fileName + '.pdf', pdf)
					} catch (error) {
						options.errorMsgList.push(`${item.name}`)
					}
				})
			} else {
				if (!url) return
				promise = getFile(url).then((res) => {
					fileName = url.split('/').pop()
					// 更改重名文件
					if (repeatNameMap.has(fileName)) {
						fileName = repeatNamehandlingFn(fileName)
						zip.file(fileName, res)
					} else {
						repeatNameMap.set(fileName, true)
						zip.file(fileName, res)
					}
				})
			}

			promises.push(promise)
		})
	}

	await zipRules(
		(promises, zip, options) => {
			addfile(promises, zip, options)
		},
		{
			zipName,
		}
	)
}

/**
 * @description 打包成多目录zip形式下载文件
 * @params urlName 指定url 字段key
 * @params zipName 文件夹名称
 * @params htmlName 单个html自定义名称
 * @params renameList 重命名url的列表（命中的url可自定义修改name）
 * */
export const downloadFolderZip = async (
	urlList,
	{ urlName, zipName = '文件压缩包', htmlName = '', renameList = [] }
) => {
	if (urlList?.length === 0) return
	/**添加文件目录形式 */
	const addfileDirectory = async (promises, zip, options) => {
		/**
		 * 遍历获取网络资源
		 * 1.直接下载至zip
		 * 2.html转pdf再下载至zip
		 */
		urlList.map(async (item) => {
			const folder = zip.folder(item.name)
			const repeatNameMap = new Map()
			item.list?.map((val) => {
				// 初始文件路径
				const initUrl = urlName ? val[urlName] : val
				let url = getFullPath(initUrl)
				let fileName
				// 生成promise
				let promise
				// 如果是html前端转换成pdf
				if (url.includes('.html')) {
					promise = fetchGetDynamicUrl(url).then(async (res) => {
						//eg edit.html
						fileName = url.split('/').pop()
						// 可自定义名称
						fileName = htmlName || fileName.split('.html')?.[0]
						try {
							const pdf = await htmlPdf.getPdf(fileName, res, true)
							folder.file(fileName + '.pdf', pdf)
						} catch (error) {
							options.errorMsgList.push(`${item.name}`)
						}
					})
				} else {
					if (!url) return
					promise = getFile(url).then((res) => {
						fileName = url.split('/').pop()
						// 如果有命中重命名的url，就使用对应自定义name
						const item = renameList.find((item) => item.url === initUrl)
						if (item?.name) {
							const splitFileNameList = fileName.split('.')
							fileName = item.name + '.' + splitFileNameList?.[splitFileNameList?.length - 1]
						}
						// 更改重名文件
						if (repeatNameMap.has(fileName)) {
							fileName = repeatNamehandlingFn(fileName)
							folder.file(fileName, res)
						} else {
							repeatNameMap.set(fileName, true)
							folder.file(fileName, res)
						}
					})
				}

				promises.push(promise)
			})
		})
	}

	await zipRules(
		(promises, zip, options) => {
			addfileDirectory(promises, zip, options)
		},
		{
			zipName,
		}
	)
}

/**截取html中重要代码片段 */
export const htmlToDomFragment = async (htmlUrl) => {
	if (!htmlUrl) return ''
	const url = getFullPath(htmlUrl)
	const res = await getFile(url)

	if (!res) return

	// 将 ArrayBuffer 转换为 Uint8Array
	const uint8Array = new Uint8Array(res)

	// 将 Uint8Array 转换为字符串
	const decoder = new TextDecoder('utf-8')
	const html = decoder?.decode(uint8Array) ?? ''

	const parser = new DOMParser()
	const doc = parser.parseFromString(html, 'text/html')
	const bodyContent = doc?.body?.innerHTML

	return bodyContent ?? ''
}
