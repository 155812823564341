<template>
	<div v-loading="isLoading">
		<el-tabs v-model="activeName">
			<el-tab-pane label="基本信息" name="basic"> </el-tab-pane>
			<el-tab-pane label="上报明细" name="record"> </el-tab-pane>
		</el-tabs>
		<DetailsTemplate
			v-show="activeName === 'basic'"
			:list="[
				{
					list: [
						{ label: '上报记录编号', value: formData.stockRecordCode },
						{
							label: '渠道客户编号',
							value: formData.code,
							viewConfig: {
								type: 'link',
								link: {
									func: () => {
										drawerOpen(DrawerType.DEALERMGR, {
											title: formData.name,
											props: {
												rowId: formData.distributorId,
											},
											selectList: [{ ...formData, ID: formData.distributorId }],
										})
									},
								},
							},
						},
						{ label: '渠道客户名称', value: formData.name },
						{ label: '渠道客户类型', value: suppliers.find((item) => item.value === formData.channel)?.label },
						{ label: '状态', value: statusList.find((item) => item.id === formData.status)?.name },
						{ label: '操作人', value: formData.operatorName },
						{ label: '上报时间', value: formatDate(formData.CreatedAt, DateType.YMD_HMS) },
						{
							label: '库存照片',
							value: formData.stockImg ? JSON.parse(formData.stockImg) : [],
							viewConfig: {
								type: 'image',
								image: {
									imageSize: 76,
								},
							},
						},
						{ label: '核销年月', value: formData.verifyYears, hidden: formData.stockType === 1 },
						{ label: '', value: '', hidden: formData.stockType === 1 },
						{ label: '作废原因', value: formData.discardDesc, col: 2 },
						{ label: '上报备注', value: formData.remark, col: 2 },
					],
				},
			]"
		/>
		<ReportDetails v-show="activeName === 'record'" :tableData="tableData" :formData="formData" />
	</div>
</template>

<script setup lang="tsx">
import { ref } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { formatDate, DateType } from '@/utils/format'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { suppliers } from '@/config/Constantconfig'
import { statusList } from '../../config'
import { ChannelInventoryRecord, Warehouse } from '../../types'
import ReportDetails from './reportDetails.vue'
import { getWarehouseList } from '@/api/serveApi/basicInformation/dealerMgr'
import { findDistributorStock } from '@/api/serveApi/inventoryManagement/channelInventoryRecord'

const props = defineProps<{
	rowId: number
}>()

const { drawerOpen } = usePageDrawer()

const formData = ref<ChannelInventoryRecord.ResInfo>({} as ChannelInventoryRecord.ResInfo)
const tableData = ref([])
const warehouseList = ref<Warehouse.WarehouseList[]>([] as Array<Warehouse.WarehouseList>)
const activeName = ref<string>('basic')

const { isLoading } = useAsyncState(
	findDistributorStock({ ID: props.rowId }),
	{ code: 0, data: null },
	{
		onSuccess: (res) => {
			if (res && res.code === 0 && res.data) {
				formData.value = res.data
				getWarehouseData(0, { id: formData.value.distributorId })
			}
		},
	}
)

// 获取仓库列表
const { execute: getWarehouseData } = useAsyncState(
	(arg) => getWarehouseList(arg),
	{ code: 0, data: [] },
	{
		immediate: false,
		onSuccess: (res) => {
			if (res && res.code === 0) {
				warehouseList.value = res.data ?? []
				initTableData()
			}
		},
	}
)

// 初始化上报明细
const initTableData = () => {
	// 表格
	tableData.value = formData.value.productDetails ? JSON.parse(formData.value.productDetails) : []
	tableData.value.forEach((item) => {
		const proInfo = formData.value.stockProductList?.find((el) => el.productId === item.productId)
		if (proInfo) {
			Object.assign(item, proInfo)
		}
	})

	// 所选仓库
	const ids = tableData.value[0]?.stockDetailsList?.map((item) => item.warehouseId)
	formData.value.warehouses = warehouseList.value.filter((item) => ids.includes(item.ID))
}
</script>
