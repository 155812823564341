// 将数据转化为百分数(data传入的数据, number,精确位数)
import { multiply } from '@/utils/bigNumber'
import { toValue } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
export const getPercentNumber = (data, number, isNeedMultip = true) => {
	if (isNeedMultip) {
		return multiply(data, 100).toFixed(number) + '%'
	} else {
		return data.toFixed(number) + '%'
	}
}
// 设置级联数据的可编辑数据状态
// 设置数据状态(禁用状态) (data: 传入的级联数据, statusName:状态名字key(默认status), 值为1启用，2禁用; childrenName: 下级key:默认children)
export const setSelectStatus = (data = [], statusName = 'status', childrenName = 'children') => {
	// data: [], statusName: String, childrenName: String
	data?.forEach((item, index) => {
		item.disabled = item[statusName] == 1 ? false : true
		if (item[childrenName] && Array.isArray(item[childrenName]) && item[childrenName].length !== 0) {
			setSelectStatus(item[childrenName], statusName, childrenName)
		}
	})
	return data
}

// 处理接口以rowId 为参数的(数组(数组中只有一个成员)和单个值问题)
// 返回的rowId是否是数组 isArray
export const formateRowId = (rowId, isArray = true) => {
	const toValueRowId = toValue(rowId)
	const returnArray = (id) => {
		if (Array.isArray(id)) {
			return id
		} else {
			return [id]
		}
	}
	const returnId = (id) => {
		if (Array.isArray(id)) {
			return id[0]
		} else {
			return id
		}
	}
	if (isArray) {
		return returnArray(toValueRowId)
	} else {
		return returnId(toValueRowId)
	}
}

// 级联数据返回名字数组格式
// 根据当前id递归查询
const findId = (ID, data, prop, findArr, childrenProp) => {
	data?.find((item) => {
		if (item[prop] == ID) {
			// 找到了
			findArr.push(item)
			return true
		} else {
			if (item[childrenProp]) {
				findId(ID, item[childrenProp], prop, findArr, childrenProp)
			} else {
				// 都没找到
				return false
			}
		}
	})
}
// 回显数据
/**
 * @param editorData 需要回显的数据(或者传入的数据)
 * @param data 所有的数据
 * @param prop 需要处理的属性
 */
export const findSelectData = (editorData, data, prop, childrenProp) => {
	const findArr = []
	editorData?.forEach((item) => {
		findId(item, data, prop, findArr, childrenProp)
	})
	return findArr
}

/**
 * (我们经常遇到(状态为1, 2 true, false undefined 交织的判断), 导致业务页面判断这些状态的逻辑过于复杂)
 * @param data // 传入的状态数据
 * @param decineUndefinedStatus // 当data是undefined时候, 你想想返回什么状态 true or false (默认将返回false)
 */

export const getDataStatus = (data, decineUndefinedStatus = false) => {
	if (data === undefined) {
		return decineUndefinedStatus
	}
	return data === true || data == 1
}

/**
 * 将数据处理为千分位显示
 */

export const formateToThousandthData = (num) => {
	let numValue = null
	// 数字类型
	if (typeof num === 'number') {
		numValue = num
	}
	// 字符串类型
	if (num && typeof num === 'string') {
		numValue = Number(num)
	}
	if (typeof numValue !== 'object') {
		return new Intl.NumberFormat('zh', {
			currency: 'CNY',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(numValue)
	}
}

/**
 * 取消千分位得到最初数值
 */
export const resetFormateToThousandthData = (value) => {
	let clearNum = null
	if (typeof value === 'string' && value?.includes(',')) {
		// 代表是千分值
		const replaceNum = value?.replace(/[^\d.-]/g, '')?.replace(/,/g, '')
		clearNum = parseFloat(replaceNum)
	}
	// 数字类型
	if (typeof value === 'number') {
		clearNum = value
	}
	// 字符串类型
	if (value && typeof value === 'string' && !value?.includes(',')) {
		clearNum = Number(value)
	}
	if (typeof clearNum !== 'object') {
		return clearNum
	} else {
		return 0
	}
}

/**
 * 
 * @param {*} query {
 * 		帅选key:fromType/operator: 值
		'promotion_declare.write_off_status:select/IN': 1,
		'promotion_declare.status:select/IN': 1,
		'promotion_policy.policy_no:text/LIKE': row?.policyNo,
	},
 * @returns 
 */
// 路由条状传递参数
export const getFilters = (query = route?.query) => {
	if (!query) return []
	const filters = []
	// 设置过滤值的类型(string | number)
	const formateValue = (type, value) => {
		const operationType = {
			text: value,
			select: Number(value),
		}
		return operationType[type]
	}
	for (let key in query) {
		const filterKey = key?.split(':')
		const filterOther = filterKey?.[1]?.split('/')
		const filterValue = Array.isArray(query[key])
			? query[key]?.map((item) => formateValue(filterOther[0], item))
			: [formateValue(filterOther[0], query[key])]
		const filterObject = {
			fieldName: filterKey[0],
			fieldValues: filterValue,
			fromType: filterOther[0],
			operator: filterOther[1],
			isExposed: true,
		}
		filters?.push(filterObject)
	}
	return filters
}

// 清除列表页面缓存的搜索参数 可重新设置缓存搜索参数
export const clearPageSearchParams = ({
	pageId = '/layout/promotionPolicy/promotionSalesReport?type=promotion',
	filters,
}) => {
	const pagesArr = JSON.parse(sessionStorage.getItem('pages')) || []
	const newPageArr = pagesArr?.filter((item) => pageId !== item?.pageId) ?? []
	filters &&
		newPageArr?.push({
			pageId: pageId,
			value: filters,
		})
	sessionStorage.setItem('pages', JSON.stringify(newPageArr))
	console.log(JSON.parse(sessionStorage.getItem('pages')), 'sdffffffffffffff')
}

// 设置列表合计值(需要在列表最后一个数据中添加 summaryValue 属性 值为后端返回的数据)
export const listSumMethod = (params) => {
	const { columns, data } = params
	const summaryData = data[data?.length - 1]
	const sums = []
	columns.forEach((clomn, index) => {
		if (clomn.type == 'selection') {
			sums[index] = '合计值'
		} else {
			sums[index] = formateToThousandthData(summaryData?.summaryValue[clomn.property])
		}
	})
	return sums
}
